import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    position: 'sticky',
    paddingTop: theme.spacing(2),
    top: theme.spacing(2),
  },
  nameHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // @ts-ignore
    color: theme.palette.secondary[900],
  },
  contentBlock: {
    margin: theme.spacing(2, 0),
    '& > p': {
      marginTop: theme.spacing(1),
    },
  },
  terminateButton: {
    marginTop: theme.spacing(2),
    fontSize: '1.1em',
  },
}));
