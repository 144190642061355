import { has } from 'lodash';

const generateTimeSlots = (date, callback, rawConfig) => {
  const config = { ...rawConfig };
  if (!has(config, 'hoursRange')) config.hoursRange = { start: 9, end: 17 };
  // eslint-disable-next-line no-plusplus
  for (let i = config.hoursRange.start; i < config.hoursRange.end; i++) {
    const full = new Date(date);
    const half = new Date(date);
    full.setHours(i);
    half.setHours(i);
    half.setMinutes(30);
    callback({ full, half });
  }
};

export default generateTimeSlots;
