import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  text: {
    animationName: '$fadeIn',
    animationDuration: '300',
    '& span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem !important',
      },
    },
  },
  errorText: {
    animationName: '$fadeIn',
    animationDuration: '800',
  },
  errorButton: {
    width: '100%',
    // @ts-ignore
    color: theme.palette.text.inverted,
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
    minWidth: '38px',
    textWrap: 'wrap',
  },
  popover: {
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    display: 'grid',
    // @ts-ignore
    backgroundColor: theme.palette.background.dark,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
