import React, { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { lastDayOfMonth } from 'date-fns';
import { useQuery } from 'react-query';

import organizationsApi from 'config/api/organizations/organizations';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import usersEndpoints from 'config/api/users/users';
import useApiCall from 'hooks/useApiCall';
import userDataStorage from 'storages/userDataStorage';

import OrganizationAdministrationInfo from './_components/OrganizationAdministrationInfo';
import OrganizationAdministrationManage from './_components/OrganizationAdministrationManage/OrganizationAdministrationManage';
import useStyles from './OrganizationAdministrationPage.styles';

const OrganizationAdministrationPage = () => {
  // @ts-ignore
  const { organization } = userDataStorage.get() || {};
  const { apiCall, loading } = useApiCall();
  const [statistics, setStatistics] = useState(null);

  const { data: organizationData } = useQuery([QUERY_KEYS.GET_ORGANIZATION_INFO, organization], () =>
    organizationsApi.getSingleOrganization(organization),
  );

  const getStatistics = async () => {
    // TODO change to react-query and api modules
    const begin = new Date();
    begin.setDate(1);
    begin.setMonth(0);
    const end = new Date();
    end.setDate(lastDayOfMonth(new Date()).getDate());
    const params = {
      'begin-date__gte': begin.toISOString(),
      'end-date__lte': end.toISOString(),
    };
    const { data } = await apiCall(usersEndpoints.getOrganizationStats(params));
    setStatistics(data);
  };

  useEffect(() => {
    // getOrganizationData();
    getStatistics();
  }, []);

  const styles = useStyles();
  return (
    <Container>
      <div className={styles.container}>
        <OrganizationAdministrationInfo data={statistics} loading={loading} />
        <OrganizationAdministrationManage isLoading={loading} organizationData={organizationData} organizationId={organization} />
      </div>
    </Container>
  );
};

export default OrganizationAdministrationPage;
