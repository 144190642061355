import React from 'react';

import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormDateInput from 'components/FormDateInput';
import FormTextInput from 'components/FormTextInput';
import incidentReportsEndpoints from 'config/api/incident_reports';
import useApiCall from 'hooks/useApiCall';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import report_messages from 'messages/report_messages';
import validation_messages from 'messages/validation_messages';

import DialogGrid from '../_components/DialogGrid';

const FORM_ID = 'AddIncidentLogDialog';
const FORM = {
  DATE_TIME: 'date_time',
  CONTACTED: 'contacted',
  ACTIVITY: 'actions',
  NOTES: 'notes',
};

const AddIncidentLogDialog = ({ onClose, open, reportId, refreshData }) => {
  const { t } = useTranslation();
  const { apiCall } = useApiCall();

  const onSubmit = async data => {
    const { status } = await apiCall(incidentReportsEndpoints.addReportLog(), { data: { ...data, incident_report_id: reportId } });
    if (status < 300) {
      refreshData();
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      [FORM.DATE_TIME]: new Date(),
      [FORM.CONTACTED]: '',
      [FORM.ACTIVITY]: '',
      [FORM.NOTES]: '',
    },
    validationSchema: yup.object({
      [FORM.DATE_TIME]: yup.string().required(t(...validation_messages.required)),
      [FORM.CONTACTED]: yup.string().required(t(...validation_messages.required)),
      [FORM.ACTIVITY]: yup.string().required(t(...validation_messages.required)),
      [FORM.NOTES]: yup.string().required(t(...validation_messages.required)),
    }),
    onSubmit,
  });

  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          <ColoredButton customColor='secondary' form={FORM_ID} type='submit' variant='outlined'>
            {t(...general_messages.add)}
          </ColoredButton>
          <Button data-testid='cancel-add-log' onClick={onClose} size='small'>
            {t(...general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      title={t(...guide_messages.add_incident_log_title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={1.5} withoutPadding>
          <FormDateInput dateTime formik={formik} id={FORM.DATE_TIME} label={t(...report_messages.log_labels.date_and_time)} required />
          <FormTextInput formik={formik} id={FORM.ACTIVITY} label={t(...report_messages.log_labels.activity)} required />
          <FormTextInput formik={formik} id={FORM.CONTACTED} label={t(...report_messages.log_labels.contacted)} required />
          <FormTextInput formik={formik} id={FORM.NOTES} label={t(...report_messages.log_labels.notes)} multiline required />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

AddIncidentLogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
};

export default AddIncidentLogDialog;
