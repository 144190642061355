import PATHS from 'router/PATHS';

export const logObjects = {
  // LANDING_PAGE: { code: 1, paths: ['?example_path?'] },
  LOGIN_PAGE: { code: 2, paths: [PATHS.AUTH, PATHS.AUTH_LOGIN] },
  USER_DASHBOARD: { code: 3, paths: [PATHS.ROOT] },
  PASSWORD_RESET_REQUEST_PAGE: { code: 4, paths: [PATHS.AUTH_FORGOT_PASSWORD] },
  PASSWORD_RESET_PAGE: { code: 5, paths: [`${PATHS.AUTH_PASSWORD_RESET}/:id/:token`] },
  SIGN_UP_PAGE: { code: 6, paths: [PATHS.AUTH_REGISTER] },
  USER_ADMINISTRATION_PAGE: { code: 7, paths: [PATHS.USERS] },
  UPLOAD_USER_FILE: { code: 8 },
  EDIT_ADD_USER: { code: 9 },
  CONTENT_ADMINISTRATION_PAGE: { code: 10, paths: [PATHS.MANAGE_CONTENT] },
  // CONTENT_NEW_EDIT: { code: 11 },
  USER_PROFILE_PAGE: { code: 12, paths: [PATHS.MY_PROFILE] },
  ARTICLES_MAIN_PAGES: { code: 13, paths: [PATHS.ARTICLES] },
  INCIDENT_MANAGEMENT: { code: 14, paths: [PATHS.INCIDENT_REPORTS] },
  INCIDENT_REPORT_PAGE: { code: 15, paths: [PATHS.INCIDENT_REPORTS] },
  // ADMINISTRATOR_MEETING_TIMESLOT_PAGE: { code: 17, paths: ['?example_path?'] },
  ADVISORY_PAGE: { code: 18, paths: [PATHS.ADVISORY_PAGE] },

  // USER_ACCOUNT: { code: 100, paths: [PATHS.MY_PROFILE] },
  USER_PROFILE: { code: 101, paths: [PATHS.MY_PROFILE] },
  // USE_ROLE: { code: 102, paths: ['?example_path?'] },
  ARTICLE: { code: 200, paths: [`${PATHS.ARTICLES}/:id`] },
  // LINKED_OBJECT: { code: 201, paths: ['?example_path?'] },
  // SELF_TEST: { code: 300, paths: ['?example_path?'] },
  GUIDE: { code: 400 },
  GUIDE_STEP: { code: 401 },
  // GUIDE_ACTION: { code: 402, paths: ['?example_path?'] },
  // GUIDE_INSTANCE: { code: 500, paths: ['?example_path?'] },
  INCIDENT_REPORT: { code: 600 },
  // INCIDENT_REPORT_LOG_ENTRY: { code: 601, paths: ['?example_path?'] },
  // ADVISORY_MEETING: { code: 700, paths: ['?example_path?'] },
  // ADVISORY_REPORT: { code: 701 },
  // SEARCH: { code: 800, paths: ['?example_path?'] },
};

export const events = {
  OPENED: 1,
  CLOSED: 2,
};
