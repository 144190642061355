import React from 'react';

import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormFileInput from 'components/FormFileInput';
import FormTextInput from 'components/FormTextInput';
import general_messages from 'messages/general_messages';
import { FileInForm } from 'types/FileForm';

import DialogGrid from '../_components/DialogGrid';

export type FormDataType = {
  label: string;
  file: File | '';
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (fileData: FileInForm) => void;
  withLabelField: boolean;
};

const FORM_ID = 'upload-file-form';

const UploadFileDialog: React.FC<Props> = ({ open, onClose, onSubmit, withLabelField }) => {
  const { t } = useTranslation();

  const onFormSubmit = (formData: FormDataType, formInstance: any) => {
    const fileInForm: FileInForm = {
      id: uuid(),
      name: formData.label,
      file: formData.file as File, // after form validation it's always a file
      isFromDb: false,
    };
    onSubmit(fileInForm);
    formInstance.resetForm();
    onClose();
  };

  const formik = useFormik<FormDataType>({
    onSubmit: onFormSubmit,
    initialValues: {
      file: '',
      label: '',
    },
  });

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={!formik.isValid} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.add)}
          </ColoredButton>
          <Button onClick={onClose} size='small'>
            {t(general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      title={t(general_messages.upload_file)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={2} withoutPadding>
          <FormFileInput formik={formik} id='file' label={t(general_messages.upload_file)} required />
          {withLabelField && <FormTextInput formik={formik} id='label' label={t(general_messages.add_label)} required />}
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default UploadFileDialog;
