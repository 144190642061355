import { useQuery } from 'react-query';

import organizationsApi from 'config/api/organizations/organizations';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { useAuthContext } from 'reactContext/AuthContext';
import userDataStorage from 'storages/userDataStorage';

const useOrganizationPaymentStageInfo = () => {
  // @ts-ignore
  const { isAuthorized } = useAuthContext();

  // @ts-ignore
  const { organization } = userDataStorage.get() || {};

  const { data: organizationData, isLoading } = useQuery(
    [QUERY_KEYS.GET_ORGANIZATION_PAYMENT_STAGE_INFO, organization],
    () => organizationsApi.getOrganizationPaymentStage(organization),
    { enabled: !!isAuthorized },
  );

  return { stage: organizationData?.organizationPaymentStage, isLoading };
};

export default useOrganizationPaymentStageInfo;
