const meeting_messages = {
  page_title: ['meeting>>page_title', 'Advisory meeting'],
  page_description: [
    'meeting>>page_description',
    'The meeting is 20 minutes long but the slot is 30 minutes. The last 10 minutes is reserved for us to summarize the advisory meeting into an advise.',
  ],
  next_available_time_title: ['meeting>>next_available_time_title', 'Next available time'],
  find_time_for_meeting_title: ['meeting>>find_time_for_meeting_title', 'Find a time for your meeting'],
  book_meeting: ['meeting>>book_meeting', 'Book meeting'],
  ask_question: ['meeting>>ask_question', 'Ask a question'],
  book: ['meeting>>book', 'Book'],
  reserved: ['meeting>>reserved', 'Reserved'],
  unavailable: ['meeting>>unavailable', 'N/A'],
  available: ['meeting>>available', 'Available'],
  book_meeting_dialog_title: ['meeting>>book_meeting_dialog_title', 'Book advisory meeting'],
  book_meeting_details_dialog_title: ['meeting>>book_meeting_details_dialog_title', 'Advisory meeting'],
  contact_data_section_title: ['meeting>>contact_data_section_title', 'Contact data'],
  agenda_section_title: ['meeting>>agenda_section_title', 'Agenda'],
  add_incident_report_checkbox_label: ['meeting>>add_incident_report_checkbox_label', 'I’d like to discuss an incident report'],
  add_incident_report_dropdown_placeholder: ['meeting>>add_incident_report_dropdown_placeholder', 'Select incident report'],
  add_incident_report_field_placeholder: [
    'meeting>>add_incident_report_field_placeholder',
    'Please specify what you seek advice about regarding the incident report',
  ],
  add_agenda_item_button_label: ['meeting>>add_agenda_item_button_label', 'Add agenda item'],
  add_agenda_item_input_placeholder: ['meeting>>add_agenda_item_input_placeholder', 'What would you like to discuss?'],
  fill_all_agenda_items: ['meeting>>fill_all_agenda_items', 'Fill all agenda items before adding new one'],
  knowledge_area_label: ['meeting>>knowledge_area_label', 'Knowledge area'],
  knowledge_area_info: ['meeting>>knowledge_area_info', 'Please select knowledge area in order to see calendar availability'],
  update_meeting: ['meeting>>update_meeting', 'Update meeting'],
  cancel_meeting: ['meeting>>cancel_meeting', 'Cancel meeting'],
  cancel_meeting_confirm_title: ['meeting>>cancel_meeting_confirm_title', 'Cancel meeting'],
  cancel_meeting_confirm: ['meeting>>cancel_meeting_confirm', 'Are you sure you want to cancel meeting?'],
  meeting_canceled: ['meeting>>meeting_canceled', 'Meeting canceled'],
  meeting_updated: ['meeting>>meeting_updated', 'Meeting updated!'],
  meeting_created: ['meeting>>meeting_created', 'Meeting created!'],
  calendar: ['meeting>>calendar', 'Calendar'],
  upcoming_meetings: ['meeting>>upcoming_meetings', 'Upcoming meetings'],
  advisory_meeting: ['meeting>>advisory_meeting', 'Advisory meeting'],
  discuss_incident_report: ['meeting>>discuss_incident_report', 'Discuss incident report'],
  own_agenda_item_title: ['meeting>>own_agenda_item_title', 'Agenda item {{number}}'],
  create_advice: ['meeting>>create_advice', 'Create advice'],
  absent_customer: ['meeting>>absent_customer', 'Absent customer'],
  meeting_link_title: ['meeting>>meeting_link_title', 'Meeting link'],
  meeting_link_label: ['meeting>>meeting_link_label', 'Start video meeting'],
  meeting_link_phone_fallback: [
    'meeting>>meeting_link_phone_fallback',
    'If there is any technical issues on either side, we will call you on the following phone number',
  ],
  meeting_phone_clarification: [
    'meeting>>meeting_phone_clarification',
    'Meeting will be held via secure video link. In case of technical issues we will call you on the phone number provided below',
  ],
  organization_input_label: ['meeting>>organization_input_label', 'Input from organization'],
  my_advice_label: ['meeting>>my_advice_label', 'My advice'],
  advices_page_title: ['meeting>>advices_page_title', 'Advices'],
  questions_title: ['meeting>>questions_title', 'Questions'],
  my_meeting_advices_title: ['meeting>>my_meeting_advices_title', 'Meetings advices'],
  advice_saved: ['meeting>>advice_saved', 'Advice saved'],
  advice_published: ['meeting>>advice_published', 'Advice published'],
  customer_absent_notification: ['meeting>>customer_absent_notification', 'Customer was marked as absent'],
  status: {
    published: ['meeting>>published', 'Advisory report'],
    new: ['meeting>>advice_published', 'Meeting notes'],
  },
  previous_advice: ['meeting>>previous_advice', 'Previous advice ({{advice_number}})'],
  meeting_with: ['meeting>>meeting_with', 'Meeting with'],
  edit_meeting_by_advisor: {
    main_title: ['meeting>>edit_meeting_by_advisor>>main_title', 'Edit meeting'],

    change_advisor: {
      select: ['meeting>>edit_meeting_by_advisor>>change_advisor>>select', 'Change Pocket Safe Advisor'],
      title: ['meeting>>edit_meeting_by_advisor>>change_advisor>>title', 'Change Pocket Safe Advisor'],
      no_advisors: ['meeting>>edit_meeting_by_advisor>>change_advisor>>no_advisors', 'No available advisors'],
      success_msg: ['meeting>>edit_meeting_by_advisor>>change_advisor>>success_msg', 'Advisor changed!'],
    },
    move_meeting: {
      select: ['meeting>>edit_meeting_by_advisor>>move_meeting>>select', 'Move meeting (as agreed with client)'],
      title: ['meeting>>edit_meeting_by_advisor>>move_meeting>>title', 'Move meeting (as agreed with client)'],
      no_slots: ['meeting>>edit_meeting_by_advisor>>change_advisor>>no_advisors', 'No available slots'],
      success_msg: ['meeting>>edit_meeting_by_advisor>>move_meeting>>success_msg', 'Meeting time changed!'],
    },
    cancel: {
      select: ['meeting>>edit_meeting_by_advisor>>cancel>>select', 'Cancel - Advisor can’t make it'],
      title: ['meeting>>edit_meeting_by_advisor>>cancel>>title', 'Cancel meeting'],
      comment_label: ['meeting>>edit_meeting_by_advisor>>cancel>>comment_label', 'Comment (for email)'],
      success_msg: ['meeting>>edit_meeting_by_advisor>>cancel>>success_msg', 'Meeting canceled!'],
    },
    absent: {
      select: ['meeting>>edit_meeting_by_advisor>>absent>>select', 'Cancel - Client did not show up (15 min wait mandatory)'],
      title: ['meeting>>edit_meeting_by_advisor>>absent>>title', 'Cancel meeting: customer did not show up'],
      comment_label: ['meeting>>edit_meeting_by_advisor>>absent>>comment_label', 'Other actions taken'],
      success_msg: ['meeting>>edit_meeting_by_advisor>>absent>>success_msg', 'Customer reported as absent!'],
    },
  },
};

export default meeting_messages;
