import React, { useEffect, useRef, useState } from 'react';

import ReactPlayer from 'react-player/lazy';

import { UrlString } from '../../types/UrlString';

type Props = {
  url: UrlString;
};

const ResponsivePlayer: React.FC<Props> = ({ url }) => {
  const [height, setHeight] = useState<undefined | number>(undefined);

  const videoRef = useRef(null);

  const updateHeight = () => {
    if (videoRef.current) {
      const { wrapper } = videoRef.current as { wrapper: HTMLDivElement };
      const youTubeRatio = 16 / 9;
      setHeight(wrapper.offsetWidth / youTubeRatio);
    }
  };

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [videoRef.current]);

  return <ReactPlayer ref={videoRef} height={height} url={url} width='100%' />;
};

export default ResponsivePlayer;
