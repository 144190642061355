import React from 'react';

import OrganizationPaymentStages from 'config/permissions/OrganizationPaymentStages';
import RolesOrganization, { EVERY_ORGANIZATION_ROLE } from 'config/permissions/RolesOrganization';
import RolesPocketSafe from 'config/permissions/RolesPocketSafe';
import STAGES_WITH_ENABLED_FUNCTIONALITIES from 'config/permissions/STAGES_WITH_ENABLED_FUNCTIONALITIES';
import AdvisoryPage from 'pages/AdvisoryPage';
import ArticlesPage from 'pages/ArticlesPage';
import AssetEditPage from 'pages/AssetEditPage/AssetEditPage';
import AssetsPage from 'pages/AssetsPage/AssetsPage';
import AuthPage from 'pages/AuthPage';
import BookMeetingPage from 'pages/BookMeetingPage';
import DashboardPage from 'pages/DashboardPage';
import EditArticlePage from 'pages/EditArticlePage';
import EditGuidePage from 'pages/EditGuidePage';
import GlobalSearchPage from 'pages/GlobalSearchPage';
import GuidesPage from 'pages/GuidesPage';
import IncidentReportsPage from 'pages/IncidentReportsPage';
import ManageContentPage from 'pages/ManageContentPage';
import ManageMeetingSlotsPage from 'pages/ManageMeetingSlotsPage';
import MeetingAdvicesPage from 'pages/MeetingAdvicesPage';
import MeetingReportPage from 'pages/MeetingReportPage';
import MyProfilePage from 'pages/MyProfilePage';
import NotFoundPage from 'pages/NotFoundPage';
import OrganizationAdministrationPage from 'pages/OrganizationAdministrationPage/OrganizationAdministrationPage';
import PrePaymentPage from 'pages/PrePaymentPage/PrePaymentPage';
import QuestionsPage from 'pages/QuestionsPage';
import SelfTestsPage from 'pages/SelfTestsPage/SelfTestsPage';
import ServiceAreaPage from 'pages/ServiceAreaPage/ServiceAreaPage';
import SingleArticlePage from 'pages/SingleArticlePage/SingleArticlePage';
import SingleAssetPage from 'pages/SingleAssetPage/SingleAssetPage';
import SingleGuidePage from 'pages/SingleGuidePage';
import SingleIncidentReportPage from 'pages/SingleIncidentReportPage/SingleIncidentReportPage';
import SingleSelfTestPage from 'pages/SingleSelfTestPage/SingleSelfTestPage';
import SingleWorkplacePage from 'pages/SingleWorkplacePage/SingleWorkplacePage';
import SingleYearPlannerAssetActivityEditPage from 'pages/SingleYearPlannerAssetActivityEditPage/SingleYearPlannerAssetActivityEditPage';
import SingleYearPlannerAssetActivityPage from 'pages/SingleYearPlannerAssetActivityPage/SingleYearPlannerAssetActivityPage';
import UsersPage from 'pages/UsersPage';
import WorkplacesPage from 'pages/WorkplacesPage/WorkplacesPage';
import WorkplacesTemplatePage from 'pages/WorkplacesTemplatePage/WorkplacesTemplatePage';
import PATHS from 'router/PATHS';

import AssetTaskDashboardPage from '../pages/AssetTaskDashboardPage/AssetTaskDashboardPage';
import SingleRiskAnalysisPage from '../pages/SingleRiskAnalysisPage/SingleRiskAnalysisPage';

type RouteConfig = {
  allowedForPSRoles: RolesPocketSafe[];
  allowedForOrganizationRoles: RolesOrganization[];
  allowedInStages?: OrganizationPaymentStages[];
  redirectPath?: string;
};

type Route = {
  path: string;
  component: React.FC<any> | React.Component;
  isPrivate: boolean;
  redirectIfAuthorized: boolean;
  routeConfig?: RouteConfig;
};

const routes: Route[] = [
  {
    path: PATHS.ROOT,
    component: DashboardPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.AUTH}/:type?/:uid?/:token?`,
    component: AuthPage,
    isPrivate: false,
    redirectIfAuthorized: true,
  },
  {
    path: PATHS.MY_PROFILE,
    component: MyProfilePage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.ARTICLES,
    component: ArticlesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.ARTICLES}/:id`,
    component: SingleArticlePage,
    isPrivate: false,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.MANAGE_CONTENT,
    component: ManageContentPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [RolesPocketSafe.ContentEditor],
      allowedForOrganizationRoles: [],
    },
  },
  {
    path: `${PATHS.EDIT_GUIDE}/:id?`,
    component: EditGuidePage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [RolesPocketSafe.ContentEditor],
      allowedForOrganizationRoles: [],
    },
  },
  {
    path: `${PATHS.EDIT_ARTICLE}/:id?`,
    component: EditArticlePage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [RolesPocketSafe.ContentEditor],
      allowedForOrganizationRoles: [],
    },
  },
  {
    path: PATHS.GUIDES,
    component: GuidesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
    },
  },
  {
    path: `${PATHS.GUIDES}/:guide_id/:version_id/:progress_id?`,
    component: SingleGuidePage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: PATHS.SELF_TESTS,
    component: SelfTestsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: `${PATHS.SELF_TESTS}/:selfTestRootId/:selfTestVersionId/:selfTestProgressId?`,
    component: SingleSelfTestPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: PATHS.USERS,
    component: UsersPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForOrganizationRoles: [RolesOrganization.UserAdministrator],
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
      allowedForPSRoles: [],
    },
  },
  {
    path: `${PATHS.CONTENT_BY_SERVICE_AREA}/:service_area_id`,
    component: ServiceAreaPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.BOOK_MEETING,
    component: BookMeetingPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForOrganizationRoles: [RolesOrganization.SecurityOfficer],
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
      allowedForPSRoles: [],
    },
  },
  {
    path: PATHS.INCIDENT_REPORTS,
    component: IncidentReportsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForOrganizationRoles: [RolesOrganization.IncidentManager],
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
      allowedForPSRoles: [],
    },
  },
  {
    path: `${PATHS.INCIDENT_REPORT}/:report_id?`,
    component: SingleIncidentReportPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
      allowedForPSRoles: [],
    },
  },
  {
    path: PATHS.MANAGE_MEETING_SLOTS,
    component: ManageMeetingSlotsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [RolesPocketSafe.SecurityOnline, RolesPocketSafe.SecuritySupervisor],
      allowedForOrganizationRoles: [],
    },
  },
  {
    path: PATHS.ADVISORY_PAGE,
    component: AdvisoryPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [RolesPocketSafe.SecurityOnline, RolesPocketSafe.SecuritySupervisor],
      allowedForOrganizationRoles: [],
    },
  },
  {
    path: PATHS.MEETING_ADVICES,
    component: MeetingAdvicesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: [RolesOrganization.SecurityOfficer],
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
    },
  },
  {
    path: `${PATHS.MEETING_REPORT}/:meeting_id`,
    component: MeetingReportPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [RolesPocketSafe.SecurityOnline, RolesPocketSafe.SecuritySupervisor],
      allowedForOrganizationRoles: [],
    },
  },
  {
    path: PATHS.ORGANIZATION_ADMINISTRATION,
    component: OrganizationAdministrationPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForOrganizationRoles: [RolesOrganization.AccountOwner],
      allowedForPSRoles: [],
    },
  },
  {
    path: PATHS.GLOBAL_SEARCH,
    component: GlobalSearchPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.QUESTIONS,
    component: QuestionsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [RolesPocketSafe.SecuritySupervisor, RolesPocketSafe.SecurityOnline],
      allowedForOrganizationRoles: [],
    },
  },
  {
    path: PATHS.WORKPLACES,
    component: WorkplacesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: PATHS.WORKPLACES_TEMPLATE,
    component: WorkplacesTemplatePage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: [RolesOrganization.AccountOwner],
    },
  },
  {
    path: `${PATHS.WORKPLACES}/:workplace_id`,
    component: SingleWorkplacePage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: PATHS.ASSETS,
    component: AssetsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: `${PATHS.ASSETS}/:assetId`,
    component: SingleAssetPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: `${PATHS.ASSETS_EDIT}/:assetId`,
    component: AssetEditPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: `${PATHS.YEAR_PLANNER_ASSETS_ACTIVITY}/:activityId`,
    component: SingleYearPlannerAssetActivityPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: `${PATHS.YEAR_PLANNER_ASSETS_ACTIVITY_EDIT}/:activityId`,
    component: SingleYearPlannerAssetActivityEditPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE,
    },
  },
  {
    path: PATHS.PRE_PAYMENT,
    component: PrePaymentPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: [RolesOrganization.AccountOwner],
    },
  },
  {
    path: PATHS.ASSET_TASKS_DASHBOARD,
    component: AssetTaskDashboardPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: [RolesOrganization.AssetsManager, RolesOrganization.AssetTaskDashboardAccess],
    },
  },
  {
    path: PATHS.ASSET_TASKS_DASHBOARD,
    component: AssetTaskDashboardPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: [RolesOrganization.AssetsManager, RolesOrganization.AssetTaskDashboardAccess],
    },
  },
  {
    path: PATHS.ASSET_TASKS_DASHBOARD,
    component: AssetTaskDashboardPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: [RolesOrganization.AssetsManager, RolesOrganization.AssetTaskDashboardAccess],
    },
  },
  {
    path: PATHS.RISK_ANALYSIS_DETAIL,
    component: SingleRiskAnalysisPage,
    isPrivate: true,
    redirectIfAuthorized: false,
    routeConfig: {
      allowedForPSRoles: [],
      allowedForOrganizationRoles: EVERY_ORGANIZATION_ROLE, // access needs to be checked dynamically, look at SingleRiskAnalysisPage
    },
  },
  {
    path: '*',
    component: NotFoundPage,
    isPrivate: false,
    redirectIfAuthorized: false,
  },
];

export default routes;
