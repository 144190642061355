import React, { useEffect, useState } from 'react';

import { Container, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import ManagePageHeader from 'components/ManagePageHeader/ManagePageHeader';
import meetingsEndpoints from 'config/api/meetings';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import ROLES from 'config/constants/ROLES';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import useSendingState from 'hooks/useSendingState';
import meeting_messages from 'messages/meeting_messages';
import userDataStorage from 'storages/userDataStorage';

import MeetingReportMainContent from './_components/MeetingReportMainContent';
import MeetingReportRightPanel from './_components/MeetingReportRightPanel';
import MeetingReportTimeTile from './_components/MeetingReportTimeTile';
import useStyles from './MeetingReportPage.styles';

const FORM = {
  INCIDENT_REPORT: 'incident_report',
  ITEMS: 'items',
};

const parseDataForRequest = rawData => {
  const data = {};
  // INCIDENT REPORT
  data.incident_report_input = rawData[FORM.INCIDENT_REPORT].organization_input;
  data.incident_report_advice = rawData[FORM.INCIDENT_REPORT].advice;
  data.incident_report_related_articles = [];
  data.incident_report_related_guides = [];
  rawData[FORM.INCIDENT_REPORT].related_content.forEach(({ object_content_type, key }) => {
    if (object_content_type === CONTENT_TYPES.ARTICLE) data.incident_report_related_articles.push(key);
    if (object_content_type === CONTENT_TYPES.GUIDE) data.incident_report_related_guides.push(key);
    if (object_content_type === CONTENT_TYPES.INCIDENT_GUIDE) data.incident_report_related_guides.push(key);
  });

  // AGENDA ITEMS
  data.items = Object.entries(rawData[FORM.ITEMS]).map(([agenda_item_id, { organization_input, advice, id, related_content }]) => {
    const relatedContent = {
      related_articles: [],
      related_guides: [],
    };
    if (related_content) {
      related_content.forEach(({ object_content_type, key }) => {
        if (object_content_type === CONTENT_TYPES.ARTICLE) relatedContent.related_articles.push(key);
        if (object_content_type === CONTENT_TYPES.GUIDE) relatedContent.related_guides.push(key);
      });
    }
    return {
      agenda_item_id,
      advice,
      input: organization_input,
      ...relatedContent,
      ...(id && { id }),
    };
  });
  return data;
};

const relatedContentUpdate = (container, { id: key, friendly_id, title, heading, object_content_type, metadata }) => {
  const label = object_content_type === CONTENT_TYPES.GUIDE ? heading : title;
  container.push({ key, friendly_id, label, object_content_type, metadata });
};

const prepareAgendaForm = ({ items }, { agenda_items }) => {
  const result = {};
  agenda_items.forEach(({ id }) => {
    const dataElement = items && items.find(({ agenda_item_id }) => agenda_item_id === id);
    if (dataElement) {
      const related_content = [];
      dataElement.related_articles.forEach(data => relatedContentUpdate(related_content, data));
      dataElement.related_guides.forEach(data => relatedContentUpdate(related_content, data));
      result[id] = {
        organization_input: dataElement?.input || '',
        advice: dataElement?.advice || '',
        id: dataElement?.id || null,
        related_content,
      };
    }
  });
  return result;
};

const MeetingReportPage = () => {
  const { t } = useTranslation();
  const { apiCall } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const { meeting_id } = useParams();
  const { queryParams } = useQueryParams();
  const userData = userDataStorage.get();

  const { setLoaded } = useLoadingState(true);
  const { sending, setSending, setSent } = useSendingState(false);
  const [meetingData, setMeetingData] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [readOnly, setReadOnly] = useState(queryParams.readOnly);

  const isMine = meetingData?.adviser_id && meetingData.adviser_id === userData?.id;

  const [isAdviser] = usePermissions([ROLES.PS_ROLES.SECURITY_ONLINE]);
  const [isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_SUPERVISOR]);

  const onSubmit = async shouldPublish => {
    setSending();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const data = parseDataForRequest(formik.values);
    const url = isEdit ? meetingsEndpoints.updateReportAsAdviser : meetingsEndpoints.createReportAsAdviser;
    const { status } = await apiCall(url(meeting_id), { data });
    if (status < 300) {
      if (shouldPublish) {
        const { status: publishStatus } = await apiCall(meetingsEndpoints.publishReportAsAdviser(meeting_id));
        if (publishStatus > 300) enqueueSnackbar(t(...meeting_messages.advice_published), { variant: 'success' });
        setReadOnly(true);
      } else {
        enqueueSnackbar(t(...meeting_messages.advice_saved), { variant: 'success' });
      }
      setSent();
    }
  };

  const formik = useFormik({
    initialValues: {
      [FORM.INCIDENT_REPORT]: {
        organization_input: '',
        advice: '',
        related_content: [],
      },
      [FORM.ITEMS]: {},
    },
  });

  const fillInitData = (dataToFill = {}, meetingDataFromAPI) => {
    const related_content = [];
    const { incident_report_related_articles, incident_report_related_guides } = dataToFill;
    if (incident_report_related_articles) incident_report_related_articles.forEach(data => relatedContentUpdate(related_content, data));
    if (incident_report_related_guides) incident_report_related_guides.forEach(data => relatedContentUpdate(related_content, data));
    formik.setValues({
      [FORM.INCIDENT_REPORT]: {
        organization_input: dataToFill.incident_report_input || '',
        advice: dataToFill.incident_report_advice || '',
        related_content,
      },
      [FORM.ITEMS]: prepareAgendaForm(dataToFill, meetingDataFromAPI),
    });
  };

  const getMeetingData = async ({ updateForms } = { updateForms: true }) => {
    const [{ data: meetingDataFromAPI }, { data: reportDataFromAPI, status }] = await (() => {
      if (isSupervisor) {
        return Promise.all([
          apiCall(meetingsEndpoints.getMeetingAsSupervisor(meeting_id)),
          apiCall(meetingsEndpoints.getReportAsSupervisor(meeting_id), {}, { showError: false }),
        ]);
      }
      if (isAdviser) {
        return Promise.all([
          apiCall(meetingsEndpoints.getMeetingAsAdviser(meeting_id)),
          apiCall(meetingsEndpoints.getReportAsAdviser(meeting_id), {}, { showError: false }),
        ]);
      }
      return Promise.all([
        apiCall(meetingsEndpoints.getMeetingAsSecurityOfficer(meeting_id)),
        apiCall(meetingsEndpoints.getReportAsSecurityOfficer(meeting_id), {}, { showError: false }),
      ]);
    })();

    setMeetingData(meetingDataFromAPI);
    setIsEdit(status < 300);
    if (reportDataFromAPI.publish_status === 'published') setReadOnly(true);
    if (updateForms) fillInitData(reportDataFromAPI, meetingDataFromAPI);
    setLoaded();
  };

  const refreshData = () => {
    getMeetingData({ updateForms: false });
  };

  useEffect(() => {
    getMeetingData();
  }, []);

  const styles = useStyles();
  return (
    <Container>
      {meetingData ? (
        <div className={styles.container}>
          <ManagePageHeader
            bottomAdornment={
              <div className={styles.bottomAdornment}>
                <Typography className={styles.subtitle} variant='h3'>
                  {meetingData?.org_officer && `${meetingData.org_officer.first_name} ${meetingData.org_officer.last_name}`}
                </Typography>
                {meetingData?.knowledge_area && (
                  <>
                    <Typography variant='h5'>{meetingData?.knowledge_area.heading}</Typography>
                    <Typography variant='body2'>{meetingData?.knowledge_area.description}</Typography>
                  </>
                )}
              </div>
            }
            className={styles.header}
            rightAdornment={
              <MeetingReportTimeTile
                absent={meetingData?.absent}
                advisor={meetingData?.adviser_profile}
                advisorId={meetingData?.adviser_id}
                isMine={isMine}
                meetingId={meeting_id}
                readOnly={readOnly}
                refreshData={refreshData}
                reportExists={isEdit}
                time={meetingData?.begin}
              />
            }
          >
            {t(...meeting_messages.advisory_meeting)}
          </ManagePageHeader>
          <MeetingReportMainContent
            data={meetingData}
            FORM={FORM}
            formik={formik}
            onSubmit={onSubmit}
            readOnly={readOnly || !isMine}
            sending={sending}
          />
          <MeetingReportRightPanel data={meetingData} />
        </div>
      ) : (
        <Loader inner open />
      )}
    </Container>
  );
};

export default MeetingReportPage;
