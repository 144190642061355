import React from 'react';

import { makeStyles, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ManagePageHeader from 'components/ManagePageHeader/ManagePageHeader';
import ROLES from 'config/constants/ROLES';
import usePermissions from 'hooks/usePermissions';
import title_messages from 'messages/title_messages';
import AdvisorProvider from 'reactContext/AdvisorContext';

import AdvisoryPageMeetingBar from './_components/AdvisoryPageMeetingBar';
import AdvisoryPageMeetingTable from './_components/AdvisoryPageMeetingTable';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '2fr 1fr',
    gridGap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '3fr 2fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
    },
  },
  container: {
    maxHeight: ({ isSupervisor }) => `calc(100vh - ${isSupervisor ? 230 : 130}px - ${theme.spacing(5)}px)`,
    overflow: 'scroll',
    [theme.breakpoints.down('xs')]: {
      maxHeight: ({ isSupervisor }) => (isSupervisor ? '33vh' : '50vh'),
    },
  },
}));

const AdvisoryPage = () => {
  const { t } = useTranslation();
  const [isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_SUPERVISOR]);

  const styles = useStyles({ isSupervisor });
  return (
    <Container>
      <AdvisorProvider>
        <ManagePageHeader>{t(...title_messages.advisory_page)}</ManagePageHeader>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <AdvisoryPageMeetingTable />
          </div>
          <div className={styles.container}>
            <AdvisoryPageMeetingBar />
          </div>
        </div>
      </AdvisorProvider>
    </Container>
  );
};

export default AdvisoryPage;
