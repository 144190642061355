import React from 'react';

import { Container, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const MobileGuttersContainer = ({ children, className }) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container className={className} disableGutters={upMd}>
      {children}
    </Container>
  );
};

MobileGuttersContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

MobileGuttersContainer.defaultProps = {
  className: null,
};

export default MobileGuttersContainer;
