import React from 'react';

import { IconButton, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import general_messages from 'messages/general_messages';

import useStyles from './ExternalLinkTile.styles';

type Props = {
  url: string;
  label: string;
  title?: string;
};

const ExternalLinkTile: React.FC<Props> = ({ url, title, label }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copyLink = async () => {
    await navigator.clipboard.writeText(url);
    enqueueSnackbar(t(general_messages.link_copied), { variant: 'success' });
  };

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.description}>
        {title && <Typography className={styles.title}>{title}</Typography>}
        <Typography className={styles.link} component='a' href={url} rel='noreferrer' target='_blank'>
          {label}
        </Typography>
      </div>
      <IconButton onClick={copyLink}>
        <FileCopyOutlinedIcon className={styles.icon} />
      </IconButton>
    </div>
  );
};
export default ExternalLinkTile;
