import METHOD from 'config/api/_methods';

const articleEndpoints = {
  getAll: () => ({ url: '/api/v1/article/list_post/', method: METHOD.POST }),
  get: id => ({ url: `/api/v1/article/${id}/`, method: METHOD.GET }),
  create: () => ({ url: '/api/v1/article/', method: METHOD.POST }),
  getLink: () => ({ url: '/api/v1/article/link_add/', method: METHOD.GET }),
  getEditLink: id => ({ url: `/api/v1/article/${id}/link_edit/`, method: METHOD.GET }),
  getSaved: () => ({ url: '/api/v1/saved-articles/', method: METHOD.GET }),
  addToSaved: () => ({ url: '/api/v1/saved-articles/', method: METHOD.POST }),
  removeFromSaved: article_id => ({ url: `/api/v1/saved-articles/${article_id}/`, method: METHOD.DELETE }),
};

export default articleEndpoints;
