import React, { FC } from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import activeUserRiskAnalyses from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import asset_risk_messages from 'messages/asset_risk_messages';
import { Id } from 'types/Id';

import RiskMatrix from './_components/RiskMatrix/RiskMatrix';
import useStyles from './RiskAnalysisMatrix.styles';

type Props = {
  riskAnalysisId: Id;
};

const RiskAnalysisMatrix: FC<Props> = ({ riskAnalysisId }) => {
  const { t } = useTranslation();

  const { data: matrixData } = useQuery({
    queryKey: [QUERY_KEYS.GET_RISK_ANALYSIS_MATRIX, riskAnalysisId],
    queryFn: () => activeUserRiskAnalyses.getRiskMatrixData(riskAnalysisId),
    enabled: !!riskAnalysisId,
  });

  const styles = useStyles();

  return (
    <section>
      <Typography className={styles.title} variant='h3'>
        {t(asset_risk_messages.details_page.matrix_title)}
      </Typography>
      <RiskMatrix matrixData={matrixData} />
    </section>
  );
};

export default RiskAnalysisMatrix;
