import axios from 'axios';

import { parseOrganizationAssetTasksFilterDataToTableFilters } from './organizationAssetTasksFilterData.parsers';
import type OrganizationAssetTasksFilterDataApi from './organizationAssetTasksFilterData.types';

const endpoint = 'api/v1/organization/asset_tasks/filter_data/';

const organizationAssetTasksFilterDataApi: OrganizationAssetTasksFilterDataApi = {
  getOrganizationAssetTasksFilterData: async () => {
    const { data } = await axios.get(endpoint);
    return parseOrganizationAssetTasksFilterDataToTableFilters(data);
  },
};

export default organizationAssetTasksFilterDataApi;
