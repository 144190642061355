import axios from 'axios';

import {
  parseSingleOrganizationForFE,
  parseOrganizationRemainingRetentionDaysForFE,
  parseOrganizationRemainingTrialDaysForFE,
  parsePartialFormOrganizationDataForBE,
  parseCreateFormOrganizationDataForBE,
  parseCreatedOrganizationForFE,
  parseResendActivationLinkForBE,
  parseOrganizationPaymentStageForFE,
} from './organizations.parsers';
import type OrganizationsApi from './organizations.types';

const endpoint = 'api/v1/organizations/';
// TODO extract it to API module
const endpointForCreate = 'api/v1/auth/users/create_with_org/';
const endpointForResend = 'api/v1/auth/users/resend_activation/';

const organizationsApi: OrganizationsApi = {
  getSingleOrganization: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleOrganizationForFE(data);
  },
  getOrganizationPaymentStage: async id => {
    const { data } = await axios.get(`${endpoint}${id}/payment_method_state/`);
    return parseOrganizationPaymentStageForFE(data);
  },
  getOrganizationRemainingRetentionDays: async id => {
    const { data } = await axios.get(`${endpoint}${id}/retention_period_days_left/`);
    return parseOrganizationRemainingRetentionDaysForFE(data);
  },
  getOrganizationRemainingTrialDays: async id => {
    const { data } = await axios.get(`${endpoint}${id}/trial_period_days_left/`);
    return parseOrganizationRemainingTrialDaysForFE(data);
  },
  updatePartialOrganization: async ({ id, inputData }) => {
    const { data } = await axios.patch(`${endpoint}${id}/`, parsePartialFormOrganizationDataForBE(inputData));
    return parseSingleOrganizationForFE(data);
  },
  updateOrganization: async ({ id, inputData }) => {
    const { data } = await axios.put(`${endpoint}${id}/`, parsePartialFormOrganizationDataForBE(inputData));
    return parseSingleOrganizationForFE(data);
  },
  createOrganization: async inputData => {
    const { data } = await axios.post(endpointForCreate, parseCreateFormOrganizationDataForBE(inputData));
    return parseCreatedOrganizationForFE(data);
  },
  resendActivationLink: async inputData => {
    const { status } = await axios.post(endpointForResend, parseResendActivationLinkForBE(inputData));
    return status < 300;
  },
};

export default organizationsApi;
