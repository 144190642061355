import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import AssetEditForm from 'components/AssetEditForm/AssetEditForm';
import DialogViewWrapper from 'components/DialogViewWrapper';
import Loader from 'components/Loader';
import { assetsApi } from 'config/api/assets/assets';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import useDynamicAssetPageAccessCheck from 'hooks/_assets/useDynamicAssetPageAccessCheck/useDynamicAssetPageAccessCheck';
import assets_messages from 'messages/assets_messages';
import UnauthorizedPage from 'pages/UnauthorizedPage';

const AssetEditPage: React.FC = () => {
  const { t } = useTranslation();
  const { assetId } = useParams() as { assetId: string };

  const { data: assetData, isLoading: assetDataIsLoading } = useQuery([QUERY_KEYS.ASSETS_GET_SINGLE, assetId], () =>
    assetsApi.getSingleAsset(assetId),
  );

  const hasAccess = useDynamicAssetPageAccessCheck(assetData);

  if (!assetDataIsLoading && !hasAccess) return <UnauthorizedPage />;
  return (
    <DialogViewWrapper contentSize='sm' noCardPadding title={t(assets_messages.edit_page.title)}>
      {!assetDataIsLoading && assetData ? <AssetEditForm id={assetId} initialData={assetData} /> : <Loader inner />}
    </DialogViewWrapper>
  );
};

export default AssetEditPage;
