import React from 'react';

import { useTranslation } from 'react-i18next';

import InfoBox from 'components/InfoBox/InfoBox';
import info_boxes_messages from 'messages/info_boxes_messages';

import useStyles from './MeetingPageInfoBox.styles';

const MeetingPageInfoBox: React.FC = () => {
  const { t } = useTranslation();

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <InfoBox content={t(info_boxes_messages.book_meeting.content)} title={t(info_boxes_messages.book_meeting.title)} />
    </div>
  );
};

export default MeetingPageInfoBox;
