import React, { useCallback, useMemo, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import MonthsDropdown from 'components/MonthsDropdown/MonthsDropdown';
import Table from 'components/Table';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import yearPlannerActivities, { yearPlannerActivitiesApi } from 'config/api/yearPlannerActivities/yearPlannerActivities';
import { formatTimePeriodToString, parseAllYearPlannerActivityForFE } from 'config/api/yearPlannerActivities/yearPlannerActivities.parsers';
import { YearPlannerActivity } from 'config/api/yearPlannerActivities/yearPlannerActivities.types';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import safeT from 'helpers/safeT/safeT';
import usePagination from 'hooks/usePagination';
import usePermissions from 'hooks/usePermissions';
import useSubscription from 'hooks/useSubscription';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';
import StorageService from 'services/StorageService';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

import TrimHTMLText from '../TrimHTMLText/TrimHTMLText';

// @ts-ignore
const selectedMonthForYearPlannerActivityTable = new StorageService('selectedMonthForYearPlannerActivityTable', {
  storage: 'sessionStorage',
});

const YearPlannerAssetsActivityTable: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isAssetsManager] = usePermissions([ROLES.ORGANIZATION.ASSETS_MANAGER]);
  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();

  // @ts-ignore
  const [month, setLocalMonth] = useState<number>(selectedMonthForYearPlannerActivityTable.get() || new Date().getMonth());
  const setMonth = (value: number) => {
    setLocalMonth(value);
    selectedMonthForYearPlannerActivityTable.set(value);
  };

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: yearPlannerActivities.getAllAssets,
    limits: [5, 5, 5],
    isGet: true,
    dependencies: [sort, month],
    sort,
    queryParams: { begin__month: month + 1 },
  });

  const refresh = () => {
    refreshData({ queryParamsOverride: { begin__month: month + 1 } });
  };

  const deleteMutation = useMutation(['delete asset activity'], yearPlannerActivitiesApi.removeYearPlannerActivity, {
    onSuccess: refresh,
  });

  const onEdit = (id: Id) => {
    history.push(generatePath(`${PATHS.YEAR_PLANNER_ASSETS_ACTIVITY_EDIT}/:activityId`, { activityId: id }));
  };
  const onDelete = async ({ id, activity }: YearPlannerActivity) => {
    const shouldProceed = await showConfirmationModal({
      title: t(assets_messages.remove_asset_activity_modal.title),
      body: t(assets_messages.remove_asset_activity_modal.body, { activityName: activity.name }),
    });
    if (shouldProceed) {
      deleteMutation.mutate(id);
    }
  };

  const getRowHref = ({ id }: YearPlannerActivity) => generatePath(`${PATHS.YEAR_PLANNER_ASSETS_ACTIVITY}/:activityId`, { activityId: id });

  const renderContextMenu = useCallback(
    (data: YearPlannerActivity) => {
      const elements = [
        { label: t(general_messages.edit), onClick: () => onEdit(data.id) },
        { label: t(general_messages.delete), onClick: () => onDelete(data) },
      ];

      if (!isAssetsManager) return null;
      return (
        <ContextMenu elements={elements} id={`assets-table-row-${data.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData],
  );

  useSubscription(EVENTS.ASSETS_YEAR_PLANNER_UPDATED, refresh);

  const dataMapping: TableDataMappingRow<YearPlannerActivity>[] = useMemo(
    () => [
      {
        id: 'activity__name',
        label: t(assets_messages.activities_table.activity),
        get: ({ activity }) => safeT(t, activity.nameTranslationKey, activity.name),
        width: '18%',
      },
      {
        id: 'begin',
        label: t(assets_messages.activities_table.date),
        get: ({ begin, end }) => (
          <div>
            <p>{`${t(assets_messages.activities_table.start_date)}: ${formatTimePeriodToString(begin)}`}</p>
            <p>{`${t(assets_messages.activities_table.end_date)}: ${formatTimePeriodToString(end)}`}</p>
          </div>
        ),
        width: '18%',
      },
      {
        id: 'activity__description',
        label: t(assets_messages.activities_table.description),
        get: ({ activity }) => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{safeT(t, activity.descriptionTranslationKey, activity.description)}</TypographyWithHTML>
          </TrimHTMLText>
        ),
        width: '58%',
      },
      {
        id: 'context',
        width: '6%',
        label: '',
        isAddon: true,
        get: (data): React.ReactNode => renderContextMenu(data) || ' ',
        blockSorting: true,
      },
    ],
    [tableData],
  );

  return (
    <>
      <MonthsDropdown onChange={setMonth} value={month} />
      <Table
        clickableRow
        data={tableData && parseAllYearPlannerActivityForFE(tableData)}
        dataMapping={dataMapping}
        getRowHref={getRowHref}
        onSort={onSort}
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
    </>
  );
};

export default YearPlannerAssetsActivityTable;
