import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    width: '100%',
  },
  icon: {
    fill: theme.palette.secondary[900],
  },
}));
