import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import AssetDetailsCard from 'components/AssetDetailsCard/AssetDetailsCard';
import DialogViewWrapper from 'components/DialogViewWrapper';
import SomethingWentWrong from 'components/ErrorBoundary/_components/SomethingWentWrong';
import Loader from 'components/Loader';
import { assetsApi } from 'config/api/assets/assets';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import useDynamicAssetPageAccessCheck from 'hooks/_assets/useDynamicAssetPageAccessCheck/useDynamicAssetPageAccessCheck';
import assets_messages from 'messages/assets_messages';
import UnauthorizedPage from 'pages/UnauthorizedPage';

const SingleAssetPage: React.FC = () => {
  const { t } = useTranslation();
  const { assetId } = useParams() as { assetId: string };

  const { data: assetData, isLoading: assetDataIsLoading } = useQuery([QUERY_KEYS.ASSETS_GET_SINGLE, assetId], () =>
    assetsApi.getSingleAsset(assetId),
  );

  const hasAccess = useDynamicAssetPageAccessCheck(assetData);

  if (assetDataIsLoading) return <Loader inner />;

  if (!assetData) return <SomethingWentWrong />;

  if (!hasAccess) return <UnauthorizedPage />;

  return (
    <DialogViewWrapper contentSize='640px' noCardPadding title={t(assets_messages.details_page.title)}>
      <AssetDetailsCard assetData={assetData} />
    </DialogViewWrapper>
  );
};

export default SingleAssetPage;
