import AUDIENCES from 'config/translatableConstants/AUDIENCES';
import general_messages from 'messages/general_messages';
import prepareDataForFilter from 'services/prepareDataForFilter';

const audience = {
  sectionName: general_messages.audience,
  filters: prepareDataForFilter(AUDIENCES),
};

export default audience;
