import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  addOrConfirmDialogRoot: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  addOrConfirmDialogRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // @ts-ignore
    maxWidth: theme.sizes.container.xs,
    width: '100%',
    margin: '0 auto',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1, 0),
  },
  switchButton: {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1.25, 2),
    borderRadius: theme.shape.borderRadius,
    // @ts-ignore
    border: `1px solid ${theme.palette.secondary[200]}`,
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  switchButtonActive: {
    // @ts-ignore
    background: theme.palette.secondary[50],
  },
  icon: {
    // @ts-ignore
    color: theme.palette.secondary[900],
    marginLeft: theme.spacing(2),
  },
}));
