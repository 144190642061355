import { useMemo } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import manageTotpDevicesApi from 'config/api/manageTotpDevices/manageTotpDevices';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { CurrentUser } from 'config/api/users/_types';
import { usersApi } from 'config/api/users/users';
import otp_messages from 'messages/otp_messages';
import { useOTPAuthModalContext } from 'reactContext/OTPAuthModalContext/OTPAuthModalContext';

const queryKey = QUERY_KEYS.GET_CURRENT_USER;

const useTwoFASwitch = () => {
  const queryClient = useQueryClient();
  const { showOTPAuthModal } = useOTPAuthModalContext();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: currentUserData, refetch } = useQuery<CurrentUser>(queryKey, usersApi.getCurrentUser);
  const isTwoFAEnabled = useMemo(() => currentUserData?.has_2fa_enabled, [currentUserData]);

  const switchTwoFAMutation = useMutation('switchTwoFA', manageTotpDevicesApi.switchTwoFactorAuth, {
    onMutate: () => {
      const currentData = queryClient.getQueryData<CurrentUser>(queryKey);
      if (currentData) {
        queryClient.setQueryData<CurrentUser>(queryKey, {
          ...currentData,
          has_2fa_enabled: !currentData.has_2fa_enabled,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: () => {
      enqueueSnackbar(t(otp_messages.otp_auth_failed), { variant: 'error' });
    },
  });

  const disableTwoFA = async () => {
    const otdData = await showOTPAuthModal();
    if (otdData) {
      await switchTwoFAMutation.mutateAsync({
        enabled: false,
        token: otdData.otpToken,
        deviceId: otdData.deviceId,
      });
      return 'success';
    }

    enqueueSnackbar(t(otp_messages.otp_auth_failed), { variant: 'error' });
    return null;
  };

  const enableTwoFA = () => switchTwoFAMutation.mutateAsync({ enabled: true });

  return { disableTwoFA, enableTwoFA, isTwoFAEnabled, refetchTwoFAStatus: refetch };
};

export default useTwoFASwitch;
