import React, { useCallback, useMemo, useState } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import AssetRiskDialog from 'components/_dialogs/AssetRiskDialog/AssetRiskDialog';
import AssetRiskTaskDialog from 'components/_dialogs/AssetRiskTaskDialog/AssetRiskTaskDialog';
import ColoredButton from 'components/ColoredButton';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import activeUserRiskAnalysesApi from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import assetRisksApi, { legacyAssetRisksApi } from 'config/api/assetRisks/assetRisks';
import { parseAllAssetRisksForFE } from 'config/api/assetRisks/assetRisks.parsers';
import { AssetRisk } from 'config/api/assetRisks/assetRisks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import EVENTS from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import affectsIndividualsFilter from 'services/_filters/parsers/_riskAnalysisFilters/affectsIndividualsFilter';
import riskQuadrantFilter from 'services/_filters/parsers/_riskAnalysisFilters/riskQuadrantFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

import riskDecisionFilter from '../../../../services/_filters/parsers/_riskAnalysisFilters/riskDecisionFilter';
import RiskQuadrantCell from '../RiskQuadrantCell/RiskQuadrantCell';

type Props = {
  riskAnalysisId: string;
};

const RiskAnalysisRisksTable: React.FC<Props> = ({ riskAnalysisId }) => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();
  const { showConfirmationModal } = useConfirmationModalContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const showRiskAnalysisRisksTableIntroductionDialog = () => {
    showHelpDialog({
      title: t(asset_risk_messages.risk_table_introduction.title),
      body: t(asset_risk_messages.risk_table_introduction.body),
    });
  };

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [editDialogId, setEditDialogId] = useState<null | Id>(null);
  const isEditDialogOpen = useMemo(() => Boolean(editDialogId), [editDialogId]);

  const [addTaskDialogAssetData, setAddTaskDialogAssetData] = useState<null | AssetRisk>(null);
  const isTaskDialogOpen = useMemo(() => Boolean(addTaskDialogAssetData), [addTaskDialogAssetData]);

  const { data: baseRiskData } = useQuery({
    queryKey: [QUERY_KEYS.GET_SINGLE_RISK_ANALYSIS, riskAnalysisId],
    queryFn: () => activeUserRiskAnalysesApi.getSingleActiveUserRiskAnalysis(riskAnalysisId),
  });

  const onAdd = () => {
    setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const onEdit = (id: number) => {
    setEditDialogId(id);
  };

  const closeEditDialog = () => {
    setEditDialogId(null);
  };

  const onAddTask = (assetRisk: AssetRisk) => {
    setAddTaskDialogAssetData(assetRisk);
  };

  const closeAddTaskDialog = () => {
    setAddTaskDialogAssetData(null);
  };

  const removeMutation = useMutation(assetRisksApi.removeAssetRisk);
  const onRemove = async (id: number) => {
    const confirmed = await showConfirmationModal({
      title: t(general_messages.are_you_sure),
      body: t(general_messages.operation_irreversible),
    });

    if (confirmed) {
      await removeMutation.mutateAsync(id);

      enqueueSnackbar(t(general_messages.data_removed), { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_RISK_ANALYSIS_MATRIX, riskAnalysisId] });
      PubSub.publish(EVENTS.RISK_ANALYSIS_RISKS_UPDATED);
    }
  };

  const availableFilters = usePrepareAvailableFilters({
    affect_individuals: affectsIndividualsFilter,
    risk_quadrant: riskQuadrantFilter,
    decision: riskDecisionFilter,
  });

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyAssetRisksApi.getAllAssetRisks(riskAnalysisId),
    limits: [5, 5, 5],
    isGet: true,
    sort,
    dependencies: [filters],
    filters: parseFiltersForRequest(filters, {}, availableFilters || {}),
  });

  useSubscription(EVENTS.RISK_ANALYSIS_RISKS_UPDATED, refreshData);

  const renderContextMenu = useCallback(
    (data: AssetRisk) => {
      const elements = [
        { label: t(general_messages.edit), onClick: () => onEdit(data.id), disabled: baseRiskData?.status === 'completed' },
        { label: t(general_messages.delete), onClick: () => onRemove(data.id), disabled: !data.canBeDeleted },
        {
          label: t(asset_risk_messages.risk_table.context_add_task),
          onClick: () => onAddTask(data),
          disabled: baseRiskData?.status === 'completed',
        },
      ];

      return (
        <ContextMenu elements={elements} id={`assetRisk-${data.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData, baseRiskData],
  );

  const dataMapping: TableDataMappingRow<AssetRisk>[] = useMemo(
    () => [
      { label: t(asset_risk_messages.risk_table.risk_label), id: 'name', get: data => data.name, width: '20%' },
      {
        label: t(asset_risk_messages.risk_table.consequence_label),
        id: 'primary_consequence',
        get: data => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{data.primaryConsequence}</TypographyWithHTML>
          </TrimHTMLText>
        ),
        width: '28%',
      },
      {
        label: t(asset_risk_messages.risk_table.priority_label),
        id: 'risk_quadrant',
        get: data => <RiskQuadrantCell consequence={data.consequence} probability={data.probability} riskQuadrant={data.riskQuadrant} />,
        width: '20%',
      },
      {
        label: t(asset_risk_messages.risk_table.decision_label),
        id: 'decision',
        get: data => t(asset_risk_messages.risk_decision[data.decision]),
        width: '10%',
      },
      {
        label: t(asset_risk_messages.risk_table.affects_ppl_label),
        id: 'affect_individuals',
        get: data => (data.affectIndividuals ? t(general_messages.yes) : t(general_messages.no)),
        width: '10%',
      },
      {
        label: t(asset_risk_messages.risk_table.tasks_label),
        id: 'risk_tasks_count',
        get: data => data.riskTasksCount,
        width: '6%',
      },
      {
        label: '',
        id: 'context',
        isAddon: true,
        blockSorting: true,
        get: (data): React.ReactNode => renderContextMenu(data) || ' ',
        width: '6%',
      },
    ],
    [t, tableData, baseRiskData],
  );

  const addingNewDisabled = useMemo(() => baseRiskData?.status === 'completed', [baseRiskData]);

  return (
    <Section
      paddingTop={3}
      showSeparator
      smallPadding
      title={asset_risk_messages.risk_table.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showRiskAnalysisRisksTableIntroductionDialog}>
          {t(asset_risk_messages.risk_table_introduction.button)}
        </TextButton>,
        <Tooltip key='button' title={addingNewDisabled ? t(asset_risk_messages.disabled_info.adding_new_risk) : ''}>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={addingNewDisabled} onClick={onAdd}>
            {t(asset_risk_messages.risk_table.add_button)}
          </ColoredButton>
        </Tooltip>,
      ]}
      titleVariant='h3'
    >
      <Table
        data={tableData && parseAllAssetRisksForFE(tableData)}
        dataMapping={dataMapping}
        filtersService={{ filters, setFilters, availableFilters }}
        onSort={onSort}
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
      {isAddDialogOpen && <AssetRiskDialog onClose={closeAddDialog} open={isAddDialogOpen} riskAnalysisId={riskAnalysisId} />}
      {isEditDialogOpen && (
        <AssetRiskDialog
          onClose={closeEditDialog}
          open={isEditDialogOpen}
          riskAnalysisId={riskAnalysisId}
          riskId={editDialogId as number}
        />
      )}
      {isTaskDialogOpen && (
        <AssetRiskTaskDialog
          onClose={closeAddTaskDialog}
          open={isTaskDialogOpen}
          previewMode={false}
          risk={addTaskDialogAssetData}
          taskId={null}
        />
      )}
    </Section>
  );
};

export default RiskAnalysisRisksTable;
