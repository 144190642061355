import { parseAuthorForFE, parseLanguageForFE, parseServiceAreaForFE } from '../../../parsers/generalParser';
import { RecommendedSectionItem, RecommendedSectionItemDTO } from '../../../types/General';

// eslint-disable-next-line import/prefer-default-export
export const parseRecommendedItemForFE = (raw: RecommendedSectionItemDTO[]): RecommendedSectionItem[] =>
  raw.map(item => ({
    heading: item.heading,
    objectContentType: item.object_content_type,
    createdAt: new Date(item.created_at),
    updatedAt: new Date(item.updated_at),
    versionId: item.version_id,
    completedAt: item.completed_at ? new Date(item.completed_at) : null,
    progressId: item.progress_id || null,
    metadata: {
      versionId: item.metadata?.version_id || '',
    },
    author: parseAuthorForFE(item.author),
    language: parseLanguageForFE(item.language),
    serviceArea: parseServiceAreaForFE(item.service_area),
    id: item.id || '',
    audience: item.audience || null,
    customerLevel: item.customer_level || null,
    depthLevel: item.depth_level || null,
    description: item.description,
    estimatedTime: item.estimated_time,
    publishStatus: item.publish_status,
    title: item.title,
    tags: item.tags,
    isSticky: item.is_sticky,
    friendlyId: item.friendly_id,
    imageUrl: item.image_url,
    originalImageUrl: item.original_image_url,
  }));
