import React, { useMemo } from 'react';

import { ButtonBase, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import MenuElementsList from 'components/Layout/_components/MenuElementsList/MenuElementsList';
import MenuSection from 'components/Layout/_components/MenuSection';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import menu_messages from 'messages/menu_messages';
import { useAuthContext } from 'reactContext/AuthContext';
import PATHS from 'router/PATHS';
import useLayoutStore from 'storages/layoutStore';
import userDataStorage from 'storages/userDataStorage';

import menuElements from '../menuElements';

import useStyles from './UserSection.styles';

type ButtonProps = {
  onClick: () => void;
};

const AuthorizedButton: React.FC<ButtonProps> = ({ onClick }) => {
  const { isMenuOpen } = useLayoutStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // @ts-ignore
  const { id: userId } = userDataStorage.get() || {};

  const styles = useStyles({ selected: pathname === PATHS.ROOT });
  return isMenuOpen ? (
    <ButtonBase className={styles.button} component={Link} onClick={onClick} to={PATHS.ROOT}>
      <span className={styles.buttonContent}>
        <UserAvatar userId={userId} />
        <Typography className={styles.dashboardText}>{t(menu_messages.dashboard)}</Typography>
      </span>
    </ButtonBase>
  ) : (
    <IconButton component={Link} disableRipple to={PATHS.ROOT}>
      <UserAvatar disableUserCard userId={userId} />
    </IconButton>
  );
};

const UnauthorizedButton: React.FC<ButtonProps> = ({ onClick }) => {
  const { isMenuOpen } = useLayoutStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const styles = useStyles({ selected: pathname === PATHS.AUTH_LOGIN });
  return isMenuOpen ? (
    <ButtonBase className={styles.button} component={Link} onClick={onClick} to={PATHS.AUTH_LOGIN}>
      <span className={styles.buttonContent}>
        <LockOutlinedIcon className={styles.lockIcon} />
        <Typography>{t(menu_messages.login)}</Typography>
      </span>
    </ButtonBase>
  ) : (
    <IconButton component={Link} disableRipple to={PATHS.AUTH_LOGIN}>
      <LockOutlinedIcon className={styles.lockIcon} />
    </IconButton>
  );
};

const UserSection = () => {
  const { isMenuOpen, closeMenu } = useLayoutStore();

  // @ts-ignore
  const { isAuthorized } = useAuthContext();

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const onClick = () => {
    if (!upMd) closeMenu();
  };

  const menuElementsTdDisplay = useMemo(() => {
    if (isAuthorized) {
      return menuElements.userAuthorized;
    }
    return process.env.REACT_APP_REGISTRATION_AVAILABLE === 'true' ? menuElements.userUnauthorized : [];
  }, [isAuthorized]);

  return (
    // @ts-ignore
    <MenuSection collapsible={false} keepVisible>
      {isAuthorized ? <AuthorizedButton onClick={onClick} /> : <UnauthorizedButton onClick={onClick} />}
      {isMenuOpen && <MenuElementsList elements={menuElementsTdDisplay} />}
    </MenuSection>
  );
};

export default UserSection;
