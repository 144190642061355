import PUBLISH_STATUSES from 'config/constants/PUBLISH_STATUSES';
import general_messages from 'messages/general_messages';

const TRANSLATABLE_PUBLISH_STATUSES_FALLBACKS = {
  PUBLISHED: general_messages.publish_statuses.published[1],
  INACTIVE: general_messages.publish_statuses.inactive[1],
  UNDER_REVISION: general_messages.publish_statuses.under_revision[1],
  NEW: general_messages.publish_statuses.new[1],
};

const TRANSLATABLE_PUBLISH_STATUSES = {
  PUBLISHED: [general_messages.publish_statuses.published[0], TRANSLATABLE_PUBLISH_STATUSES_FALLBACKS.PUBLISHED],
  INACTIVE: [general_messages.publish_statuses.inactive[0], TRANSLATABLE_PUBLISH_STATUSES_FALLBACKS.INACTIVE],
  UNDER_REVISION: [general_messages.publish_statuses.under_revision[0], TRANSLATABLE_PUBLISH_STATUSES_FALLBACKS.UNDER_REVISION],
  NEW: [general_messages.publish_statuses.new[0], TRANSLATABLE_PUBLISH_STATUSES_FALLBACKS.NEW],
};

export const TRANSLATABLE_PUBLISH_STATUSES_FILTER = Object.entries(PUBLISH_STATUSES).map(([key, value]) => ({
  id: value,
  name: TRANSLATABLE_PUBLISH_STATUSES[key],
}));

export const publishStatusResolver = typeKey => {
  switch (typeKey) {
    case PUBLISH_STATUSES.PUBLISHED:
      return TRANSLATABLE_PUBLISH_STATUSES.PUBLISHED;
    case PUBLISH_STATUSES.INACTIVE:
      return TRANSLATABLE_PUBLISH_STATUSES.INACTIVE;
    case PUBLISH_STATUSES.UNDER_REVISION:
      return TRANSLATABLE_PUBLISH_STATUSES.UNDER_REVISION;
    case PUBLISH_STATUSES.NEW:
      return TRANSLATABLE_PUBLISH_STATUSES.NEW;
    default:
      return general_messages.no_data;
  }
};

export default TRANSLATABLE_PUBLISH_STATUSES;
