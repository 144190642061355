import axios from 'axios';

import updateAuthTokens from 'services/updateAuthTokens/updateAuthTokens';

import { parseAllTotpDevicesForFE } from '../manageTotpDevices/manageTotpDevices.parsers';

import AuthTotpDevicesApi, { VerifyResponse } from './authTotpDevices.types';

export const endpoint = 'api/v1/auth/totp_devices/';

const authTotpDevicesApi: AuthTotpDevicesApi = {
  getTotpDevicesForAuth: async () => {
    const response = await axios.get(endpoint);
    return parseAllTotpDevicesForFE(response.data);
  },
  verifyTotpToken: async ({ deviceId, otpToken }): Promise<VerifyResponse> => {
    const { data } = await axios.post(`${endpoint}${deviceId}/verify_token/`, { otp_token: otpToken });

    if (data.refresh && data.access) updateAuthTokens({ access: data.access, refresh: data.refresh });
    return data;
  },
};

export default authTotpDevicesApi;
