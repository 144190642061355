import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormTextInput from 'components/FormTextInput';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const GuideComments = ({ formik }) => {
  const { t } = useTranslation();

  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Typography component='h2' variant='h5'>
        {t(...general_messages.comments)}
      </Typography>
      <FormTextInput formik={formik} id='comment' label={t(...guide_messages.comments_placeholder)} multiline />
    </div>
  );
};

GuideComments.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default GuideComments;
