import React from 'react';

import PropTypes from 'prop-types';

import useStyles from './ButtonsGrid.styles';

const ButtonsGrid = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.wrapper}>{children}</div>;
};

ButtonsGrid.propTypes = { children: PropTypes.node.isRequired };

export default ButtonsGrid;
