import React, { useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import EditRiskAnalysisMetadataDialog from 'components/_dialogs/EditRiskAnalysisMetadataDialog/EditRiskAnalysisMetadataDialog';
import DateWithCalendarIcon from 'components/DateWithCalendarIcon/DateWithCalendarIcon';
import activeUserRiskAnalysesApi from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import { Id } from 'types/Id';

import useStyles from './RiskAnalysisMetadata.styles';

type Props = {
  riskAnalysisId: Id;
};

const RiskAnalysisMetadata: React.FC<Props> = ({ riskAnalysisId }) => {
  const { t } = useTranslation();
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const openEditDialog = () => setEditDialogOpen(true);
  const closeEditDialog = () => setEditDialogOpen(false);

  const { data: baseRiskData, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_SINGLE_RISK_ANALYSIS, riskAnalysisId],
    queryFn: () => activeUserRiskAnalysesApi.getSingleActiveUserRiskAnalysis(riskAnalysisId),
  });

  const styles = useStyles();
  return (
    <section>
      <div className={styles.titleContainer}>
        <Typography variant='h3'>{t(asset_risk_messages.details_page.metadata_title)}</Typography>
        <IconButton onClick={openEditDialog} size='small'>
          <PenIcon />
        </IconButton>
      </div>
      <div className={styles.metadataSection}>
        <DateWithCalendarIcon
          date={baseRiskData?.createdAt}
          dateFormat={DATE_FORMAT.defaultDateWithTime}
          label={t(asset_risk_messages.details_page.creation_date_label)}
        />
        <DateWithCalendarIcon
          date={baseRiskData?.updatedAt}
          dateFormat={DATE_FORMAT.defaultDateWithTime}
          label={t(asset_risk_messages.details_page.last_update_label)}
        />
        <DateWithCalendarIcon date={baseRiskData?.date} label={t(asset_risk_messages.details_page.date_of_analysis_label)} />
        <div>
          <Typography>{t(asset_risk_messages.details_page.status_label)}</Typography>
          <Typography>{baseRiskData ? t(asset_risk_messages.status[baseRiskData.status]) : <Skeleton width={120} />}</Typography>
        </div>
        <div>
          <Typography>{t(asset_risk_messages.details_page.participants_label)}</Typography>
          <Typography>{isLoading ? <Skeleton width={300} /> : baseRiskData?.participants || t(general_messages.no_data)}</Typography>
        </div>
      </div>
      {isEditDialogOpen && (
        <EditRiskAnalysisMetadataDialog
          initialData={baseRiskData}
          onClose={closeEditDialog}
          open={isEditDialogOpen}
          riskAnalysisId={riskAnalysisId}
        />
      )}
    </section>
  );
};

export default RiskAnalysisMetadata;
