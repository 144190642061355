import React from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import BorderedCard from 'components/BorderedCard/BorderedCard';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import DetailsCardHeader from 'components/DetailsCardHeader/DetailsCardHeader';
import DialogViewWrapper from 'components/DialogViewWrapper';
import Loader from 'components/Loader';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import YearPlannerActivityDownloads from 'components/YearPlannerActivityDownloads/YearPlannerActivityDownloads';
import YearPlannerActivityRelatedContent from 'components/YearPlannerActivityRelatedContent/YearPlannerActivityRelatedContent';
import { formatTimePeriodToString } from 'config/api/yearPlannerActivities/yearPlannerActivities.parsers';
import ROLES from 'config/constants/ROLES';
import safeT from 'helpers/safeT/safeT';
import useYearPlannerAssetActivityQuery from 'hooks/_query/useYearPlannerAssetActivityQuery/useYearPlannerAssetActivityQuery';
import usePermissions from 'hooks/usePermissions';
import assets_messages from 'messages/assets_messages';
import PATHS from 'router/PATHS';

import useStyles from './SingleYearPlannerAssetActivityPage.styles';

const SingleYearPlannerAssetActivityPage: React.FC = () => {
  const [isAssetManager] = usePermissions([ROLES.ORGANIZATION.ASSETS_MANAGER]);

  const history = useHistory();
  const { t } = useTranslation();
  const { yearPlannerActivity, yearPlannerActivityIsLoading, activityId } = useYearPlannerAssetActivityQuery();

  const onEdit = () => {
    history.push(generatePath(`${PATHS.YEAR_PLANNER_ASSETS_ACTIVITY_EDIT}/:activityId`, { activityId }));
  };

  const onClose = () => {
    history.push(PATHS.ASSETS);
  };

  const styles = useStyles();
  return (
    <DialogViewWrapper contentSize='640px' noCardPadding title={t(assets_messages.details_activities_page.title)}>
      {yearPlannerActivityIsLoading || !yearPlannerActivity ? (
        <Loader inner />
      ) : (
        <div>
          <DetailsCardHeader
            onClose={onClose}
            onEdit={isAssetManager ? onEdit : undefined}
            title={safeT(t, yearPlannerActivity.activity.nameTranslationKey, yearPlannerActivity.activity.name)}
          />
          <div className={styles.paddingSection}>
            <CenteredGrid gridGap={4} withoutPadding>
              <div className={styles.section}>
                <Typography variant='h5'>
                  {t(assets_messages.year_activity_asset.activity_description_title, {
                    activityName: safeT(t, yearPlannerActivity.activity.nameTranslationKey, yearPlannerActivity.activity.name),
                  })}
                </Typography>
                <TypographyWithHTML>
                  {safeT(t, yearPlannerActivity.activity.descriptionTranslationKey, yearPlannerActivity.activity.description)}
                </TypographyWithHTML>
              </div>
              <div className={styles.section}>
                <Typography variant='h5'>{t(assets_messages.year_activity_asset.company_notes_title)}</Typography>
                <Typography>{yearPlannerActivity.companyNotes}</Typography>
              </div>

              <div className={styles.section}>
                <Typography variant='h5'>{t(assets_messages.year_activity_asset.dates_title)}</Typography>
                <div className={styles.twoColumnSection}>
                  <Typography>
                    {t(assets_messages.year_activity_asset.begin_date)}: {formatTimePeriodToString(yearPlannerActivity.begin)}
                  </Typography>
                  <Typography>
                    {t(t(assets_messages.year_activity_asset.end_date))}: {formatTimePeriodToString(yearPlannerActivity.end)}
                  </Typography>
                </div>
              </div>

              <div className={styles.section}>
                <Typography variant='h5'>{t(assets_messages.year_activity_asset.categories_title)}</Typography>
                {yearPlannerActivity.categories.map(({ name, id, nameTranslationKey }) => (
                  <BorderedCard key={id}>
                    <Typography>{safeT(t, nameTranslationKey, name)}</Typography>
                  </BorderedCard>
                ))}
              </div>

              <div className={styles.section}>
                <Typography variant='h5'>{t(assets_messages.year_activity_asset.templates_title)}</Typography>
                <YearPlannerActivityDownloads elements={yearPlannerActivity.activity.templates} />
              </div>
            </CenteredGrid>
          </div>
          <div className={styles.section}>
            <YearPlannerActivityRelatedContent
              relatedArticles={yearPlannerActivity.activity.relatedArticles}
              relatedGuides={yearPlannerActivity.activity.relatedGuides}
              relatedSelfTests={yearPlannerActivity.activity.relatedSelfTests}
            />
          </div>
        </div>
      )}
    </DialogViewWrapper>
  );
};

export default SingleYearPlannerAssetActivityPage;
