import { UrlString } from 'types/UrlString';

const downloadFromUrl = async (url: UrlString, name: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  const tag = document.createElement('a');
  tag.href = imageUrl;
  tag.download = name;
  document.body.appendChild(tag);
  tag.click();
  document.body.removeChild(tag);
};

export default downloadFromUrl;
