import { parseAuthorForFE, parseLanguageForFE, parseServiceAreaForFE } from 'parsers/generalParser';

import {
  AnsweredQuestion,
  AnsweredQuestionDTO,
  ContentItem,
  ContentItemDTO,
  RelatedGuide,
  RelatedGuideDTO,
  SelfTestFeedback,
  SelfTestFeedbackDTO,
  SelfTestProgress,
  SelfTestProgressDTO,
  SelfTestResult,
  SelfTestResultDTO,
  SingleSelfTestDetails,
  SingleSelfTestDetailsDTO,
} from './_types';

export const parseAnswerForFe = (raw: AnsweredQuestionDTO): AnsweredQuestion => ({
  questionId: raw.question_id,
  id: raw.id,
  userSelfTestId: raw.user_self_test_id,
  answerId: raw.answer_id,
});

export const parseAnswerForBE = (answeredQuestion: AnsweredQuestion): AnsweredQuestionDTO => ({
  question_id: answeredQuestion.questionId,
  id: answeredQuestion.id,
  user_self_test_id: answeredQuestion.userSelfTestId,
  answer_id: answeredQuestion.answerId,
});

// TODO split it to three parsers
export const parseContentItemForFE = (raw: ContentItemDTO[]): ContentItem[] =>
  raw.map(object => ({
    metadata: {
      versionId: object.metadata?.version_id || object.version_id,
    },
    author: parseAuthorForFE(object.author),
    language: parseLanguageForFE(object.language),
    serviceArea: parseServiceAreaForFE(object.service_area),
    id: object.id,
    audience: object.audience,
    createdAt: new Date(object.created_at),
    customerLevel: object.customer_level,
    depthLevel: object.depth_level,
    description: object.description,
    estimatedTime: object.estimated_time,
    heading: object.heading,
    publishStatus: object.publish_status,
    title: object.title,
    updatedAt: new Date(object.updated_at),
    tags: object.tags,
    isSticky: object.is_sticky,
    friendlyId: object.friendly_id,
    imageUrl: object.image_url,
    originalImageUrl: object.original_image_url,
    objectContentType: object.object_content_type,
    versionId: object.version_id || '',
    teaser: object.teaser,
    isSaved: object.is_saved,
  }));

export const parseSingleSelfTestDetailsForFE = (raw: SingleSelfTestDetailsDTO): SingleSelfTestDetails => ({
  author: parseAuthorForFE({ ...raw.author }),
  language: parseLanguageForFE({ ...raw.language }),
  serviceArea: parseServiceAreaForFE({ ...raw.service_area }),
  id: raw.id,
  description: raw.description,
  heading: raw.heading,
  publishStatus: raw.publish_status,
  isSticky: raw.is_sticky,
  imageUrl: raw.image_url,
  versionId: raw.version_id,
  versionNumber: raw.version_number,
  questions: raw.questions,
  maxScore: raw.max_score || 0,
});

export const parseSelfTestProgressInstanceForFE = (raw: SelfTestProgressDTO): SelfTestProgress => ({
  id: raw.id,
  selfTest: {
    id: raw.self_test.id,
    versionId: raw.self_test.version_id,
    versionNumber: raw.self_test.version_number,
    author: parseAuthorForFE(raw.self_test.author),
    language: parseLanguageForFE(raw.self_test.language),
    serviceArea: parseServiceAreaForFE(raw.self_test.service_area),
    description: raw.self_test.description,
    heading: raw.self_test.heading,
    imageUrl: raw.self_test.image_url,
    publishStatus: raw.self_test.publish_status,
    isSticky: raw.self_test.is_sticky,
  },
  answeredQuestions: raw.answered_questions?.map(question => parseAnswerForFe(question)),
  progressStatus: raw.progress_status,
});

export const parseRelatedItemForFE = (raw: ContentItemDTO): ContentItem => ({
  id: raw.id,
  createdAt: new Date(raw.created_at),
  updatedAt: new Date(raw.updated_at),
  author: parseAuthorForFE(raw.author),
  customerLevel: raw.customer_level,
  depthLevel: raw.depth_level,
  estimatedTime: raw.estimated_time,
  friendlyId: raw.friendly_id,
  heading: raw.heading,
  imageUrl: raw.image_url,
  isSaved: raw.is_saved,
  language: parseLanguageForFE(raw.language),
  publishStatus: raw.publish_status,
  serviceArea: parseServiceAreaForFE(raw.service_area),
  tags: raw.tags,
  teaser: raw.teaser,
  title: raw.title,
  description: raw.description,
  metadata: { versionId: raw.metadata?.version_id },
  audience: raw.audience || null,
  isSticky: raw.is_sticky || false,
  originalImageUrl: raw.original_image_url || '',
  objectContentType: raw.object_content_type || '',
  versionId: raw.version_id || '',
});

export const parseRelatedGuideForFE = (raw: RelatedGuideDTO[]): RelatedGuide[] =>
  raw.map(item => ({
    id: item.id,
    relatedArticles: item.related_articles,
  }));

export const parseTestResultForFE = (raw: SelfTestResultDTO): SelfTestResult => ({
  id: raw.id,
  selfTest: parseSingleSelfTestDetailsForFE(raw.self_test),
  progressStatus: raw.progress_status,
  currentScore: raw.current_score,
  maxScore: raw.max_score,
  answeredQuestions: raw.answered_questions.map(question => ({
    id: question.id,
    questionId: question.question_id,
    userSelfTestId: question.user_self_test_id,
    answerId: question.answer_id,
  })),
  recommendationTexts: raw.recommendation_texts,
  recommendedArticles: raw.recommended_articles.map(item => parseRelatedItemForFE(item)),
  recommendedGuides: raw.recommended_guides.map(item => parseRelatedItemForFE(item)),
  metadata: {
    versionId: raw.metadata?.version_id,
  },
});

export type SelfTestProgressIdDTO = {
  self_test_id: string;
};

export type SelfTestProgressIdDTOWithRootId = SelfTestProgressIdDTO & {
  id: 0;
};

export type SelfTestProgressIdWithSelfId = SelfTestProgressId & {
  id: number;
};

export type SelfTestProgressId = {
  selfTestId: string;
};

export const parseProgressForFE = (raw: SelfTestProgressIdDTOWithRootId): SelfTestProgressIdWithSelfId => ({
  id: raw.id,
  selfTestId: raw.self_test_id,
});

export const parseFeedbackTextForFE = (raw: SelfTestFeedbackDTO): SelfTestFeedback => ({
  heading: raw.heading,
  description: raw.description,
});

export const parseSelfTestIdForBE = (id: string): SelfTestProgressIdDTO => ({
  self_test_id: id,
});
