import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import FormDateInput from 'components/FormDateInput';
import FormTextInput from 'components/FormTextInput';
import CONTROL_TYPES from 'config/constants/CONTROL_TYPES';

const useStyles = makeStyles(theme => ({
  inputsWrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    width: '100%',
  },
  heading: {
    fontWeight: '500',
  },
  wrapper: {
    width: '100%',
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}));

const IncidentFormBlock = ({ heading, fields, formik, notEditable }) => {
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputsWrapper}>
        <Typography className={styles.heading}>{heading}</Typography>
        {fields.map(field => {
          switch (field.data_type) {
            case CONTROL_TYPES.DATE:
              return (
                <FormDateInput
                  key={`${CONTROL_TYPES.DATE}-${field.id}`}
                  disabled={notEditable}
                  formik={formik}
                  id={field.id}
                  label={field.label}
                />
              );
            case CONTROL_TYPES.NUMBER:
              return (
                <FormTextInput
                  key={`${CONTROL_TYPES.NUMBER}-${field.id}`}
                  disabled={notEditable}
                  formik={formik}
                  id={field.id}
                  label={field.label}
                  type='number'
                />
              );
            case CONTROL_TYPES.TEXT:
              return (
                <FormTextInput
                  key={`${CONTROL_TYPES.TEXT}-${field.id}`}
                  disabled={notEditable}
                  formik={formik}
                  id={field.id}
                  label={field.label}
                  multiline
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

IncidentFormBlock.propTypes = {
  notEditable: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formik: PropTypes.shape({}).isRequired,
};

IncidentFormBlock.defaultProps = {
  notEditable: false,
};

export default IncidentFormBlock;
