import React from 'react';

import { Typography, Container, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

import Jumbotron from '../Jumbotron';

import useStyles from './DialogViewWrapper.styles';

const DialogViewWrapper = ({ children, title, contentSize, noCardPadding, maxWidth }) => {
  const styles = useStyles({ contentSize, noCardPadding });

  return (
    <Container className={styles.wrapper} maxWidth={maxWidth}>
      <Jumbotron background='white'>
        <Typography align='center' component='h1' variant='h1'>
          {title}
        </Typography>
      </Jumbotron>
      <Paper className={styles.content}>{children}</Paper>
    </Container>
  );
};

DialogViewWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  contentSize: PropTypes.string,
  noCardPadding: PropTypes.bool,
  maxWidth: PropTypes.string,
};

DialogViewWrapper.defaultProps = {
  contentSize: 'sm',
  maxWidth: 'md',
};

export default DialogViewWrapper;
