import { AssetTaskStatus } from 'config/api/_types/AssetTaskStatus';
import assets_messages from 'messages/assets_messages';

const TASK_STATUSES_SELECT: { key: AssetTaskStatus; label: string[] }[] = [
  { key: 'started', label: assets_messages.asset_task_status.started },
  { key: 'not_started', label: assets_messages.asset_task_status.not_started },
  { key: 'finished', label: assets_messages.asset_task_status.finished },
];

export default TASK_STATUSES_SELECT;
