import general_messages from 'messages/general_messages';

const LANGUAGES_FALLBACKS = {
  EN: general_messages.languages.en[1],
  SV: general_messages.languages.sv[1],
};

const LANGUAGES = {
  EN: [general_messages.languages.en[0], LANGUAGES_FALLBACKS.EN],
  SV: [general_messages.languages.sv[0], LANGUAGES_FALLBACKS.SV],
};

export const LANGUAGES_ARRAY = Object.values(LANGUAGES);

export const LANGUAGES_DICTIONARY = LANGUAGES_ARRAY.map((label, key) => ({ label, key: key + 1 }));

export default LANGUAGES;
