import React from 'react';

import clsx from 'clsx';

import { ContentItem } from 'config/api/selfTests/_types';

import ContentGuideTile, { GuideTileLoader } from '../ContentGuideTile/ContentGuideTile';

import useStyles from './GuidesTilesGrid.styles';

type Props = {
  data: ContentItem[];
  placeholdersNumber?: number;
  scrollable?: boolean;
};

const GuidesTilesGrid: React.FC<Props> = ({ data, placeholdersNumber = 3, scrollable }) => {
  const styles = useStyles({ length: data?.length });

  if (!data)
    return (
      <div className={clsx(styles.grid, scrollable && styles.scrollableGrid)}>
        {Array.from(Array(placeholdersNumber).keys()).map(key => (
          <GuideTileLoader key={key} />
        ))}
      </div>
    );
  return (
    <div className={clsx(styles.grid, scrollable && styles.scrollableGrid)}>
      {data.map(content => (
        <ContentGuideTile key={content.id} data={content} />
      ))}
    </div>
  );
};

export default GuidesTilesGrid;
