const INCIDENT_PHASES = {
  IDENTIFY_AND_REPORT: 'identify-and-report',
  RECEIVE: 'receive',
  MANAGE_THE_INCIDENT: 'manage-the-incident',
  CLOSE_AND_SEND_FEEDBACK: 'close-and-send-feedback',
  CLOSED: 'closed',
};

export type IncidentPhaseT = typeof INCIDENT_PHASES[keyof typeof INCIDENT_PHASES];

export const incidentPhaseInOrder: IncidentPhaseT[] = [
  INCIDENT_PHASES.IDENTIFY_AND_REPORT,
  INCIDENT_PHASES.RECEIVE,
  INCIDENT_PHASES.MANAGE_THE_INCIDENT,
  INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK,
  INCIDENT_PHASES.CLOSED,
];

export default INCIDENT_PHASES;
