import React from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import SpecialMessageWrapper from 'components/SpecialMessageWrapper';
import general_messages from 'messages/general_messages';

const SomethingWentWrong = () => {
  const { t } = useTranslation();
  return (
    <SpecialMessageWrapper>
      <Typography variant='h2'>{t(...general_messages.something_went_wrong)}</Typography>
    </SpecialMessageWrapper>
  );
};

export default SomethingWentWrong;
