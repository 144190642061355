import addZeroPrefix from 'helpers/addZeroPrefix/addZeroPrefix';

import { parseRelatedItemForFE } from '../selfTests/parsers';

import { TimePeriod, YearPlannerActivityInput } from './yearPlannerActivities.types';
import type {
  YearPlannerActivityDetails,
  YearPlannerActivityDetailsDTO,
  YearPlannerActivity,
  YearPlannerActivityDTO,
  YearPlannerActivityInputDTO,
} from './yearPlannerActivities.types';

const parseDateForFE = (date: string): TimePeriod => {
  const [month, day] = date.split('-');
  return { month: +month, day: +day };
};

export const formatTimePeriodToString = ({ month, day }: TimePeriod) => `${addZeroPrefix(month)}-${addZeroPrefix(day)}`;

const parseDateForBE = ({ month, day }: TimePeriod): string => `${addZeroPrefix(month + 1)}-${addZeroPrefix(day)}`;

export const parseSingleYearPlannerActivityForFE = (raw: YearPlannerActivityDetailsDTO): YearPlannerActivityDetails => ({
  id: raw.id,
  begin: parseDateForFE(raw.begin),
  end: parseDateForFE(raw.end),
  activity: {
    id: raw.activity.id,
    name: raw.activity.name,
    nameTranslationKey: raw.activity.name_translation_key,
    description: raw.activity.description,
    descriptionTranslationKey: raw.activity.description_translation_key,
    relatedArticles: raw.activity.related_articles.map(parseRelatedItemForFE),
    relatedGuides: raw.activity.related_guides.map(parseRelatedItemForFE),
    relatedSelfTests: raw.activity.related_self_tests.map(parseRelatedItemForFE),
    templates: raw.activity.templates,
  },
  categories: raw.categories.map(({ id, name, name_translation_key }) => ({ id, name, nameTranslationKey: name_translation_key })),
  companyNotes: raw.company_notes,
});

export const parseAllYearPlannerActivityForFE = (raw: YearPlannerActivityDTO[]): YearPlannerActivity[] =>
  raw.map(({ id, activity, end, begin }) => ({
    id,
    end: parseDateForFE(end),
    begin: parseDateForFE(begin),
    activity: {
      id: activity.id,
      description: activity.description,
      descriptionTranslationKey: activity.description_translation_key,
      name: activity.name,
      nameTranslationKey: activity.name_translation_key,
    },
  }));

export const parseYearPlannerActivityCreateInputForBE = (formData: YearPlannerActivityInput): YearPlannerActivityInputDTO => ({
  activity_id: formData.activityId,
  end: parseDateForBE(formData.end),
  begin: parseDateForBE(formData.begin),
  company_notes: formData.companyNotes || null,
  categories_ids: Object.entries(formData.categoriesIds)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => !!value)
    .map(([key]) => key),
});
