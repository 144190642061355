import React, { useMemo } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';

import useStyles from './ColoredTile.styles';

const ColoredTile = props => {
  const { title, description, color, onClick, centered, tileHref, children, disableRipple, isLoading, className } = props;
  const to = useMemo(() => {
    if (!tileHref) return undefined;
    if (typeof tileHref === 'string') return tileHref;
    return tileHref(props);
  }, []);

  const styles = useStyles({ color, centered, disableRipple });

  return (
    <ButtonBase
      className={className}
      color={color || 'secondary'}
      component={to ? Link : 'button'}
      data-testid='colored-tile'
      disableRipple={disableRipple}
      disableTouchRipple={disableRipple}
      focusRipple={disableRipple}
      onClick={onClick}
      to={to}
    >
      <div className={styles.container}>
        <div className={styles.tile}>
          <Typography align={centered ? 'center' : 'left'} component='h2' variant='h3'>
            {isLoading ? <Skeleton style={{ margin: '0 auto' }} width='1.5rem' /> : title}
          </Typography>
          <Typography align={centered ? 'center' : 'left'} component='p' variant='body1'>
            {isLoading ? <Skeleton style={{ margin: '0 auto' }} width='10rem' /> : description}
          </Typography>
        </div>

        {children && <div className={styles.children}>{children}</div>}
      </div>
    </ButtonBase>
  );
};

export default ColoredTile;
