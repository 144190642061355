import React from 'react';

import { Box, Button, Drawer, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterListIcon } from 'assets/icons/filter.svg';
import ColoredButton from 'components/ColoredButton';
import safeT from 'helpers/safeT/safeT';
import useBoolState from 'hooks/useBoolState';
import general_messages from 'messages/general_messages';

import FilterChip from './_components/FilterChip';

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderBottom: `3px solid ${theme.palette.primary[100]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2, 1, 0),
  },
  filtersTab: {
    minWidth: '220px',
    maxWidth: '320px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      ...theme.mixins.toolbarPadding,
    },
  },
  hideWrapper: {
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    zIndex: 1,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(2),
    justifyItems: 'start',
  },
  filtersTabWrapper: {
    padding: theme.spacing(0, 2, 2, 2),
    gridGap: theme.spacing(2),
    display: 'grid',
    justifyItems: 'start',
  },
  filterButton: {
    height: '41px',
    width: '41px',
    paddingTop: '14px',
    paddingBottom: '10px',
    boxShadow: theme.shadows[2],
  },
  filtersSection: {
    marginLeft: theme.spacing(2),
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  filtersCount: {
    fontWeight: 700,
    fontSize: '1.1rem',
    color: theme.palette.text.secondaryColor,
    marginRight: theme.spacing(1),
  },
  hideButton: {
    cursor: 'pointer',
  },
}));

const Filters = ({ filters, setFilters, availableFilters, withTab }) => {
  const { t } = useTranslation();

  const addFilter = ({ sectionKey, ...rest }) => {
    const { isSingleSelect } = availableFilters[sectionKey];
    setFilters(prevState => {
      const newState = { ...prevState };
      if (!newState[sectionKey]) newState[sectionKey] = [];

      // TODO add isSingleSelect handling for parser
      if (isSingleSelect) newState[sectionKey] = [rest];
      else newState[sectionKey] = [...newState[sectionKey], rest];

      return newState;
    });
  };

  const deleteFilter = ({ id, sectionKey }) => {
    setFilters(prevState => {
      const newState = { ...prevState };
      newState[sectionKey] = newState[sectionKey].filter(selected => selected.id !== id);
      return newState;
    });
  };
  const clearAllFilters = () => setFilters({});

  const { state: isFilersTabOpen, setFalse: closeFilersTab, setTrue: openFiltersTab } = useBoolState(false);

  const styles = useStyles();

  return (
    <div>
      {withTab ? (
        <div className={styles.wrapper}>
          <Box flexGrow={1}>
            {filters &&
              Object.entries(filters).map(([sectionKey, filterChips]) =>
                filterChips.map(({ name, id }) => (
                  <FilterChip
                    key={id}
                    deleteFilter={deleteFilter}
                    id={id}
                    isSelected
                    name={Array.isArray(name) ? t(...name) : name}
                    sectionKey={sectionKey}
                  />
                )),
              )}
          </Box>
          <ColoredButton className={styles.filterButton} customColor='secondary' icon onClick={openFiltersTab}>
            <FilterListIcon />
          </ColoredButton>
        </div>
      ) : (
        <div className={styles.buttonWrapper}>
          <span className={styles.filtersCount}>{filters.length ? filters.length : null}</span>
          <ColoredButton className={styles.filterButton} customColor='secondary' icon onClick={openFiltersTab}>
            <FilterListIcon />
          </ColoredButton>
        </div>
      )}

      <Drawer
        anchor='right'
        classes={{
          paper: styles.filtersTab,
        }}
        onClose={closeFilersTab}
        open={isFilersTabOpen}
        PaperProps={{
          elevation: 3,
        }}
        style={{ zIndex: 1250 }}
      >
        <div className={styles.hideWrapper}>
          <ColoredButton className={styles.filterButton} customColor='secondary' icon onClick={closeFilersTab}>
            <FilterListIcon />
          </ColoredButton>
          <Typography className={styles.hideButton} onClick={closeFilersTab} variant='button'>
            {t(...general_messages.hide)}
          </Typography>
        </div>
        <div className={styles.filtersTabWrapper}>
          <Button onClick={clearAllFilters}>{t(...general_messages.clear_all)}</Button>
          {availableFilters &&
            Object.entries(availableFilters).map(([key, { sectionName, filters: filtersToRender }]) => (
              <div key={key} className={styles.filtersSection}>
                {sectionName && (
                  <Typography component='h3' variant='subtitle1'>
                    {t(...sectionName)}
                  </Typography>
                )}
                <div>
                  {filtersToRender.map(({ id, name }) => (
                    <FilterChip
                      key={id}
                      addFilter={addFilter}
                      deleteFilter={deleteFilter}
                      filterTab
                      id={id}
                      isSelected={filters[key] && filters[key].findIndex(selected => selected.id === id) >= 0}
                      name={Array.isArray(name) ? safeT(t, ...name) : name}
                      sectionKey={key}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </Drawer>
    </div>
  );
};

const FiltersPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
);

Filters.propTypes = {
  availableFilters: PropTypes.objectOf(
    PropTypes.shape({
      sectionName: PropTypes.arrayOf(PropTypes.string),
      filters: FiltersPropTypes,
      isSingleSelect: PropTypes.bool,
    }),
  ),
  filters: PropTypes.objectOf(FiltersPropTypes),
  setFilters: PropTypes.func.isRequired,
  withTab: PropTypes.bool,
};

Filters.defaultProps = {
  filters: null,
  availableFilters: null,
  withTab: false,
};

export default Filters;
