import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import convertObjectToFormData from '../../../helpers/convertObjectToFormData';
import METHOD from '../_methods';

import {
  parseSingleActiveUserAssetTasksForFE,
  parseAllActiveUserAssetTasksForFE,
  parseFormDataToUpdate,
} from './activeUserAssetTasks.parsers';
import type ActiveUserAssetTasksApi from './activeUserAssetTasks.types';

const endpoint = 'api/v1/active_user/asset_tasks/';

// TODO: remove if react-query
export const legacyActiveUserAssetTasksApi: Record<string, (params: any) => { url: string; method: string }> = {
  getAllActiveUserAssetTasks: params => ({ url: createUrlWithParams(endpoint, params), method: METHOD.GET }),
};

const activeUserAssetTasksApi: ActiveUserAssetTasksApi = {
  getAllActiveUserAssetTasks: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllActiveUserAssetTasksForFE(data.results);
  },
  getSingleActiveUserAssetTask: async taskId => {
    const { data } = await axios.get(`${endpoint}${taskId}/`);
    return parseSingleActiveUserAssetTasksForFE(data);
  },
  updateSingleActiveUserAssetTask: taskId => async formData => {
    const { data } = await axios.patch(`${endpoint}${taskId}/`, parseFormDataToUpdate(formData));
    return parseSingleActiveUserAssetTasksForFE(data);
  },
  uploadSingleActiveUserAssetTaskDocument: taskId => async formData => {
    const response = await axios.post(`${endpoint}${taskId}/upload_document/`, convertObjectToFormData(formData));
    return response;
  },
  removeSingleActiveUserAssetTaskDocument: taskId => async fileId => {
    // TODO BE needed
    const response = await axios.delete(`${endpoint}${taskId}/upload_document/${fileId}`);
    return response;
  },
};

export default activeUserAssetTasksApi;
