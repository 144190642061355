import React from 'react';

import { useTranslation } from 'react-i18next';

import InfoBox from 'components/InfoBox/InfoBox';
import info_boxes_messages from 'messages/info_boxes_messages';

type Props = {
  action: () => void;
};

const DashboardInfoBoxCardInvalid: React.FC<Props> = ({ action }) => {
  const { t } = useTranslation();

  return (
    <InfoBox
      action={action}
      buttonLabel={t(info_boxes_messages.card_invalid.buttonLabel)}
      content={t(info_boxes_messages.card_invalid.content)}
      showButton
      title={t(info_boxes_messages.card_invalid.title)}
    />
  );
};

export default DashboardInfoBoxCardInvalid;
