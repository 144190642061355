import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 240px',
    gridGap: theme.spacing(5),
    gridTemplateAreas: `
      'header info'
      'form info'
    `,

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto',
      gridTemplateAreas: `
        'header'
        'info'
        'form'
      `,
    },
  },
  header: {
    gridArea: 'header',
  },
  bottomAdornment: {
    display: 'grid',
    paddingTop: theme.spacing(2),
    gridGap: theme.spacing(2),
  },
}));
