import React from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormTextInput from 'components/FormTextInput';
import meetingsEndpoints from 'config/api/meetings';
import useApiCall from 'hooks/useApiCall';
import meeting_messages from 'messages/meeting_messages';
import validation_messages from 'messages/validation_messages';

import EditMeetingDialogGrid from '../_components/EditMeetingDialogGrid';

const EditMeetingByAdvisorCancelDialog = ({ meetingId, onClose, open, openBase, onCancel }) => {
  const { t } = useTranslation();
  const { apiCall, loading } = useApiCall();

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: yup.object({
      comment: yup.string().required(t(...validation_messages.required)),
    }),
  });

  const onConfirm = async () => {
    const { status } = await apiCall(meetingsEndpoints.cancelMeetingAsSupervisor(meetingId), {
      data: { adviser_message: formik.values.comment },
    });
    if (status < 300) onCancel();
  };

  return (
    <EditMeetingDialogGrid
      disabled={!formik.isValid || !formik.dirty}
      loading={loading}
      onBack={openBase}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      title={t(...meeting_messages.edit_meeting_by_advisor.cancel.title)}
    >
      <FormTextInput
        formik={formik}
        id='comment'
        label={t(...meeting_messages.edit_meeting_by_advisor.cancel.comment_label)}
        multiline
        rows={3}
      />
    </EditMeetingDialogGrid>
  );
};

EditMeetingByAdvisorCancelDialog.propTypes = {
  meetingId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openBase: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditMeetingByAdvisorCancelDialog;
