import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  calendarColumn: {
    display: 'grid',
    width: '100%',
    gridGap: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
    },
  },
}));

const CalendarColumn = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.calendarColumn}>{children}</div>;
};

CalendarColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CalendarColumn;
