import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  menu: {
    display: 'flex',
    gap: theme.spacing(6),
    padding: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  monthsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gap: theme.spacing(1),
  },
  daysGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, auto)',
    gridTemplateRows: 'repeat(5, 2.25rem)',
    gap: theme.spacing(0.5),
    position: 'relative',

    '&:before': {
      content: '""',
      height: '100%',
      width: '1px',
      position: 'absolute',
      left: -theme.spacing(3),
      // @ts-ignore
      background: theme.palette.secondary[200],
    },
  },
  tile: {
    borderRadius: theme.shape.borderRadius,
    justifySelf: 'center',
  },
  selected: {
    // @ts-ignore
    background: theme.palette.secondary[200],
    '&:hover': {
      // @ts-ignore
      background: theme.palette.secondary[200],
    },
  },
}));
