import React from 'react';

import { useFormik } from 'formik';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormAutocomplete from 'components/FormAutocomplete';
import FormCheckbox from 'components/FormCheckbox';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import assetCategoriesApi from 'config/api/assetCategories/assetCategories';
import { assetsApi } from 'config/api/assets/assets';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import EVENTS from 'config/events/pubsub';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import coworkerOptionsParser from 'services/autocompleteServices/coworkerOptionsParser/coworkerOptionsParser';
import coworkerParamsGetter from 'services/autocompleteServices/coworkerParamsGetter/coworkerParamsGetter';
import coworkerResultComponent from 'services/autocompleteServices/coworkerResultComponent/coworkerResultComponent';

import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
};

export type CreateAssetFormInput = {
  name: string;
  category: string;
  owner: { key: number; fullName: string }[];
  fillDetails: boolean;
};

const FORM_ID = `AddAssetForm`;

const AddAssetDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const mutation = useMutation(QUERY_KEYS.ASSETS_CREATE, assetsApi.createAsset);

  const onSubmit = async (formData: CreateAssetFormInput) => {
    const { data } = await mutation.mutateAsync(formData);
    if (!data) return;
    if (formData.fillDetails) history.push(generatePath(`${PATHS.ASSETS_EDIT}/:assetId`, { assetId: data.id }));
    PubSub.publish(EVENTS.ASSETS_UPDATED);
    onClose();
  };

  const formik = useFormik<CreateAssetFormInput>({
    initialValues: {
      name: '',
      category: '',
      owner: [],
      fillDetails: false,
    },
    validationSchema: yup.object({
      name: yup.string().required(t(validation_messages.required)),
      category: yup.string().required(t(validation_messages.required)),
      owner: yup.array().min(1, t(validation_messages.required)),
    }),
    onSubmit,
  });

  const { data: categoriesOptions } = useQuery(QUERY_KEYS.ASSETS_CATEGORIES, assetCategoriesApi.getAllAssetCategoriesForSelect, {
    staleTime: 1000 * 60 * 10,
  });

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={mutation.isLoading} onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={mutation.isLoading} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(assets_messages.add_asset_modal.title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {/* @ts-ignore */}
        <CenteredGrid gridGap={2} withoutPadding>
          <FormTextInput formik={formik} id='name' label={t(assets_messages.information_label.name)} />
          <FormSelect
            formik={formik}
            id='category'
            label={t(assets_messages.information_label.category)}
            options={categoriesOptions || []}
          />
          <FormAutocomplete
            apiCallParamsGetter={coworkerParamsGetter}
            customizeLabel={coworkerResultComponent}
            formik={formik}
            id='owner'
            label={t(assets_messages.information_label.owner)}
            multiple={false}
            optionsParser={coworkerOptionsParser}
          />
          <FormCheckbox formik={formik} id='fillDetails' label={t(assets_messages.information_label.fill_details_checkbox)} />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default AddAssetDialog;
