import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import GuideStepCreateActionInput from 'components/_dialogs/GuideStepCreateDialog/_components/GuideStepCreateActionInput';
import ColoredButton from 'components/ColoredButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    justifyItems: 'center',
    borderTop: `1px solid ${theme.palette.secondary[200]}`,
  },
  button: {
    margin: theme.spacing(3, 0),
  },
}));

const getEmptyAction = () => ({
  newAction: true,
  id: uuid(),
});

const GuideStepCreateActions = ({ formik, fieldId, setValid }) => {
  const [expandedActions, setExpandedActions] = useState([]);
  const { values, setFieldValue } = formik;

  const onExpand = idToExpand => {
    setExpandedActions(prevExpandedActions => [...prevExpandedActions, idToExpand]);
  };

  const onShrink = idToShrink => {
    setExpandedActions(prevExpandedActions => prevExpandedActions.filter(index => idToShrink !== index));
  };

  const onRemove = idToRemove => {
    setFieldValue(
      fieldId,
      values[fieldId].filter(({ id }) => id !== idToRemove),
    );
  };

  const onAdd = () => {
    const newAction = getEmptyAction();
    setFieldValue(fieldId, [...values[fieldId], newAction]);
    setExpandedActions(prevExpandedActions => [...prevExpandedActions, newAction.id]);
  };

  const onUpdate = (idToUpdate, data) => {
    const newValues = [...values[fieldId]];
    const index = values[fieldId].findIndex(({ id }) => id === idToUpdate);
    newValues[index] = { ...data, id: uuid() };
    setFieldValue(fieldId, newValues);
  };

  useEffect(() => {
    setValid(!expandedActions.length);
  }, [expandedActions.length]);

  const styles = useStyles();
  return (
    <div className={styles.container}>
      {values[fieldId]
        ? values[fieldId].map(data => (
            <GuideStepCreateActionInput
              key={data.id}
              isExpanded={expandedActions.includes(data.id)}
              onExpand={onExpand}
              onRemove={onRemove}
              onShrink={onShrink}
              update={onUpdate}
              {...data}
            />
          ))
        : null}
      <ColoredButton className={styles.button} customColor='secondary' icon onClick={onAdd}>
        <AddIcon />
      </ColoredButton>
    </div>
  );
};

GuideStepCreateActions.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  fieldId: PropTypes.string.isRequired,
  setValid: PropTypes.func.isRequired,
};

export default GuideStepCreateActions;
