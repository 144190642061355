import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import InfoBox from 'components/InfoBox/InfoBox';
import organizationsApi from 'config/api/organizations/organizations';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import info_boxes_messages from 'messages/info_boxes_messages';
import userDataStorage from 'storages/userDataStorage';

type Props = {
  action: () => void;
};

const DashboardInfoBoxTrial: React.FC<Props> = ({ action }) => {
  const { t } = useTranslation();

  // @ts-ignore
  const { organization } = userDataStorage.get() || {};

  const { data: trialData } = useQuery(QUERY_KEYS.REMAINING_TRIAL_DAYS, () =>
    organizationsApi.getOrganizationRemainingTrialDays(organization),
  );

  if (!trialData) return null;

  return (
    <InfoBox
      action={action}
      buttonLabel={t(info_boxes_messages.trial.buttonLabel)}
      content={t(info_boxes_messages.trial.content, { remainingDays: trialData.remainingDays })}
      showButton
      title={t(info_boxes_messages.trial.title)}
    />
  );
};

export default DashboardInfoBoxTrial;
