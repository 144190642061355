import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import DialogViewWrapper from 'components/DialogViewWrapper';
import Loader from 'components/Loader';
import WorkplaceDetailsCard from 'components/WorkplaceDetailsCard/WorkplaceDetailsCard';
import useGetWorkplaceFullData from 'hooks/useGetWorkplaceFullData/useGetWorkplaceFullData';
import workplace_messages from 'messages/workplace_messages';

const SingleWorkplacePage: React.FC = () => {
  const { workplace_id } = useParams() as unknown as { workplace_id: number };
  const { t } = useTranslation();

  const { data, isLoading, refreshData } = useGetWorkplaceFullData(Number(workplace_id));

  return (
    <DialogViewWrapper contentSize='640px' noCardPadding title={t(workplace_messages.singleWorkplace.pageTitle)}>
      {isLoading || !data ? (
        <Loader inner />
      ) : (
        <WorkplaceDetailsCard refreshData={refreshData} templateValues={data.templateValues} workplaceBaseData={data.workplaceBaseData} />
      )}
    </DialogViewWrapper>
  );
};

export default SingleWorkplacePage;
