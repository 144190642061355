import table_messages from 'messages/table_messages';

import booleanDictionary from '../booleanDictionary';

const isOngoingFilter = {
  sectionName: table_messages.is_ongoing,
  filters: booleanDictionary,
  isSingleSelect: true,
};

export default isOngoingFilter;
