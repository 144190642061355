const auth_messages = {
  password_changed: ['auth>>notifications>>password_changed', 'Password has been changed'],
  request: ['auth>>labels>>request', 'Request'],
  reset_password: ['auth>>subheading>>reset_password', 'Reset password'],
  email: ['auth>>labels>>email', 'Email'],
  password_rules_heading: ['auth>>password_rules_heading', 'Make sure that password rules are fulfilled'],
  current_password: ['auth>>labels>>password_current', 'Current password'],
  password: ['auth>>labels>>password', 'Password'],
  password_repeat: ['auth>>labels>>password_repeat', 'Repeat password'],
  go_to_login: ['auth>>labels>>go_to_login', 'Back to login'],
  password_changed_heading: ['auth>>subheading>>password_changed', 'Password changed'],
  password_changed_body: ['auth>>body>>password_changed', 'Your password has been changed'],
  login_after_password_change: [
    'auth>>body>>login_after_password_change',
    'Your password has been updated, back to login page and use it to authorize',
  ],
  headings: {
    login: ['auth>>heading>>login', 'Nice to have you'],
    register: ['auth>>heading>>register', 'The beginning of something great'],
    forgot_password: ['auth>>heading>>forgot_password', 'Forgot password'],
  },
  navigation: {
    forgot_password: ['auth>>navigation>>forgot_password', 'Forgot Password?'],
    register: ['auth>>navigation>>register', 'Create an account'],
    login: ['auth>>navigation>>login', 'Login'],
    sign_in: ['auth>>navigation>>sign_in', 'Sign in'],
    sign_up: ['auth>>navigation>>sign_up', 'Sign up'],
  },
  resend_request_email_notification: ['auth>>notifications>>resend_request_email', 'Reset password link has been sent to your email'],
  resend_request_email: ['auth>>labels>>resend_request_email', 'Resend request email'],
  reset_password_sent: ['auth>>subheading>>reset_password_sent', 'Request sent'],
  check_email: ['auth>>body>>check_email', 'Please check your email'],

  activation_email_notification: ['auth>>notifications>>activation_email_notification', 'Activation link has been sent to your email'],
  resend_activation_email: ['auth>>labels>>resend_activation_email', 'Resend request email'],

  password_reset_link: [
    'auth>>body>>password_reset_link',
    'A reset password link has been sent to your email. Please check you spamfilter if you can’t see it.',
  ],
  activation_link: [
    'auth>>body>>activation_link',
    'A validate account link has been sent to your email. please check you spamfilter if you can’t see it',
  ],
  account_activation: ['auth>>account_activation', 'Verify account and set password'],
  verify_account: ['auth>>verify_account', 'Verify account'],
  register_subheading: ['auth>>subheading>>register', 'Sign up your organization'],
  register_sections: {
    company: ['auth>>register_sections>>company', 'Company info'],
    personal: ['auth>>register_sections>>personal', 'Personal info'],
    billing: ['auth>>register_sections>>billing', 'Billing details'],
    contact_person: ['auth>>register_sections>>contact_person', 'Contact person'],
  },
  accept_terms: ['auth>>accept_terms', 'I have read and accept <1>terms and conditions</1>'],
  terms: ['auth>>terms', 'terms and conditions'],
  accept_gdpr: ['auth>>accept_gdpr', 'I have read and accept <1>the GDPR privacy terms</1>'],
  gdpr: ['auth>>gdpr', 'the GDPR privacy terms'],
  create_account: ['auth>>create_account', 'Create account'],
  update_organization_info: ['auth>>update_organization_info', 'Update organization information'],
  payment: {
    add_payment_method: ['auth>>payment>>add_payment_method', 'Add payment method'],
    update_payment_method: ['auth>>payment>>update_payment_method', 'Update payment method'],
    failed: ['auth>>payment>>failed', 'Adding payment method failed!'],
    success: ['auth>>payment>>success', 'Payment method added!'],
    configuration_needed_info_for_owner: [
      'auth>>payment>>configuration_needed_info_for_owner',
      'Configure your payment method to use the application',
    ],
    configuration_needed_info_for_member: [
      'auth>>payment>>configuration_needed_info_for_member',
      'Wait for the organization owner to configure the application',
    ],
  },
  campaign_code: ['auth>>campaign_code', 'Campaign code'],
  campaign_code_error: {
    not_exist: ['auth>>campaign_code_error>>not_exist', 'Campaign code is not valid'],
    outdated: ['auth>>campaign_code_error>>outdated', 'Campaign code is outdated'],
    limit_reached: ['auth>>campaign_code_error>>limit_reached', 'All campaign codes have been used'],
  },
  register_additional_info: [
    'auth>>register_additional_info',
    'After you have signed up and verified your account. You can start inviting your colleagues to join your company. You should only have one account per organization',
  ],
};

export default auth_messages;
