import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  subtitle: {
    fontWeight: 500,
    fontSize: '18px',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.secondaryColor,
  },
}));
