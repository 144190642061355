import { intersection, isNil } from 'lodash';

import ensureIsArray from 'helpers/ensureIsArray';

import OrganizationPaymentStages from './OrganizationPaymentStages';
import RolesOrganization from './RolesOrganization';
import RolesPocketSafe from './RolesPocketSafe';

type Roles = (RolesOrganization | RolesPocketSafe | OrganizationPaymentStages)[];

type CheckAccess = (userRoles: Roles, allowedRoles: Roles) => boolean;

/**
 * Function to check permission in basic way - compare user roles and allowed roles
 * @param userRoles
 * @param allowedRoles
 */
const checkAccess: CheckAccess = (userRoles, allowedRoles: Roles) => {
  if (isNil(allowedRoles)) return false;
  return !!intersection(allowedRoles, ensureIsArray(userRoles)).length;
};

export default checkAccess;
