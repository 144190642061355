import assetRisksApi from 'config/api/assetRisks/assetRisks';
import { AssetRisk, GetAllAssetRisksParams } from 'config/api/assetRisks/assetRisks.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import asset_risk_messages from 'messages/asset_risk_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const riskFilterFactory = (params: Required<Pick<GetAllAssetRisksParams, 'risk_analysis'>>): DynamicTableFilter<AssetRisk[]> => ({
  isDynamic: true,
  queryFn: () => assetRisksApi.getAllAssetRisks({ ordering: 'name', ...params }),
  sectionName: asset_risk_messages.risk_task_table.identified_risk_filter_label,
  isSingleSelect: false,
  queryKey: [QUERY_KEYS.GET_ALL_ASSET_RISKS, params.risk_analysis.toString()],
  dataParser: data =>
    data.map(({ name, id }) => ({
      id,
      name,
    })),
});

export default riskFilterFactory;
