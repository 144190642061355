import React from 'react';

import { Card, Grow, Typography } from '@material-ui/core';

import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import { HtmlString } from 'types/HtmlString';

import ColoredButton from '../ColoredButton';

import useStyles from './InfoBox.styles';

export type Props =
  | {
      title: string;
      content: HtmlString;
      showButton?: false;
      action?: never;
      buttonLabel?: never;
    }
  | {
      title: string;
      content: HtmlString;
      showButton: true;
      action: () => void;
      buttonLabel: string | React.ReactNode;
    };

const InfoBox: React.FC<Props> = ({ buttonLabel, showButton, action, title, content }) => {
  const styles = useStyles();
  return (
    <Grow in>
      <Card className={styles.card}>
        <Typography align='center' variant='h3'>
          {title}
        </Typography>
        <TypographyWithHTML align='center'>{content}</TypographyWithHTML>
        {showButton && (
          /* @ts-ignore */
          <ColoredButton className={styles.button} customColor='grey' onClick={action}>
            {buttonLabel}
          </ColoredButton>
        )}
      </Card>
    </Grow>
  );
};

export default InfoBox;
