import { Option } from 'types/Option';

import type { AssetActivitiesDTO } from './assetActivities.types';

// eslint-disable-next-line import/prefer-default-export
export const parseAllAssetActivitiesForSelect = (raw: AssetActivitiesDTO[]): Option<{ description: string[] }>[] =>
  raw.map(({ name, name_translation_key, id, description_translation_key, description }) => ({
    key: id,
    label: [name_translation_key, name],
    metaData: { description: [description_translation_key, description] },
  }));
