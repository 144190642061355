import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'grid',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
  button: {
    justifySelf: 'end',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));
