import { useEffect, useRef } from 'react';

import PubSub from 'pubsub-js';

const useSubscription = (event, callback, deps = []) => {
  const subscriptionsRef = useRef();

  useEffect(() => {
    subscriptionsRef.current = PubSub.subscribe(event, callback);
    return () => {
      if (subscriptionsRef.current) PubSub.unsubscribe(subscriptionsRef.current);
    };
  }, deps);
  return subscriptionsRef;
};

export default useSubscription;
