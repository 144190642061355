import React, { useEffect, useState } from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ArticleTilesGrid from 'components/ArticleTilesGrid';
import Filters from 'components/Filters';
import FooterButton from 'components/FooterButton';
import GuidesTilesGrid from 'components/GuideTilesGrid/GuidesTilesGrid';
import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import SelfTestsTilesGrid from 'components/SelfTestsTilesGrid/SelfTestsTilesGrid';
import commonEndpoints from 'config/api/common';
import { parseContentItemForFE } from 'config/api/selfTests/parsers';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import PUBLISH_STATUSES from 'config/constants/PUBLISH_STATUSES';
import ensureIsArray from 'helpers/ensureIsArray';
import useMultipleApiCall from 'hooks/useMultipleApiCall';
import usePagination from 'hooks/usePagination';
import general_messages from 'messages/general_messages';
import title_messages from 'messages/title_messages';
import parseFiltersForRequest from 'services/parseFiltersForRequest';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.background.default,
    },
  },
  articlesContainer: {
    display: 'grid',
    gridGap: theme.spacing(5),
  },
}));

const ContentPage = ({
  contentType,
  filtersGetter,
  staticFilters,
  preparePreselectedFilters,
  customTitle,
  customParams,
  customFetchDependencies,
}) => {
  const { state: locationState } = useLocation();
  const { t } = useTranslation();
  const { multipleApiCall } = useMultipleApiCall();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  // FILTERS LOGIC
  const [filters, setFilters] = useState({});
  const [availableFilters, setAvailableFilters] = useState(null);

  const setPreselectedFilers = (preselectedFilters, allAvailableFilters) => {
    setFilters(preparePreselectedFilters(preselectedFilters, allAvailableFilters));
  };

  const hasPreselectedFilters = locationState?.preselectedFilters && preparePreselectedFilters;

  const getAvailableFilters = async () => {
    const urls = filtersGetter();
    const dynamicFilters = await multipleApiCall(urls);
    const result = { ...dynamicFilters, ...staticFilters };
    if (hasPreselectedFilters) setPreselectedFilers(locationState.preselectedFilters, result);
    setAvailableFilters(result);
  };

  useEffect(() => {
    if (filtersGetter) getAvailableFilters();
  }, []);

  const baseParams = {
    order_by: ['-is_sticky', '-created_at'],
    object_content_type: ensureIsArray(contentType),
    publish_status: [PUBLISH_STATUSES.PUBLISHED],
  };

  // FETCHING DATA LOGIC
  const {
    data,
    getData: getArticlesData,
    hasNextPage,
    loading,
    limit,
  } = usePagination({
    endpointFunc: commonEndpoints.search,
    limits: [15, 10, 5],
    dependencies: customFetchDependencies ? [filters, ...customFetchDependencies] : [filters],
    filters: parseFiltersForRequest(filters),
    omitInitialCall: !!locationState?.preselectedFilters,
    params: customParams ? { ...baseParams, ...customParams } : baseParams,
    sticky: true,
    disabled: hasPreselectedFilters && isEmpty(filters),
  });

  const resolveTitle = () => {
    if (customTitle) return customTitle;
    switch (contentType) {
      case CONTENT_TYPES.INCIDENT_GUIDE:
      case CONTENT_TYPES.GUIDE:
        return t(...title_messages.guide);
      case CONTENT_TYPES.ARTICLE:
        return t(...title_messages.article);
      case CONTENT_TYPES.SELF_TEST:
        return t(...title_messages.self_tests);
      default:
        return t(...title_messages.article);
    }
  };

  const resolvePage = () => {
    const contentData = parseContentItemForFE(data);
    if (contentType.includes(CONTENT_TYPES.GUIDE) || contentType.includes(CONTENT_TYPES.INCIDENT_GUIDE)) {
      return <GuidesTilesGrid data={contentData} placeholdersNumber={limit} />;
    }
    if (contentType === CONTENT_TYPES.SELF_TEST && data) {
      return <SelfTestsTilesGrid data={contentData} placeholdersNumber={limit} />;
    }
    if (contentType === CONTENT_TYPES.ARTICLE) {
      return <ArticleTilesGrid data={contentData} placeholdersNumber={limit} />;
    }
    throw Error('Incorrect content type');
  };

  const styles = useStyles();
  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {resolveTitle()}
        </Typography>
      </Jumbotron>

      <MobileGuttersContainer>
        <section className={styles.articlesContainer}>
          <Filters availableFilters={availableFilters} filters={filters} setFilters={setFilters} withTab />
          {contentType && data && resolvePage()}
          {hasNextPage && (
            <FooterButton loading={loading} onClick={getArticlesData}>
              {t(...general_messages.show_more)}
            </FooterButton>
          )}
        </section>
      </MobileGuttersContainer>
    </Container>
  );
};

ContentPage.defaultProps = {
  preparePreselectedFilters: null,
  staticFilters: {},
  filtersGetter: null,
  customTitle: null,
  customParams: null,
  customFetchDependencies: null,
};

export default ContentPage;
