import axios from 'axios';

import METHOD from 'config/api/_methods';
import createUrlWithParams, { ParamsType } from 'helpers/createUrlWithParams/createUrlWithParams';
import { Id } from 'types/Id';

import { parseRecommendedItemForFE } from './general/parsers';
import { ContentType } from './selfTests/_types';
import { parseContentItemForFE } from './selfTests/parsers';

const commonEndpoints = {
  serviceAreas: () => ({ url: '/api/v1/service_area/', method: METHOD.GET }),
  serviceAreaInfo: (service_area_id: string) => ({ url: `/api/v1/service_area/${service_area_id}/`, method: METHOD.GET }),
  tags: (params?: { content_type: Exclude<ContentType, 'incident_guide'> }) => ({
    url: createUrlWithParams('/api/v1/tag/', params || {}),
    method: METHOD.GET,
  }),
  search: (params: { limit?: number; search?: string }) => ({ url: createUrlWithParams('/api/v1/search/', params), method: METHOD.POST }),
  searchAll: (params: { search_term: string }) => ({ url: createUrlWithParams('/api/v1/search_all/', params), method: METHOD.GET }),
  // TODO type it
  activities: (params: ParamsType) => ({ url: createUrlWithParams('/api/v1/activities/', params), method: METHOD.POST }),
  getBusinessTypes: () => ({ url: '/api/v1/business_types/', method: METHOD.GET }),
  getAuthors: () => ({ url: '/api/v1/authors/', method: METHOD.POST }),
  // TODO type it
  getAdvisers: (params: ParamsType) => ({ url: createUrlWithParams('/api/v1/advisers/', params), method: METHOD.GET }),
};

// usage for react-query
export const commonApi = {
  getEnvSettings: () => axios.get('/api/v1/common/get_current_environment_settings'),
  getRecommendedContent: async () => {
    const result = await axios.get('/api/v1/recommended_content/');
    return parseRecommendedItemForFE(result.data);
  },
  search: async (params: { limit?: number; search?: string }) => {
    // TODO type it
    const { data } = await axios.get(createUrlWithParams('/api/v1/search/', params));
    return parseContentItemForFE(data);
  },
  removeAttachment: async (attachmentId: Id) => {
    const response = await axios.delete(`/api/v1/attachments/${attachmentId}/`);
    return response;
  },
};

export default commonEndpoints;
