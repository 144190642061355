export const ADVISOR_EDIT_DIALOGS = {
  BASE: 'dialog_base',
  ADVISOR: 'dialog_advisor',
  DATE: 'dialog_date',
  CANCEL: 'dialog_cancel',
  ABSENT: 'dialog_absent',
};

export const CUSTOMER_EDIT_DIALOGS = {
  BASE: 'customer_dialog_base',
  EDIT: 'customer_dialog_edit',
};
