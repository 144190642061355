const label_messages = {
  organization_name: ['label>>organization_name', 'Organization name'],
  organization_number: ['label>>organization_number', 'Organization number'],
  business_type: ['label>>business_type', 'Type of business'],
  employees_number: ['label>>employees_number', 'Number of employees'],
  first_name: ['general>>first_name', 'First name'],
  last_name: ['general>>last_name', 'Last name'],
  email: ['auth>>labels>>email', 'Email'],
  billing_email: ['label>>billing_email', 'Billing email'],
  billing_street: ['label>>billing_street', 'Billing street address'],
  billing_postal: ['label>>billing_postal', 'Billing postal code'],
  billing_city: ['label>>billing_city', 'Billing city'],
  billing_ref: ['label>>billing_ref', 'Billing reference'],
  country: ['label>>country', 'Country'],
  campaign_code: ['label>>campaign_code', 'Campaign code'],
};

export default label_messages;
