import React from 'react';

import { Checkbox, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import GUIDE_ACTION_TYPES from 'config/constants/GUIDE_ACTION_TYPES';
import general_messages from 'messages/general_messages';

const useStyles = makeStyles(theme => ({
  actionWrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    width: '100%',
  },
  heading: {
    fontSize: '1.125rem',
    fontWeight: '500',
  },
  action: {
    width: '100%',
    border: `thin solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadiusLarge,
    display: 'grid',
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'stretch',
  },
  freeText: {
    padding: theme.spacing(1.5, 2),
    fontSize: '0.875rem',
    color: theme.palette.grey[700],
  },
  label: {
    '&.MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  checkbox: {
    padding: theme.spacing(0.5, 2),
    marginRight: 0,
  },
  checked: {
    background: theme.palette.grey[200],
  },
  subtitle: {
    fontWeight: '500',
  },
  wrapper: {
    width: '100%',
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}));

const TakenStepsSummary = ({ steps }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      {steps.map(({ heading, actions, user_data }) => (
        <div key={heading} className={styles.actionWrapper}>
          <Typography className={styles.subtitle}>{heading}</Typography>
          {actions.length
            ? actions.map(action => {
                const checked = !!action.user_data;
                return (
                  <div key={action.id} className={styles.action}>
                    <FormControlLabel
                      checked={checked}
                      classes={{ label: styles.label, root: styles.root }}
                      className={clsx(styles.checkbox, checked && styles.checked)}
                      control={<Checkbox disabled />}
                      label={action.heading}
                    />
                    {action.action_type === GUIDE_ACTION_TYPES.FREETEXT && checked && (
                      <Typography className={styles.freeText}>{action.user_data?.freetext}</Typography>
                    )}
                  </div>
                );
              })
            : null}
          {user_data?.comment && (
            <Typography>
              <Typography className={styles.subtitle}>{`${t(...general_messages.comments)}: `}</Typography>
              {user_data.comment}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

TakenStepsSummary.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      actions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ).isRequired,
};

export default TakenStepsSummary;
