import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: '4px',
    right: '0',
    width: '100%',
    maxWidth: '720px',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255)',
    border: `thin solid ${theme.palette.primary.main}`,
    display: 'grid',
    gridGap: theme.spacing(2),
    justifyItems: 'center',
    zIndex: 12,
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
    },
  },
  content: {
    '& a': {
      fontWeight: '600',
      textDecoration: 'none',
    },
  },
}));
export default useStyles;
