import React, { useEffect, useMemo } from 'react';

import { Link, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ARTICLE_BODY_CONTENT_TYPES from 'config/constants/ARTICLE_BODY_CONTENT_TYPES';
import stringShortener from 'helpers/stringShortener';
import general_messages from 'messages/general_messages';
import { useArticleContext } from 'reactContext/ArticleContext';
import extractAnchorsFromHTML from 'services/htmlParse/extractAnchorsFromHTML';

const useStyles = makeStyles(theme => ({
  navigation: {
    position: 'sticky',
    top: theme.spacing(5),
    color: theme.palette.grey[600],
    paddingLeft: theme.spacing(4),
    borderLeft: `thin solid ${theme.palette.grey[600]}`,
    display: 'grid',
    gridGap: theme.spacing(2.5),
    maxHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    overflowY: 'scroll',
  },
  header: {
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamilyBaseCondensed,
  },
  active: {
    color: theme.palette.secondary[800],
  },
}));

const ArticleNavigation = ({ bodyStream }) => {
  const { intersectionQueue, setIntersectionQueue, cursor } = useArticleContext();
  const { t } = useTranslation();

  const anchors = useMemo(() => {
    const textContent = bodyStream
      .filter(({ type }) => type === ARTICLE_BODY_CONTENT_TYPES.TEXT)
      .map(({ value }) => value)
      .join('');

    return extractAnchorsFromHTML(textContent);
  }, [bodyStream]);

  useEffect(() => {
    if (anchors?.length)
      setIntersectionQueue(prev => {
        prev.push(...anchors.map(({ key }) => key));
        return prev;
      });
  }, [anchors]);

  const styles = useStyles();
  if (!anchors || !anchors.length) return null;
  return (
    <div>
      <nav className={styles.navigation}>
        <Typography className={styles.header}>{t(...general_messages.contents)}</Typography>
        {anchors.map(({ label, key }, index) => (
          <Link
            key={key}
            className={clsx(intersectionQueue[cursor] === key && styles.active, !intersectionQueue.length && index === 0 && styles.active)}
            color='inherit'
            href={`#${key}`}
          >
            {stringShortener(label, 55)}
          </Link>
        ))}
      </nav>
    </div>
  );
};

ArticleNavigation.propTypes = {
  bodyStream: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      type: PropTypes.string,
    }),
  ).isRequired,
};

export default ArticleNavigation;
