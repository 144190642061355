import { Option } from 'types/Option';

import { TableFilterEntity } from '../../../types/TableFilter';

import type { AssetCategoryDTO } from './assetCategories.types';
import { AssetCategory } from './assetCategories.types';

export const parseAllAssetCategoriesForSelect = (raw: AssetCategoryDTO[]): Option[] =>
  raw.map(({ name, name_translation_key, id }) => ({
    key: id,
    label: [name_translation_key, name],
  }));

export const parseAllAssetCategoriesForFilters = (raw: AssetCategoryDTO[]): TableFilterEntity[] =>
  raw.map(({ name, name_translation_key, id }) => ({
    id,
    name: [name_translation_key, name],
  }));

export const parseAllAssetCategoriesForFE = (raw: AssetCategoryDTO[]): AssetCategory[] =>
  raw.map(({ name, name_translation_key, id }) => ({
    name,
    id,
    nameTranslationKey: name_translation_key,
  }));
