import { set } from 'date-fns';

import removeTimezoneFromTime from 'helpers/removeTimezoneFromTime';

const generateDayRange = (startDate, endDate) => {
  const endDateFallback = endDate || startDate;
  return {
    start: removeTimezoneFromTime(set(new Date(startDate), { hours: 0, minutes: 0 })),
    end: removeTimezoneFromTime(set(new Date(endDateFallback), { hours: 23, minutes: 59 })),
  };
};

export default generateDayRange;
