import React from 'react';

import ManageMeetingCalendar from 'components/ManageMeetingCalendar';
import PATHS from 'router/PATHS';

const AdvisoryPageMeetingBar = () => {
  const isClickableCheck = ({ meetingId }) => !!meetingId;
  const getHref = ({ slotId }) => (slotId ? `${PATHS.MEETING_REPORT}/${slotId}` : null);

  return <ManageMeetingCalendar autoScroll forceSingleDay hideNA isClickableCheck={isClickableCheck} tileHref={getHref} />;
};

export default AdvisoryPageMeetingBar;
