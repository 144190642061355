import React from 'react';

import { TFunction } from 'react-i18next';

import { Id } from 'types/Id';

type Params = {
  id: Id;
  key: Id;
  name: string;
  category: [string, string];
};
const assetResultComponent = ({ name, category }: Params, t: TFunction) => {
  const categoryTranslated = t(category);
  const content = [name];
  if (categoryTranslated) content.push(`(${categoryTranslated})`);
  return <span>{content.join(' ')}</span>;
};

export default assetResultComponent;
