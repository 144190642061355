import axios from 'axios';

import createUrlWithParams from '../../../helpers/createUrlWithParams';
import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import { parseSingleAssetRisksForFE, parseAllAssetRisksForFE, parseDataForCreateAsset } from './assetRisks.parsers';
import type AssetRisksApi from './assetRisks.types';

const endpoint = 'api/v1/asset_risks/';

export const legacyAssetRisksApi = {
  getAllAssetRisks: (riskAnalysisId: string) => (params: BasePaginatedQueryParams) => ({
    url: createUrlWithParams(endpoint, { risk_analysis: riskAnalysisId, ...params }),
    method: METHOD.GET,
  }),
};

const assetRisksApi: AssetRisksApi = {
  getAllAssetRisks: async params => {
    const { data } = await axios.get(createUrlWithParams(endpoint, { limit: params.limit || 100, ...params }));
    return parseAllAssetRisksForFE(data.results);
  },
  getSingleAssetRisk: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleAssetRisksForFE(data);
  },
  createAssetRisk: formInput => axios.post(endpoint, parseDataForCreateAsset(formInput)),
  updateAssetRisk: riskId => formInput => axios.put(`${endpoint}${riskId}/`, parseDataForCreateAsset(formInput)),
  removeAssetRisk: riskId => axios.delete(`${endpoint}${riskId}/`),
};

export default assetRisksApi;
