import { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { refreshToken } from 'config/axios';
import instances from 'config/constants/instances';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import general_messages from 'messages/general_messages';

const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;

const useMultipleApiCall = () => {
  const { t } = useTranslation();
  const { loading, setLoading, setLoaded } = useLoadingState(true);
  const { apiCall } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();

  const multipleApiCall = useCallback(async urls => {
    setLoading();

    const withDataParsing = urls && !Array.isArray(urls);
    if (withDataParsing) {
      await refreshToken();
      const response = await Promise.all(Object.values(urls).map(({ url, rest }) => apiCall(url, rest)));
      let result = {};
      Object.values(urls).forEach(({ dataParser }, index) => {
        try {
          const { data } = response[index];
          result = dataParser(data, result);
        } catch (error) {
          // eslint-disable-next-line no-console
          if (!isProduction) console.error(error);
          enqueueSnackbar(t(...general_messages.api_error), { variant: 'error' });
        }
      });
      setLoaded();
      return result;
    }
    if (urls) {
      return Promise.all(urls.map(url => apiCall(url)));
    }
    return null;
  }, []);

  return { loading, multipleApiCall };
};

export default useMultipleApiCall;
