import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  twoColumnSection: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(4),
  },
  section: {
    display: 'grid',
    gap: theme.spacing(2),
  },
}));
