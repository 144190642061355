import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  input: {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
  },
}));
