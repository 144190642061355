import create from 'zustand';
import { persist } from 'zustand/middleware';

type Logo = {
  full: string;
  symbol: string;
};

type BrandingStore = {
  logo?: Logo;
  setLogo: (newLogo: Logo) => void;

  favicon?: string;
  setFavicon: (newFavicon: string) => void;

  pageTitle?: string;
  setPageTitle: (newPageTitle: string) => void;
};

const store = persist<BrandingStore>(
  set => ({
    logo: undefined,
    setLogo: newLogo => set({ logo: newLogo }),

    favicon: undefined,
    setFavicon: newFavicon => set({ favicon: newFavicon }),

    pageTitle: undefined,
    setPageTitle: newPageTitle => set({ pageTitle: newPageTitle }),
  }),
  { name: 'BrandingStore', getStorage: () => localStorage },
);

const useBrandingStore = create(store);

export default useBrandingStore;
