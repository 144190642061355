import React, { FC, MouseEventHandler } from 'react';

import { Button, Fade, ListItem, Popover } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import checkProtectedFeatureAccess from 'config/permissions/checkProtectedFeatureAccess';
import ProtectedFeatures from 'config/permissions/ProtectedFeatures';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';
import menu_messages from 'messages/menu_messages';
import { COMMON_DIALOG_TYPES, useCommonDialogsContext } from 'reactContext/CommonDialogsContext';
import useLayoutStore from 'storages/layoutStore';
import userDataStorage from 'storages/userDataStorage';

import useStyles from './EmergencyMenu.styles';

const EmergencyMenu: FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const isContextMenuOpen = Boolean(anchorEl);

  const openContextMenu: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();
  // @ts-ignore
  const { openCommonDialog } = useCommonDialogsContext();
  // @ts-ignore
  const userData = userDataStorage.get() || {};
  const { isMenuOpen } = useLayoutStore();

  const { stage } = useOrganizationPaymentStageInfo();

  const openIncidentDialog = () => {
    openCommonDialog(COMMON_DIALOG_TYPES.INCIDENT_GUIDE);
    closeMenu();
  };

  const openEmergencyDialog = () => {
    openCommonDialog(COMMON_DIALOG_TYPES.EMERGENCY);
    closeMenu();
  };

  const styles = useStyles({ isMenuOpen });

  return (
    <ListItem>
      <Button className={styles.errorButton} onClick={openContextMenu} variant='contained'>
        {isMenuOpen ? (
          <span className={clsx(styles.text, styles.errorText)}>{t(menu_messages.emergency_wrapper_button)}</span>
        ) : (
          <AlertIcon />
        )}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        classes={{ paper: styles.popover }}
        getContentAnchorEl={null}
        id='fade-menu'
        keepMounted
        onClose={closeMenu}
        open={isContextMenuOpen}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        TransitionComponent={Fade}
      >
        {checkProtectedFeatureAccess(ProtectedFeatures.EmergencyButton, {
          organization: userData.roles,
          paymentStage: [stage!],
        }).hasAccess && (
          <Button className={styles.errorButton} onClick={openEmergencyDialog} variant='contained'>
            {t(menu_messages.emergency_button)}
          </Button>
        )}
        {checkProtectedFeatureAccess(ProtectedFeatures.MyOrganizationSections, { paymentStage: [stage!] }).hasAccess && (
          <Button className={styles.errorButton} onClick={openIncidentDialog} variant='contained'>
            {t(menu_messages.report_incident)}
          </Button>
        )}
      </Popover>
    </ListItem>
  );
};
export default EmergencyMenu;
