import React, { createContext, useState, useContext, useRef, ReactNode, FC, useMemo } from 'react';

import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import ColoredButton from 'components/ColoredButton';
import general_messages from 'messages/general_messages';

const useStyles = makeStyles(theme => ({
  button: {
    // @ts-ignore
    minWidth: theme.sizes.button.narrow,
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    gap: theme.spacing(3),
    width: '100%',
  },
}));

type ConfirmationModalContextType = {
  showConfirmationModal: (options: ModalOptions) => Promise<boolean>;
  closeConfirmationModal: () => void;
};

const ConfirmationModalContext = createContext({} as ConfirmationModalContextType);

const { Provider } = ConfirmationModalContext;

type Props = {
  children: ReactNode;
};

type ModalOptions = {
  title: ReactNode;
  body: ReactNode;
  yesLabel?: ReactNode;
  noLabel?: ReactNode;
  hideYesButton?: boolean;
};

const ConfirmationModalProvider: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState<ModalOptions | null>(null);

  const awaitingConfirmation: React.MutableRefObject<{ resolve: (result: boolean) => void } | undefined> = useRef();

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      setConfirmationOptions(null);
    }, 300);
  };

  const closeModalWithResolve = (resolve?: boolean) => {
    awaitingConfirmation.current?.resolve(!!resolve);
    closeModal();
  };

  const showConfirmationModal = (options: ModalOptions) => {
    setOpen(true);
    setConfirmationOptions(options);
    return new Promise(resolve => {
      awaitingConfirmation.current = { resolve };
    });
  };

  const onCancel = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(false);
    }

    closeModal();
  };

  const onConfirm = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(true);
    }

    closeModal();
  };

  const value = useMemo(() => ({ showConfirmationModal, closeConfirmationModal: closeModalWithResolve }), []);

  const styles = useStyles();
  return (
    <Provider value={value as ConfirmationModalContextType}>
      {children}
      {/* @ts-ignore */}
      <DialogGrid
        dialogActions={
          <div className={styles.wrapper}>
            <Button className={styles.button} onClick={onCancel} variant='outlined'>
              {confirmationOptions?.noLabel || t(general_messages.no)}
            </Button>
            {confirmationOptions?.hideYesButton ? null : (
              // @ts-ignore
              <ColoredButton className={styles.button} customColor='secondary' onClick={onConfirm} variant='outlined'>
                {confirmationOptions?.yesLabel || t(general_messages.yes)}
              </ColoredButton>
            )}
          </div>
        }
        onClose={onCancel}
        open={open}
        title={confirmationOptions?.title || null}
      >
        {confirmationOptions?.body || null}
      </DialogGrid>
    </Provider>
  );
};

export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export default ConfirmationModalProvider;
