import { makeStyles } from '@material-ui/core';

// @ts-ignore
export default makeStyles(theme => ({
  tile: {
    // @ts-ignore
    backgroundColor: theme.palette.selfTest.primary,
    // @ts-ignore
    borderRadius: theme.shape.borderRadiusLarge,
    justifyContent: 'flex-start',
    // @ts-ignore
    border: `1px solid ${theme.palette.selfTest.dark}`,
    color: theme.palette.text.primary,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerItems: {
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.primary.main,
  },
  footerFont: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: '.75rem',
  },
  selfTestAnchor: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '1rem',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2.5),
    height: '100%',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  description: {
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '175px',
    '& p': {
      margin: 0,
    },
    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '20px',
      background: 'linear-gradient(0deg, rgba(182, 195, 238,1) 0%, rgba(182, 195, 238,0) 100%)',
      top: '155px',
    },
  },
}));
