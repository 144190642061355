import INCIDENT_PRIORITIES from 'config/constants/INCIDENT_PRIORITIES';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';

const { priorities } = guide_messages;

const TRANSLATABLE_INCIDENT_PRIORITIES_FALLBACKS = {
  LOW: priorities.low[1],
  MEDIUM: priorities.medium[1],
  HIGH: priorities.high[1],
  HIGHEST: priorities.highest[1],
};

const TRANSLATABLE_INCIDENT_PRIORITIES = {
  LOW: [priorities.low[0], TRANSLATABLE_INCIDENT_PRIORITIES_FALLBACKS.LOW, INCIDENT_PRIORITIES.LOW],
  MEDIUM: [priorities.medium[0], TRANSLATABLE_INCIDENT_PRIORITIES_FALLBACKS.MEDIUM, INCIDENT_PRIORITIES.MEDIUM],
  HIGH: [priorities.high[0], TRANSLATABLE_INCIDENT_PRIORITIES_FALLBACKS.HIGH, INCIDENT_PRIORITIES.HIGH],
  HIGHEST: [priorities.highest[0], TRANSLATABLE_INCIDENT_PRIORITIES_FALLBACKS.HIGHEST, INCIDENT_PRIORITIES.HIGHEST],
};

export const TRANSLATABLE_INCIDENT_PRIORITIES_DICTIONARY = [
  { label: TRANSLATABLE_INCIDENT_PRIORITIES.LOW, key: INCIDENT_PRIORITIES.LOW },
  { label: TRANSLATABLE_INCIDENT_PRIORITIES.MEDIUM, key: INCIDENT_PRIORITIES.MEDIUM },
  { label: TRANSLATABLE_INCIDENT_PRIORITIES.HIGH, key: INCIDENT_PRIORITIES.HIGH },
  { label: TRANSLATABLE_INCIDENT_PRIORITIES.HIGHEST, key: INCIDENT_PRIORITIES.HIGHEST },
];

export const incidentPriorityResolver = key => {
  switch (key) {
    case INCIDENT_PRIORITIES.LOW:
      return TRANSLATABLE_INCIDENT_PRIORITIES.LOW;
    case INCIDENT_PRIORITIES.MEDIUM:
      return TRANSLATABLE_INCIDENT_PRIORITIES.MEDIUM;
    case INCIDENT_PRIORITIES.HIGH:
      return TRANSLATABLE_INCIDENT_PRIORITIES.HIGH;
    case INCIDENT_PRIORITIES.HIGHEST:
      return TRANSLATABLE_INCIDENT_PRIORITIES.HIGHEST;
    default:
      return general_messages.no_data;
  }
};

export default TRANSLATABLE_INCIDENT_PRIORITIES;
