import { useHistory, useLocation } from 'react-router-dom';

import ensureIsArray from 'helpers/ensureIsArray';

const useQueryParams = <KeysT extends string>() => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = {} as Record<KeysT, string | string[] | undefined>;
  Array.from(new URLSearchParams(location.search).entries()).forEach(([key, value]) => {
    if (!queryParams.hasOwnProperty(key)) {
      queryParams[key as KeysT] = value;
    } else {
      queryParams[key as KeysT] = [...ensureIsArray(queryParams[key as KeysT]), value];
    }
  });

  const deleteParam = (name: KeysT) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete(name);
    const paramsString = urlParams.toString();
    history.replace(`${location.pathname}?${paramsString}`);
  };

  const addParam = (name: KeysT, value: string) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set(name, value);
    const paramsString = urlParams.toString();
    history.replace(`${location.pathname}?${paramsString}`);
  };

  return { queryParams, deleteParam, addParam };
};

export default useQueryParams;
