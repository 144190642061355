import { makeStyles } from '@material-ui/core';

type StylesProps = {
  selected: boolean;
};

const useStyles = makeStyles(theme => ({
  button: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    padding: theme.spacing(1, 2),
    marginBottom: '-8px',
    // @ts-ignore
    color: ({ selected }: StylesProps) => (selected ? theme.palette.secondary.main : theme.palette.text.inverted),
    '&.MuiButton-root': {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    '@media (hover: hover)': {
      '&:hover': {
        color: () => theme.palette.secondary.main,
        '&.MuiButton-root': {
          padding: theme.spacing(1),
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
  buttonContent: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridGap: theme.spacing(2),
    textTransform: 'none',
    textAlign: 'left',
  },
  dashboardText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  lockIcon: {
    background: theme.palette.common.black,
    borderRadius: '100%',
    padding: '.5rem',
    height: '2.5rem',
    width: '2.5rem',
    // @ts-ignore
    color: theme.palette.text.inverted,
  },
}));

export default useStyles;
