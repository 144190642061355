import React from 'react';

import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';

import CustomAccordion from '../CustomAccordion';

import useStyles from './InputInfoWrapper.styles';

type Props = {
  children: React.ReactElement<any, any>;
  description: string;
};

const InputInfoWrapper: React.FC<Props> = ({ children, description }) => {
  const styles = useStyles();
  return (
    <CustomAccordion
      heading={children}
      iconsSet={{
        expanded: <InfoIcon />,
        notExpanded: <InfoOutlinedIcon />,
      }}
      id={description}
      summaryClasses={{ expanded: styles.expanded, content: styles.summaryContent }}
    >
      <TypographyWithHTML>{description}</TypographyWithHTML>
    </CustomAccordion>
  );
};

export default InputInfoWrapper;
