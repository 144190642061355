const personal_info_messages = {
  name_relation: ['personal_info>>name_relation', 'Name and relation'],
  name_title: ['personal_info>>name_title', 'Name and title'],
  address: ['personal_info>>address', 'Contact'],
  contact_details: ['personal_info>>contact_details', 'Contact details'],
  title: ['personal_info>>title', 'Title'],
  contact_roles: {
    supervisor: ['user>>contact_roles>>supervisor', 'Supervisor'],
    emergency: ['user>>contact_roles>>emergency', 'Emergency contact'],
  },
  add_picture: ['user>>add_picture', 'Add picture'],
  edit_picture: ['user>>edit_picture', 'Edit picture'],
  edit_picture_title: ['user>>edit_picture_title', 'Profile picture'],
  upload_picture: ['user>>upload_picture', 'Upload picture'],
  upload_new_picture: ['user>>upload_new_picture', 'Upload new picture'],
  delete_picture: ['user>>delete_picture', 'Delete picture'],
  picture_scale: ['user>>picture_scale', 'Scale'],
  picture_rotate: ['user>>picture_rotate', 'Rotate'],
};

export default personal_info_messages;
