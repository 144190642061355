import commonEndpoints from 'config/api/common';
import { ContentType } from 'config/api/selfTests/_types';
import general_messages from 'messages/general_messages';

const prepareTags = ({ tags }: { tags: { id: string; name: string }[] }) => tags.map(tag => ({ id: tag, name: tag }));

const getTagFilter = (contentType?: Exclude<ContentType, 'incident_guide'>) => ({
  url: commonEndpoints.tags(contentType ? { content_type: contentType } : undefined),
  // TODO type it
  dataParser: (filters: { tags: any }, result: any) => ({
    ...result,
    tags: { sectionName: general_messages.tags, filters: prepareTags(filters) },
  }),
});

export default getTagFilter;
