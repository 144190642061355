import axios from 'axios';

import { parseAssetCategoryContactForFE } from '../assets/assets.parsers';

import { parseDataToCreateAndEditAssetContact, parseSingleAssetContactsForFE } from './assetContacts.parsers';
import type AssetContactsApi from './assetContacts.types';

const endpoint = 'api/v1/asset_contacts/';

const assetContactsApi: AssetContactsApi = {
  getSingleAssetContact: async contactId => {
    const { data } = await axios.get(`${endpoint}${contactId}/`);
    return parseSingleAssetContactsForFE(data);
  },
  removeAssetContact: async contactId => {
    const { data } = await axios.delete(`${endpoint}${contactId}/`);
    return parseSingleAssetContactsForFE(data);
  },
  createAssetContact: async assetData => {
    const { data } = await axios.post(endpoint, parseDataToCreateAndEditAssetContact(assetData));
    return parseSingleAssetContactsForFE(data);
  },
  editAssetContact: contactId => async assetData => {
    const { data } = await axios.put(`${endpoint}${contactId}/`, parseDataToCreateAndEditAssetContact(assetData));
    return parseSingleAssetContactsForFE(data);
  },
  getAssetContactCategories: async () => {
    const { data } = await axios.get(`api/v1/assets/contact_categories/`);
    return parseAssetCategoryContactForFE(data);
  },
};
export default assetContactsApi;
