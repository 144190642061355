import commonEndpoints from 'config/api/common';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import table_messages from 'messages/table_messages';

const prepareAuthors = authors => authors.map(({ first_name, last_name, id }) => ({ id, name: `${first_name} ${last_name}` }));

const authorFilter = {
  url: commonEndpoints.getAuthors(),
  rest: { data: { object_content_type: [CONTENT_TYPES.ARTICLE, CONTENT_TYPES.GUIDE, CONTENT_TYPES.INCIDENT_GUIDE] } },
  dataParser: (filters, result) => ({ ...result, author: { sectionName: table_messages.author, filters: prepareAuthors(filters) } }),
};

export default authorFilter;
