const questions_messages = {
  page_title: ['questions>>page_title', 'Questions'],
  dialog_title: ['questions>>dialog_title', 'Ask a question'],
  dialog_success_title: ['questions>>dialog_success_title', 'Confirmation'],
  dialog_overview_title: ['questions>>dialog_overview_title', 'Question overview'],
  dialog_draft_title: ['questions>>dialog_draft_title', 'Draft'],
  dialog_response_title: ['questions>>dialog_response_title', 'Response to the question'],
  dialog_description: ['questions>>dialog_description', 'Description  text lorem ipsum dolor sit amet del faminata del barista el magnfic'],
  dialog_success_description: [
    'questions>>dialog_success_description',
    'Your questions was sent in successfully. It usually takes around X-Y business days to receive an answer. You will get an email confirmation',
  ],
  dialog_question_title: ['questions>>dialog_question_title', 'Question'],
  dialog_answer_title: ['questions>>dialog_answer_title', 'Answer'],
  dialog_question_by: ['questions>>dialog_question_by', 'Question by'],
  dialog_answered_by: ['questions>>dialog_answered_by', 'Answered by'],
  question_input_label: ['questions>>question_input_label', 'Question'],
  answer_input_label: ['questions>>answer_input_label', 'Answer'],
  question_send_notification: ['questions>>question_send_notification', 'Question sent'],
  table_header: {
    knowledge_area: ['questions>>table_header>>knowledge_area', 'Knowledge area'],
    question: ['questions>>table_header>>question', 'Question'],
    status: ['questions>>table_header>>status', 'Status'],
    date: ['questions>>table_header>>date', 'Date'],
    questioned_by: ['questions>>table_header>>questioned_by', 'Q by'],
  },
  status: {
    unanswered: ['questions>>status>>unanswered', 'Unanswered'],
    draft: ['questions>>status>>draft', 'Draft'],
    answered: ['questions>>status>>answered', 'Answered'],
  },
  edit_buttons: {
    save_draft: ['questions>>edit_button>>save_draft', 'Save draft'],
    publish: ['questions>>edit_button>>publish', 'Publish'],
  },
  snackbar: {
    answer_published: ['questions>>snackbar>>answer_published', 'Answer published'],
    draft_saved: ['questions>>snackbar>>draft_saved', 'Draft saved'],
  },
};

export default questions_messages;
