import React, { useMemo } from 'react';

import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormTextInput from 'components/FormTextInput';
import usersEndpoints from 'config/api/users/users';
import CONTACT_TYPES from 'config/constants/CONTACT_TYPES';
import emailValidator from 'config/validators/emailValidator';
import phoneNumberRegex from 'config/validators/phoneNumberRegex';
import useApiCall from 'hooks/useApiCall';
import general_messages from 'messages/general_messages';
import personal_info_messages from 'messages/personal_info_messages';
import validation_messages from 'messages/validation_messages';

const FORM_ID = 'personal_info_form';

const PersonalInfoEditDialog = ({ onClose, open, initialData, type, FORM, refreshData }) => {
  const { t } = useTranslation();
  const { apiCall, sending } = useApiCall();
  const isUser = type === CONTACT_TYPES.USER;

  const prefix = useMemo(() => {
    if (type === CONTACT_TYPES.SUPERVISOR) return 'sprvsr_';
    if (type === CONTACT_TYPES.EMERGENCY) return 'mrgnc_';
    return '';
  }, [type]);

  const getKey = key => `${prefix}${key}`;

  const onSubmit = async profile => {
    const { status } = await apiCall(usersEndpoints.updateCurrentUser(), { data: { profile } });
    if (status < 300) {
      onClose();
      refreshData();
    }
  };

  const getInitialValues = () => {
    const values = {};

    Object.values(FORM).forEach(key => {
      values[getKey(key)] = initialData[key] || '';
    });

    return values;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    isInitialValid: false,
    validationSchema: yup.object({
      [getKey(FORM.FIRST_NAME)]: yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
      [getKey(FORM.LAST_NAME)]: yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
      ...(isUser && {
        [getKey(FORM.EMAIL)]: emailValidator({
          valid: t(...validation_messages.email_valid),
        }),
      }),
    }),
  });

  const title = useMemo(() => {
    switch (type) {
      case CONTACT_TYPES.SUPERVISOR: {
        return t(...personal_info_messages.contact_roles.supervisor);
      }
      case CONTACT_TYPES.EMERGENCY: {
        return t(...personal_info_messages.contact_roles.emergency);
      }
      default: {
        return t(...general_messages.edit_personal_info);
      }
    }
  }, [type]);

  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          <ColoredButton customColor='secondary' disabled={!formik.isValid} form={FORM_ID} type='submit' variant='outlined'>
            {t(...general_messages.save_continue)}
          </ColoredButton>
          <Button onClick={onClose} variant='outlined'>
            {t(...general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      sending={sending}
      title={title}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={1.5} tight title={t(...personal_info_messages.name_title)}>
          <FormTextInput formik={formik} id={getKey(FORM.FIRST_NAME)} label={t(...general_messages.first_name)} required />
          <FormTextInput formik={formik} id={getKey(FORM.LAST_NAME)} label={t(...general_messages.last_name)} required />
          <FormTextInput formik={formik} id={getKey(FORM.TITLE)} label={t(...personal_info_messages.title)} />
        </CenteredGrid>
        <CenteredGrid gridGap={1.5} title={t(...personal_info_messages.contact_details)} withoutPadding>
          <FormTextInput
            formik={formik}
            id={getKey(FORM.EMAIL)}
            label={t(...general_messages.email)}
            readonly={isUser}
            readonlyInfo={t(...general_messages.email_readonly)}
          />
          <FormTextInput
            blockedChars={phoneNumberRegex}
            formik={formik}
            id={getKey(FORM.PHONE_NUMBER)}
            label={t(...general_messages.phone)}
          />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

PersonalInfoEditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  FORM: PropTypes.shape({
    FIRST_NAME: PropTypes.string.isRequired,
    LAST_NAME: PropTypes.string.isRequired,
    EMAIL: PropTypes.string.isRequired,
    PHONE_NUMBER: PropTypes.string.isRequired,
    TITLE: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.objectOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(Object.values(CONTACT_TYPES)).isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default PersonalInfoEditDialog;
