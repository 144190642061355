import USER_STATUSES from 'config/translatableConstants/USER_STATUSES';
import table_messages from 'messages/table_messages';
import prepareDataForFilter from 'services/prepareDataForFilter';

const userStatusFilter = {
  sectionName: table_messages.status,
  filters: prepareDataForFilter(USER_STATUSES),
  isSingleSelect: true,
};

export default userStatusFilter;
