import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  titleContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  metadataSection: {
    display: 'grid',
    alignItems: 'flex-start',
    width: 'fit-content',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(4, 8),
  },
}));
