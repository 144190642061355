import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import MenuElementsList from 'components/Layout/_components/MenuElementsList/MenuElementsList';
import MenuSection from 'components/Layout/_components/MenuSection';
import checkAccess from 'config/permissions/checkAccess';
import compareWithRoutingPermissions from 'config/permissions/compareWithRoutingPermissions';
import menu_messages from 'messages/menu_messages';
import userDataStorage from 'storages/userDataStorage';

import menuElements from '../menuElements';

const PocketSafeAdministrationSection = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { ps_roles, roles } = userDataStorage.get() || { ps_roles: [], roles: [] };

  const elementsToDisplay = useMemo(() => {
    const userRoles = [...ps_roles, ...roles];

    return menuElements.pocketSafeAdmin.filter(element => {
      if (element.to) return compareWithRoutingPermissions(userRoles, element.to);
      const allowedRoles = [...(element.allowedForOrganizationRoles || []), ...(element.allowedForPSRoles || [])];
      return checkAccess(userRoles, allowedRoles);
    });
  }, [ps_roles, roles]);

  if (!elementsToDisplay.length) return null;

  return (
    // @ts-ignore
    <MenuSection title={t(...menu_messages.ps_administrator)}>
      <MenuElementsList elements={elementsToDisplay} />
    </MenuSection>
  );
};

export default PocketSafeAdministrationSection;
