import { useState } from 'react';

const useSendingState = init => {
  const [sending, setSendingState] = useState(init || false);
  const setSent = () => setSendingState(false);
  const setSending = () => setSendingState(true);
  return { sending, setSending, setSent };
};

export default useSendingState;
