import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line
export default makeStyles(theme => ({
  stepWrapper: {
    display: 'grid',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2),
    margin: theme.spacing(1),
    border: `1px solid transparent`,
    borderRadius: theme.shape.borderRadius,
    opacity: 0.4,
    transition: theme.transitions.create(['border-color', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  active: {
    opacity: 1,
    borderColor: theme.palette.secondary.light,
  },
  qrCode: {
    height: '240px',
    border: '1px solid black',
  },
}));
