import { AuthorWithId, AuthorWithIdDTO } from 'types/Author';
import { DateString } from 'types/DateString';
import { Language, LanguageDTO } from 'types/Language';
import { ServiceArea, ServiceAreaDTO } from 'types/ServiceArea';

// TODO move to common
export enum PublishEnum {
  INACTIVE = 'inactive',
  NEW = 'new',
  PUBLISHED = 'published',
  UNDER_REVISION = 'under_revision',
}

export enum ContentTypeEnum {
  ARTICLE = 'article',
  GUIDE = 'guide',
  INCIDENT_GUIDE = 'incident_guide',
  SELF_TEST = 'self_test',
}

const availableContentTypes = ['article', 'guide', 'incident_guide', 'self_test'] as const;

export type ContentType = typeof availableContentTypes[number];

export enum AudienceEnum {
  PRIVATE_INDIVIDUALS,
  ORGANIZATIONS,
  BOTH,
}

export type Answer = {
  id: number;
  heading: string;
  description: string;
};

export type Question = {
  id: number;
  heading: string;
  description: string;
  answers: Answer[];
};

// TODO split it to 3 types (guide, article, selfTest)
export type ContentItemDTO = {
  metadata: {
    version_id: string;
  };
  author: AuthorWithIdDTO;
  language: LanguageDTO;
  service_area: ServiceAreaDTO;
  id: string;
  audience: AudienceEnum | null;
  created_at: DateString;
  customer_level: number | null;
  depth_level: number | null;
  description: string;
  estimated_time: number | null;
  heading: string;
  publish_status: PublishEnum;
  title: string;
  updated_at: DateString;
  tags: [];
  is_sticky: boolean;
  friendly_id: string;
  image_url: string | null;
  original_image_url: string | null;
  object_content_type: ContentTypeEnum;
  version_id: string | '';
  is_saved?: boolean;
  teaser?: string;
};

export type ContentItem = {
  metadata?: {
    versionId: string;
  };
  author: AuthorWithId;
  language: Language;
  serviceArea: ServiceArea;
  id: string;
  audience: AudienceEnum | null;
  createdAt: Date;
  customerLevel: number | null;
  depthLevel: number | null;
  description: string;
  estimatedTime: number | null;
  heading: string;
  publishStatus: PublishEnum;
  title: string;
  updatedAt: Date;
  tags: [];
  isSticky: boolean;
  friendlyId: string;
  imageUrl: string | null;
  originalImageUrl: string | null;
  objectContentType: ContentTypeEnum;
  versionId: string | '';
  isSaved?: boolean;
  teaser?: string;
};

type MetadataDTO = {
  version_id?: string;
};

type Metadata = {
  versionId?: string;
};

export type SingleSelfTestDetailsDTO = {
  id: string;
  description: string;
  heading: string;
  image_url: string;
  version_id: string;
  version_number: number;
  author: AuthorWithIdDTO;
  publish_status: PublishEnum;
  is_sticky: string;
  questions: Question[];
  service_area: ServiceAreaDTO;
  language: LanguageDTO;
  max_score?: number;
};

export type SingleSelfTestDetails = {
  id: string;
  description: string;
  heading: string;
  imageUrl: string;
  versionId: string;
  versionNumber: number;
  author: AuthorWithId;
  publishStatus: PublishEnum;
  isSticky: string;
  questions: Question[];
  serviceArea: ServiceArea;
  language: Language;
  maxScore?: number;
};

export type RelatedGuideDTO = {
  id: string;
  related_articles: number[];
};

export type RelatedGuide = {
  id: string;
  relatedArticles: number[];
};

export type SelfTestResultDTO = {
  id: number | null;
  self_test: SingleSelfTestDetailsDTO;
  progress_status: string;
  current_score: number;
  max_score: number;
  answered_questions: AnsweredQuestionDTO[];
  recommendation_texts: string[];
  recommended_articles: ContentItemDTO[];
  recommended_guides: ContentItemDTO[];
  metadata?: MetadataDTO | undefined;
};

export type SelfTestResult = {
  id: number | null;
  selfTest: SingleSelfTestDetails;
  progressStatus: string;
  currentScore: number;
  maxScore: number;
  answeredQuestions: AnsweredQuestion[];
  recommendationTexts: string[];
  recommendedArticles: ContentItem[];
  recommendedGuides: ContentItem[];
  metadata?: Metadata | undefined;
};

export type AnsweredQuestionDTO = {
  user_self_test_id: number | string | null;
  question_id?: number | null | string;
  answer_id: number | null | string;
  id?: number | null;
};

export type AnsweredQuestion = {
  userSelfTestId: number | string | null;
  answerId: number | null | string;
  questionId?: number | null | string; // null and string is for formik
  id?: number | null;
};

export type SelfTestProgress = {
  id: number | string;
  selfTest: {
    id: string | number;
    versionId: string;
    versionNumber: number | null;
    author: AuthorWithId;
    language: Language;
    serviceArea: ServiceArea;
    description: string;
    heading: string;
    imageUrl: string;
    publishStatus: PublishEnum;
    isSticky: boolean;
  };
  answeredQuestions?: AnsweredQuestion[];
  progressStatus: string;
};

export type SelfTestProgressDTO = {
  id: number | string;
  self_test: {
    id: string | number;
    version_id: string;
    version_number: number | null;
    author: AuthorWithIdDTO;
    language: LanguageDTO;
    service_area: ServiceAreaDTO;
    description: string;
    heading: string;
    image_url: string;
    publish_status: PublishEnum;
    is_sticky: boolean;
  };
  answered_questions?: AnsweredQuestionDTO[];
  progress_status: string;
};

export type SelfTestFeedbackDTO = {
  heading: string;
  description: string;
};

export type SelfTestFeedback = {
  heading: string;
  description: string;
};

export type SelfTests = {
  getSelfTests: (params: { limit: number; offset: number; type: string }) => { url: string; method: string };
  getAddTestLink: () => { url: string; method: string };
};

export type SelfTestsEndpoints = {
  getSelfTestData: (selfTestId: string) => Promise<SingleSelfTestDetails>;
  answerQuestion: (id: string, data: AnsweredQuestion) => Promise<AnsweredQuestionDTO>;
};
