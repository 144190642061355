import { makeStyles } from '@material-ui/core';

type StylesProps = {
  isDragging: boolean;
};
export default makeStyles(theme => ({
  row: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  root: {
    opacity: ({ isDragging }: StylesProps) => (isDragging ? 0 : 1),
  },
  dndIcon: {
    cursor: 'grab',
  },
  addButton: {
    justifySelf: 'center',
  },
}));
