const table_messages = {
  no_data_cell: ['table>>no_data_cell', '-'],
  no_data_table: ['table>>no_data_table', 'No content to display'],
  last_edit_date_shortcut: ['table>>last_edit_date_shortcut', 'LE'],
  creation_date_shortcut: ['table>>creation_date_shortcut', 'CR'],
  author: ['table>>author', 'Author'],
  titleH1: ['table>>titleH1', 'H1'],
  date: ['table>>date', 'Date'],
  service_area: ['table>>service_area', 'Service Area'],
  audience: ['table>>audience', 'Audience'],
  name: ['table>>name', 'Name'],
  email: ['table>>email', 'Email'],
  last_login: ['table>>last_login', 'Last login'],
  status: ['table>>status', 'Status'],
  roles: ['table>>roles', 'Roles'],
  type: ['table>>type', 'Type'],
  guide_type: ['table>>guide_type', 'Guide type'],
  start: ['table>>start', 'Start'],
  customer_user_name: ['table>>customer_user_name', 'Customer username'],
  customer_organization_name: ['table>>customer_organization_name', 'Customer org. name'],
  advisor: ['table>>advisor', 'Advisor'],
  not_edited: ['table>>not_edited', 'Not edited in {{days}} days'],
  is_gdpr: ['table>>is_gdpr', 'Is GDPR'],
  is_ongoing: ['table>>is_ongoing', 'Is ongoing'],
  priority: ['table>>priority', 'Priority'],
  phase: ['table>>phase', 'Phase'],
  assets_filters: {
    is_my_assets: ['table>>assets_filters>>is_my_assets', 'My assets'],
    assets_category: ['table>>assets_filters>>assets_category', 'Asset category'],
    confidentiality: ['table>>assets_filters>>confidentiality', 'Confidentiality'],
    integrity_change_control: ['table>>assets_filters>>integrity_change_control', 'Integrity/change control'],
    availability: ['table>>assets_filters>>availability', 'Availability'],
    identifiable_information: ['table>>assets_filters>>identifiable_information', 'Contains identifiable information about individuals'],
    sensitive_individual_information: [
      'table>>assets_filters>>sensitive_individual_information',
      'Contains sensitive individual information',
    ],
    assets_importance: ['table>>assets_filters>>assets_importance', 'Assets importance for organization'],
    legal_demands: [
      'table>>assets_filters>>legal_demands',
      'Is the asset under some sort of legal control/demand of insurance requirement ',
    ],
  },
  use_two_fa: ['table>>use_two_fa', '2FA'],
};

export default table_messages;
