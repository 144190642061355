import React from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import auth_messages from 'messages/auth_messages';
import { AUTH_PATHS } from 'router/PATHS';

import AuthTile from './_components/AuthTile';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.primary[50],
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5, 0),
    minHeight: `calc(100vh - ${theme.spacing(5)}px)`,
    [theme.breakpoints.down('sm')]: {
      ...theme.mixins.fullHeight,
      padding: theme.spacing(2, 0),
    },
  },
  tileWrapper: {
    width: '100%',
  },
}));

const AuthPage = () => {
  const { t } = useTranslation();
  const { type } = useParams();

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const getTitle = () => {
    switch (type) {
      case AUTH_PATHS.LOGIN:
        return t(...auth_messages.headings.login);
      case AUTH_PATHS.REGISTER:
        return t(...auth_messages.headings.register);
      case AUTH_PATHS.FORGOT_PASSWORD:
        return t(...auth_messages.headings.forgot_password);
      case AUTH_PATHS.PASSWORD_RESET_LINK_SENT:
        return t(...auth_messages.headings.forgot_password);
      case AUTH_PATHS.PASSWORD_RESET:
        return t(...auth_messages.headings.forgot_password);
      case AUTH_PATHS.PASSWORD_CHANGED:
        return t(...auth_messages.headings.forgot_password);
      default:
        return t(...auth_messages.headings.login);
    }
  };

  const styles = useStyles();

  return (
    <Container disableGutters={!upMd}>
      <div className={styles.wrapper}>
        <div className={styles.tileWrapper}>
          <Typography align='center' component='h1' variant='h1'>
            {getTitle()}
          </Typography>
          <AuthTile type={type} />
        </div>
      </div>
    </Container>
  );
};

export default AuthPage;
