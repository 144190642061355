import React, { FC, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useTranslation } from 'react-i18next';

import AddIncidentLogDialog from 'components/_dialogs/AddIncidentLogDialog';
import ColoredButton from 'components/ColoredButton';
import Table from 'components/Table';
import { IncidentReportLogDTO } from 'config/api/incident_reports.types';
import ROLES from 'config/constants/ROLES';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import parseDate from 'helpers/parseDate';
import parseTime from 'helpers/parseTime';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import report_messages from 'messages/report_messages';

import IncidentLogDetailsDialog from '../_dialogs/IncidentLogDetailsDialog';

import useStyles from './IncidentLogs.styles';

type Props = {
  readonly: boolean;
  reportId: string;
  refreshData: () => void;
  logsData: IncidentReportLogDTO[];
};

const IncidentLogs: FC<Props> = ({ readonly, reportId, logsData, refreshData }) => {
  const { t } = useTranslation();
  const [isIncidentManager] = usePermissions([ROLES.ORGANIZATION.INCIDENT_MANAGER]);

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const openAddDialog = () => setIsAddDialogOpen(true);
  const closeAddDialog = () => setIsAddDialogOpen(false);

  const [detailsDialogData, setDetailsDialogData] = useState<IncidentReportLogDTO | null>(null);
  const openDetailsDialog = setDetailsDialogData;
  const closeDetailsDialog = () => setDetailsDialogData(null);

  const styles = useStyles();

  const dataMapping = [
    {
      id: 'contacted',
      width: '30%',
      label: t(report_messages.log_table_headers.contacted),
      get: (data: IncidentReportLogDTO | null) => data?.contacted,
    },
    {
      id: 'date_time',
      label: t(report_messages.log_table_headers.date),
      width: '25%',

      get: (data: IncidentReportLogDTO | null) => (
        <div>
          {/* eslint-disable react/destructuring-assignment */}
          <p>{parseDate(data?.date_time)}</p>
          <p>{parseTime(data?.date_time)}</p>
          {/* eslint-enable react/destructuring-assignment */}
        </div>
      ),
    },
    {
      id: 'phase',
      width: '35%',
      label: t(report_messages.log_table_headers.phase),
      get: (data: IncidentReportLogDTO | null) => (data?.phase ? t(incidentPhaseResolver(data.phase)) : null),
    },
    {
      id: 'info',
      width: '10%',
      label: '',
      isAddon: true,
      get: (data: IncidentReportLogDTO | null) => (
        <IconButton data-testid='log-dialog' onClick={() => openDetailsDialog(data)}>
          <InfoOutlinedIcon className={styles.icon} />
        </IconButton>
      ),
    },
  ];

  if (!isIncidentManager) return null;

  return (
    <div className={styles.wrapper}>
      {/* @ts-ignore */}
      <Table data={logsData} dataMapping={dataMapping} fixedHeader />
      {!readonly && (
        <>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' data-testid='add-log-dialog' endIcon={<Add />} onClick={openAddDialog}>
            {t(general_messages.add)}
          </ColoredButton>
          <AddIncidentLogDialog onClose={closeAddDialog} open={isAddDialogOpen} refreshData={refreshData} reportId={reportId} />
        </>
      )}
      {/* @ts-ignore */}
      <IncidentLogDetailsDialog logData={detailsDialogData} onClose={closeDetailsDialog} open={!!detailsDialogData} />
    </div>
  );
};

export default IncidentLogs;
