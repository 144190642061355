// TODO unify params, and check isSingleSelect values - needs BE changes in most EPs

const parseFiltersForRequest = (filters, fallbacks = {}, availableFilters = {}) => {
  const filtersToRequest = Object.entries(filters).reduce((acc, [key, values]) => {
    const matchingConfig = availableFilters[key];
    let value = values.map(({ id }) => `${id}`);
    if (matchingConfig?.isSingleSelect) [value] = value;
    const phaseFilter = 'phase';
    if (key === phaseFilter) {
      return {
        ...acc,
        [key]: value,
      };
    }
    return {
      ...acc,
      ...(value?.length && { [key]: value }),
    };
  }, {});
  Object.entries(fallbacks).forEach(([key, value]) => {
    if (!filtersToRequest.hasOwnProperty(key)) {
      filtersToRequest[key] = value;
    }
  });
  return filtersToRequest;
};

export default parseFiltersForRequest;
