import { format } from 'date-fns';

import locale from 'config/locale';

const generateMonths = (language: 'en' | 'sv', stringFormat = 'MMMM') => {
  const dropdownBase = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const options = dropdownBase.map(optionValue => {
    const localeValue = locale[language || 'en'];
    return { optionValue, label: format(new Date(1971, optionValue), stringFormat, { locale: localeValue }) };
  });

  return { dropdownBase, dropdownBaseLength: dropdownBase.length, options };
};

export default generateMonths;
