import { makeStyles } from '@material-ui/core';

type StylesProps = {
  isMenuOpen: boolean;
};

export default makeStyles(theme => ({
  list: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  item: {
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.secondary.light,
      },
    },
    display: ({ isMenuOpen }: StylesProps) => (isMenuOpen ? 'flex' : 'none'),
  },
  itemText: {
    '& span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem !important',
      },
    },
  },
  active: {
    color: theme.palette.secondary.main,
    position: 'relative',
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    '&:before': {
      display: 'block',
      content: '"•"',
      position: 'absolute',
      left: 5,
    },
  },
}));
