import React from 'react';

import clsx from 'clsx';

import useStyles from './TextButton.styles';

type Props = {
  children: React.ReactElement | string;
  className?: string;
  onClick?: () => void;
};

const TextButton: React.FC<Props> = ({ children, className, ...rest }) => {
  const styles = useStyles();
  return (
    <button className={clsx(styles.button, className)} type='button' {...rest}>
      {children}
    </button>
  );
};

export default TextButton;
