import React from 'react';

import { Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import EditOrganizationDataDialog from 'components/_dialogs/EditOrganizationDataDialog/EditOrganizationDataDialog';
import TerminateAccountDialog from 'components/_dialogs/TerminateAccountDialog';
import ColoredButton from 'components/ColoredButton';
import { Organization } from 'config/api/organizations/organizations.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { usersApi } from 'config/api/users/users';
import { employeesNumberResolver } from 'config/dictionaries/EMPLOYES_NUMBER_SELECT';
import parseDate from 'helpers/parseDate';
import useBoolState from 'hooks/useBoolState';
import general_messages from 'messages/general_messages';
import organization_administration_messages from 'messages/organization_administration_messages';
import { Id } from 'types/Id';

import useStyles from './OrganizationAdministrationManage.styles';

type Props = {
  organizationData?: Organization;
  isLoading: boolean;
  organizationId: Id;
};

const OrganizationAdministrationManage: React.FC<Props> = ({ organizationData, isLoading, organizationId }) => {
  const { t } = useTranslation();
  const { state: terminateDialog, setFalse: closeTerminateDialog, setTrue: openTerminateDialog } = useBoolState(false);
  const { state: editDialog, setFalse: closeEditDialog, setTrue: openEditDialog } = useBoolState(false);

  const { data: pricingData, isLoading: isPriceLoading } = useQuery([QUERY_KEYS.PRICING_INFO, organizationId], () =>
    usersApi.getOrganizationPricingInfo(organizationId),
  );

  const styles = useStyles();
  return (
    <>
      <div>
        <div className={styles.container}>
          <section>
            <Typography className={styles.nameHeader} component='h3' variant='h3'>
              {isLoading ? (
                <>
                  <Skeleton height='2rem' width='50%' />
                  <Skeleton height='30px' variant='circle' width='30px' />
                </>
              ) : (
                <>
                  <span>{organizationData?.name}</span>
                  {/* @ts-ignore */}
                  <ColoredButton customColor='none' disabled={!organizationData} icon onClick={openEditDialog}>
                    <EditOutlinedIcon fontSize='small' />
                  </ColoredButton>
                </>
              )}
            </Typography>
            <div className={styles.contentBlock}>
              {isLoading ? (
                <Skeleton height='3rem' width='70%' />
              ) : (
                <>
                  <Typography component='p' variant='body2'>
                    {organizationData?.organizationNumber}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {organizationData?.info.businessType &&
                      t(`general>>business_types>>${organizationData?.info.businessType}`, organizationData?.info.businessType)}
                  </Typography>
                </>
              )}
            </div>
            <div className={styles.contentBlock}>
              {isLoading ? (
                <Skeleton height='2rem' width='90%' />
              ) : (
                <Typography component='p' variant='body2'>
                  {t(organization_administration_messages.employees)}: {t(employeesNumberResolver(organizationData?.info.organizationSize))}
                </Typography>
              )}
            </div>
          </section>
          <section>
            <Typography component='h3' variant='h3'>
              {t(organization_administration_messages.contact_person)}
            </Typography>
            <div className={styles.contentBlock}>
              {isLoading ? (
                <Skeleton height='2rem' width='80%' />
              ) : (
                <>
                  <Typography component='p' variant='body2'>
                    {organizationData?.info.contactFullName || t(general_messages.no_data)}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {organizationData?.info.contactEmail}
                  </Typography>
                </>
              )}
            </div>
          </section>
          {organizationData?.campaignCode && (
            <section>
              <Typography component='h3' variant='h3'>
                {t(organization_administration_messages.campaign_code)}
              </Typography>
              <div className={styles.contentBlock}>
                {isLoading ? (
                  <Skeleton height='2rem' width='80%' />
                ) : (
                  <Typography component='p' variant='body2'>
                    {organizationData?.campaignCode}
                  </Typography>
                )}
              </div>
            </section>
          )}
          <section>
            <Typography component='h3' variant='h3'>
              {t(organization_administration_messages.pricing_info.title)}
            </Typography>
            <div className={styles.contentBlock}>
              {isPriceLoading ? (
                <>
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                </>
              ) : (
                <>
                  <Typography component='p' variant='body2'>
                    {`${t(organization_administration_messages.pricing_info.number_of_free_months_for_users)}: ${
                      pricingData?.data?.number_of_free_months_for_users || t(general_messages.no_data)
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(organization_administration_messages.pricing_info.price_for_first_active_user)}: ${
                      pricingData?.data?.price_for_first_active_user || t(general_messages.no_data)
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(organization_administration_messages.pricing_info.price_for_second_and_next_active_user)}: ${
                      pricingData?.data?.price_for_second_and_next_active_user || t(general_messages.no_data)
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(organization_administration_messages.pricing_info.price_for_advisory_meeting)}: ${
                      pricingData?.data?.price_for_advisory_meeting || t(general_messages.no_data)
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(organization_administration_messages.pricing_info.valid_from)}: ${
                      parseDate(pricingData?.data?.valid_from) || t(general_messages.no_data)
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(organization_administration_messages.pricing_info.valid_to)}: ${
                      parseDate(pricingData?.data?.valid_to) || t(general_messages.no_data)
                    }`}
                  </Typography>
                </>
              )}
            </div>
          </section>
          <section>
            {/* @ts-ignore */}
            <ColoredButton
              className={styles.terminateButton}
              customColor='danger'
              disabled={isLoading}
              onClick={openTerminateDialog}
              textLike
            >
              {t(organization_administration_messages.terminate_organization)}
            </ColoredButton>
          </section>
        </div>
      </div>
      {organizationData && <EditOrganizationDataDialog onClose={closeEditDialog} open={editDialog} organizationData={organizationData} />}
      <TerminateAccountDialog onClose={closeTerminateDialog} open={terminateDialog} />
    </>
  );
};

export default OrganizationAdministrationManage;
