import { makeStyles } from '@material-ui/core';

type StylesProps = {
  showDivider: boolean;
};

export default makeStyles(theme => ({
  section: {
    display: 'grid',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    // @ts-ignore
    borderTop: ({ showDivider }: StylesProps) => (showDivider ? `2px solid ${theme.palette.primary[300]}` : 'unset'),
    paddingTop: ({ showDivider }: StylesProps) => (showDivider ? theme.spacing(3) : 'unset'),
  },
  grid: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  row: {
    display: 'grid',
    alignItems: 'flex-end',
    gridTemplateColumns: '1fr 3fr',
    gridGap: theme.spacing(2),
  },

  wideLabel: {
    gridTemplateColumns: '3fr 2fr',
  },
}));
