import React from 'react';

import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';

import useStyles from './DateWithCalendarIcon.styles';

type Props = {
  label: string;
  date?: Date;
  dateFormat?: string;
};

const DateWithCalendarIcon: React.FC<Props> = ({ date, label, dateFormat = DATE_FORMAT.defaultDate }) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <CalendarIcon />
      <div>
        <Typography>{label}</Typography>
        {date ? <Typography>{format(date, dateFormat)}</Typography> : <Skeleton width={90} />}
      </div>
    </div>
  );
};

export default DateWithCalendarIcon;
