import React from 'react';

import { useTranslation } from 'react-i18next';

import UpdateOrganizationForm from 'components/UpdateOrganizationForm/UpdateOrganizationForm';
import { parseOrganizationToFormInput } from 'config/api/organizations/organizations.parsers';
import { Organization } from 'config/api/organizations/organizations.types';
import organization_administration_messages from 'messages/organization_administration_messages';
import { BaseDialogProps } from 'types/BaseDialogProps';

import DialogGrid from '../_components/DialogGrid';

type Props = BaseDialogProps & { organizationData: Organization };

const EditOrganizationDataDialog: React.FC<Props> = ({ open, onClose, organizationData }) => {
  const { t } = useTranslation();

  return (
    // @ts-ignore
    <DialogGrid onClose={onClose} open={open} title={t(organization_administration_messages.update_info_dialog_title)}>
      <UpdateOrganizationForm initialData={parseOrganizationToFormInput(organizationData)} onClose={onClose} />
    </DialogGrid>
  );
};

export default EditOrganizationDataDialog;
