import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import MenuElementsList from 'components/Layout/_components/MenuElementsList/MenuElementsList';
import MenuSection from 'components/Layout/_components/MenuSection';
import checkAccess from 'config/permissions/checkAccess';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';
import menu_messages from 'messages/menu_messages';

import menuElements from '../menuElements';

const ContentSection = () => {
  const { t } = useTranslation();

  const { stage } = useOrganizationPaymentStageInfo();

  const elementsToDisplay = useMemo(
    () =>
      menuElements.content.map(element => {
        const { allowedInStages } = element;
        if (!allowedInStages || !stage) {
          return element;
        }
        return { ...element, disabled: !checkAccess([stage], allowedInStages) };
      }),
    [stage],
  );

  return (
    // @ts-ignore
    <MenuSection title={t(...menu_messages.content)}>
      <MenuElementsList elements={elementsToDisplay} />
    </MenuSection>
  );
};

export default ContentSection;
