import React, { SyntheticEvent } from 'react';

import { FormControlLabel, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';

import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';

import CustomAccordion from '../CustomAccordion';

import useStyles from './CheckboxWithInfo.styles';

type Props = {
  label: string;
  onChange?: () => void;
  selected: boolean;
  control: React.ReactElement<any, any>;
  description: string;
  className?: string;
  expandable?: boolean;
};

const CheckboxWithInfo: React.FC<Props> = ({
  label,
  onChange = () => null,
  selected,
  control,
  description,
  className,
  expandable,
  ...rest
}) => {
  const styles = useStyles();

  const stopPropagation = (event: SyntheticEvent) => event.stopPropagation();

  if (expandable && description) {
    return (
      <CustomAccordion
        accordionClassName={clsx(styles.accordionTile, selected && styles.selected, className)}
        heading={
          <FormControlLabel
            classes={{ label: styles.label, root: styles.root }}
            control={control}
            label={label}
            onChange={onChange}
            onClick={stopPropagation}
            onFocus={stopPropagation}
            {...rest}
          />
        }
        iconsSet={{
          expanded: <InfoIcon />,
          notExpanded: <InfoOutlinedIcon />,
        }}
        id={label}
        summaryClasses={{ expanded: styles.expanded }}
      >
        <TypographyWithHTML>{description}</TypographyWithHTML>
      </CustomAccordion>
    );
  }

  return (
    <div className={clsx(styles.tile, selected && styles.selected, className)}>
      <FormControlLabel
        classes={{ label: styles.label, root: styles.root }}
        control={control}
        label={label}
        onChange={onChange}
        {...rest}
      />
      {description && (
        <Tooltip classes={{ tooltip: styles.tooltip }} title={description}>
          <InfoOutlinedIcon className={styles.icon} />
        </Tooltip>
      )}
    </div>
  );
};

export default CheckboxWithInfo;
