const convertObjectToFormData = (obj: object) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    let safeValue: string | File;

    if (typeof value === 'number') safeValue = value.toString();
    else safeValue = value;
    if (safeValue instanceof File && safeValue.size === 0) return;
    if (safeValue) formData.append(key, safeValue);
  });

  return formData;
};

export default convertObjectToFormData;
