import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  informationWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(1, 0.5),
  },
}));

const InformationSummary = ({ data }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  if (!data || !data.length) return null;
  return (
    <div className={styles.informationWrapper}>
      {data.map(({ label, value }) => (
        <Typography key={label}>{`${t(...label)}: ${value}`}</Typography>
      ))}
    </div>
  );
};

InformationSummary.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.arrayOf(PropTypes.string), value: PropTypes.string })).isRequired,
};

export default InformationSummary;
