import { uniqBy } from 'lodash';

import { OrganizationAssetTasksFilterData, OrganizationAssetTasksFilterDTO } from './organizationAssetTasksFilterData.types';

// eslint-disable-next-line import/prefer-default-export
export const parseOrganizationAssetTasksFilterDataToTableFilters = (
  dto: OrganizationAssetTasksFilterDTO,
): OrganizationAssetTasksFilterData => {
  const result: OrganizationAssetTasksFilterData = {
    owner: [],
    name: [],
    activityName: [],
  };

  const uniqueByAsset = uniqBy(dto.assets, 'id');
  uniqueByAsset.forEach(({ name: assetName }) => {
    result.name.push({ id: assetName, name: [assetName, assetName] });
  });

  const uniqueByOwner = uniqBy(dto.assets, 'owner.id');
  uniqueByOwner.forEach(({ owner: { id, first_name, last_name } }) => {
    const name = [first_name, last_name].join(' ');
    result.owner.push({ id, name: [name, name] });
  });

  const uniqueByActivity = uniqBy(dto.activities, 'id');
  uniqueByActivity.forEach(({ id, name, name_translation_key }) => {
    result.activityName.push({ id, name: [name_translation_key, name] });
  });
  return result;
};
