import React from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ContentArticleTile from 'components/ContentArticleTile/ContentArticleTile';
import ContentGuideTile from 'components/ContentGuideTile/ContentGuideTile';
import SelfTestTile from 'components/SelfTestTile/SelfTestTile';
import type { ContentItem } from 'config/api/selfTests/_types';
import assets_messages from 'messages/assets_messages';

import useStyles from './YearPlannerActivityRelatedContent.styles';

type Props = {
  relatedGuides: ContentItem[];
  relatedSelfTests: ContentItem[];
  relatedArticles: ContentItem[];
};

const YearPlannerActivityRelatedContent: React.FC<Props> = ({ relatedGuides, relatedArticles, relatedSelfTests }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <>
      <CenteredGrid withoutPadding>
        <Typography variant='h5'>{t(assets_messages.year_activity_asset.related_content_title)}</Typography>
      </CenteredGrid>
      <CarouselWrapper leftMargin={6} noMargin noTopPadding>
        {relatedSelfTests.map(selfTest => (
          <div key={selfTest.id} className={styles.relatedContentItemWrapper}>
            <SelfTestTile key={selfTest.id} data={selfTest} />
          </div>
        ))}
        {relatedGuides.map(guide => (
          <div key={guide.id} className={styles.relatedContentItemWrapper}>
            <ContentGuideTile key={guide.id} data={guide} unified />
          </div>
        ))}
      </CarouselWrapper>
      <CarouselWrapper leftMargin={6} noMargin>
        {relatedArticles.map(article => (
          <div key={article.id} className={styles.relatedContentItemWrapper}>
            <ContentArticleTile key={article.id} data={article} smallTile withImage />
          </div>
        ))}
      </CarouselWrapper>
    </>
  );
};

export default YearPlannerActivityRelatedContent;
