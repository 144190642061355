enum RolesPocketSafe {
  ContentEditor = 'pocketsafe_content_editor',
  CustomerAdministrator = 'pocketsafe_customer_administrator',
  SecurityOnline = 'pocketsafe_security_online',
  SecuritySupervisor = 'pocketsafe_security_supervisor',
}

export const EVERY_PS_ROLE = Object.values(RolesPocketSafe);

export default RolesPocketSafe;
