import DIRECTIONS from 'config/constants/sortDirections';
import { SortDirections } from 'types/General';

const resolveDirection = (currentDirection: SortDirections | undefined, sameKey: boolean) => {
  if (!sameKey) return DIRECTIONS.ASC;
  switch (currentDirection) {
    case DIRECTIONS.ASC:
      return DIRECTIONS.DESC;
    case DIRECTIONS.DESC:
      return null;
    default:
      return DIRECTIONS.ASC;
  }
};

export default resolveDirection;
