import { makeStyles } from '@material-ui/core/styles';

type StylesProps = {
  width: string;
  gridGap: number;
  withoutPadding?: boolean;
  tight?: boolean;
};

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    // @ts-ignore
    maxWidth: ({ width }: StylesProps) => theme.sizes.container[width],
    gridGap: ({ gridGap }: StylesProps) => theme.spacing(gridGap),
    display: 'grid',
    margin: '0 auto',
    paddingBottom: ({ withoutPadding, tight }: StylesProps) => (!withoutPadding ? theme.spacing(tight ? 3 : 6) : 0),
  },
}));

export default useStyles;
