import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingTop: ({ isEmpty }) => theme.spacing(isEmpty ? 0 : 8),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  meetingsContainer: {
    display: 'grid',
    width: '100%',
    maxWidth: theme.sizes.container.sm,
    gridGap: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
}));
