import React from 'react';

import { Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import placeholder from 'assets/placeholders/article.jpg';
import { ContentItem } from 'config/api/selfTests/_types';
import { contentTypeResolver } from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import stripHTMLTags from 'helpers/stripHTMLTags';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';

import ImageWithFallback from '../ImageWithFallback';

import useStyles from './ContentArticleTile.styles';

type Props = {
  data: ContentItem;
  withImage: boolean;
  smallTile?: boolean;
};
type SkeletonProps = {
  withImage: boolean;
};

const ArticleTileSkeleton: React.FC<SkeletonProps> = ({ withImage }) => {
  const styles = useStyles({ withImage });
  return (
    <Paper className={styles.paper} elevation={0}>
      {withImage && (
        <div className={styles.imageWrapper}>
          <img alt='placeholder' className={styles.image} src={placeholder} />
        </div>
      )}
      <div className={styles.wrapper}>
        <Typography component='p'>
          <Skeleton variant='text' width='50%' />
        </Typography>
        <Typography component='h3' variant='body1'>
          <Skeleton height='40px' variant='text' />
        </Typography>
        <Typography className={styles.teaser} component='p' variant='body2'>
          <Skeleton height='8em' variant='text' />
        </Typography>
      </div>
      <div className={styles.footer}>
        <Typography component='span'>
          <Skeleton variant='text' width='40px' />
        </Typography>
        <Typography component='span'>
          <Skeleton variant='text' width='40px' />
        </Typography>
      </div>
    </Paper>
  );
};

const ContentArticleTile: React.FC<Props> = ({ data, smallTile, withImage }) => {
  if (!data) return <ArticleTileSkeleton withImage={withImage} />;
  const { t } = useTranslation();
  const { estimatedTime, id, imageUrl, serviceArea, objectContentType, teaser, heading, language } = data;

  const styles = useStyles({ withImage, smallTile });

  return (
    <Paper className={styles.paper} elevation={0}>
      <Link className={styles.link} to={`${PATHS.ARTICLES}/${id}`}>
        {withImage && (
          <div className={styles.imageWrapper}>
            <ImageWithFallback alt={heading} className={styles.image} src={imageUrl} />
          </div>
        )}
        <div className={styles.wrapper}>
          <Typography className={styles.greenText} component='h3'>
            {serviceArea?.name}
          </Typography>
          <Typography className={styles.heading} component='h3' variant='body1'>
            {stripHTMLTags(heading)}
          </Typography>
          <Typography className={styles.teaser} component='div' variant='body2'>
            {teaser && parse(teaser)}
          </Typography>
        </div>
        <div className={styles.footer}>
          <Typography className={styles.greenText} component='span'>
            {estimatedTime && estimatedTime >= 1 && `${estimatedTime} ${t(general_messages.minutes_shortcut).toUpperCase()}`}
          </Typography>
          <Typography className={styles.greenText} component='span'>
            {t(contentTypeResolver(objectContentType))}
          </Typography>
          <Typography className={styles.greenText} component='span'>
            {language?.languageCode}
          </Typography>
        </div>
      </Link>
    </Paper>
  );
};

export default ContentArticleTile;
