import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import UserAvatar from 'components/UserAvatar/UserAvatar';
import UserCardSection from 'components/UserCardSection/UserCardSection';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { usersApi } from 'config/api/users/users';
import personal_info_messages from 'messages/personal_info_messages';

import useStyles from './UserCard.styles';

type Props = {
  isLoading: boolean;
  lazy?: boolean;
  userId?: string | number;
  userData?: {
    email: string;
    first_name: string;
    last_name: string;
    title: string;
    phone_number: string;
    image_url: string;
    sprvsr_first_name: string;
    sprvsr_last_name: string;
    sprvsr_title: string;
    sprvsr_email: string;
    sprvsr_phone_number: string;
    mrgnc_first_name: string;
    mrgnc_last_name: string;
    mrgnc_title: string;
    mrgnc_email: string;
    mrgnc_phone_number: string;
  };
};

const UserCard: React.FC<Props> = ({ isLoading, userData, lazy, userId }) => {
  const { t } = useTranslation();
  const shouldUseLocalQuery = useMemo(() => lazy && !!userId, [userId, lazy]);

  const { data: localData, isLoading: isLocalQueryLoading } = useQuery(
    [QUERY_KEYS.GET_USER_DATA, userId],
    () => usersApi.getUserData(userId),
    {
      enabled: shouldUseLocalQuery,
    },
  );

  const stateSource = useMemo(() => {
    if (shouldUseLocalQuery) {
      return { isLoading: isLocalQueryLoading, userData: localData };
    }
    return { isLoading, userData };
  }, [shouldUseLocalQuery, isLocalQueryLoading, localData, isLoading, userData]);

  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <div className={styles.mainSection}>
        <UserAvatar
          disableUserCard
          size='l'
          userData={userData && { firstName: userData.first_name, imageUrl: userData.image_url, lastName: userData.last_name }}
        />
        <div className={styles.headerInfo}>
          <Typography component='h1' variant='h5'>
            {userData?.first_name}
            <br />
            {userData?.last_name}
          </Typography>
          <Typography component='p' variant='h5'>
            {userData?.title}
          </Typography>
        </div>
      </div>
      <UserCardSection
        data={{ email: { value: userData?.email, id: 'email' }, phoneNumber: { value: userData?.phone_number, id: 'phone_number' } }}
        isLoading={stateSource.isLoading}
        shouldBeHidden={false}
        title={t(personal_info_messages.address)}
      />
      <UserCardSection
        data={{
          email: { value: userData?.sprvsr_email, id: 'sprvsr_email' },
          phoneNumber: { value: userData?.sprvsr_phone_number, id: 'sprvsr_phone_number' },
        }}
        isLoading={stateSource.isLoading}
        shouldBeHidden={!userData?.sprvsr_email && !stateSource.isLoading}
        subtitle={stateSource.isLoading ? <Skeleton /> : `${userData?.sprvsr_first_name} ${userData?.sprvsr_last_name}`}
        title={t(personal_info_messages.contact_roles.supervisor)}
      />
      <UserCardSection
        data={{
          email: { value: userData?.mrgnc_email, id: 'mrgnc_email' },
          phoneNumber: { value: userData?.mrgnc_phone_number, id: 'mrgnc_phone_number' },
        }}
        isLoading={stateSource.isLoading}
        shouldBeHidden={!userData?.mrgnc_email && !stateSource.isLoading}
        subtitle={stateSource.isLoading ? <Skeleton /> : `${userData?.mrgnc_first_name} ${userData?.mrgnc_last_name}`}
        title={t(personal_info_messages.contact_roles.emergency)}
      />
    </div>
  );
};

export default UserCard;
