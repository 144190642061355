import React, { useMemo } from 'react';

import { FormHelperText, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormCheckboxTile from 'components/FormCheckboxTile';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import TimePeriodPickerForPlanner from 'components/TimePeriodPickerForPlanner/TimePeriodPickerForPlanner';
import assetActivitiesApi from 'config/api/assetActivities/assetActivities';
import assetCategoriesApi from 'config/api/assetCategories/assetCategories';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { yearPlannerActivitiesApi } from 'config/api/yearPlannerActivities/yearPlannerActivities';
import { YearPlannerActivityInput } from 'config/api/yearPlannerActivities/yearPlannerActivities.types';
import EVENTS from 'config/events/pubsub';
import safeT from 'helpers/safeT/safeT';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';

import TypographyWithHTML from '../../TypographyWithHTML/TypographyWithHTML';
import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './AddYearPlannerAssetActivityDialog.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};

const FORM_ID = 'AddAssetActivityDialogForm';

type YearPlannerActivityFormInput = Omit<YearPlannerActivityInput, 'end' | 'begin'> & {
  end: { day: number | ''; month: number | '' };
  begin: { day: number | ''; month: number | '' };
};

const AddYearPlannerAssetActivityDialog: React.FC<Props> = ({ onClose, open }) => {
  const { t } = useTranslation();

  const mutation = useMutation(MUTATION_KEYS.ASSETS_YEAR_PLANNER_CREATE, yearPlannerActivitiesApi.createYearPlannerActivity);

  const onSubmit = async (formData: YearPlannerActivityFormInput) => {
    const { data } = await mutation.mutateAsync(formData as YearPlannerActivityInput);
    if (!data) return;
    PubSub.publish(EVENTS.ASSETS_YEAR_PLANNER_UPDATED);
    onClose();
  };

  const formik = useFormik<YearPlannerActivityFormInput>({
    initialValues: {
      activityId: '',
      categoriesIds: {},
      end: { day: '', month: '' },
      begin: { day: '', month: '' },
      companyNotes: '',
    },
    onSubmit,
    validationSchema: yup.object({
      activityId: yup.string().required(t(validation_messages.required)),
      categoriesIds: yup
        .object()
        .test('category', t(validation_messages.year_activity_needs_one_category), (value: Record<number, boolean>): boolean =>
          Object.values(value).some(v => v),
        ),
      end: yup
        .object()
        .test(
          'end_date',
          t(validation_messages.required),
          (value: Record<string, boolean>): boolean => !isNil(value.day) && !isNil(value.month),
        ),
      begin: yup
        .object()
        .test(
          'end_date',
          t(validation_messages.required),
          (value: Record<string, boolean>): boolean => !isNil(value.day) && !isNil(value.month),
        ),
    }),
  });

  const { data: categories } = useQuery(QUERY_KEYS.ASSETS_CATEGORIES, assetCategoriesApi.getAllAssetCategories);
  const { data: activitiesOptions } = useQuery(
    QUERY_KEYS.ASSETS_YEAR_PLANNER_ACTIVITIES,
    assetActivitiesApi.getAllAssetActivitiesForSelect,
  );

  const selectedActivity = useMemo(() => {
    const chosenValue = formik.values.activityId;
    if (!chosenValue || !activitiesOptions) return null;
    const match = activitiesOptions.find(({ key }) => key === chosenValue);
    return match || null;
  }, [formik.values.activityId, activitiesOptions]);

  const styles = useStyles();
  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={mutation.isLoading} onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={mutation.isLoading} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(assets_messages.add_year_activity_asset_modal.title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {/* @ts-ignore */}
        <CenteredGrid gridGap={4} withoutPadding>
          <FormSelect
            formik={formik}
            id='activityId'
            label={t(assets_messages.add_year_activity_asset_modal.activity)}
            options={activitiesOptions || []}
          />
          {selectedActivity && (
            <div className={styles.section}>
              <Typography variant='h5'>
                {t(assets_messages.year_activity_asset.activity_description_title, {
                  activityName: safeT(t, ...((selectedActivity?.label as string[] | undefined) || [])),
                })}
              </Typography>
              <TypographyWithHTML>{safeT(t, ...(selectedActivity.metaData?.description || []))}</TypographyWithHTML>
            </div>
          )}

          <div className={styles.section}>
            <Typography variant='h5'>{t(assets_messages.year_activity_asset.company_notes_title)}</Typography>
            <FormTextInput
              formik={formik}
              id='companyNotes'
              label={t(assets_messages.add_year_activity_asset_modal.company_notes)}
              multiline
              rows={3}
            />
          </div>

          <div className={styles.section}>
            <Typography variant='h5'>{t(assets_messages.year_activity_asset.dates_title)}</Typography>
            <div className={styles.twoColumnSection}>
              <TimePeriodPickerForPlanner formik={formik} id='begin' label={t(assets_messages.year_activity_asset.begin_date)} />
              <TimePeriodPickerForPlanner formik={formik} id='end' label={t(assets_messages.year_activity_asset.end_date)} />
            </div>
          </div>

          <div className={styles.section}>
            <Typography variant='h5'>{t(assets_messages.year_activity_asset.categories_title)}</Typography>
            <div className={styles.section}>
              {categories?.map(({ name, nameTranslationKey, id }) => (
                <FormCheckboxTile
                  key={id}
                  formik={formik}
                  id={`categoriesIds.${id}`}
                  label={safeT(t, nameTranslationKey, name)}
                  required={false}
                />
              ))}
              <FormHelperText error>{formik.touched.categoriesIds && (formik.errors.categoriesIds as unknown as string)}</FormHelperText>
            </div>
          </div>
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default AddYearPlannerAssetActivityDialog;
