import React from 'react';

import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ColoredButton from 'components/ColoredButton';
// eslint-disable-next-line import/extensions
import ExternalLinkTile from 'components/ExternalLinkTile/ExternalLinkTile.tsx';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';

import MeetingReportAgendaItem from '../MeetingReportAgendaItem';

import useStyles from './MeetingReportMainContent.styles';

const MeetingReportMainContent = ({ data, FORM, readOnly, formik, onSubmit, sending }) => {
  const { t } = useTranslation();

  const onSave = () => onSubmit();
  const onCreateAdvice = () => onSubmit(true);

  const renderDynamicFields = () => (
    <>
      {data?.incident_report_id && (
        <MeetingReportAgendaItem
          description={data.advice_on}
          formik={formik}
          id={FORM.INCIDENT_REPORT}
          isIncidentReport
          readOnly={readOnly}
          report_id={data?.incident_report_id}
          title={t(...meeting_messages.discuss_incident_report)}
        />
      )}
      {data?.agenda_items &&
        data.agenda_items.map((agendaItem, index) => (
          <MeetingReportAgendaItem
            key={agendaItem.id}
            description={agendaItem.topic}
            formik={formik}
            id={`${FORM.ITEMS}[${agendaItem.id}]`}
            readOnly={readOnly}
            title={`${t(...meeting_messages.own_agenda_item_title, { number: index + 1 })}`}
          />
        ))}
    </>
  );

  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      {data?.video_link && (
        <>
          <Typography className={styles.subtitle} component='span' variant='h5'>
            {t(...meeting_messages.meeting_link_title)}
          </Typography>
          <ExternalLinkTile label={t(...meeting_messages.meeting_link_label)} url={data.video_link} />
        </>
      )}
      {readOnly ? renderDynamicFields() : <form onSubmit={formik.handleSubmit}>{renderDynamicFields()}</form>}
      {!readOnly && (
        <div className={styles.buttons}>
          <Button color='secondary' disabled={sending} onClick={onSave} variant='outlined'>
            {t(...general_messages.save)}
          </Button>
          <ColoredButton customColor='secondary' disabled={sending} onClick={onCreateAdvice}>
            {t(...meeting_messages.create_advice)}
          </ColoredButton>
        </div>
      )}
    </div>
  );
};

MeetingReportMainContent.propTypes = {
  data: PropTypes.shape({
    incident_report_id: PropTypes.string,
    advice_on: PropTypes.string,
    agenda_items: PropTypes.array,
  }),
  readOnly: PropTypes.bool,
  FORM: PropTypes.objectOf(PropTypes.string).isRequired,
  formik: PropTypes.shape({
    handleSubmit: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
};

MeetingReportMainContent.defaultProps = {
  data: null,
  readOnly: false,
};

export default MeetingReportMainContent;
