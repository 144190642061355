import React from 'react';

import { Container, useMediaQuery, useTheme } from '@material-ui/core';

import MobileGuttersContainer from 'components/MobileGuttersContainer';
import AdvisorProvider from 'reactContext/AdvisorContext';

import ManageMeetingSlotsCalendar from './_components/ManageMeetingSlotsCalendar';
import UpcomingMeetings from './_components/UpcomingMeetings';

const ManageMeetingSlotsPage = () => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container disableGutters={!upMd}>
      <AdvisorProvider>
        <UpcomingMeetings />
        <MobileGuttersContainer>
          <ManageMeetingSlotsCalendar />
        </MobileGuttersContainer>
      </AdvisorProvider>
    </Container>
  );
};

export default ManageMeetingSlotsPage;
