import { parseUserForFE } from '../_parsers/parseUser';

import {
  OrganizationAssetTasksStats,
  OrganizationAssetTasksStatsDTO,
  OrganizationAssetTasksInList,
  OrganizationAssetTasksInListDTO,
  OrganizationAssetTasksInDetail,
  OrganizationAssetTasksInDetailDTO,
} from './organizationAssetTasks.types';

export const parseAllOrganizationAssetTasksForFE = (dto: OrganizationAssetTasksInListDTO[]): OrganizationAssetTasksInList[] =>
  dto.map(task => ({
    id: task.id,
    name: task.name,
    dueDate: new Date(task.due_date),
    status: task.status,
    asset: {
      id: task.asset.id,
      key: task.asset.key,
      name: task.asset.name,
      owner: parseUserForFE(task.asset.owner),
      category: {
        id: task.asset.category.id,
        name: task.asset.category.name,
        nameTranslationKey: task.asset.category.name_translation_key,
      },
      info: {
        assetDescription: task.asset.info.asset_description,
      },
    },
    yearPlannerActivity: {
      id: task.year_planner_activity.id,
      begin: new Date(task.year_planner_activity.begin),
      end: new Date(task.year_planner_activity.end),
      activity: {
        id: task.year_planner_activity.activity.id,
        name: task.year_planner_activity.activity.name,
        nameTranslationKey: task.year_planner_activity.activity.name_translation_key,
        description: task.year_planner_activity.activity.description,
        descriptionTranslationKey: task.year_planner_activity.activity.description_translation_key,
      },
    },
  }));

export const parseSingleOrganizationAssetTasksForFE = (dto: OrganizationAssetTasksInDetailDTO): OrganizationAssetTasksInDetail => ({
  id: dto.id,
  name: dto.name,
  dueDate: new Date(dto.due_date),
  status: dto.status,
  createdAt: new Date(dto.created_at),
  updatedAt: new Date(dto.updated_at),
  comment: dto.comment,
  asset: {
    id: dto.asset.id,
    key: dto.asset.key,
    name: dto.asset.name,
    owner: parseUserForFE(dto.asset.owner),
    category: {
      id: dto.asset.category.id,
      name: dto.asset.category.name,
      nameTranslationKey: dto.asset.category.name_translation_key,
    },
    info: {
      assetDescription: dto.asset.info.asset_description,
    },
  },
  yearPlannerActivity: {
    id: dto.year_planner_activity.id,
    begin: new Date(dto.year_planner_activity.begin),
    end: new Date(dto.year_planner_activity.end),
    activity: {
      id: dto.year_planner_activity.activity.id,
      name: dto.year_planner_activity.activity.name,
      nameTranslationKey: dto.year_planner_activity.activity.name_translation_key,
      description: dto.year_planner_activity.activity.description,
      descriptionTranslationKey: dto.year_planner_activity.activity.description_translation_key,
    },
  },
  documents: dto.documents.map(doc => ({
    name: doc.name,
    url: doc.url,
    id: doc.id,
  })),
});

export const parseOrganizationAssetTasksStatsForFE = (dto: OrganizationAssetTasksStatsDTO): OrganizationAssetTasksStats => ({
  notStarted: dto.not_started_tasks,
  started: dto.started_tasks,
  finished: dto.finished_tasks,
  overdue: dto.overdue_tasks,
});
