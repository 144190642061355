const organization_administration_messages = {
  current_status: ['organization_administration_messages>>current_status', 'Current status'],
  history: ['organization_administration_messages>>history', 'History'],
  active_accounts: ['organization_administration_messages>>active_accounts', 'Active accounts'],
  advisory_meetings: ['organization_administration_messages>>advisory_meetings', 'Advisory meetings'],
  month_year: ['organization_administration_messages>>month_year', 'Month year'],
  summary_active_user: [
    'organization_administration_messages>>summary_active_user',
    'Your organization currently has {{count}} active user.',
  ],
  summary_active_users: [
    'organization_administration_messages>>summary_active_users',
    'Your organization currently has {{count}} active users.',
  ],
  summary_booked_meeting: [
    'organization_administration_messages>>summary_booked_meeting',
    'During this month your organization has booked {{count}} meeting.',
  ],
  summary_booked_meetings: [
    'organization_administration_messages>>summary_booked_meetings',
    'During this month your organization has booked {{count}} meetings.',
  ],
  employees: ['organization_administration_messages>>employees', 'Employees'],
  contact_person: ['organization_administration_messages>>contact_person', 'Contact person'],
  billing_info: ['organization_administration_messages>>billing_info', 'Billing info'],
  reference: ['organization_administration_messages>>reference', 'Reference'],
  terminate_organization: ['organization_administration_messages>>terminate_organization', 'Terminate Organization'],
  terminate_dialog: {
    title: ['organization_administration_messages>>terminate_dialog>>title', 'We are so sorry to see you leave'],
    content: [
      'organization_administration_messages>>terminate_dialog>>content',
      'If you proceed, we will close your account and inactivate all users in your organization. We will keep some of the information about you, your organization, and your coworkers for a while for invoicing nd bookkepping purposes. After that everything will be anonymized and no one will be able to see your specific information, not even our brigthest data scientists.',
    ],
    agreement: [
      'organization_administration_messages>>terminate_dialog>>agreement',
      'I understand that by terminating the organisation account, all user access will be terminated and access to content lost lorem ipsum dolor sit amet',
    ],
    button_label: ['organization_administration_messages>>terminate_dialog>>button_label', 'Terminate Organization'],
  },
  reason: ['organization_administration_messages>>reason', 'Reason'],
  update_info_dialog_title: [
    'organization_administration_messages>>update_info_dialog_title',
    'Update information about your organization',
  ],
  organization_country: {
    sweden: ['organization_administration_messages>>organization_country>>sweden', 'Sweden'],
    other: ['organization_administration_messages>>organization_country>>other', 'Other'],
  },
  campaign_code: ['organization_administration_messages>>campaign_code', 'Current campaign code'],
  pricing_info: {
    title: ['organization_administration_messages>>pricing_info>>title', 'Current pricing info'],
    number_of_free_months_for_users: [
      'organization_administration_messages>>pricing_info>>number_of_free_months_for_users',
      'Number of free months',
    ],
    price_for_first_active_user: [
      'organization_administration_messages>>pricing_info>>price_for_first_active_user',
      'Price for first active user',
    ],
    price_for_second_and_next_active_user: [
      'organization_administration_messages>>pricing_info>>price_for_second_and_next_active_user',
      'Price next active user',
    ],
    price_for_advisory_meeting: [
      'organization_administration_messages>>pricing_info>>price_for_advisory_meeting',
      'Price for advisory meeting',
    ],
    valid_from: ['organization_administration_messages>>pricing_info>>valid_from', 'Valid from'],
    valid_to: ['organization_administration_messages>>pricing_info>>valid_to', 'Valid to'],
  },
};

export default organization_administration_messages;
