import guide_messages from 'messages/guide_messages';

const GUIDE_ACTION_TYPES = {
  FREETEXT: guide_messages.action_types.freetext[1],
  GENERAL: guide_messages.action_types.general[1],
};

const GUIDE_ACTION_FALLBACKS = GUIDE_ACTION_TYPES;

export const GUIDE_ACTION_TYPES_TRANSLATABLE = {
  FREETEXT: [guide_messages.action_types.freetext[0], GUIDE_ACTION_FALLBACKS.FREETEXT],
  GENERAL: [guide_messages.action_types.general[0], GUIDE_ACTION_FALLBACKS.GENERAL],
};

export const GUIDE_ACTION_TYPES_ARRAY = Object.values(GUIDE_ACTION_TYPES_TRANSLATABLE);

export const GUIDE_ACTION_TYPES_DICTIONARY = Object.entries(GUIDE_ACTION_TYPES).map(([key, value]) => ({
  label: GUIDE_ACTION_TYPES_TRANSLATABLE[key],
  key: value,
}));

export default GUIDE_ACTION_TYPES;
