import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import { parseActiveUserAssetTasksFilterDataToTableFilters } from './activeUserAssetsFilterData.parsers';
import ActiveUserAssetFilterDataApi, { ActiveUserAssetFilterBaseEndpoint } from './activeUserAssetsFilterData.types';

const endpoints: Record<ActiveUserAssetFilterBaseEndpoint, string> = {
  assetTasks: 'api/v1/active_user/asset_tasks/filter_data/',
  riskAnalyses: 'api/v1/active_user/risk_analyses/filter_data/',
};

const activeUserAssetFilterDataApi: ActiveUserAssetFilterDataApi = {
  getActiveUserAssetFilterData: baseEndpoint => async params => {
    const { data } = await axios.get(createUrlWithParams(endpoints[baseEndpoint], params));
    return parseActiveUserAssetTasksFilterDataToTableFilters(data);
  },
};

export default activeUserAssetFilterDataApi;
