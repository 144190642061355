import { useMemo } from 'react';

import { useQuery } from 'react-query';

import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { selfTestApi } from 'config/api/selfTests/selfTests';

const useGetSelfTestProgressInstance = (userProgressId: number | null, newInstance: boolean | null) => {
  const existingProgress = useQuery(
    [QUERY_KEYS.GET_SELF_TEST_PROGRESS_INSTANCE, userProgressId],
    () => selfTestApi.getSelfTestProgress(userProgressId),
    { enabled: newInstance === false, refetchOnWindowFocus: false },
  );

  const { progressData, error, isLoading, currentTestVersionId } = useMemo(
    () => ({
      progressData: existingProgress.data,
      error: existingProgress.error,
      isLoading: existingProgress.isLoading,
      currentTestVersionId: userProgressId,
    }),
    [existingProgress.data],
  );

  return { data: progressData, isLoading, error, currentTestVersionId };
};

export default useGetSelfTestProgressInstance;
