import LANGUAGES from 'config/translatableConstants/LANGUAGES';
import general_messages from 'messages/general_messages';
import prepareDataForFilter from 'services/prepareDataForFilter';

const languageFilter = {
  sectionName: general_messages.language,
  filters: prepareDataForFilter(LANGUAGES, false),
};

export default languageFilter;
