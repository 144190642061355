import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';

import { MembersEndpoint } from './_types';

const endpoint = '/api/v1/members/';

const members: MembersEndpoint = {
  search: params => ({ url: createUrlWithParams(endpoint, params), method: METHOD.GET }),
};

export default members;
