import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';

import {
  parseAllActiveUserRiskAnalysesForFE,
  parseRiskMatrixDataForFE,
  parseSingleActiveUserRiskAnalysisDetailForFE,
} from './activeUserRiskAnalyses.parsers';
import type ActiveUserRiskAnalysesApi from './activeUserRiskAnalyses.types';

const endpoint = 'api/v1/active_user/risk_analyses/';

// // TODO: remove if react-query
export const legacyActiveUserRiskAnalysesApi: Record<string, (params: any) => { url: string; method: string }> = {
  getAllActiveUserRiskAnalyses: params => ({ url: createUrlWithParams(endpoint, params), method: METHOD.GET }),
};

const activeUserRiskAnalysesApi: ActiveUserRiskAnalysesApi = {
  getAllActiveUserRiskAnalyses: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllActiveUserRiskAnalysesForFE(data);
  },
  getSingleActiveUserRiskAnalysis: async analysisId => {
    const { data } = await axios.get(`${endpoint}${analysisId}/`);
    return parseSingleActiveUserRiskAnalysisDetailForFE(data);
  },
  setStatusOfRiskAnalysis: async ({ newStatus, analysisId }) => axios.post(`${endpoint}${analysisId}/set_status/`, { status: newStatus }),
  removeRiskAnalysis: async analysisId => axios.delete(`${endpoint}${analysisId}/`),
  createRiskAnalysis: async body => {
    const { data } = await axios.post(endpoint, body);
    return data;
  },
  getRiskMatrixData: async analysisId => {
    const { data } = await axios.get(`${endpoint}${analysisId}/risk_matrix/`);
    return parseRiskMatrixDataForFE(data);
  },
  updateRiskAnalysis: async ({ data: dataToUpdate, analysisId }) => {
    const { data } = await axios.put(`${endpoint}${analysisId}/`, dataToUpdate);
    return data;
  },
};

export default activeUserRiskAnalysesApi;
