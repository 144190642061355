import React from 'react';

import { useTranslation } from 'react-i18next';

import MeetingPageInfoBox from 'components/_paymentStageInfoBoxes/MeetingPageInfoBox/MeetingPageInfoBox';
import FullHeightContainer from 'components/FullHeightContainer';
import ManagePageHeader from 'components/ManagePageHeader/ManagePageHeader';
import meeting_messages from 'messages/meeting_messages';

import useBookMeetingAvailabilityCheck from '../../hooks/useBookMeetingAvailabilityCheck/useBookMeetingAvailabilityCheck';

import MeetingAdvicesPageAdvicesTable from './_components/MeetingAdvicesPageAdvicesTable';
import MeetingAdvicesPageEmergencyTable from './_components/MeetingAdvicesPageEmergencyTable/MeetingAdvicesPageEmergencyTable';
import MeetingAdvicesPageQuestionsTable from './_components/MeetingAdvicesPageQuestionsTable';
import MeetingAdvicesPageRightAdornment from './_components/MeetingAdvicesPageRightAdornment';

const MeetingAdvicesPage = () => {
  const { t } = useTranslation();

  const isBookMeetingAvailable = useBookMeetingAvailabilityCheck();

  return (
    <FullHeightContainer>
      <ManagePageHeader rightAdornment={<MeetingAdvicesPageRightAdornment isBookMeetingAvailable={isBookMeetingAvailable} />}>
        {t(...meeting_messages.advices_page_title)}
      </ManagePageHeader>
      {!isBookMeetingAvailable && <MeetingPageInfoBox />}
      <MeetingAdvicesPageAdvicesTable />
      <MeetingAdvicesPageQuestionsTable />
      <MeetingAdvicesPageEmergencyTable />
    </FullHeightContainer>
  );
};

export default MeetingAdvicesPage;
