import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';

import type { WorkplacesEndpoints, WorkplacesEndpointsApi } from './_types';
import {
  parseCreateWorkplaceForBE,
  parseEditBaseWorkplaceForBE,
  parseWorkplaceForFE,
  parseWorkplaceTemplateValueForBE,
  parseWorkplaceTemplateValuesForFE,
  prepareTemplateForFE,
} from './parsers';

const endpoint = '/api/v1/workplaces/';
const templateEndpoint = 'api/v1/workplace_sections/';
const templateValuesEndpoint = 'api/v1/workplace_information/';
const userDefaultEndpoint = 'api/v1/current_user/';

const workplaces: WorkplacesEndpoints = {
  getWorkplacesForOrganization: params => ({ url: createUrlWithParams(endpoint, params), method: METHOD.GET }),
};

export const workplacesApi: WorkplacesEndpointsApi = {
  setUserDefaultWorkspace: workplaceId => axios.patch(userDefaultEndpoint, { workplace_id: workplaceId }),

  getWorkplaceInfo: async (id: number) => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseWorkplaceForFE(data);
  },

  removeWorkplace: id => axios.delete(`${endpoint}${id}/`),

  createWorkplace: async data => axios.post(endpoint, parseCreateWorkplaceForBE(data)),

  updateWorkplace: data => axios.put(`${endpoint}${data.id}/`, parseEditBaseWorkplaceForBE(data)),

  // TEMPLATE VALUES
  getWorkplaceTemplateValues: async workplaceId => {
    const { data } = await axios.get(createUrlWithParams(templateValuesEndpoint, { workplace_id: workplaceId }));
    return parseWorkplaceTemplateValuesForFE(data);
  },

  updateWorkplaceTemplateValues: data => axios.put(`${templateValuesEndpoint}${data.id}/`, parseWorkplaceTemplateValueForBE(data)),

  createWorkplaceTemplateValues: data => axios.post(templateValuesEndpoint, parseWorkplaceTemplateValueForBE(data)),

  // WORKPLACE TEMPLATES
  getWorkplaceTemplate: async () => {
    const { data } = await axios.get(templateEndpoint);
    return prepareTemplateForFE(data);
  },

  createWorkplaceSectionTemplate: data => axios.post(templateEndpoint, data),

  updateWorkplaceSectionTemplate: data => axios.put(`${templateEndpoint}${data.id}/`, data),

  deleteWorkplaceSectionTemplate: id => axios.delete(`${templateEndpoint}${id}/`),
};

export default workplaces;
