import { parseUserForFE } from '../_parsers/parseUser';

import {
  ActiveUserAssetTaskInDetail,
  ActiveUserAssetTaskInDetailDTO,
  ActiveUserAssetTaskInList,
  ActiveUserAssetTaskInListDTO,
  ActiveUserAssetTasksFormInputDTO,
  ActiveUserAssetTasksUpdate,
} from './activeUserAssetTasks.types';

export const parseSingleActiveUserAssetTasksForFE = (raw: ActiveUserAssetTaskInDetailDTO): ActiveUserAssetTaskInDetail => ({
  id: raw.id,
  name: raw.name,
  dueDate: new Date(raw.due_date),
  status: raw.status,
  asset: {
    id: raw.asset.id,
    key: raw.asset.key,
    name: raw.asset.name,
    owner: parseUserForFE(raw.asset.owner),
    category: {
      id: raw.asset.category.id,
      name: raw.asset.category.name,
      nameTranslationKey: raw.asset.category.name_translation_key,
    },
    info: {
      assetDescription: raw.asset.info.asset_description,
    },
  },
  yearPlannerActivity: {
    id: raw.year_planner_activity.id,
    begin: new Date(raw.year_planner_activity.begin),
    end: new Date(raw.year_planner_activity.end),
    activity: {
      id: raw.year_planner_activity.activity.id,
      name: raw.year_planner_activity.activity.name,
      nameTranslationKey: raw.year_planner_activity.activity.name_translation_key,
      description: raw.year_planner_activity.activity.description,
      descriptionTranslationKey: raw.year_planner_activity.activity.description_translation_key,
    },
  },
  documents: raw.documents.map(doc => ({
    id: doc.id,
    name: doc.name,
    url: doc.url,
  })),
  createdAt: new Date(raw.created_at),
  updatedAt: new Date(raw.updated_at),
  comment: raw.comment,
});

export const parseAllActiveUserAssetTasksForFE = (raw: ActiveUserAssetTaskInListDTO[]): ActiveUserAssetTaskInList[] =>
  raw.map(task => ({
    id: task.id,
    name: task.name,
    dueDate: new Date(task.due_date),
    status: task.status,
  }));

export const parseFormDataToUpdate = (formData: ActiveUserAssetTasksUpdate): ActiveUserAssetTasksFormInputDTO => ({
  comment: formData.comment,
  status: formData.status,
});
