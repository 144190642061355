import React, { useCallback, useEffect } from 'react';

import { Button, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { publish } from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormTextInput from 'components/FormTextInput';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import emergencyRequestReportApi from 'config/api/emergencyRequestReport/emergencyRequestReport';
import { EmergencyRequestReportsFormData } from 'config/api/emergencyRequestReport/emergencyRequestReport.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { usersApi } from 'config/api/users/users';
import EVENTS from 'config/events/pubsub';
import emergency_messages from 'messages/emergency_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';

import DialogGrid from '../_components/DialogGrid';

import useStyles from './EmergencyDialog.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};

type FormData = EmergencyRequestReportsFormData;

const FORM_ID = 'emergency-form';

const EmergencyDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const currentUser = useQuery(QUERY_KEYS.GET_CURRENT_USER, usersApi.getCurrentUser);

  const createMutation = useMutation(emergencyRequestReportApi.createEmergencyRequestReport, {
    onSuccess: () => {
      enqueueSnackbar(t(emergency_messages.dialog.success_message), { variant: 'success' });
      publish(EVENTS.EMERGENCY_REPORTED);
      onClose();
    },
    onError: () => {
      enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
    },
  });

  const onSubmit = (formData: FormData) => {
    createMutation.mutate(formData);
  };

  const populateData = useCallback((key: string) => (currentUser.data ? currentUser.data.profile[key] : ''), [currentUser]);

  // @ts-ignore
  const formik = useFormik<FormData>({
    onSubmit,
    initialValues: {
      contactPersonLastName: populateData('last_name'),
      contactPersonFirstName: populateData('first_name'),
      contactEmail: populateData('email'),
      contactPhone: populateData('phone_number'),
      description: '',
    },
    validationSchema: yup.object({
      contactPersonLastName: yup.string().required(t(validation_messages.required)),
      contactPersonFirstName: yup.string().required(t(validation_messages.required)),
      contactEmail: yup.string().required(t(validation_messages.required)),
      contactPhone: yup.string().required(t(validation_messages.required)),
      description: yup.string().required(t(validation_messages.required)),
    }),
  });

  useEffect(() => {
    if (currentUser.data && !formik.values.contactPersonFirstName && !formik.touched.contactPersonLastName) {
      formik.setValues({
        contactPersonLastName: populateData('last_name'),
        contactPersonFirstName: populateData('first_name'),
        contactEmail: populateData('email'),
        contactPhone: populateData('phone_number'),
        description: formik.values.description,
      });
    }
  }, [currentUser]);

  const styles = useStyles();
  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={!formik.isValid} form={FORM_ID} type='submit' variant='outlined'>
            {t(emergency_messages.dialog.send_button_label)}
          </ColoredButton>
          <Button data-testid='cancel-add-log' onClick={onClose} size='small'>
            {t(general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      loading={currentUser.isLoading}
      onClose={onClose}
      open={open}
      title={t(emergency_messages.dialog.title)}
    >
      <div className={styles.container}>
        <div>
          <Typography className={styles.subtitle} variant='h4'>
            {t(emergency_messages.dialog.information_section_title)}
          </Typography>
          <TypographyWithHTML>{t(emergency_messages.dialog.information)}</TypographyWithHTML>
        </div>
        <form className={styles.containerForm} id={FORM_ID} onSubmit={formik.handleSubmit}>
          <div>
            <Typography className={styles.subtitle} variant='h4'>
              {t(emergency_messages.dialog.contact_section_title)}
            </Typography>
            <div className={styles.contactSection}>
              <FormTextInput
                formik={formik}
                id='contactPersonLastName'
                label={t(emergency_messages.dialog.contact_person_first_name_label)}
                required
              />
              <FormTextInput
                formik={formik}
                id='contactPersonFirstName'
                label={t(emergency_messages.dialog.contact_person_last_name_label)}
                required
              />
              <FormTextInput formik={formik} id='contactEmail' label={t(emergency_messages.dialog.contact_person_email_label)} required />
              <FormTextInput formik={formik} id='contactPhone' label={t(emergency_messages.dialog.contact_details_phone_label)} required />
            </div>
          </div>
          <div>
            <Typography className={styles.subtitle} variant='h4'>
              {t(emergency_messages.dialog.problem_description_section_title)}
            </Typography>
            <FormTextInput
              className={styles.fullWidth}
              formik={formik}
              id='description'
              label={t(emergency_messages.dialog.send_button_label)}
              multiline
              required
              rows={4}
            />
          </div>
        </form>
      </div>
    </DialogGrid>
  );
};

export default EmergencyDialog;
