import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  statusContainer: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },

  icon: {
    // @ts-ignore
    fill: theme.palette.secondary[900],
  },
}));
