import axios from 'axios';

import { parseAllAssetActivitiesForSelect } from './assetActivities.parsers';
import type AssetActivitiesApi from './assetActivities.types';

const endpoint = 'api/v1/asset_activities/';

const assetActivitiesApi: AssetActivitiesApi = {
  getAllAssetActivitiesForSelect: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllAssetActivitiesForSelect(data);
  },
};

export default assetActivitiesApi;
