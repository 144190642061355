import { makeStyles } from '@material-ui/core';

export default makeStyles({
  relatedContentItemWrapper: {
    width: '300px',
    height: '100%',
    display: 'grid',
    alignItems: 'stretch',
  },
});
