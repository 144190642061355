import general_messages from 'messages/general_messages';
import organization_administration_messages from 'messages/organization_administration_messages';

export const ORGANIZATION_COUNTRIES = {
  SE: 'SE',
  O: 'O',
};

const ORGANIZATION_COUNTRIES_FALLBACKS = {
  SE: organization_administration_messages.organization_country.sweden[1],
  O: organization_administration_messages.organization_country.other[1],
};

const TRANSLATABLE_ORGANIZATION_COUNTRIES = {
  SE: [organization_administration_messages.organization_country.sweden[0], ORGANIZATION_COUNTRIES_FALLBACKS.SE],
  O: [organization_administration_messages.organization_country.other[0], ORGANIZATION_COUNTRIES_FALLBACKS.O],
};
export const TRANSLATABLE_ORGANIZATION_COUNTRIES_ARRAY = Object.entries(TRANSLATABLE_ORGANIZATION_COUNTRIES);

export const TRANSLATABLE_ORGANIZATION_COUNTRIES_DICTIONARY = TRANSLATABLE_ORGANIZATION_COUNTRIES_ARRAY.map(([key, label]) => ({
  label,
  key,
}));

export const organizationCountryResolver = typeKey => {
  switch (typeKey) {
    case ORGANIZATION_COUNTRIES.O:
      return TRANSLATABLE_ORGANIZATION_COUNTRIES.O;
    case ORGANIZATION_COUNTRIES.SE:
      return TRANSLATABLE_ORGANIZATION_COUNTRIES.SE;
    default:
      return general_messages.no_data;
  }
};

export default TRANSLATABLE_ORGANIZATION_COUNTRIES;
