import React, { useEffect, useRef } from 'react';

import { Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import parse, { domToReact } from 'html-react-parser';
import PropTypes from 'prop-types';

import ColoredButton from 'components/ColoredButton';
import ResponsivePlayer from 'components/ResponsivePlayer/ResponsivePlayer';
import RelatedArticleLink from 'pages/SingleArticlePage/_components/RelatedArticleLink';
import { useArticleContext } from 'reactContext/ArticleContext';
import parseAnchor from 'services/htmlParse/parseAnchor';

const typographyVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
  'srOnly',
  'inherit',
];

const Header = ({ variant, name, id, onAnchorClick, getOptions, children }) => {
  const { onIntersectIn, onIntersectOut } = useArticleContext();
  const ref = useRef();

  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.intersectionRatio === 1) {
              onIntersectIn(id);
            } else if (entry.intersectionRect.top) {
              onIntersectOut();
            }
          });
        },
        { threshold: 1 },
      );
      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <Typography className={`article-${variant}`} component={name} id={id} innerRef={ref} variant={variant}>
      {domToReact(children, getOptions(onAnchorClick))}
      <ColoredButton customColor='secondary' icon onClick={onAnchorClick(id)}>
        <LinkIcon />
      </ColoredButton>
    </Typography>
  );
};

const Media = ({ url }) => <ResponsivePlayer height='100%' url={url} width='100%' />;

const Default = ({ variant, name, onAnchorClick, getOptions, children }) => {
  if (typographyVariants.includes(variant)) {
    return (
      <Typography className={`article-${variant}`} component={name} variant={variant}>
        {domToReact(children, getOptions(onAnchorClick))}
      </Typography>
    );
  }
  return React.createElement(name, { className: `article-${variant}` }, domToReact(children, getOptions(onAnchorClick)));
};

const commonPropTypes = {
  variant: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onAnchorClick: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

Header.propTypes = { ...commonPropTypes, id: PropTypes.string.isRequired };
Default.propTypes = commonPropTypes;

const possibleTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ul', 'li', 'embed', 'ol'];
const tagsWithoutChildren = ['embed'];

const getOptions = onAnchorClick => ({
  // <embed embedtype="media" url="https://www.youtube.com/watch?v=TSGp_Jaiphw"/>
  // eslint-disable-next-line consistent-return
  replace: ({ name, children, attribs }) => {
    if (!tagsWithoutChildren.includes(name) && !children?.length) return null;
    if (name && possibleTags.includes(name)) {
      let variant = name;
      if (name === 'p') variant = 'body1';
      if (name === 'h2') {
        return (
          <Header getOptions={getOptions} id={parseAnchor(children[0].data)} name={name} onAnchorClick={onAnchorClick} variant={variant}>
            {children}
          </Header>
        );
      }
      if (name === 'embed') {
        return <Media url={attribs.url} />;
      }
      return (
        <Default getOptions={getOptions} name={name} onAnchorClick={onAnchorClick} variant={variant}>
          {children}
        </Default>
      );
    }
    if (name === 'a' && attribs.linktype === 'page') {
      return <RelatedArticleLink id={attribs.id}>{domToReact(children)}</RelatedArticleLink>;
    }
  },
});

const parseArticleBodyText = (html, onAnchorClick) => (html ? parse(html, getOptions(onAnchorClick)) : '');

export default parseArticleBodyText;
