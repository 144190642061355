import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  contactRow: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 40px',
    gridGap: theme.spacing(1),
  },
  value: {
    maxWidth: `calc(300px - ${theme.spacing(10)}px)`,
  },
  input: {
    margin: theme.spacing(1, 0),
  },
}));
