import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ColoredButton from 'components/ColoredButton';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(3),
    padding: theme.spacing(2, 4),
    textAlign: 'center',
    justifyItems: 'center',
  },
}));

const SpecialMessageWrapper = ({ children }) => {
  const { t } = useTranslation();

  const reload = () => {
    window.location.href = PATHS.ROOT;
  };

  const styles = useStyles();
  return (
    <Box alignItems='center' display='flex' height='100vh' justifyContent='center' width='100%'>
      <div className={styles.wrapper}>
        {children}
        <ColoredButton customColor='secondary' onClick={reload} variant='outlined'>
          {t(...general_messages.back_to_home)}
        </ColoredButton>
      </div>
    </Box>
  );
};

SpecialMessageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SpecialMessageWrapper;
