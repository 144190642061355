import { GUIDE_STATUSES_FILTER } from 'config/translatableConstants/TRANSLATABLE_GUIDE_STATUSES';
import table_messages from 'messages/table_messages';

const status = {
  sectionName: table_messages.status,
  filters: GUIDE_STATUSES_FILTER,
  isSingleSelect: true,
};

export default status;
