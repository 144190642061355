import commonEndpoints from 'config/api/common';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';

const relatedContentParamsGetter = query => [
  commonEndpoints.search({ limit: 100 }), // TODO pagination or minimal char limit
  {
    data: {
      query,
      object_content_type: [CONTENT_TYPES.GUIDE, CONTENT_TYPES.INCIDENT_GUIDE, CONTENT_TYPES.ARTICLE, CONTENT_TYPES.SELF_TEST],
    },
  },
];

export default relatedContentParamsGetter;
