import commonEndpoints from 'config/api/common';
import general_messages from 'messages/general_messages';

const serviceAreaFilter = {
  url: commonEndpoints.serviceAreas(),
  dataParser: (filters, result) => ({
    ...result,
    service_area: { sectionName: general_messages.service_area, filters },
  }),
};

export default serviceAreaFilter;
