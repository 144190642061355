import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import { emergencyRequestReportApiLegacy } from 'config/api/emergencyRequestReport/emergencyRequestReport';
import EVENTS from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import useSubscription from 'hooks/useSubscription';
import emergency_messages from 'messages/emergency_messages';
import general_messages from 'messages/general_messages';
import getDataMappingForEmergencyReports from 'services/getDataMappingForEmergencyReports/getDataMappingForEmergencyReports';

const MeetingAdvicesPageEmergencyTable = () => {
  const { t } = useTranslation();

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });

  const {
    data: emergencyRequestsData,
    hasNextPage,
    loading: isLoading,
    getData,
    refreshData,
  } = usePagination({
    endpointFunc: emergencyRequestReportApiLegacy.getAllEmergencyRequestReports,
    limits: [5, 5, 5],
    dependencies: [sort],
    sort,
    isGet: true,
  });

  useSubscription(EVENTS.EMERGENCY_REPORTED, refreshData);

  return (
    <Section paddingTop={4} smallPadding title={t(emergency_messages.table.title)} titleVariant='h3'>
      {/*  @ts-ignore */}
      <Table data={emergencyRequestsData} dataMapping={getDataMappingForEmergencyReports(t)} onSort={onSort} sort={sort} />
      {hasNextPage && (
        // TODO type it
        //  @ts-ignore
        <FooterButton loading={isLoading} onClick={getData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
    </Section>
  );
};

export default MeetingAdvicesPageEmergencyTable;
