import React from 'react';

import { Container, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import AddAssetDialog from 'components/_dialogs/AddAssetDialog/AddAssetDialog';
import AddYearPlannerAssetActivityDialog from 'components/_dialogs/AddYearPlannerAssetActivityDialog/AddYearPlannerAssetActivityDialog';
import AssetsTable from 'components/AssetsTable/AssetsTable';
import ColoredButton from 'components/ColoredButton';
import ManagePageHeader from 'components/ManagePageHeader/ManagePageHeader';
import Section from 'components/Section';
import TextButton from 'components/TextButton/TextButton';
import YearPlannerAssetsActivityTable from 'components/YearPlannerAssetsActivityTable/YearPlannerAssetsActivityTable';
import ROLES from 'config/constants/ROLES';
import useBoolState from 'hooks/useBoolState';
import useFeatureFlagCheck from 'hooks/useFeatureFlagCheck/useFeatureFlagCheck';
import usePermissions from 'hooks/usePermissions';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';

const AssetsPage: React.FC = () => {
  const { t } = useTranslation();
  const [isAssetsManager] = usePermissions([ROLES.ORGANIZATION.ASSETS_MANAGER]);
  const { availableFeatures } = useFeatureFlagCheck();
  const { showHelpDialog } = useHelpDialogContext();
  const { state: isAddAssetDialogOpen, setTrue: openAddAssetDialog, setFalse: closeAddAssetDialog } = useBoolState(false);
  const { state: isAddActivityDialogOpen, setTrue: openAddActivityDialog, setFalse: closeAddActivityDialog } = useBoolState(false);

  const openHelpDialog = () => showHelpDialog({ title: t(assets_messages.help.title), body: t(assets_messages.help.body) });

  const openNewAssetDialog = () => {
    openAddAssetDialog();
  };

  const openNewActivityDialog = () => {
    openAddActivityDialog();
  };

  return (
    <Container>
      <ManagePageHeader
        rightAdornment={
          <>
            <TextButton onClick={openHelpDialog}>{t(general_messages.how_it_works)}</TextButton>
            {isAssetsManager && (
              <>
                {/* @ts-ignore */}
                <ColoredButton customColor='secondary' onClick={openNewAssetDialog} variant='outlined'>
                  {t(assets_messages.buttons.new_asset)}
                </ColoredButton>
                {/* @ts-ignore */}
                <ColoredButton customColor='secondary' onClick={openNewActivityDialog} variant='outlined'>
                  {t(assets_messages.buttons.new_task)}
                </ColoredButton>
              </>
            )}
          </>
        }
      >
        {t(assets_messages.page_header)}
      </ManagePageHeader>
      {availableFeatures.displayTaskTable && (
        <>
          <Section smallPadding title={t(assets_messages.activities_table.title)} titleVariant='h3'>
            <YearPlannerAssetsActivityTable />
          </Section>
          <Divider />
        </>
      )}
      <Section smallPadding title={t(assets_messages.assets_table.title)} titleVariant='h3'>
        <AssetsTable />
      </Section>
      {isAddAssetDialogOpen && <AddAssetDialog onClose={closeAddAssetDialog} open={isAddAssetDialogOpen} />}
      {isAddActivityDialogOpen && <AddYearPlannerAssetActivityDialog onClose={closeAddActivityDialog} open={isAddActivityDialogOpen} />}
    </Container>
  );
};

export default AssetsPage;
