import React, { useCallback, useEffect, useMemo } from 'react';

import { Button, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormTextInput from 'components/FormTextInput';
import assetContactsApi from 'config/api/assetContacts/assetContacts';
import { AssetContactFormInput } from 'config/api/assetContacts/assetContacts.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import emailValidator from 'config/validators/emailValidator';
import phoneNumberRegex from 'config/validators/phoneNumberRegex';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import { Id } from 'types/Id';

import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
  assetId: Id;
  contactId: Id | null;
};

type FormDataType = Omit<AssetContactFormInput, 'assetId'>;

const FORM_ID = 'asset-contact-form';

const AssetContactDialog: React.FC<Props> = ({ open, onClose, assetId, contactId }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const isEdit = !!contactId;

  const mutation = useMutation(isEdit ? assetContactsApi.editAssetContact(contactId) : assetContactsApi.createAssetContact);

  const { data: contactData, isLoading: initialDataLoading } = useQuery(
    [QUERY_KEYS.ASSET_CONTACTS_GET_SINGLE, contactId?.toString()],
    () => assetContactsApi.getSingleAssetContact(contactId as Id),
    {
      enabled: isEdit,
    },
  );

  const { data: contactCategoriesData, isLoading: categoriseLoading } = useQuery(
    [QUERY_KEYS.ASSET_CONTACTS_GET_CATEGORIES, assetId.toString()],
    () => assetContactsApi.getAssetContactCategories(),
  );

  const onSubmit = async (formData: FormDataType) => {
    await mutation.mutateAsync({ ...formData, assetId });
    await queryClient.invalidateQueries([QUERY_KEYS.ASSETS_GET_SINGLE, assetId?.toString()]);
    if (contactCategoriesData?.contactCategories.includes(formData.category)) {
      queryClient.invalidateQueries([QUERY_KEYS.ASSET_CONTACTS_GET_CATEGORIES, assetId.toString()]);
    }
    onClose();
  };

  const formik = useFormik<FormDataType>({
    onSubmit,
    initialValues: {
      name: contactData?.name || '',
      phoneNumber: contactData?.phoneNumber || '',
      email: contactData?.email || '',
      category: contactData?.category || '',
      comment: contactData?.comment || '',
    },

    validationSchema: yup.object({
      name: yup.string().required(t(validation_messages.required)),
      phoneNumber: yup.string().required(t(validation_messages.required)),
      category: yup.string().required(t(validation_messages.required)),
      email: emailValidator({
        required: t(validation_messages.email_required),
        valid: t(validation_messages.email_valid),
      }),
    }),
  });

  useEffect(() => {
    if (contactData) {
      formik.setValues({
        name: contactData.name,
        phoneNumber: contactData.phoneNumber,
        email: contactData.email,
        category: contactData.category,
        comment: contactData.comment,
      });
    }
  }, [contactData]);

  const disabled = useMemo(() => mutation.isLoading || initialDataLoading, [mutation, initialDataLoading]);

  const updateAutocomplete = useCallback((value: string) => {
    formik.setFieldValue('category', value);
    formik.setFieldTouched('category');
  }, []);

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={!formik.isValid || disabled} form={FORM_ID} type='submit' variant='outlined'>
            {t(isEdit ? general_messages.save : general_messages.add)}
          </ColoredButton>
          <Button onClick={onClose} size='small'>
            {t(general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      title={t(isEdit ? assets_messages.contact.dialog_title_edit : assets_messages.contact.dialog_title_add)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={2} withoutPadding>
          <FormTextInput disabled={disabled} formik={formik} id='name' label={t(general_messages.name)} required />
          <FormTextInput
            blockedChars={phoneNumberRegex}
            disabled={disabled}
            formik={formik}
            id='phoneNumber'
            label={t(general_messages.phone)}
            required
          />
          <FormTextInput disabled={disabled} formik={formik} id='email' label={t(general_messages.email)} required />
          <Autocomplete
            disabled={disabled || categoriseLoading}
            freeSolo
            id='category'
            onBlur={() => formik.setFieldTouched('category')}
            onChange={(_, value) => updateAutocomplete(value || '')}
            onInputChange={(_, value) => updateAutocomplete(value || '')}
            options={contactCategoriesData?.contactCategories || []}
            renderInput={params => (
              <TextField
                {...params}
                error={!!formik.touched.category && !!formik.errors.category}
                label={`${t(assets_messages.contact.category_label)} *`}
                variant='outlined'
              />
            )}
            value={formik.values.category}
          />
          <FormTextInput disabled={disabled} formik={formik} id='comment' label={t(assets_messages.contact.comment_label)} />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default AssetContactDialog;
