import { UserDTO, User } from 'types/User';

import { parseUserProfileForFE } from './parseUserProfile';

// eslint-disable-next-line import/prefer-default-export
export const parseUserForFE = (dto: UserDTO): User => ({
  id: dto.id,
  email: dto.email,
  lastLogin: new Date(dto.last_login),
  isActive: dto.is_active,
  profile: parseUserProfileForFE(dto.profile),
});
