import React from 'react';

import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  innerLoader: {
    height: '100%',
    minHeight: '600px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cover: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: ({ noBackdrop }) => !noBackdrop && 'rgba(0,0,0,0.15)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
}));

const Loader = ({ open, color, inner, cover, noBackdrop, className, size }) => {
  const styles = useStyles({ noBackdrop });
  if (inner) {
    return (
      <span className={clsx(styles.innerLoader, className)}>
        <CircularProgress color={color} size={size} />
      </span>
    );
  }
  if (cover) {
    return (
      <span className={clsx(styles.cover, className)}>
        <CircularProgress color={color} size={size} />
      </span>
    );
  }
  return (
    <Backdrop className={clsx(styles.backdrop, className)} open={open}>
      <CircularProgress color={color} size={size} />
    </Backdrop>
  );
};

Loader.propTypes = {
  open: PropTypes.bool,
  inner: PropTypes.bool,
  cover: PropTypes.bool,
  noBackdrop: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
};

Loader.defaultProps = {
  open: false,
  inner: false,
  cover: false,
  noBackdrop: false,
  color: 'primary',
  className: null,
  size: 60,
};

export default Loader;
