import { makeStyles } from '@material-ui/core';

type Size = 'm' | 's' | 'l';

type StylesProps = { size: Size; imageUrl?: string };

const resolveStylesForSize = (size: Size, theme: any) => {
  switch (size) {
    case 'l':
      return {
        fontSize: '34px',
        height: '6rem',
        width: '6rem',
        padding: theme.spacing(2),
      };
    case 'm':
      return {
        fontSize: '22px',
        height: '3.75rem',
        width: '3.75rem',
      };
    case 's':
    default:
      return {
        fontSize: '17px',
        height: '2.5rem',
        width: '2.5rem',
        padding: '.5rem',
      };
  }
};

export default makeStyles(theme => ({
  avatarWrapper: ({ size, imageUrl }: StylesProps) => ({
    // @ts-ignore
    backgroundColor: theme.palette.primary[200],
    backgroundImage: imageUrl && `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // @ts-ignore
    color: theme.palette.text.inverted,
    // @ts-ignore
    fontFamily: theme.typography.fontFamilyTitle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    flexShrink: 0,
    wordBreak: 'keep-all',
    ...resolveStylesForSize(size, theme),
  }),
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  name: {
    marginLeft: theme.spacing(2),
  },
}));
