import React, { useEffect, useState } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { add, format, isToday, sub } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ColoredButton from 'components/ColoredButton';
import CustomDayPicker from 'components/CustomDayPicker';
import meetingsEndpoints from 'config/api/meetings';
import generateDayRange from 'helpers/generateDayRange';
import useApiCall from 'hooks/useApiCall';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';

import EditMeetingDialogGrid from '../_components/EditMeetingDialogGrid';

import useStyles from './EditMeetingByAdvisorChangeDateDialog.styles';

const EditMeetingByAdvisorChangeDateDialog = ({ meetingId, onClose, open, openBase, advisorId, refreshData }) => {
  const { t } = useTranslation();
  const { apiCall, loading } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();

  const [day, setDay] = useState(new Date());
  const onBack = () => setDay(prevDay => sub(prevDay, { days: 1 }));
  const onNext = () => setDay(prevDay => add(prevDay, { days: 1 }));

  const [slot, setSlot] = useState(null);
  const onSlotClick = ({ target }) => {
    setSlot(Number(target.id));
  };

  const [availableSlots, setAvailableSlots] = useState([]);
  const getAvailableSlots = async () => {
    setSlot(null);
    const { start, end } = generateDayRange(day);
    const { data } = await apiCall(
      meetingsEndpoints.getAvailableSlotsAsAdviser({ begin__gte: start.toISOString(), end__lte: end.toISOString(), adviser: advisorId }),
    );
    setAvailableSlots(data.filter(({ is_booked }) => !is_booked));
  };

  useEffect(() => {
    if (open) getAvailableSlots();
  }, [open, day]);

  const onConfirm = async () => {
    const { status } = await apiCall(meetingsEndpoints.moveMeetingAsAdviser(meetingId), { data: { time_slot_id: slot } });
    if (status < 300) {
      enqueueSnackbar(t(...meeting_messages.edit_meeting_by_advisor.move_meeting.success_msg), { variant: 'success' });
      refreshData();
      onClose();
    }
  };

  const styles = useStyles();
  return (
    <EditMeetingDialogGrid
      disabled={!slot || loading}
      loading={loading}
      onBack={openBase}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      title={t(...meeting_messages.edit_meeting_by_advisor.move_meeting.title)}
    >
      <div className={styles.navigationPanel}>
        <CustomDayPicker disablePast label={t(...general_messages.date)} onChange={setDay} value={day} />
        <ColoredButton customColor='secondary' disabled={isToday(day)} icon onClick={onBack} size='small'>
          <ChevronLeftIcon fontSize='small' />
        </ColoredButton>
        <ColoredButton customColor='secondary' icon onClick={onNext}>
          <ChevronRightIcon fontSize='small' />
        </ColoredButton>
      </div>
      <div className={styles.results}>
        {availableSlots.length ? (
          availableSlots.map(({ id, begin }) => (
            <ButtonBase
              key={id}
              className={clsx(styles.tile, id === slot && styles.tileSelected)}
              data-testid={`${id}-change-advisor`}
              id={id}
              onClick={onSlotClick}
            >
              <span className={styles.time}>{format(new Date(begin), 'HH:mm')}</span>
              <span>{t(...meeting_messages.available)}</span>
            </ButtonBase>
          ))
        ) : (
          <Typography>{t(...meeting_messages.edit_meeting_by_advisor.move_meeting.no_slots)}</Typography>
        )}
      </div>
    </EditMeetingDialogGrid>
  );
};

EditMeetingByAdvisorChangeDateDialog.propTypes = {
  meetingId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openBase: PropTypes.func.isRequired,
  advisorId: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default EditMeetingByAdvisorChangeDateDialog;
