import React, { useState } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import BorderedCard from 'components/BorderedCard/BorderedCard';
import downloadFromUrl from 'helpers/downloadFromUrl/downloadFromUrl';
import { Id } from 'types/Id';
import { UrlString } from 'types/UrlString';

import useStyles from './YearPlannerActivityDownloads.styles';

type Props = {
  elements: { name: string; id: Id; url: UrlString }[];
};

const downloadTemplateCallback = (id: Id, url: UrlString, name: string, onBegin: (id: Id) => void, onEnd: (id: Id) => void) => async () => {
  onBegin(id);
  await downloadFromUrl(url, name);
  onEnd(id);
};

const YearPlannerActivityDownloads: React.FC<Props> = ({ elements }) => {
  const [inPreparation, setInPreparation] = useState<Id[]>([]);
  const addToPreparation = (idToAdd: Id) => setInPreparation(prevState => [...prevState, idToAdd]);
  const removeFromPreparation = (idToRemove: Id) => setInPreparation(prevState => prevState.filter(id => id !== idToRemove));

  const styles = useStyles();

  return (
    <>
      {elements.map(({ name, id, url }) => {
        const isInPreparation = inPreparation.includes(id);
        return (
          <BorderedCard
            key={id}
            onClick={isInPreparation ? undefined : downloadTemplateCallback(id, url, name, addToPreparation, removeFromPreparation)}
          >
            <div className={clsx(styles.templateCard, isInPreparation && styles.inPreparation)}>
              {isInPreparation ? <CircularProgress color='secondary' size={24} /> : <DownloadIcon />}
              <Typography>{name}</Typography>
            </div>
          </BorderedCard>
        );
      })}
    </>
  );
};

export default YearPlannerActivityDownloads;
