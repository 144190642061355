import React, { ChangeEventHandler, useMemo } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next';

import ColoredButton from 'components/ColoredButton';
import generateMonths from 'services/generateMonths/generateMonths';

import useStyles from './MonthsDropdown.styles';

type Props = {
  onChange: (value: number) => void;
  value: number;
};

const MonthsDropdown: React.FC<Props> = ({ value, onChange }) => {
  const { i18n } = useTranslation();

  const { dropdownBase, dropdownBaseLength, options } = useMemo(() => generateMonths(i18n.language as 'en' | 'sv'), [i18n.language]);

  const localOnChange: ChangeEventHandler<any> = ({ target }) => {
    onChange(target.value);
  };

  const onPrevious = () => {
    if (value === dropdownBase[0]) onChange(dropdownBase[dropdownBaseLength - 1]);
    else onChange(value - 1);
  };

  const onNext = () => {
    if (value === dropdownBase[dropdownBaseLength - 1]) onChange(dropdownBase[0]);
    else onChange(value + 1);
  };

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Select color='secondary' onChange={localOnChange} value={value} variant='outlined'>
        {options.map(({ label, optionValue }) => (
          <MenuItem key={optionValue} value={optionValue}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {/* @ts-ignore */}
      <ColoredButton customColor='secondary' icon onClick={onPrevious} size='small'>
        <ChevronLeftIcon fontSize='small' />
      </ColoredButton>
      {/* @ts-ignore */}
      <ColoredButton customColor='secondary' icon onClick={onNext}>
        <ChevronRightIcon fontSize='small' />
      </ColoredButton>
    </div>
  );
};

export default MonthsDropdown;
