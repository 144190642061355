import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  tile: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(3),
  },
  description: {
    display: 'grid',
  },
}));
