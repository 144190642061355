import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'grid',
    gap: theme.spacing(4),
  },
  containerForm: {
    display: 'grid',
    gap: theme.spacing(4),
    justifyContent: 'stretch',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  contactSection: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
}));
