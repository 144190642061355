import general_messages from 'messages/general_messages';

const AUDIENCES_FALLBACKS = {
  PRIVATE_INDIVIDUALS: general_messages.audiences.private_individuals[1],
  ORGANIZATIONS: general_messages.audiences.organizations[1],
  BOTH: general_messages.audiences.both[1],
};

const AUDIENCES = {
  PRIVATE_INDIVIDUALS: [general_messages.audiences.private_individuals[0], AUDIENCES_FALLBACKS.PRIVATE_INDIVIDUALS],
  ORGANIZATIONS: [general_messages.audiences.organizations[0], AUDIENCES_FALLBACKS.ORGANIZATIONS],
  BOTH: [general_messages.audiences.both[0], AUDIENCES_FALLBACKS.BOTH],
};

export const AUDIENCES_ARRAY = Object.values(AUDIENCES);

export const AUDIENCES_DICTIONARY = AUDIENCES_ARRAY.map((label, key) => ({ label, key }));

export default AUDIENCES;
