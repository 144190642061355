import React from 'react';

import { Container } from '@material-ui/core';

import useStyles from './FullHeightContainer.styles';

const FullHeightContainer = ({ children, ...rest }) => {
  const styles = useStyles();
  return (
    <Container className={styles.container} {...rest}>
      {children}
    </Container>
  );
};

export default FullHeightContainer;
