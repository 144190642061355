import React from 'react';

import { useTranslation } from 'react-i18next';

import InfoBox from 'components/InfoBox/InfoBox';
import info_boxes_messages from 'messages/info_boxes_messages';

const DashboardInfoBoxRetention: React.FC = () => {
  const { t } = useTranslation();

  return <InfoBox content={t(info_boxes_messages.retention.content)} title={t(info_boxes_messages.retention.title)} />;
};

export default DashboardInfoBoxRetention;
