import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  getMoreButton: {
    width: '100%',
    background: 'none',
    border: 'none',
    fontFamily: theme.typography.fontFamilyBase,
    fontSize: '1rem',
    color: theme.palette.text.secondaryColor,
    cursor: 'pointer',
    textAlign: 'left',
    padding: '6px 16px',
  },
}));
