import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import DialogGrid, { DialogGridPaddingWrapper } from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormTextInput from 'components/FormTextInput';
import FormTitle from 'components/FormTitle';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import validation_messages from 'messages/validation_messages';

import GuideStepCreateActions from './_components/GuideStepCreateActions';

const FORM_ID = 'step-form';

const FORM_KEYS = {
  HEADING: 'heading',
  DESCRIPTION: 'description',
  NEXT_STEP_DESCRIPTION: 'next_step_description',
  ACTIONS: 'actions',
};

const GuideStepCreateDialog = ({ open, onClose, initialData, addStep, onRemove }) => {
  const { t } = useTranslation();
  const isEditing = !!initialData;
  const [actionsValid, setIsActionsValid] = useState(true);

  const formik = useFormik({
    initialValues: {
      [FORM_KEYS.HEADING]: '',
      [FORM_KEYS.DESCRIPTION]: '',
      [FORM_KEYS.NEXT_STEP_DESCRIPTION]: '',
      [FORM_KEYS.ACTIONS]: [],
    },
    validationSchema: yup.object({
      [FORM_KEYS.HEADING]: yup.string().required(t(...validation_messages.required)),
      [FORM_KEYS.DESCRIPTION]: yup.string().required(t(...validation_messages.required)),
      [FORM_KEYS.NEXT_STEP_DESCRIPTION]: yup.string().required(t(...validation_messages.required)),
    }),
    onSubmit: addStep,
  });

  const resolveTitle = data => {
    if (!data) return t(...general_messages.new);
    return t(...general_messages.edit);
  };

  useEffect(() => {
    if (initialData) {
      const { heading, description, next_step_description, actions } = initialData;
      formik.setValues({ heading, description, next_step_description, actions });
    }
  }, [initialData]);

  const onDelete = () => {
    onRemove();
    onClose();
  };

  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          <ColoredButton
            customColor='secondary'
            disabled={!formik.isValid || !actionsValid}
            form={FORM_ID}
            type='submit'
            variant='outlined'
          >
            {t(...general_messages.save_close)}
          </ColoredButton>
          {isEditing && <Button onClick={onDelete}>{t(...guide_messages.delete_step)}</Button>}
        </CenteredGrid>
      }
      fullWidth={false}
      noPadding
      onClose={onClose}
      open={open}
      title={resolveTitle(initialData)}
      withCloseConfirmation
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <DialogGridPaddingWrapper>
          <CenteredGrid gridGap={1.5} title={t(...guide_messages.step)} width='sm'>
            <FormTextInput formik={formik} id={FORM_KEYS.HEADING} label={t(...guide_messages.heading)} required />
            <FormTextInput formik={formik} id={FORM_KEYS.DESCRIPTION} label={t(...guide_messages.description)} multiline required />
          </CenteredGrid>
        </DialogGridPaddingWrapper>
        <DialogGridPaddingWrapper>
          <FormTitle>{t(...guide_messages.actions)}</FormTitle>
        </DialogGridPaddingWrapper>
        <GuideStepCreateActions fieldId={FORM_KEYS.ACTIONS} formik={formik} setValid={setIsActionsValid} />
        <DialogGridPaddingWrapper>
          <CenteredGrid title={t(general_messages.next_step)} width='sm' withoutPadding>
            <FormTextInput formik={formik} id={FORM_KEYS.NEXT_STEP_DESCRIPTION} label={t(...general_messages.description)} required />
          </CenteredGrid>
        </DialogGridPaddingWrapper>
      </form>
    </DialogGrid>
  );
};

const guideDataPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  heading: PropTypes.string,
  description: PropTypes.string,
  next_step_description: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      heading: PropTypes.string,
      description: PropTypes.string,
      action_type: PropTypes.string,
    }),
  ),
});

GuideStepCreateDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  initialData: guideDataPropTypes,
  addStep: PropTypes.func.isRequired,
};

GuideStepCreateDialog.defaultProps = {
  initialData: null,
};

export default GuideStepCreateDialog;
