import React from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import SpecialMessageWrapper from 'components/SpecialMessageWrapper';
import general_messages from 'messages/general_messages';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <SpecialMessageWrapper>
      <Typography align='center' component='h2' variant='h2'>
        {t(...general_messages.not_found_title)}
      </Typography>
      <Typography variant='h4'>{t(...general_messages.not_found_description)}</Typography>
    </SpecialMessageWrapper>
  );
};

export default NotFoundPage;
