import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  contactSection: {
    position: 'relative',
    display: 'grid',

    '&:before': {
      position: 'relative',
      content: '""',
      display: 'block',
      height: '1px',
      width: '100%',
      top: -theme.spacing(2.5),
      // @ts-ignore
      backgroundColor: theme.palette.primary[200],
    },
    '&:first-child:before': {
      display: 'none',
    },
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 40px',
    gridGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    display: 'grid',
    gridGap: theme.spacing(0.5),
  },
}));
