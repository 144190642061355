import React from 'react';

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader';
import general_messages from 'messages/general_messages';
// eslint-disable-next-line import/no-cycle
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';

const useStyles = makeStyles(theme => ({
  titleLine: {
    paddingRight: theme.spacing(7),
    borderBottom: `thin solid ${theme.palette.primary[200]}`,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
  },
  content: {
    overflowY: ({ scrollDisabled }) => (scrollDisabled ? 'hidden' : 'auto'),
    padding: ({ noPadding }) => theme.spacing(5, noPadding ? 0 : 5),
    [theme.breakpoints.down('sm')]: {
      padding: ({ noPadding }) => theme.spacing(3, noPadding ? 0 : 3),
    },
  },
  actions: {
    padding: theme.spacing(2, 5, 5, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3, 3, 3),
    },
  },
  paddingWrapper: {
    padding: () => theme.spacing(0, 5),
    [theme.breakpoints.down('sm')]: {
      padding: () => theme.spacing(0, 3),
    },
  },
  loaderWrapper: {
    minHeight: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogActionsWrapper: {
    position: 'relative',
    width: '100%',
  },
  sending: {
    left: 0,
    top: 0,
    padding: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgba(255,255,255,.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
}));

const DialogGridPaddingWrapper = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.paddingWrapper}>{children}</div>;
};

DialogGridPaddingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const DialogGrid = ({
  onClose,
  open,
  title,
  children,
  dialogActions,
  loading,
  displayTitle,
  fullWidth,
  noPadding,
  withCloseConfirmation,
  loadingCover,
  sending,
  color,
  scrollDisabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const { showConfirmationModal } = useConfirmationModalContext();
  const theme = useTheme();
  const downXs = useMediaQuery(theme.breakpoints.down('xs'));

  const onCloseAction = async () => {
    if (withCloseConfirmation) {
      const sure = await showConfirmationModal({
        title: t(...general_messages.unsaved_changes_title),
        body: t(...general_messages.unsaved_changes_body),
      });
      if (sure) onClose();
    } else {
      onClose();
    }
  };

  const styles = useStyles({ noPadding, scrollDisabled });

  return (
    <Dialog
      aria-describedby='scroll-dialog-description'
      aria-labelledby='scroll-dialog-title'
      data-testid='dialog'
      fullScreen={downXs}
      fullWidth={fullWidth}
      maxWidth='sm'
      onClose={onCloseAction}
      open={open}
      {...rest}
    >
      {displayTitle ? (
        <DialogTitle className={styles.titleLine} id='scroll-dialog-title'>
          {!loadingCover && loading ? <Skeleton width={200} /> : title}
          <IconButton className={styles.closeButton} data-testid='close-dialog' onClick={onCloseAction}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      ) : (
        <IconButton className={styles.closeButton} data-testid='close-dialog' onClick={onCloseAction}>
          <CloseIcon />
        </IconButton>
      )}
      {loading && !loadingCover ? (
        <div className={styles.loaderWrapper}>
          <Loader inner />
        </div>
      ) : (
        <>
          <DialogContent className={styles.content} id='scroll-dialog-description'>
            {children}
          </DialogContent>
          {dialogActions && (
            <DialogActions className={styles.actions}>
              <div className={styles.dialogActionsWrapper}>
                {dialogActions}
                {sending && (
                  <div className={styles.sending}>
                    <CircularProgress color={color} size={36} />
                  </div>
                )}
              </div>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

DialogGrid.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  dialogActions: PropTypes.node,
  loading: PropTypes.bool,
  displayTitle: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  withCloseConfirmation: PropTypes.bool,
  loadingCover: PropTypes.bool,
  sending: PropTypes.bool,
  color: PropTypes.string,
};

DialogGrid.defaultProps = {
  title: null,
  dialogActions: null,
  fullWidth: true,
  loading: false,
  displayTitle: true,
  noPadding: false,
  children: null,
  withCloseConfirmation: false,
  loadingCover: false,
  sending: false,
  color: 'secondary',
};

export { DialogGridPaddingWrapper };

export default DialogGrid;
