import React from 'react';

import { format } from 'date-fns';
import { TFunction } from 'react-i18next';

import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { EmergencyRequestReportsDTO } from 'config/api/emergencyRequestReport/emergencyRequestReport.types';
import emergency_messages from 'messages/emergency_messages';

const getDataMappingForEmergencyReports = (t: TFunction) => [
  {
    id: 'description',
    width: '30%',
    label: t(emergency_messages.table.description_label),
    get: (data: EmergencyRequestReportsDTO) => (
      <TrimHTMLText linesToShow={2}>
        <TypographyWithHTML>{data.description}</TypographyWithHTML>
      </TrimHTMLText>
    ),
  },
  {
    id: 'contact_person',
    width: '18%',
    label: t(emergency_messages.table.contact_person_label),
    get: (data: EmergencyRequestReportsDTO) => data.contact_person,
  },
  {
    id: 'contact_details',
    width: '22%',
    label: t(emergency_messages.table.contact_details_label),
    get: (data: EmergencyRequestReportsDTO) => data.contact_details,
  },
  {
    id: 'reported_date',
    width: '15%',
    label: t(emergency_messages.table.reported_date_label),
    get: (data: EmergencyRequestReportsDTO) => format(new Date(data.reported_date), 'yyyy-MM-dd HH:mm'),
  },
  {
    id: 'reported_by',
    width: '15%',
    label: t(emergency_messages.table.reported_by_label),
    get: (data: EmergencyRequestReportsDTO) => (
      <UserAvatar
        lazy
        showName
        userData={{
          firstName: data.reported_by.first_name,
          lastName: data.reported_by.last_name,
          imageUrl: data.reported_by.image_url,
        }}
        userId={data.reported_by.id}
      />
    ),
  },
];

export default getDataMappingForEmergencyReports;
