import { makeStyles } from '@material-ui/core';

type StylesProps = {
  isDragging: boolean;
};
export default makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridGap: theme.spacing(1),
    alignItems: 'center',
    opacity: ({ isDragging }: StylesProps) => (isDragging ? 0 : 1),
    marginLeft: theme.spacing(2),
  },
  dndIcon: {
    cursor: 'grab',
  },
}));
