import React from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './ManagePageHeader.styles';

type Props = {
  children: React.ReactNode;
  subtitle?: string | React.ReactNode;
  noBorder?: boolean;
  className?: string;
  rightAdornment?: React.ReactNode;
  bottomAdornment?: React.ReactNode;
};

const ManagePageHeader: React.FC<Props> = ({ children, subtitle, rightAdornment, noBorder, bottomAdornment, className }) => {
  const styles = useStyles({ noBorder: !!noBorder });
  return (
    <header className={clsx(styles.header, className)}>
      <div>
        <Typography component='h1' variant='h2'>
          {children}
        </Typography>
        {subtitle && <Typography className={styles.subtitle}>{subtitle}</Typography>}
        {bottomAdornment || null}
      </div>
      {rightAdornment && <div className={styles.rightAdornment}>{rightAdornment}</div>}
    </header>
  );
};

export default ManagePageHeader;
