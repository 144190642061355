import axios from 'axios';

import { parseSingleAssetDocumentForFE, parseAssetDocumentToCreate } from './assetDocuments.parsers';
import type AssetDocumentsApi from './assetDocuments.types';

const endpoint = 'api/v1/asset_documents/';

const assetDocumentsApi: AssetDocumentsApi = {
  removeAssetDocument: async assetId => {
    const { data } = await axios.delete(`${endpoint}${assetId}`);
    return parseSingleAssetDocumentForFE(data);
  },
  addAssetDocument: async formData => {
    const { data } = await axios.post(endpoint, parseAssetDocumentToCreate(formData));
    return parseSingleAssetDocumentForFE(data);
  },
};

export default assetDocumentsApi;
