import instances from 'config/constants/instances';

const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;

const preparePreselectedFilters = (preselectedFilters, allAvailableFilters) => {
  const newState = {};
  if (!preselectedFilters || !allAvailableFilters) return newState;
  Object.entries(preselectedFilters).forEach(([key, values]) => {
    try {
      const matchedFilters = allAvailableFilters[key].filters.filter(({ id }) => values.includes(id));
      newState[key] = matchedFilters.map(({ id, name }) => ({ id, name }));
    } catch (error) {
      // eslint-disable-next-line no-console
      if (!isProduction) console.error(error);
    }
  });
  return newState;
};

export default preparePreselectedFilters;
