import React from 'react';

import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImageWithFallback from 'components/ImageWithFallback';
import ARTICLE_BODY_CONTENT_TYPES from 'config/constants/ARTICLE_BODY_CONTENT_TYPES';
import general_messages from 'messages/general_messages';
import parseArticleBodyText from 'services/htmlParse/parseArticleBodyText';

import ArticleImage from '../ArticleImage';
import ArticleLoginInfo from '../ArticleLoginInfo';
import ArticleTable from '../ArticleTable';

const useStyles = makeStyles(theme => ({
  mainImage: {
    maxWidth: '100%',
  },
  articleBody: {
    '& .article-body1': {
      fontSize: '1.3rem',
      lineHeight: '2rem',
      marginBottom: '2rem',
    },
    '& .article-h2': {
      padding: theme.spacing(5, 0, 3, 0),
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'flex-start',
      '&:not(:first-of-type)': {
        borderTop: `thin solid ${theme.palette.primary[300]}`,
        marginTop: theme.spacing(5),
      },
      '& .MuiIconButton-root': {
        marginLeft: theme.spacing(3),
      },
    },
    '& .article-h3': {
      padding: theme.spacing(4, 0, 4, 0),
      position: 'relative',
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(3),
        '&:after': {
          background: theme.palette.secondary[300],
          content: '""',
          width: '44px',
          height: '1px',
          display: 'block',
          top: '0',
          left: '0',
          position: 'absolute',
        },
      },
    },
    '& .article-h4': {
      fontSize: '1rem',
      fontFamily: theme.typography.fontFamilyBase,
      fontWeight: '500',
      padding: theme.spacing(2, 0, 1, 0),
    },
    '& blockquote': {
      textAlign: 'center',
      fontSize: '1.25rem',
      fontFamily: theme.typography.fontFamilyTitle,
      fontStyle: 'italic',
      background: theme.palette.secondary[50],
      margin: theme.spacing(3),
      padding: theme.spacing(3, 6),
      color: theme.palette.text.secondary,
      borderRadius: theme.shape.borderRadiusLarge,
      '&:after, &:before': {
        content: `'"'`,
        display: 'inline-block',
        margin: '2px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 4),
      },
    },
    '& .article-li': {
      fontSize: '1.3rem',
      lineHeight: '2rem',
      marginBottom: '1rem',
    },
  },
}));

const resolveContent = ({ type, value, id }, copyLink) => {
  switch (type) {
    case ARTICLE_BODY_CONTENT_TYPES.TEXT:
      return parseArticleBodyText(value, copyLink);
    case ARTICLE_BODY_CONTENT_TYPES.TABLE:
      return <ArticleTable key={id} {...value} />;
    case ARTICLE_BODY_CONTENT_TYPES.IMAGE:
      return <ArticleImage key={id} {...value} />;
    default:
      return null;
  }
};

const ArticleBody = ({ image_url, bodyStream }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copyLink = anchor => async () => {
    const { origin, pathname } = window.location;
    await navigator.clipboard.writeText(`${origin}${pathname}#${anchor}`);
    enqueueSnackbar(t(...general_messages.link_copied), { variant: 'success' });
  };

  const styles = useStyles();
  return (
    <div className={styles.articleBody}>
      <ImageWithFallback className={styles.mainImage} src={image_url} />
      {bodyStream ? bodyStream.map(element => resolveContent(element, copyLink)) : <ArticleLoginInfo />}
    </div>
  );
};

ArticleBody.propTypes = {
  image_url: PropTypes.string,
  bodyStream: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
      type: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
};

ArticleBody.defaultProps = {
  image_url: null,
};

export default ArticleBody;
