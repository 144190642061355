import {
  EmergencyRequestReport,
  EmergencyRequestReportsDTO,
  EmergencyRequestReportsFormData,
  EmergencyRequestReportsCreateDTO,
} from './emergencyRequestReport.types';

export const parseAllEmergencyRequestReportForFE = (raw: EmergencyRequestReportsDTO[]): EmergencyRequestReport[] => raw;

export const parseSingleEmergencyRequestReportForFE = (raw: EmergencyRequestReportsDTO): EmergencyRequestReport => raw;

export const parseDataToCreate = (raw: EmergencyRequestReportsFormData): EmergencyRequestReportsCreateDTO => ({
  reported_date: new Date().toISOString(),
  contact_person: `${raw.contactPersonFirstName} ${raw.contactPersonLastName}`,
  contact_details: `${raw.contactEmail}, ${raw.contactPhone}`,
  description: raw.description,
});
