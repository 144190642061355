import table_messages from 'messages/table_messages';

import booleanDictionary from '../booleanDictionary';

const isGDPR = {
  sectionName: table_messages.is_gdpr,
  filters: booleanDictionary,
  isSingleSelect: true,
};

export default isGDPR;
