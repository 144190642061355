import React, { useEffect, useMemo, useState } from 'react';

import { Radio, RadioGroup } from '@material-ui/core';
import { add, addMinutes, differenceInMilliseconds, isAfter } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonsGrid from 'components/_dialogs/_components/ButtonsGrid';
import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import CheckboxWithInfo from 'components/CheckboxWithInfo/CheckboxWithInfo';
import ColoredButton from 'components/ColoredButton';
import meetingsEndpoints from 'config/api/meetings';
import { ADVISOR_EDIT_DIALOGS } from 'config/constants/EDIT_MEETING_DIALOG_TYPES';
import ROLES from 'config/constants/ROLES';
import useApiCall from 'hooks/useApiCall';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';

import useStyles from './EditMeetingByAdvisorBaseDialog.styles';

const EditMeetingByAdvisorBaseDialog = ({ open, onClose, openAnother, date, isMine }) => {
  const { t } = useTranslation();
  const [nextDialog, setNextDialog] = useState(null);
  const [shouldShowAbsent, setShouldShowAbsent] = useState(false);
  const [isSupervisor, isAdviser] = usePermissions([ROLES.PS_ROLES.SECURITY_SUPERVISOR, ROLES.PS_ROLES.SECURITY_ONLINE]);

  const [safetyCancelTime, setSafetyCancelTime] = useState(0);
  const { apiCall } = useApiCall();

  const getSafetyCancelTime = async () => {
    const { data } = await (() => {
      if (isSupervisor) {
        return apiCall(meetingsEndpoints.getTimePriorToCancelAsSupervisor(), {}, { cache: true });
      }
      if (isAdviser) {
        return apiCall(meetingsEndpoints.getTimePriorToCancelAsAdviser(), {}, { cache: true });
      }
      return apiCall(meetingsEndpoints.getTimePriorToCancelAsSecurityOfficer(), {}, { cache: true });
    })();
    setSafetyCancelTime(data.min_minutes_prior_to_time_slot);
  };
  useEffect(getSafetyCancelTime, []);

  const isCancelDisabled = useMemo(
    () => !safetyCancelTime || isAfter(add(new Date(), { minutes: safetyCancelTime }), date),
    [safetyCancelTime],
  );

  const onChange = ({ target }) => setNextDialog(target.value);

  const dateForAbsent = useMemo(() => addMinutes(date, 15), [date]);

  const showAbsentUpdate = () => {
    const shouldShow = isAfter(new Date(), dateForAbsent);
    setShouldShowAbsent(shouldShow);
    return shouldShow;
  };

  useEffect(() => {
    let timeout;
    if (dateForAbsent) {
      const shouldShow = showAbsentUpdate();
      if (!shouldShow) {
        const timeToCheckAgain = differenceInMilliseconds(dateForAbsent, new Date()) + 1000;
        timeout = setTimeout(showAbsentUpdate, timeToCheckAgain);
      }
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [dateForAbsent]);

  const onNext = () => openAnother(nextDialog);

  const styles = useStyles();
  return (
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          <span />
          <ColoredButton customColor='secondary' disabled={!nextDialog} onClick={onNext} variant='outlined'>
            {t(...general_messages.next)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(...meeting_messages.edit_meeting_by_advisor.main_title)}
    >
      <CenteredGrid gridGap={1.5} withoutPadding>
        <RadioGroup className={styles.radioWrapper} name='nextDialog' onChange={onChange} value={nextDialog}>
          {isSupervisor && (
            <CheckboxWithInfo
              control={<Radio value={ADVISOR_EDIT_DIALOGS.ADVISOR} />}
              label={t(...meeting_messages.edit_meeting_by_advisor.change_advisor.select)}
              selected={ADVISOR_EDIT_DIALOGS.ADVISOR === nextDialog}
            />
          )}
          {isSupervisor && (
            <CheckboxWithInfo
              control={<Radio value={ADVISOR_EDIT_DIALOGS.DATE} />}
              label={t(...meeting_messages.edit_meeting_by_advisor.move_meeting.select)}
              selected={ADVISOR_EDIT_DIALOGS.DATE === nextDialog}
            />
          )}
          <CheckboxWithInfo
            control={<Radio disabled={isCancelDisabled} value={ADVISOR_EDIT_DIALOGS.CANCEL} />}
            label={t(...meeting_messages.edit_meeting_by_advisor.cancel.select)}
            selected={ADVISOR_EDIT_DIALOGS.CANCEL === nextDialog}
          />
          {isMine && (
            <CheckboxWithInfo
              control={<Radio disabled={!shouldShowAbsent} value={ADVISOR_EDIT_DIALOGS.ABSENT} />}
              label={t(...meeting_messages.edit_meeting_by_advisor.absent.select)}
              selected={ADVISOR_EDIT_DIALOGS.ABSENT === nextDialog}
            />
          )}
        </RadioGroup>
      </CenteredGrid>
    </DialogGrid>
  );
};

EditMeetingByAdvisorBaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isMine: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openAnother: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

export default EditMeetingByAdvisorBaseDialog;
