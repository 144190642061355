import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    width: '100%',
    gridGap: theme.spacing(3),
    display: 'grid',
    padding: theme.spacing(3, 0, 6),
  },
  buttons: {
    display: 'grid',
    gridGap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr',
  },
  subtitle: {
    fontWeight: 'bold',
  },
}));
