import React from 'react';

import { Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { DialogGridPaddingWrapper } from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormButtons from 'components/FormButtons';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import { GUIDE_ACTION_TYPES_DICTIONARY } from 'config/constants/GUIDE_ACTION_TYPES';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import validation_messages from 'messages/validation_messages';

const useStyles = makeStyles(theme => ({
  actionInput: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.secondary[200]}`,
    padding: theme.spacing(5, 0),
  },
  expandedActionInput: {
    background: theme.palette.grey[100],
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto 50px',
    gridGap: theme.spacing(2),
  },
  content: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary[900],
  },
}));

const FORM_KEYS = {
  HEADING: 'heading',
  ACTION_TYPE: 'action_type',
  DESCRIPTION: 'description',
};

const GuideStepCreateActionInput = ({
  id,
  heading,
  description,
  newAction,
  action_type,
  isExpanded,
  onExpand,
  onRemove,
  onShrink,
  update,
}) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      heading,
      description,
      action_type,
    },
    validationSchema: yup.object({
      heading: yup.string().required(t(...validation_messages.required)),
      description: yup.string().required(t(...validation_messages.required)),
      action_type: yup.string().required(t(...validation_messages.required)),
    }),
    validateOnMount: true,
  });

  const expand = () => onExpand(id);
  const remove = () => onRemove(id);
  const shrink = () => onShrink(id);

  const onCancelClick = () => {
    if (newAction) {
      shrink();
      remove();
    } else {
      formik.setValues({ heading, description, action_type });
      shrink();
    }
  };

  const onOkClick = () => {
    update(id, formik.values);
    shrink();
  };

  const getTitle = () => (newAction ? t(...general_messages.new_guide_action) : t(...general_messages.edit_guide_action));

  const styles = useStyles();

  if (isExpanded)
    return (
      <div className={clsx(styles.actionInput, styles.expandedActionInput)}>
        <DialogGridPaddingWrapper>
          <CenteredGrid gridGap={1.5} title={getTitle()} withoutPadding>
            <FormTextInput formik={formik} id={FORM_KEYS.HEADING} label={t(...guide_messages.actions_heading)} required />
            <FormSelect
              formik={formik}
              id={FORM_KEYS.ACTION_TYPE}
              label={t(...guide_messages.type_of_action)}
              options={GUIDE_ACTION_TYPES_DICTIONARY}
              required
            />
            <FormTextInput formik={formik} id={FORM_KEYS.DESCRIPTION} label={t(...guide_messages.description)} multiline required />
            <FormButtons inline>
              <Button color='secondary' data-testid='cancel-create-step' onClick={onCancelClick} variant='outlined'>
                {t(...general_messages.cancel)}
              </Button>
              <ColoredButton customColor='secondary' disabled={!formik.isValid} onClick={onOkClick} variant='outlined'>
                {t(...general_messages.ok)}
              </ColoredButton>
            </FormButtons>
          </CenteredGrid>
        </DialogGridPaddingWrapper>
      </div>
    );
  return (
    <div className={styles.actionInput}>
      <DialogGridPaddingWrapper>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <Typography variant='subtitle2'>{formik.values.heading}</Typography>
            <Typography variant='body2'>{formik.values.action_type}</Typography>
            <Typography variant='body2'>{formik.values.description}</Typography>
          </div>
          <div>
            <IconButton onClick={expand}>
              <EditOutlinedIcon className={styles.icon} color='secondary' />
            </IconButton>
            <IconButton onClick={remove}>
              <DeleteOutlinedIcon className={styles.icon} color='secondary' />
            </IconButton>
          </div>
        </div>
      </DialogGridPaddingWrapper>
    </div>
  );
};

GuideStepCreateActionInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  heading: PropTypes.string,
  description: PropTypes.string,
  action_type: PropTypes.string,
  isExpanded: PropTypes.bool,
  newAction: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onShrink: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

GuideStepCreateActionInput.defaultProps = {
  heading: '',
  description: '',
  action_type: '',
  isExpanded: '',
  newAction: false,
};

export default GuideStepCreateActionInput;
