import React, { useCallback, useMemo, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import AssetRiskTaskDialog from 'components/_dialogs/AssetRiskTaskDialog/AssetRiskTaskDialog';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import { legacyAssetRiskTasksApi } from 'config/api/assetRiskTasks/assetRiskTasks';
import { parseAllAssetRiskTasksForUserForFE } from 'config/api/assetRiskTasks/assetRiskTasks.parsers';
import { AssetRiskTasksForUser } from 'config/api/assetRiskTasks/assetRiskTasks.types';
import { assetsApi } from 'config/api/assets/assets';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import DIRECTIONS from 'config/constants/sortDirections';
import pubsub from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import usePrepareAvailableFilters from 'hooks/usePrepareAvailableFilters/usePrepareAvailableFilters';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import assetTaskStatusFilter from 'services/_filters/parsers/_assetTasksFilters/assetTaskStatusFilter';
import isOverdueTaskFilter from 'services/_filters/parsers/_assetTasksFilters/isOverdueTaskFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';
import userDataStorage from 'storages/userDataStorage';
import { Id } from 'types/Id';
import type { TableDataMappingRow } from 'types/Table';

const MyAssetsRiskAnalysisTasksTable: React.FC = () => {
  const { t } = useTranslation();
  const { showHelpDialog } = useHelpDialogContext();
  // @ts-ignore
  const { id: userId } = userDataStorage.get() || { id: null };

  const { data: assetsData } = useQuery(
    [QUERY_KEYS.ASSETS_GET_LIST, `user=${userId}`],
    () => assetsApi.getAllAssets({ limit: 1, owner: userId }),
    {
      refetchOnWindowFocus: false,
      enabled: !!userId,
    },
  );
  const ownAnyAsset = useMemo(() => Boolean(assetsData?.length), [assetsData]);

  const showRiskAnalysisTasksTableIntroductionDialog = () => {
    showHelpDialog({
      title: t(asset_risk_messages.personal_dashboard_risk_task_table_introduction.title),
      body: t(asset_risk_messages.personal_dashboard_risk_task_table_introduction.body),
    });
  };

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: 'due_date', direction: DIRECTIONS.DESC });
  const onSort = (key: string, direction: string) => setSort({ key, direction });

  const availableFilters = usePrepareAvailableFilters({
    is_overdue: isOverdueTaskFilter,
    status: assetTaskStatusFilter,
  });
  const [filters, setFilters] = useState({});

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyAssetRiskTasksApi.getAllAssetRiskTasks('user'),
    limits: [15, 10, 5],
    isGet: true,
    dependencies: [filters],
    sort,
    filters: {
      ...parseFiltersForRequest(filters, {}, availableFilters || {}),
    },
  });
  useSubscription(pubsub.RISK_ANALYSIS_TASK_UPDATED, refreshData);

  const [taskDialog, setTaskDialog] = useState<{ id: null | Id; initiallyEdit: boolean }>({ id: null, initiallyEdit: false });
  const isTaskDialogOpen = useMemo(() => Boolean(taskDialog.id), [taskDialog]);

  const onRowClick = (id: Id) => {
    setTaskDialog({ id, initiallyEdit: false });
  };

  const closeTaskDialog = () => {
    setTaskDialog({ id: null, initiallyEdit: false });
  };

  const onEdit = (id: Id) => {
    setTaskDialog({ id, initiallyEdit: true });
  };

  const renderContextMenu = useCallback(
    (data: AssetRiskTasksForUser) => {
      const elements = [{ label: t(general_messages.edit), onClick: () => onEdit(data.id) }];

      return (
        <ContextMenu elements={elements} id={`assetRisk-${data.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData],
  );

  const dataMapping = useMemo(() => {
    const result: TableDataMappingRow<AssetRiskTasksForUser>[] = [
      {
        id: 'planned_action',
        label: t(asset_risk_messages.personal_dashboard_risk_task_table.planned_action_column),
        get: ({ plannedAction }) => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{plannedAction}</TypographyWithHTML>
          </TrimHTMLText>
        ),
        width: '35%',
      },
      {
        id: 'risk__risk_analysis__asset__name',
        label: t(asset_risk_messages.personal_dashboard_risk_task_table.asset_name_column),
        get: ({ risk }) => risk.riskAnalysis.asset.name,
        width: '35%',
      },
      {
        id: 'due_date',
        label: t(asset_risk_messages.personal_dashboard_risk_task_table.due_date_column),
        get: ({ dueDate }) => format(dueDate, DATE_FORMAT.defaultDate),
        width: '12%',
      },
      {
        id: 'status',
        label: t(asset_risk_messages.personal_dashboard_risk_task_table.status_column),
        get: ({ status }) => t(asset_risk_messages.risk_task_status[status]),
        width: '12%',
      },
      {
        id: 'context',
        width: '6%',
        label: '',
        isAddon: true,
        get: (data): React.ReactNode => renderContextMenu(data) || ' ',
        blockSorting: true,
      },
    ];

    return result;
  }, [t, tableData]);

  if (!ownAnyAsset) return null;

  return (
    <Section
      showSeparator
      smallPadding
      title={asset_risk_messages.personal_dashboard_risk_task_table.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showRiskAnalysisTasksTableIntroductionDialog}>
          {t(asset_risk_messages.personal_dashboard_risk_task_table_introduction.button)}
        </TextButton>,
      ]}
      titleVariant='h2'
    >
      <Table
        data={tableData && parseAllAssetRiskTasksForUserForFE(tableData)}
        dataMapping={dataMapping}
        filtersService={{ availableFilters, filters, setFilters }}
        onRowClick={(_, element) => onRowClick(element.id)}
        onSort={onSort}
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
      {isTaskDialogOpen && (
        <AssetRiskTaskDialog
          disableBaseData
          endpoint='user'
          onClose={closeTaskDialog}
          open={isTaskDialogOpen}
          previewMode={!taskDialog.initiallyEdit}
          risk={null}
          taskId={taskDialog.id}
        />
      )}
    </Section>
  );
};
export default MyAssetsRiskAnalysisTasksTable;
