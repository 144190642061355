import { useQuery } from 'react-query';

import featureFlagsApi from 'config/api/featureFlags/featureFlags';
import { featureFlags, UserFeatureFlags } from 'config/api/featureFlags/featureFlags.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';

const fallback = featureFlags.reduce<UserFeatureFlags>(
  (accumulator, currentValue) => ({ ...accumulator, [currentValue]: false }),
  {} as UserFeatureFlags,
);

const useFeatureFlagCheck = () => {
  const { data, isLoading } = useQuery(QUERY_KEYS.GET_FEATURE_FLAGS, featureFlagsApi.getFeatureFlags, {
    staleTime: 1000 * 60 * 60, // 1 hour
  });

  return { isLoading, availableFeatures: data || fallback };
};

export default useFeatureFlagCheck;
