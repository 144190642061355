import { useMemo } from 'react';

import { isNil } from 'lodash';

import checkProtectedFeatureAccess from 'config/permissions/checkProtectedFeatureAccess';
import ProtectedFeatures from 'config/permissions/ProtectedFeatures';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';

const useBookMeetingAvailabilityCheck = () => {
  const { stage } = useOrganizationPaymentStageInfo();

  const isBookMeetingAvailable = useMemo(() => {
    if (isNil(stage)) {
      return false;
    }
    const { hasAccess } = checkProtectedFeatureAccess(ProtectedFeatures.BookMeeting, {
      paymentStage: [stage],
    });
    return hasAccess;
  }, [stage]);

  return stage && isBookMeetingAvailable;
};

export default useBookMeetingAvailabilityCheck;
