import React from 'react';

import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ChangePasswordDialog from 'components/_dialogs/ChangePasswordDialog';
import CONTACT_TYPES from 'config/constants/CONTACT_TYPES';
import useBoolState from 'hooks/useBoolState';
import general_messages from 'messages/general_messages';
import SettingsTilesGroup from 'pages/MyProfilePage/_components/SettingsTilesGroup';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    maxWidth: `${theme.sizes.button.wide}px`,
    marginTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(4)}px auto 0 auto`,
    },
  },
}));

const PersonalInfoSettings = ({ data, FORM, refreshData }) => {
  const { t } = useTranslation();
  const { state: isPasswordDialogOpen, setFalse: closePasswordDialog, setTrue: openPasswordDialog } = useBoolState(false);

  const styles = useStyles();
  return (
    <>
      <SettingsTilesGroup data={data} FORM={FORM} refreshData={refreshData} type={CONTACT_TYPES.USER} />
      <Button className={styles.button} color='secondary' onClick={openPasswordDialog} variant='outlined'>
        {t(...general_messages.change_password)}
      </Button>
      {isPasswordDialogOpen && <ChangePasswordDialog onClose={closePasswordDialog} open={isPasswordDialogOpen} />}
    </>
  );
};

PersonalInfoSettings.propTypes = {
  data: PropTypes.shape({}),
  refreshData: PropTypes.func.isRequired,
  FORM: PropTypes.shape({
    FIRST_NAME: PropTypes.string.isRequired,
    LAST_NAME: PropTypes.string.isRequired,
    EMAIL: PropTypes.string.isRequired,
    PHONE_NUMBER: PropTypes.string.isRequired,
    TITLE: PropTypes.string.isRequired,
  }).isRequired,
};

PersonalInfoSettings.defaultProps = {
  data: null,
};

export default PersonalInfoSettings;
