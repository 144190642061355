import asset_risk_messages from 'messages/asset_risk_messages';

import { RiskDecision } from '../api/assetRisks/assetRisks.types';

const RISK_ANALYSIS_DECISION_SELECT: { key: RiskDecision; label: string[] }[] = [
  { key: 'accepted_risk', label: asset_risk_messages.risk_decision.accepted_risk },
  { key: 'handle_risk', label: asset_risk_messages.risk_decision.handle_risk },
  { key: 'not_decided', label: asset_risk_messages.risk_decision.not_decided },
];

export default RISK_ANALYSIS_DECISION_SELECT;
