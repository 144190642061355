import React, { useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import FooterButton from 'components/FooterButton';
import Table from 'components/Table';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import meetingsEndpoints from 'config/api/meetings';
import ROLES from 'config/constants/ROLES';
import usePagination from 'hooks/usePagination';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';
import table_messages from 'messages/table_messages';
import { useAdvisorContext } from 'reactContext/AdvisorContext';
import PATHS from 'router/PATHS';

const AdvisoryPageMeetingTable = () => {
  const { t } = useTranslation();
  const { advisor } = useAdvisorContext();

  const [sort, setSort] = useState({ key: null, direction: null });
  const onSort = (key, direction) => setSort({ key, direction });
  const [isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_SUPERVISOR]);

  const {
    data: meetings,
    loading,
    refreshData: getTableData,
    hasNextPage,
  } = usePagination({
    endpointFunc: isSupervisor ? meetingsEndpoints.getAllReportsAsSupervisor : meetingsEndpoints.getAllReportsAsAdviser,
    limits: [15, 10, 5],
    dependencies: [sort, advisor],
    sort,
    queryParams: { adviser: advisor },
    isGet: true,
  });

  const dataMapping = [
    {
      id: 'org_name',
      width: '28%',
      label: t(...table_messages.customer_organization_name),
      get: data => data?.org_name,
    },
    {
      id: 'org_user_profile',
      width: '28%',
      label: t(...table_messages.customer_user_name),
      get: data =>
        data?.org_user_profile && (
          <UserAvatar
            lazy
            showName
            userData={{
              firstName: data?.org_user_profile.first_name,
              lastName: data?.org_user_profile.last_name,
              imageUrl: data?.org_user_profile.image_url,
            }}
            userId={data?.org_user_id}
          />
        ),
    },
    {
      id: 'meeting_date',
      width: '17%',
      label: t(...table_messages.date),
      get: data => data?.meeting_date && format(new Date(data.meeting_date), 'yyyy-MM-dd, HH:mm'),
    },
    {
      id: 'publish_status',
      width: '17%',
      label: t(...table_messages.status),
      get: data => data?.publish_status && t(...meeting_messages.status[data.publish_status]),
    },
  ];

  const getRowHref = ({ meeting_id }) => (meeting_id ? `${PATHS.MEETING_REPORT}/${meeting_id}` : null);

  return (
    <>
      <Table clickableRow data={meetings} dataMapping={dataMapping} getRowHref={getRowHref} onSort={onSort} sort={sort} />
      {hasNextPage && (
        <FooterButton loading={loading} onClick={getTableData}>
          {t(...general_messages.show_more)}
        </FooterButton>
      )}
    </>
  );
};

export default AdvisoryPageMeetingTable;
