import { useEffect, useState } from 'react';

import useApiCall from 'hooks/useApiCall';
import useIntersect from 'hooks/useIntersect/useIntersect';
import useMultipleApiCall from 'hooks/useMultipleApiCall';

const useLazyApiCall = (ref, url) => {
  const [inView] = useIntersect(ref, { rootMargin: '10% 0%' });
  const { apiCall } = useApiCall();
  const { multipleApiCall } = useMultipleApiCall();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [asked, setAsked] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    setLoading(true);
    setAsked(true);
    const isMultiple = Array.isArray(url);
    if (isMultiple) {
      const response = await multipleApiCall(url);
      try {
        const successfulCallsData = response.filter(({ status }) => status <= 400).map(({ data: dataFromResponse }) => dataFromResponse);
        setData(successfulCallsData);
        setError(null);
      } catch (err) {
        setError(err);
      }
    } else {
      const { status, statusText, data: dataFromResponse } = await apiCall(url, {}, { showError: false });
      if (status >= 400) {
        setError(statusText);
      } else {
        setData(dataFromResponse);
        setError(null);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (inView) getData();
  }, [inView]);

  return { data, loading, asked, error };
};

export default useLazyApiCall;
