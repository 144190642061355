const otp_messages = {
  add_button: ['otp>>add_button', 'Add authenticator'],
  add_button_disabled_info: ['otp>>add_button_disabled_info', 'Turn on 2FA to add authenticator'],
  switch_button: ['otp>>switch_button', '2FA (Recommended)'],
  switch_button_info: [
    'otp>>switch_button_info',
    'Two factor authentication is a security process in which the user provides two different authentication factors to verify themselves.',
  ],
  section_title: ['otp>>section_title', '2FA (two factor authentication)'],
  authenticators_list_title: ['otp>>authenticators_list_title', 'My authentications'],
  set_as_default: ['otp>>set_as_default', 'Set as default'],
  confirm: ['otp>>confirm', 'Confirm method'],

  table: {
    description: ['otp>>description', 'Description'],
    is_confirmed: ['otp>>is_confirmed', 'Confirmed'],
    is_default: ['otp>>is_default', 'Default'],
  },
  delete_modal: {
    title: ['otp>>delete_modal>>title', 'Delete authenticator'],
    body: ['otp>>delete_modal>>body', 'Are you sure you want to delete this authenticator?'],
  },
  add_info_otp_device_dialog: {
    title: ['otp>>add_info_otp_device_dialog>>title', 'At least one authenticator is required'],
    body: ['otp>>add_info_otp_device_dialog>>body', 'To enable 2FA at least one authenticator is required. Do you want to add one now?'],
  },
  add_or_confirm_info_otp_device_dialog: {
    title: ['otp>>add_or_confirm_info_otp_device_dialog>>title', 'At least one confirmed authenticator is required'],
    body: [
      'otp>>add_or_confirm_info_otp_device_dialog>>body',
      'To enable 2FA at least one confirmed authenticator is required. Select one from the list or add a new one.',
    ],
    confirm_button_label: ['otp>>add_or_confirm_info_otp_device_dialog>>confirm_button_label', 'Confirm'],
    yesLabel: ['opt>>add_or_confirm_info_otp_device_dialog>>yesLabel', 'Add new'],
    noLabel: ['opt>>add_or_confirm_info_otp_device_dialog>>noLabel', 'Cancel'],
  },
  create_device_dialog: {
    dialog_title: ['otp>>create_device_dialog>>dialog_title', 'New authorization device'],
    step_one_title: ['otp>>create_device_dialog>>step_one_title', 'Device description'],
    step_one_description: [
      'otp>>create_device_dialog>>step_one_description',
      'Provide any description that will help you identify the device',
    ],
    step_two_title: ['otp>>create_device_dialog>>step_two_title', 'Add your device'],
    step_two_description: [
      'otp>>create_device_dialog>>step_two_description',
      'Scan the QR code with the authenticator app. Then click next',
    ],
    step_three_title: ['otp>>create_device_dialog>>step_three_title', 'Confirm in auth app'],
    step_three_description: [
      'otp>>create_device_dialog>>step_three_description',
      'Provide the auth code shown in your app to confirm pairing',
    ],
    description_input_label: ['otp>>description_input_label>>description_input_label', 'Description'],
    confirmation_input_label: ['otp>>confirmation_input_label>>confirmation_input_label', 'Auth token'],
    confirmation_button_label: ['otp>>confirmation_input_label>>confirmation_button_label', 'Verify'],
    successfully_created: ['otp>>confirmation_input_label>>successfully_created', 'Devices successfully paired'],
    invalid_token: ['otp>>confirmation_input_label>>invalid_token', 'Provided token is not valid'],
  },
  otp_auth_dialog: {
    title: ['otp>>otp_auth_dialog>>title', '2FA authentication is required'],
    description: ['otp>>otp_auth_dialog>>description', 'To proceed provide the auth code from your authenticator app ({{currentDevice}})'],
    device_label: ['otp>>otp_auth_dialog>>device_label', 'Device used for authentication'],
    device_tip: ['otp>>otp_auth_dialog>>device_tip', 'To use another device, select it from the list'],

    auth_code_label: ['otp>>otp_auth_dialog>>auth_code_label', 'Auth code'],
    confirm_button_label: ['otp>>otp_auth_dialog>>confirm_button_label', 'Confirm'],
    cancel_button_label: ['otp>>otp_auth_dialog>>cancel_button_label', 'Cancel'],
  },
  otp_auth_failed: ['otp>>otp_auth_failed', 'OTP verification failed'],
  set_as_default_success: ['otp>>set_as_default_success', 'Default authenticator set'],
  otp_new_default_dialog: {
    title: ['otp>>otp_new_default_dialog>>title', 'New default authenticator needs to be set'],
    body: [
      'otp>>otp_new_default_dialog>>body',
      'You are trying to delete the default authenticator. Please set a new default authenticator.',
    ],
    select_device_button_label: ['otp>>otp_new_default_dialog>>select_device_button_label', 'Use as default'],
    confirm_button_label: ['otp>>otp_new_default_dialog>>confirm_button_label', 'Confirm'],
    cancel_button_label: ['otp>>otp_new_default_dialog>>cancel_button_label', 'Cancel'],
  },
  otp_coworkers_encourage: {
    title: ['otp>>otp_coworkers_encourage>>title', 'Two Factor Authentication (2FA) is strongly recommended for your employees'],
    body: [
      'otp>>otp_coworkers_encourage>>body',
      'We strongly recommend encouraging your employees to enhance their own security by enabling 2FA, especially those who manage assets or work in incident management. You can see which of your employees have enabled 2FA below. If a user needs to disable their 2FA for any reason, you can assist them by editing the user profile and turning off the feature.',
    ],
  },
};

export default otp_messages;
