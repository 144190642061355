import TRANSLATABLE_INCIDENT_PRIORITIES from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PRIORITIES';
import table_messages from 'messages/table_messages';
import prepareDataForFilter from 'services/prepareDataForFilter';

const priority = {
  sectionName: table_messages.priority,
  filters: prepareDataForFilter(TRANSLATABLE_INCIDENT_PRIORITIES, false, true),
  isSingleSelect: false,
};

export default priority;
