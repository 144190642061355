import { AssetDTO } from 'config/api/assets/assets.types';

const assetOptionsParser = ({ results }: { results: AssetDTO[] }) =>
  results.map(({ id, name, category }) => ({
    key: id,
    id,
    name,
    category: [category.name_translation_key, category.name],
  }));
export default assetOptionsParser;
