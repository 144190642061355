import React from 'react';

// import { makeStyles } from '@material-ui/core';
// import PropTypes from 'prop-types';

// const useStyles = makeStyles(theme => ({}));

const EditArticlePage = () => (
  // const styles = useStyles();
  <>EditArticle</>
);
// EditArticlePage.propTypes = {};

export default EditArticlePage;
