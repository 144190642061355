import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import { parseAllTotpDevicesForFE, parseTotpDeviceDetailForFE } from './manageTotpDevices.parsers';
import ManageTotpDevicesApi, { CreateDeviceResponseDTO, SwitchTwoFactorAuthInputDTO } from './manageTotpDevices.types';

const endpoint = 'api/v1/manage_totp_devices/';
const endpointTwoFA = 'api/v1/current_user/switch_2fa/';

const manageTotpDevicesApi: ManageTotpDevicesApi = {
  addTotpDevice: async data => {
    const response = await axios.post<CreateDeviceResponseDTO>(endpoint, data);
    return { qrCode: response.data.qr_code, id: response.data.id };
  },
  getAllTotpDevices: async () => {
    const response = await axios.get(createUrlWithParams(endpoint, { limit: 100, ordering: 'confirmed' }));
    return parseAllTotpDevicesForFE(response.data);
  },
  getSingleTotpDevice: async deviceId => {
    const response = await axios.get(`${endpoint}${deviceId}`);
    return parseTotpDeviceDetailForFE(response.data);
  },
  switchTwoFactorAuth: async input => {
    const data: SwitchTwoFactorAuthInputDTO = {
      has_2fa_enabled: input.enabled,
    };

    if (!input.enabled) {
      data.device = input.deviceId;
      data.otp_token = input.token;
    }

    return axios.patch(endpointTwoFA, data);
  },
  removeTotpDevice: ({ deviceId, otpAuthData }) =>
    axios.delete(`${endpoint}${deviceId}/`, {
      data: {
        device: otpAuthData.deviceId,
        otp_token: otpAuthData.token,
      },
    }),
  editTotpDevice: ({ deviceId, name }) => axios.patch(`${endpoint}${deviceId}/`, { name }),
  setTotpDeviceAsDefault: deviceId => axios.patch(`${endpoint}${deviceId}/`, { default: true }),
};

export default manageTotpDevicesApi;
