import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import { parseSingleWorkplaceDocumentsForFE, parseWorkplaceDocumentToCreate } from './workplaceDocuments.parsers';
import type WorkplaceDocumentsApi from './workplaceDocuments.types';

const endpoint = 'api/v1/workplace_documents/';

export const legacyWorkplaceDocumentsApi = {
  getAllWorkplaceDocuments: (params: BasePaginatedQueryParams) => ({ url: createUrlWithParams(endpoint, params), method: METHOD.GET }),
};

const workplaceDocumentsApi: WorkplaceDocumentsApi = {
  editWorkplaceDocument: id => async formData => {
    const { data } = await axios.patch(`${endpoint}${id}/`, { description: formData.description });
    return parseSingleWorkplaceDocumentsForFE(data);
  },
  addWorkplaceDocument: () => async formData => {
    const { data } = await axios.post(endpoint, parseWorkplaceDocumentToCreate(formData));
    return parseSingleWorkplaceDocumentsForFE(data);
  },
  removeWorkplaceDocument: async id => {
    const { data } = await axios.delete(`${endpoint}${id}/`);
    return parseSingleWorkplaceDocumentsForFE(data);
  },
  getSingleWorkplaceDocument: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleWorkplaceDocumentsForFE(data);
  },
};

export default workplaceDocumentsApi;
