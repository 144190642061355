import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  main: {
    display: 'grid',
    gap: theme.spacing(3),
    padding: theme.spacing(5),
  },
  divider: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[100],
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(2),
    // @ts-ignore
    borderTop: `3px solid ${theme.palette.primary[100]}`,
    padding: theme.spacing(4),
  },
}));
