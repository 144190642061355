import React from 'react';

import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import { questionsApi } from 'config/api/questions';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import parseDateTime from 'helpers/parseDateTime';
import general_messages from 'messages/general_messages';
import questions_messages from 'messages/questions_messages';
import PATHS from 'router/PATHS';

import TypographyWithHTML from '../../TypographyWithHTML/TypographyWithHTML';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './QuestionOverviewDialog.styles';

const QuestionOverviewDialog = ({ open, onClose, questionData, isPS }) => {
  const { t } = useTranslation();
  const hasAnswer = !!questionData.answered_at;
  const { data: answerData } = useQuery(
    ['get question details', questionData.id],
    () => {
      if (isPS) return questionsApi.getAnswerPSOnline(questionData.id);
      return questionsApi.getAnswerSecurityOfficer(questionData.id);
    },
    {
      enabled: hasAnswer,
    },
  );

  const styles = useStyles();
  const relatedContent = answerData
    ? [...answerData.data.related_guides, ...answerData.data.related_articles, ...answerData.data.related_self_tests]
    : [];
  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid withoutPadding>
          <ColoredButton customColor='secondary' onClick={onClose} variant='outlined'>
            {t(...general_messages.close)}
          </ColoredButton>
        </CenteredGrid>
      }
      maxWidth='xs'
      onClose={onClose}
      open={open}
      title={t(...questions_messages.dialog_overview_title)}
    >
      <CenteredGrid gridGap={1.5} withoutPadding>
        <Typography variant='body2'>
          {t(questions_messages.dialog_question_by)}: {questionData.user} @ {parseDateTime(questionData.created_at)}
        </Typography>

        {hasAnswer && (
          <Typography variant='body2'>
            {t(questions_messages.dialog_answered_by)}: {answerData?.data.authors_names.join(', ')} @{' '}
            {parseDateTime(questionData.answered_at)}
          </Typography>
        )}

        <Typography className={styles.subtitle}>{t(questions_messages.dialog_question_title)}</Typography>
        <Typography variant='body2'>{questionData.text}</Typography>
        <Typography className={styles.subtitle}>{t(questions_messages.dialog_answer_title)}</Typography>
        {hasAnswer && (<TypographyWithHTML variant='body2'>{answerData?.data.text}</TypographyWithHTML> || <Skeleton width='100%' />)}
        {hasAnswer && relatedContent.length ? (
          <div>
            <Typography className={styles.subtitle}>{`${t(...general_messages.related_content)}: `}</Typography>
            <ul className={styles.linkContainer}>
              {relatedContent.map(({ object_content_type, id, title, friendly_id, metadata }) => {
                let href;
                switch (object_content_type) {
                  case CONTENT_TYPES.ARTICLE:
                    href = `${PATHS.ARTICLES}/${id}`;
                    break;
                  case CONTENT_TYPES.INCIDENT_GUIDE:
                  case CONTENT_TYPES.GUIDE:
                    href = `${PATHS.GUIDES}/${id}/${metadata?.version_id}`;
                    break;
                  case CONTENT_TYPES.SELF_TEST:
                    href = `${PATHS.SELF_TESTS}/${id}/${metadata?.version_id}`;
                    break;
                  default:
                    throw Error('Unsupported content type');
                }
                return (
                  <li key={id}>
                    <Typography className={styles.link} component={Link} rel='noreferrer' target='_blank' to={href}>
                      {`${title} (${friendly_id})`}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </CenteredGrid>
    </DialogGrid>
  );
};

export default QuestionOverviewDialog;

// TODO answer author and date
// TODO read init related content (check)
