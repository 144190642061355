const metadata_messages = {
  heading1: ['metadata>>heading1', 'Heading1'],
  language: ['metadata>>language', 'Language'],
  service_area: ['metadata>>service_area', 'Service area'],
  customer_level: ['metadata>>customer_level', 'Customer level'],
  audience: ['metadata>>audience', 'Audience'],
  depth_level: ['metadata>>depth_level', 'Depth level'],
  estimated_time: ['metadata>>estimated_time', 'Estimated time'],
  metadata: ['metadata>>metadata', 'Meta data'],
  link_image: ['metadata>>link_image', 'Link image'],
};

export default metadata_messages;
