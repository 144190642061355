import React from 'react';

import PropTypes from 'prop-types';

import ImageWithFallback from 'components/ImageWithFallback';

import useStyles from './ArticleImage.styles';

const ArticleImage = ({ image_url, title }) => {
  const styles = useStyles();
  return <ImageWithFallback alt={title} className={styles.image} src={image_url} />;
};

ArticleImage.propTypes = {
  image_url: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ArticleImage.defaultProps = {
  title: '',
};

export default ArticleImage;
