import React, { useMemo } from 'react';

import { FormControl, FormHelperText, InputLabel, Menu, MenuItem, OutlinedInput, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { format, getDaysInMonth } from 'date-fns';
import { FormikProps } from 'formik';
import { get, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import general_messages from 'messages/general_messages';
import generateMonths from 'services/generateMonths/generateMonths';

import useStyles from './TimePeriodPickerForPlanner.styles';

type Props = {
  formik: FormikProps<any>;
  label: string;
  id: string;
};

const TimePeriodPickerForPlanner: React.FC<Props> = ({ id, label, formik }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { selectedDay, selectedMonth } = useMemo(() => {
    const { day, month } = formik.values[id];
    return { selectedDay: day, selectedMonth: month };
  }, [formik.values[id].month, formik.values[id].day]);

  const onMonthClick = ({ target }: { target: EventTarget & { value: number } }) => {
    formik.setFieldValue(`${id}.month`, target.value);
  };

  const onDayClick = ({ target }: { target: EventTarget & { value: number } }) => {
    formik.setFieldValue(`${id}.day`, target.value);
    if (!isNil(selectedMonth)) handleClose();
  };

  const formattedValue = useMemo(() => {
    if (selectedDay === '' || selectedMonth === '') return undefined;
    const date = new Date();
    date.setMonth(selectedMonth);
    date.setDate(selectedDay);
    return format(date, 'do MMMM');
  }, [selectedMonth, selectedDay]);

  const daysNumber = useMemo(() => {
    const date = new Date(2021, formik.values[id].month); // make sure it's not a leap year
    const result = getDaysInMonth(date);
    if (result < selectedDay) formik.setFieldValue(`${id}.day`, result);
    return result;
  }, [selectedMonth]);

  const error = get(formik.touched, id) ? get(formik.errors, id) : null;
  const styles = useStyles();
  return (
    <>
      <FormControl variant='outlined'>
        <InputLabel htmlFor={`${id}-time-period`} shrink={!!formattedValue}>
          {label}
        </InputLabel>
        <OutlinedInput
          error={!!error}
          id={`${id}-time-period`}
          label={label}
          notched={!!formattedValue}
          onClick={handleOpen}
          readOnly
          value={formattedValue}
        />
        <FormHelperText error>{!!error && (error as unknown as string)}</FormHelperText>
      </FormControl>
      <Menu anchorEl={anchorEl} id='TimePeriodPickerForPlanner' keepMounted onClose={handleClose} open={Boolean(anchorEl)}>
        <div className={styles.menu}>
          <div>
            <Typography align='center' className={styles.header} color='secondary' variant='h5'>
              {t(general_messages.month)}
            </Typography>
            <div className={styles.monthsGrid}>
              {generateMonths(i18n.language as 'en' | 'sv', 'MMM').options.map(({ optionValue, label: optionlabel }) => (
                <MenuItem
                  className={clsx(styles.tile, selectedMonth === optionValue && styles.selected)}
                  // @ts-ignore
                  onClick={onMonthClick}
                  value={optionValue}
                >
                  {optionlabel}
                </MenuItem>
              ))}
            </div>
          </div>
          <div>
            <Typography align='center' className={styles.header} color='secondary' variant='h5'>
              {t(general_messages.day)}
            </Typography>
            <div className={styles.daysGrid}>
              {Array.from(Array(daysNumber)).map((_, index) => (
                <MenuItem
                  className={clsx(styles.tile, selectedDay === index + 1 && styles.selected)}
                  disabled={isNil(formik.values[id].month)}
                  // @ts-ignore
                  onClick={onDayClick}
                  value={index + 1}
                >
                  {index + 1}
                </MenuItem>
              ))}
            </div>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default React.memo(TimePeriodPickerForPlanner, (oldProps, newProps) => {
  const oldValues: { day: number | ''; month: number | '' } = oldProps.formik.values[oldProps.id];
  const newValues: { day: number | ''; month: number | '' } = newProps.formik.values[newProps.id];
  return newValues.month === oldValues.month && newValues.day === oldValues.day;
});
