import React from 'react';

import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import ContentPage from 'pages/_common/ContentPage';

import getTagFilter from '../../services/_filters/parsers/getTagFilter';

const getAvailableFiltersUrlsForSelfTests = () => ({
  tagFilter: getTagFilter('self_test'),
});

const SelfTestsPage: React.FC = () => (
  <ContentPage contentType={CONTENT_TYPES.SELF_TEST} filtersGetter={getAvailableFiltersUrlsForSelfTests} />
);

export default SelfTestsPage;
