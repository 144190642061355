import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  resultsContainer: {
    padding: theme.spacing(3, 0, 0),
  },

  resultsRow: {
    textDecoration: 'none',
    borderBottom: `thin solid ${theme.palette.secondary[100]}`,
    padding: theme.spacing(1.5, 0.5),
    display: 'grid',
    gridTemplateColumns: '120px 1fr 100px',
    gridGap: theme.spacing(1),
    color: theme.palette.text.primary,
    alignItems: 'center',

    transition: '.1s',
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        background: theme.palette.secondary[50],
      },
      '&:active': {
        background: theme.palette.secondary[100],
      },
    },
  },
}));
