import React, { useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import FooterButton from 'components/FooterButton';
import Table from 'components/Table';
import commonEndpoints from 'config/api/common';
import EVENTS from 'config/events/pubsub';
import { ACTIVITY_TYPES, activityTypeResolver } from 'config/translatableConstants/TRANSLATABLE_ACTIVITY_TYPES';
import { guideStatusResolver } from 'config/translatableConstants/TRANSLATABLE_GUIDE_STATUSES';
import usePagination from 'hooks/usePagination';
import useSubscription from 'hooks/useSubscription';
import general_messages from 'messages/general_messages';
import table_messages from 'messages/table_messages';
import { COMMON_DIALOG_TYPES, useCommonDialogsContext } from 'reactContext/CommonDialogsContext';
import PATHS from 'router/PATHS';
import activityTypeFilter, { activityTypeFilterFallback } from 'services/_filters/parsers/activityTypeFilter';
import guideStatusFilter from 'services/_filters/parsers/guideStatusFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';

const availableFilters = {
  progress_status: guideStatusFilter,
  object_content_type: activityTypeFilter,
};

const ActivitiesTable = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { openCommonDialog } = useCommonDialogsContext();
  const [filters, setFilters] = useState({});

  const [sort, setSort] = useState({ key: null, direction: null });
  const onSort = (key, direction) => setSort({ key, direction });
  const getQueryParams = () => {
    const { guideType, guideStatus } = filters;

    const params = {};
    if (guideType && guideType[0] !== undefined) return { is_incident: Boolean(guideType[0].id) };
    if (guideStatus && guideStatus[0] !== undefined) return { is_completed: Boolean(guideStatus[0].id) };
    return params;
  };

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData: refreshTableData,
  } = usePagination({
    endpointFunc: commonEndpoints.activities,
    limits: [6, 4, 3],
    dependencies: [sort, filters],
    sort,
    queryParams: getQueryParams(),
    filters: parseFiltersForRequest(filters, { object_content_type: activityTypeFilterFallback }),
  });

  const dataMapping = [
    {
      id: 'name',
      width: '40%',
      label: t(...table_messages.name),
      get: data => data?.name,
      blockSorting: true,
    },
    {
      id: 'is_incident',
      width: '20%',
      label: t(...table_messages.type),
      get: data => t(...activityTypeResolver(data?.object_content_type)),
      blockSorting: true,
    },
    {
      id: 'is_completed',
      width: '20%',
      label: t(...table_messages.status),
      get: data => t(...guideStatusResolver(data?.progress_status)),
      blockSorting: true,
    },
    {
      id: 'created_at',
      width: '20%',
      label: t(...table_messages.start),
      get: data => data?.created_at && format(new Date(data.created_at), 'yyyy-MM-dd'),
    },
  ];

  const onRowClick = (e, { object_content_type, metadata, id }) => {
    const { version_id, guide_id, self_test_id } = metadata;

    if (object_content_type === ACTIVITY_TYPES.GUIDE_PROGRESS || object_content_type === ACTIVITY_TYPES.INCIDENT_GUIDE_PROGRESS) {
      openCommonDialog(COMMON_DIALOG_TYPES.GUIDE_SUMMARY, { version_id, guide_id, progress_id: id });
      return;
    }
    if (object_content_type === ACTIVITY_TYPES.INCIDENT_REPORT) {
      history.push(`${PATHS.INCIDENT_REPORT}/${id}`);
    }
    if (object_content_type === ACTIVITY_TYPES.SELF_TEST_INSTANCE) {
      history.push(`${PATHS.SELF_TESTS}/${self_test_id}/${version_id}/${id}`);
    }
  };

  useSubscription(EVENTS.ACTIVITIES_UPDATED, refreshTableData);
  return (
    <>
      <Table
        data={tableData}
        dataMapping={dataMapping}
        filtersService={{ availableFilters, filters, setFilters }}
        onRowClick={onRowClick}
        onSort={onSort}
        sort={sort}
      />
      {hasNextPage && (
        <FooterButton loading={loading} onClick={getTableData}>
          {t(...general_messages.show_more)}
        </FooterButton>
      )}
    </>
  );
};

export default ActivitiesTable;
