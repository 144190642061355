import React, { useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';

import CalendarColumn from 'components/_calendar/CalendarColumn';
import CalendarGrid from 'components/_calendar/CalendarGrid';
import TimeSlot from 'components/TimeSlot/TimeSlot';
import generateTimeSlots from 'services/generateTimeSlots';

const generateSlotsForCalendar = (date, slotsMiddleware) => {
  const dates = [];
  generateTimeSlots(date, slot => slotsMiddleware(dates, slot));
  return dates;
};

const MeetingsCalendar = ({
  days,
  onSlotClick,
  singleDay,
  loadingDates,
  advisorView,
  slotsMiddleware,
  forceSingleDay,
  autoScroll,
  isClickableCheck,
  hideNA,
  tileHref,
}) => {
  const theme = useTheme();
  const showSingleDay = forceSingleDay || useMediaQuery(theme.breakpoints.down('xs'));
  const [reloadId, setReloadId] = useState(0);
  const reloadTrigger = () => setReloadId(prev => prev + 1);
  return (
    <CalendarGrid showSingleDay={showSingleDay}>
      {showSingleDay ? (
        <CalendarColumn>
          {generateSlotsForCalendar(singleDay, slotsMiddleware).map(({ date, isAvailable, ...props }, index) =>
            hideNA && !isAvailable ? null : (
              <TimeSlot
                key={index}
                advisorView={advisorView}
                autoScroll={autoScroll}
                date={date}
                isAvailable={isAvailable}
                isClickableCheck={isClickableCheck}
                loading={loadingDates && loadingDates.some(loadingDate => isEqual(loadingDate, date))}
                onClick={onSlotClick}
                reload={reloadId}
                reloadTrigger={reloadTrigger}
                responsive
                showTimeline
                tileHref={tileHref}
                {...props}
              />
            ),
          )}
        </CalendarColumn>
      ) : (
        days.map(day => (
          <CalendarColumn key={day}>
            {generateSlotsForCalendar(day, slotsMiddleware).map(({ date, ...props }, index) => (
              <TimeSlot
                key={index}
                advisorView={advisorView}
                autoScroll={autoScroll}
                date={date}
                isClickableCheck={isClickableCheck}
                loading={loadingDates && loadingDates.some(loadingDate => isEqual(loadingDate, date))}
                onClick={onSlotClick}
                reload={reloadId}
                reloadTrigger={reloadTrigger}
                responsive
                showTimeline
                tileHref={tileHref}
                {...props}
              />
            ))}
          </CalendarColumn>
        ))
      )}
    </CalendarGrid>
  );
};

MeetingsCalendar.propTypes = {
  slotsMiddleware: PropTypes.func.isRequired,
  days: PropTypes.arrayOf(PropTypes.shape({})),
  onSlotClick: PropTypes.func,
  loadingDates: PropTypes.arrayOf(PropTypes.object),
  singleDay: PropTypes.shape({}).isRequired,
  advisorView: PropTypes.bool,
  forceSingleDay: PropTypes.bool,
  autoScroll: PropTypes.bool,
  isClickableCheck: PropTypes.func,
  hideNA: PropTypes.bool,
  tileHref: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

MeetingsCalendar.defaultProps = {
  tileHref: null,
  advisorView: false,
  loadingDates: null,
  days: null,
  forceSingleDay: null,
  autoScroll: false,
  onSlotClick: null,
  isClickableCheck: () => true,
  hideNA: false,
};

export default MeetingsCalendar;
