import { AssetContact, AssetContactCreateInput, AssetContactDTO, AssetContactFormInput } from './assetContacts.types';

export const parseSingleAssetContactsForFE = (raw: AssetContactDTO): AssetContact => ({
  id: raw.id,
  createdAt: new Date(raw.created_at),
  updatedAt: new Date(raw.updated_at),
  name: raw.name,
  comment: raw.comment,
  phoneNumber: raw.phone_number,
  email: raw.email,
  category: raw.category,
  asset: raw.asset,
});

export const parseDataToCreateAndEditAssetContact = (formInput: AssetContactFormInput): AssetContactCreateInput => ({
  comment: formInput.comment,
  asset: formInput.assetId,
  category: formInput.category,
  email: formInput.email,
  name: formInput.name,
  phone_number: formInput.phoneNumber,
});
