import React from 'react';

import { SnackbarProvider } from 'notistack';
import PubSub from 'pubsub-js';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

import EVENTS from 'config/events/pubsub';
import snackBarConfig from 'config/snackBarConfig';
import SnackbarCloseButton from 'root/_components/SnackbarCloseButton/SnackbarCloseButton';
import App from 'root/App/App';
import 'styles/print.css';

import reportWebVitals from './reportWebVitals';

import 'config/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: error => {
        PubSub.publish(EVENTS.API_MUTATION_ERROR, { error });
      },
      retry: 0,
    },
    queries: {
      onError: error => {
        PubSub.publish(EVENTS.API_FETCH_ERROR, { error });
      },
    },
  },
});

if (!('IntersectionObserver' in window)) {
  // @ts-ignore
  import(`intersection-observer`);
  // eslint-disable-next-line no-console
  console.log('# IntersectionObserver is polyfilled!');
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <SnackbarProvider {...snackBarConfig} action={id => <SnackbarCloseButton id={id} />}>
      <App />
    </SnackbarProvider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
