const info_boxes_messages = {
  restricted_access_page: {
    title: ['info_boxes>>restricted_access_page>>title', 'We are sorry to say that the functionality of your organization is restricted'],
    content: [
      'info_boxes>>restricted_access_page>>content',
      'We have not received a way for payments from your organization yet. We are sorry to say we have disabled some functionality of PocketSafe. This will of course be unlocked as soon as we receive payment information from you',
    ],
  },
  trial: {
    title: ['info_boxes>>trial>>title', 'Friendly reminder to register your payment method'],
    content: [
      'info_boxes>>trial>>content',
      `You have not yet supplied any payment method . You still have {{remainingDays}} days left of your free period. During this period, you can invite your colleagues and
enjoy all our functions except booking meetings with the advisors at PocketSafe. After that, your 
account will be disabled and there after deleted according to GDPR.`,
    ],
    buttonLabel: ['info_boxes>>trial>>buttonLabel', 'Add payment method'],
  },
  retention: {
    title: ['info_boxes>>retention>>title', 'We are sorry to say that the functionality of your organization is restricted'],
    content: [
      'info_boxes>>retention>>content',
      `We have not yet received a way for payments from your organization. We are there for sad to
say we have disabled some functionality of PocketSafe. This will of course be unlocked as soon as we
receive payment information for you.`,
    ],
  },
  retention_for_account_owner: {
    title: ['info_boxes>>retention_for_account_owner>>title', 'We have not yet received your payment information'],
    content: [
      'info_boxes>>retention_for_account_owner>>content',
      `You have not yet supplied any payment method . The functionality is there for reduced for your 
entire organization. Please supply your payment information if you want to continue using 
PocketSafe. If we do not receive payment information during the next 
{{ remainingDays }} days, we will disable your organization and all accounts and will 
thereafter delete everything all your information according to GDPR.`,
    ],
    buttonLabel: ['info_boxes>>retention_for_account_owner>>buttonLabel', 'Add payment method'],
  },
  card_invalid: {
    title: ['info_boxes>>card_invalid>>title', 'We are sorry to say that the functionality of your organization is restricted'],
    content: [
      'info_boxes>>card_invalid>>content',
      `There seems to be a problem with debiting your credit card. No problems, these things happen, 
but until it is resolved we have restricted the functionality of your subscription. No users can 
currently book advisory meetings with PocketSafe. As soon as you solve the problems with your 
payment method, the full functionality will be restored. If we for some reason do not receive a valid 
payment method from you, your account will be disabled and then deleted.`,
    ],
    buttonLabel: ['info_boxes>>card_invalid>>buttonLabel', 'Edit your payment method'],
  },
  book_meeting: {
    title: ['info_boxes>>book_meeting>>title', 'The button to book a meeting is not available until we have a registered payment method'],
    content: [
      'info_boxes>>book_meeting>>content',
      `We hope to soon see you in a meeting, but until your organization has entered a payment 
method this function is disabled. Until then you are free to use all other functionality and ask free 
questions with the ask a question function.`,
    ],
  },
};

export default info_boxes_messages;
