const article_messages = {
  related_articles: ['article>>related_articles', 'Related articles'],
  related_article: ['article>>related_article', 'Related article'],
  more_guides_available: ['article>>more_guides_available', '{{number}} guides available'],
  feedback: ['article>>feedback', 'Give feedback on the article'],
  saved: ['article>>saved', 'Article saved!'],
  removed_from_saved: ['article>>removed_from_saved', 'Article has been removed from saved'],
  limited_access_title: ['article>>limited_access_title', 'Login or sign up to read the article'],
  limited_access_body: ['article>>limited_access_body', 'This part of the article is only available to logged in users'],
};

export default article_messages;
