import { makeStyles } from '@material-ui/core';

type StylesProps = {
  darker?: boolean;
  unified?: boolean;
};

export default makeStyles(theme => ({
  tile: {
    // @ts-ignore
    background: ({ darker }: StylesProps) => theme.palette.secondary[darker ? 100 : 50],
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2.5),
    // @ts-ignore
    borderRadius: theme.shape.borderRadiusLarge,
    justifyContent: 'stretch',
    alignContent: 'space-between',
    // @ts-ignore
    border: ({ unified }: StylesProps) => (unified ? `1px solid ${theme.palette.secondary[300]}` : 'none'),
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerFont: {
    textTransform: 'uppercase',
    // @ts-ignore
    color: theme.palette.text.secondaryColor,
    letterSpacing: '1px',
    fontSize: '.75rem',
    fontWeight: 500,
  },
}));
