import general_messages from 'messages/general_messages';

export const ACTIVITY_TYPES = {
  INCIDENT_REPORT: 'incident_report',
  GUIDE_PROGRESS: 'guide_progress',
  INCIDENT_GUIDE_PROGRESS: 'incident_guide_progress',
  SELF_TEST_INSTANCE: 'self_test_user_instance',
};

const TRANSLATABLE_ACTIVITY_TYPES_FALLBACKS = {
  INCIDENT_REPORT: general_messages.incident_report[1],
  GUIDE_PROGRESS: general_messages.guide[1],
  INCIDENT_GUIDE_PROGRESS: general_messages.incident_guide[1],
  SELF_TEST_INSTANCE: general_messages.self_test[1],
};

const TRANSLATABLE_ACTIVITY_TYPES = {
  GUIDE_PROGRESS: [general_messages.guide[0], TRANSLATABLE_ACTIVITY_TYPES_FALLBACKS.GUIDE_PROGRESS],
  INCIDENT_REPORT: [general_messages.incident_report[0], TRANSLATABLE_ACTIVITY_TYPES_FALLBACKS.INCIDENT_REPORT],
  INCIDENT_GUIDE_PROGRESS: [general_messages.incident_guide[0], TRANSLATABLE_ACTIVITY_TYPES_FALLBACKS.INCIDENT_GUIDE_PROGRESS],
  SELF_TEST_INSTANCE: [general_messages.self_test[0], TRANSLATABLE_ACTIVITY_TYPES_FALLBACKS.SELF_TEST_INSTANCE],
};

export const activityTypeResolver = typeKey => {
  switch (typeKey) {
    case ACTIVITY_TYPES.GUIDE_PROGRESS:
      return TRANSLATABLE_ACTIVITY_TYPES.GUIDE_PROGRESS;
    case ACTIVITY_TYPES.INCIDENT_REPORT:
      return TRANSLATABLE_ACTIVITY_TYPES.INCIDENT_REPORT;
    case ACTIVITY_TYPES.INCIDENT_GUIDE_PROGRESS:
      return TRANSLATABLE_ACTIVITY_TYPES.INCIDENT_GUIDE_PROGRESS;
    case ACTIVITY_TYPES.SELF_TEST_INSTANCE:
      return TRANSLATABLE_ACTIVITY_TYPES.SELF_TEST_INSTANCE;
    default:
      return general_messages.no_data;
  }
};

export default TRANSLATABLE_ACTIVITY_TYPES;
