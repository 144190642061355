import { useMemo } from 'react';

import { isError, useQuery } from 'react-query';

import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { selfTestApi } from 'config/api/selfTests/selfTests';

const useGetSelfTestData = (versionId: string) => {
  const selfTestData = useQuery([QUERY_KEYS.GET_SINGLE_SELF_TEST, versionId], () => selfTestApi.getSelfTestData(versionId), {
    refetchOnWindowFocus: false,
  });
  const { data, isLoading, questions, maxSteps } = useMemo(
    () => ({
      data: {
        selfTestData: selfTestData.data,
      },
      isLoading: selfTestData.isLoading,
      questions: selfTestData.data?.questions,
      maxSteps: selfTestData.data?.questions.length,
    }),
    [selfTestData.data, selfTestData.isLoading],
  );
  return { data, isLoading, isError, questions, maxSteps };
};

export default useGetSelfTestData;
