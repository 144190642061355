import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ButtonBase, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { format, isPast, isToday, isTomorrow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useScrollTo from 'hooks/useScrollTo';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';

import { isCurrentSlot, calculateTimeLinePosition, getMissingMilliseconds } from './TimeSlot.logic';
import useStyles from './TimeSlot.styles';

type BaseProps = {
  isAvailable: boolean;
  isBooked?: boolean;
  responsive?: boolean;
  advisorView?: boolean;
  showDate?: boolean;
  date: Date;
  showTimeline?: boolean;
  reload?: number;
  reloadTrigger?: () => void;
  loading?: boolean;
  autoScroll?: boolean;
};

type FunctionProps = {
  isClickableCheck?: (params: BaseProps) => boolean;
  tileHref?: string | ((params: BaseProps) => string);
  onClick?: (params: BaseProps) => void;
};

const TimeSlot: React.FC<FunctionProps & BaseProps> = ({ isClickableCheck, tileHref, onClick, ...props }) => {
  const {
    isAvailable,
    isBooked,
    date,
    responsive,
    advisorView,
    showDate,
    showTimeline,
    reload = 0,
    reloadTrigger = () => null,
    loading,
    autoScroll,
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  const isClickable = isClickableCheck ? isClickableCheck(props) : true;
  const isDisabled = isBooked ? isPast(date) : isPast(date) || (!advisorView && !isAvailable);

  const href = useMemo(() => {
    if (!tileHref) return undefined;
    if (typeof tileHref === 'string') return tileHref;
    return tileHref(props);
  }, [props]);

  const clickHandler = () => {
    if (onClick && isClickable && !href) onClick(props);
  };

  const resolveTitle = () => {
    if (showDate) {
      if (isToday(date)) return t(general_messages.today);
      if (isTomorrow(date)) return t(general_messages.tomorrow);
      return format(date, 'dd LLL');
    }
    if (isBooked) return t(meeting_messages.reserved);
    if (!isAvailable) return t(meeting_messages.unavailable);
    return advisorView ? t(meeting_messages.available) : t(meeting_messages.book);
  };

  const resolveColor = () => {
    const { palette } = theme;
    if (advisorView) {
      // @ts-ignore
      if (!isAvailable) return palette.calendar.tileBackground.unavailable;
      if (isBooked) {
        // @ts-ignore
        return palette.calendar.tileBackground.advisory_booked;
      }
    }

    // @ts-ignore
    if (isBooked) return palette.calendar.tileBackground.booked;
    // @ts-ignore
    return palette.calendar.tileBackground.default;
  };

  // TIME INDICATOR LOGIC
  // render in another component if performance issues occurs
  const isCurrent = isCurrentSlot(date);
  const [timelinePosition, setTimelinePosition] = useState(calculateTimeLinePosition());
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const intervalRef = useRef<null | ReturnType<typeof setInterval>>(null);

  const updateTimelinePosition = () => {
    if (!isCurrentSlot(date)) reloadTrigger();
    setTimelinePosition(calculateTimeLinePosition());
  };

  const initUpdateTimelinePosition = () => {
    updateTimelinePosition();
    intervalRef.current = setInterval(updateTimelinePosition, 60000);
  };

  useEffect(() => {
    if (showTimeline && isCurrentSlot(date)) {
      updateTimelinePosition();
      timeoutRef.current = setTimeout(initUpdateTimelinePosition, getMissingMilliseconds());
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [reload]);

  // AUTO SCROLL LOGIC
  const currentRef = useRef(null);
  const { scrollIntoView } = useScrollTo(currentRef);
  useEffect(() => {
    if (autoScroll) scrollIntoView();
  }, [currentRef, autoScroll]);

  const styles = useStyles({ responsive: !!responsive, timelinePosition, bgColor: resolveColor(), clickable: isClickable });
  return (
    <div ref={isCurrent && autoScroll ? currentRef : null} className={clsx(styles.wrapper, loading && styles.loading, isCurrent)}>
      <ButtonBase
        className={clsx(styles.button, isDisabled && styles.disabled)}
        component={isClickable && href ? Link : 'button'}
        data-testid='time-slot-button'
        disableTouchRipple={!onClick || !isClickable}
        onClick={clickHandler}
        to={href}
      >
        <Typography className={styles.time}>{format(date, 'HH:mm')}</Typography>
        <Typography className={styles.status}>{resolveTitle()}</Typography>
      </ButtonBase>
      {showTimeline && isCurrent && <div className={styles.timeIndicator} />}
    </div>
  );
};

export default TimeSlot;
