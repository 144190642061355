import React, { useEffect, useState } from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArticleTilesGrid from 'components/ArticleTilesGrid';
import ContentGuideTile from 'components/ContentGuideTile/ContentGuideTile';
import DescriptionText from 'components/DescriptionText';
import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import RedirectButton from 'components/RedirectButton/RedirectButton';
import Section from 'components/Section';
import SelfTestTile from 'components/SelfTestTile/SelfTestTile';
import commonEndpoints from 'config/api/common';
import { ContentItem, ContentTypeEnum } from 'config/api/selfTests/_types';
import { parseContentItemForFE } from 'config/api/selfTests/parsers';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import PUBLISH_STATUSES from 'config/constants/PUBLISH_STATUSES';
import { contentTypeResolver } from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import safeT from 'helpers/safeT/safeT';
import useApiCall from 'hooks/useApiCall';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';

import useStyles from './ServiceAreaPage.styles';

const PAGE_LIMIT = 9;

const ServiceAreaPage = () => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const { service_area_id: serviceAreaId } = useParams() as { service_area_id: string };
  const styles = useStyles();

  // TODO types needed
  const [serviceAreaData, setServiceAreaData] = useState<any>(null);
  const [serviceAreaArticles, setServiceAreaArticles] = useState<{ articlesList: ContentItem[]; articlesCount: number | null }>({
    articlesList: [],
    articlesCount: null,
  });
  const [serviceAreaGuides, setServiceAreaGuides] = useState<{ guidesList: ContentItem[]; guidesCount: number | null }>({
    guidesList: [],
    guidesCount: null,
  });
  const [serviceAreaSelfTests, setServiceAreaSelfTests] = useState<{ selfTestsList: ContentItem[]; selfTestsCount: number | null }>({
    selfTestsList: [],
    selfTestsCount: null,
  });

  const { apiCall } = useApiCall();

  const getServiceAreaData = async () => {
    const { data } = await apiCall(commonEndpoints.serviceAreaInfo(serviceAreaId));
    setServiceAreaData(data);
  };

  const getServiceAreaContent = async () => {
    const articlesQuery = apiCall(commonEndpoints.search({ limit: PAGE_LIMIT }), {
      data: {
        object_content_type: [CONTENT_TYPES.ARTICLE],
        publish_status: [PUBLISH_STATUSES.PUBLISHED],
        service_area: [serviceAreaId],
        order_by: ['-is_sticky', '-updated_at'],
      },
    });
    const guidesQuery = apiCall(commonEndpoints.search({ limit: PAGE_LIMIT }), {
      data: {
        object_content_type: [CONTENT_TYPES.GUIDE],
        publish_status: [PUBLISH_STATUSES.PUBLISHED],
        service_area: [serviceAreaId],
        order_by: ['-is_sticky', '-updated_at'],
      },
    });
    const selfTestsQuery = apiCall(commonEndpoints.search({ limit: PAGE_LIMIT }), {
      data: {
        object_content_type: [CONTENT_TYPES.SELF_TEST],
        publish_status: [PUBLISH_STATUSES.PUBLISHED],
        service_area: [serviceAreaId],
        order_by: ['-is_sticky', '-updated_at'],
      },
    });
    const [articles, guides, selfTests] = await Promise.all([articlesQuery, guidesQuery, selfTestsQuery]);
    setServiceAreaArticles({ articlesList: parseContentItemForFE(articles.data.results), articlesCount: articles.data.count });
    setServiceAreaGuides({ guidesList: parseContentItemForFE(guides.data.results), guidesCount: guides.data.count });
    setServiceAreaSelfTests({ selfTestsList: parseContentItemForFE(selfTests.data.results), selfTestsCount: selfTests.data.count });
  };

  useEffect(() => {
    getServiceAreaData();
    getServiceAreaContent();
    window.scrollTo(0, 0);
  }, [serviceAreaId]);

  const redirectToFullListButton = (pathname: string, contentType: ContentTypeEnum) => (
    <RedirectButton
      message={t(general_messages.service_area_redirect_button_label, {
        service_area: serviceAreaData?.name,
        content_type: contentType,
      })}
      pathname={pathname}
      state={{ preselectedFilters: { service_area: [Number(serviceAreaId)] } }}
    />
  );

  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {safeT(t, serviceAreaData?.name_translation_key, serviceAreaData?.name)}
        </Typography>
      </Jumbotron>
      <MobileGuttersContainer>
        {serviceAreaData?.description && (
          <DescriptionText className={styles.description} component='div'>
            {parse(safeT(t, serviceAreaData.description_translation_key, serviceAreaData.description))}
          </DescriptionText>
        )}
        {serviceAreaSelfTests?.selfTestsList.length ? (
          <>
            <Section title={t(general_messages.content_types.assessment)}>
              <div className={styles.guides}>
                {serviceAreaSelfTests?.selfTestsList.map(test => (
                  <SelfTestTile key={test.id} data={test} />
                ))}
              </div>
            </Section>
            {serviceAreaSelfTests?.selfTestsCount &&
              serviceAreaSelfTests.selfTestsCount > PAGE_LIMIT &&
              redirectToFullListButton(PATHS.SELF_TESTS, t(contentTypeResolver(CONTENT_TYPES.SELF_TEST, true)))}
          </>
        ) : null}
        {serviceAreaArticles?.articlesList.length ? (
          <>
            <Section smallPadding title={t(general_messages.content_types.article)}>
              <ArticleTilesGrid data={serviceAreaArticles?.articlesList} placeholdersNumber={PAGE_LIMIT} scrollable />
            </Section>
            {serviceAreaArticles.articlesCount &&
              serviceAreaArticles.articlesCount > PAGE_LIMIT &&
              redirectToFullListButton(PATHS.ARTICLES, t(contentTypeResolver(CONTENT_TYPES.ARTICLE, true)))}
          </>
        ) : null}
        {serviceAreaGuides?.guidesList.length ? (
          <>
            <Section title={t(general_messages.content_types.guide)}>
              <div className={styles.guides}>
                {serviceAreaGuides?.guidesList.map(guide => (
                  <ContentGuideTile key={guide.id} data={guide} />
                ))}
              </div>
            </Section>
            {serviceAreaGuides?.guidesCount &&
              serviceAreaGuides?.guidesCount > PAGE_LIMIT &&
              redirectToFullListButton(PATHS.GUIDES, t(contentTypeResolver(CONTENT_TYPES.GUIDE, true)))}
          </>
        ) : null}
      </MobileGuttersContainer>
    </Container>
  );
};

export default ServiceAreaPage;
