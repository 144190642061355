import { isNil } from 'lodash';

export type ParamsType = Record<string, string | string[] | number | number[] | boolean>;

const createUrlWithParams = (url: string, rawParams?: ParamsType, removeEmpty = true) => {
  const params = { ...(rawParams || {}) };
  if (!url) throw new Error('Url is required');
  if (!params) return url;

  Object.entries(params).forEach(([key, value]) => {
    if (removeEmpty && isNil(value)) delete params[key];
    if (typeof value === 'number' || typeof value === 'boolean') params[key] = value.toString();
    if (Array.isArray(value)) params[key] = value.join(',');
  });

  if (!Object.keys(params).length) return url;

  const urlParams = new URLSearchParams(params as Record<string, string>).toString();
  return `${url}?${urlParams}`;
};

export default createUrlWithParams;
