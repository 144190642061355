import assetInformationChoicesApi from 'config/api/assetInformationChoices/assetInformationChoices';
import { AssetInformationFilters } from 'config/api/assetInformationChoices/assetInformationChoices.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import table_messages from 'messages/table_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const confidentialityFilter: DynamicTableFilter<AssetInformationFilters> = {
  isDynamic: true,
  sectionName: table_messages.assets_filters.confidentiality,
  queryFn: assetInformationChoicesApi.getAssetInformationChoicesForFilters,
  dataParser: ({ confidentiality }) => confidentiality,
  isSingleSelect: false,
  queryKey: QUERY_KEYS.ASSETS_INFORMATION_CHOICES,
};

export default confidentialityFilter;
