import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.secondary[100]}`,
    borderRadius: '6px',
    padding: theme.spacing(0.5, 0.5, 0.5, 2),
    width: '100%',
    maxWidth: '460px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    display: 'grid',
    gridGap: theme.spacing(0.5),
    padding: theme.spacing(1, 0),
  },
  link: {
    color: theme.palette.text.secondaryColor,
    fontSize: '0.875rem',
    textDecoration: 'none',
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  icon: {
    color: theme.palette.text.secondaryColor,
  },
}));
