import axios from 'axios';

import { parseAssetInformationChoicesForFE, parseAssetInformationChoicesForFilters } from './assetInformationChoices.parsers';
import type AssetInformationChoicesApi from './assetInformationChoices.types';

const endpoint = 'api/v1/asset_information_choices/';

const assetInformationChoicesApi: AssetInformationChoicesApi = {
  getAssetInformationChoices: async () => {
    const { data } = await axios.get(endpoint);
    return parseAssetInformationChoicesForFE(data);
  },
  getAssetInformationChoicesForFilters: async () => {
    const { data } = await axios.get(endpoint);
    return parseAssetInformationChoicesForFilters(data);
  },
};

export default assetInformationChoicesApi;
