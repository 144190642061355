import { AssetTaskStatus } from 'config/api/_types/AssetTaskStatus';
import assets_messages from 'messages/assets_messages';
import { StaticTableFilter } from 'types/TableFilter';

const filters: { id: AssetTaskStatus; name: string[] }[] = [
  { id: 'finished', name: assets_messages.asset_task_status.finished },
  { id: 'started', name: assets_messages.asset_task_status.started },
  { id: 'not_started', name: assets_messages.asset_task_status.not_started },
];

const assetTaskStatusFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: assets_messages.asset_tasks_table.status,
  filters,
  isSingleSelect: false,
};

export default assetTaskStatusFilter;
