import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  button: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:not(:last-child)': {
      borderBottom: `thin solid ${theme.palette.secondary.light}`,
    },
  },
  buttonDisabled: {
    color: theme.palette.grey[400],
    cursor: 'auto',
  },
}));
