import asset_risk_messages from 'messages/asset_risk_messages';

import { RiskPlace } from '../api/assetRisks/assetRisks.types';

const RISK_ANALYSIS_PLACE_SELECT: { key: RiskPlace; label: string[] }[] = [
  { key: 'external', label: asset_risk_messages.risk_place.external },
  { key: 'internal', label: asset_risk_messages.risk_place.internal },
  { key: 'third_party', label: asset_risk_messages.risk_place.third_party },
];

export default RISK_ANALYSIS_PLACE_SELECT;
