import { RiskDecision } from 'config/api/assetRisks/assetRisks.types';
import asset_risk_messages from 'messages/asset_risk_messages';
import { StaticTableFilter } from 'types/TableFilter';

const filters: { id: RiskDecision; name: string[] }[] = [
  { id: 'not_decided', name: asset_risk_messages.risk_decision.not_decided },
  { id: 'handle_risk', name: asset_risk_messages.risk_decision.handle_risk },
  { id: 'accepted_risk', name: asset_risk_messages.risk_decision.accepted_risk },
];

const riskDecisionFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: asset_risk_messages.risk_decision_filter_label,
  filters,
  isSingleSelect: false,
};

export default riskDecisionFilter;
