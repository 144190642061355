import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  expanded: {
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'none',
    },

    '& .MuiIconButton-root': {
      background: 'none',
    },
  },
  summaryContent: {
    display: 'grid',
    alignSelf: 'center',
  },
}));

export default useStyles;
