import React, { useEffect, useState } from 'react';

import { Radio, RadioGroup } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckboxWithInfo from 'components/CheckboxWithInfo/CheckboxWithInfo';
import meetingsEndpoints from 'config/api/meetings';
import useApiCall from 'hooks/useApiCall';
import meeting_messages from 'messages/meeting_messages';

import EditMeetingDialogGrid from '../_components/EditMeetingDialogGrid';

import useStyles from './EditMeetingByAdvisorChangeAdvisorDialog.styles';

const EditMeetingByAdvisorChangeAdvisorDialog = ({ meetingId, onClose, open, openBase, refreshData }) => {
  const { t } = useTranslation();
  const { apiCall, loading } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();

  const [availableAdvisors, setAvailableAdvisors] = useState([]);
  const getAvailableAdvisors = async () => {
    const { data } = await apiCall(meetingsEndpoints.getAvailableAdvisorsAsSupervisor(meetingId));
    setAvailableAdvisors(data.map(({ profile, ...rest }) => ({ label: `${profile.first_name} ${profile.last_name}`, ...rest })));
  };

  useEffect(() => {
    if (open) getAvailableAdvisors();
  }, [open]);

  const [advisor, setAdvisor] = useState(null);
  const onChange = ({ target }) => {
    setAdvisor(Number(target.value));
  };

  const onConfirm = async () => {
    const { status } = await apiCall(meetingsEndpoints.changeAdvisorAsSupervisor(meetingId), { data: { adviser_id: advisor } });
    if (status < 300) {
      enqueueSnackbar(t(...meeting_messages.edit_meeting_by_advisor.change_advisor.success_msg), { variant: 'success' });
      refreshData();
      onClose();
    }
  };

  const styles = useStyles();
  return (
    <EditMeetingDialogGrid
      disabled={!advisor || loading}
      loading={loading}
      onBack={openBase}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      title={t(...meeting_messages.edit_meeting_by_advisor.change_advisor.title)}
    >
      <RadioGroup className={styles.radioWrapper} name='advisor' onChange={onChange} value={advisor}>
        {availableAdvisors.map(({ id, label }) => (
          <CheckboxWithInfo control={<Radio value={Number(id)} />} label={label} selected={Number(id) === advisor} />
        ))}
        {!availableAdvisors.length && !loading && t(...meeting_messages.edit_meeting_by_advisor.change_advisor.no_advisors)}
      </RadioGroup>
    </EditMeetingDialogGrid>
  );
};

EditMeetingByAdvisorChangeAdvisorDialog.propTypes = {
  meetingId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openBase: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default EditMeetingByAdvisorChangeAdvisorDialog;
