import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  paddingSection: {
    padding: theme.spacing(4, 4, 0),
  },
  heading: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  section: {
    display: 'grid',
    gridGap: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.primary[200]}`,

    '&:last-child': {
      borderBottom: 'none',
    },
  },
  twoColumnSection: {
    display: 'grid',
    gridGap: theme.spacing(2),

    gridTemplateColumns: 'auto auto',
  },
  buttons: {
    padding: theme.spacing(0, 4, 4, 4),
  },
}));
