import React from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import MainMenuDesktop from 'components/Layout/_components/MainMenuDesktop/MainMenuDesktop';
import MainMenuMobile from 'components/Layout/_components/MainMenuMobile';
import LoaderProvider from 'reactContext/LoaderContext';
import PATHS from 'router/PATHS';
import useLayoutStore from 'storages/layoutStore';
import { DRAWER_WIDTH } from 'styles/consts';

const EDITOR_PAGES = [
  PATHS.MANAGE_CONTENT,
  PATHS.EDIT_ARTICLE,
  PATHS.EDIT_GUIDE,
  PATHS.USERS,
  PATHS.MANAGE_MEETING_SLOTS,
  PATHS.ADVISORY_PAGE,
  PATHS.ORGANIZATION_ADMINISTRATION,
  PATHS.WORKPLACES,
];

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingLeft: ({ isMenuOpen }) => (isMenuOpen ? DRAWER_WIDTH.OPEN : DRAWER_WIDTH.CLOSED),
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: () => 0,
    },
  },
  main: {
    flexGrow: 1,
    paddingBottom: theme.spacing(5),
    position: 'relative',
    minHeight: `100vh`,
    paddingTop: ({ showTopBar }) => showTopBar && '10px',
    [theme.breakpoints.down('sm')]: {
      ...theme.mixins.toolbarPadding,
    },
    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '80%',
      height: '10px',
      margin: '0 auto',
      top: 0,
      left: '50%',
      transitionDuration: theme.transitions.duration.standard,
      transitionTimingFunction: theme.transitions.easing.easeIn,
      transform: ({ showTopBar }) => (showTopBar ? 'translate(-50%, 0)' : 'translate(-50%, -120%)'),
      background: theme.palette.orange,
      borderBottomLeftRadius: theme.shape.borderRadiusLarge,
      borderBottomRightRadius: theme.shape.borderRadiusLarge,
    },
  },
}));

const showTopBar = pathname => EDITOR_PAGES.some(editorPage => editorPage === pathname);

const Layout = ({ children }) => {
  const { isMenuOpen } = useLayoutStore();
  const { pathname } = useLocation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

  const styles = useStyles({ showTopBar: showTopBar(pathname) && upMd, isMenuOpen });
  return (
    <div className={styles.wrapper}>
      <LoaderProvider>
        <>
          {upMd ? <MainMenuDesktop /> : <MainMenuMobile />}
          <ErrorBoundary>
            <main className={styles.main}>{children}</main>
          </ErrorBoundary>
        </>
      </LoaderProvider>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
