import React from 'react';

import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import ContentPage from 'pages/_common/ContentPage';
import audienceFilter from 'services/_filters/parsers/audienceFilter';
import customerLevelFilter from 'services/_filters/parsers/customerLevelFilter';
import depthLevelFilter from 'services/_filters/parsers/depthLevelFilter';
import getTagFilter from 'services/_filters/parsers/getTagFilter';
import serviceAreaFilter from 'services/_filters/parsers/serviceAreaFilter';

import preparePreselectedFilters from './_services/preparePreselectedFilters';

const getAvailableFiltersUrlsForArticles = () => ({
  serviceAreaFilter,
  tagFilter: getTagFilter('article'),
});

const staticFilters = {
  audience: audienceFilter,
  customer_level: customerLevelFilter,
  depth_level: depthLevelFilter,
};

const ArticlesPage = () => (
  <ContentPage
    contentType={CONTENT_TYPES.ARTICLE}
    filtersGetter={getAvailableFiltersUrlsForArticles}
    preparePreselectedFilters={preparePreselectedFilters}
    staticFilters={staticFilters}
  />
);

export default ArticlesPage;
