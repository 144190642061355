import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
    width: '100%',
  },
}));
