import assetCategoriesApi from 'config/api/assetCategories/assetCategories';
import table_messages from 'messages/table_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const assetsCategoryFilter: DynamicTableFilter = {
  isDynamic: true,
  sectionName: table_messages.assets_filters.assets_category,
  isSingleSelect: false,
  queryFn: assetCategoriesApi.getAllAssetCategoriesForFilters,
};

export default assetsCategoryFilter;
