import convertObjectToFormData from 'helpers/convertObjectToFormData';

import {
  WorkplaceDocument,
  WorkplaceDocumentsCreateDTO,
  WorkplaceDocumentsFormData,
  WorkplaceDocumentDTO,
} from './workplaceDocuments.types';

export const parseAllWorkplaceDocumentsForFE = (raw: WorkplaceDocumentDTO[]): WorkplaceDocument[] => raw;

export const parseSingleWorkplaceDocumentsForFE = (raw: WorkplaceDocumentDTO): WorkplaceDocument => raw;

export const parseWorkplaceDocumentToCreate = (formObject: WorkplaceDocumentsFormData): WorkplaceDocumentsCreateDTO =>
  convertObjectToFormData(formObject);
