import React from 'react';

import { Container } from '@material-ui/core';

import GuidesTilesGrid from 'components/GuideTilesGrid/GuidesTilesGrid';
import SelfTestsTilesGrid from 'components/SelfTestsTilesGrid/SelfTestsTilesGrid';
import type { ContentItem } from 'config/api/selfTests/_types';
import { ContentTypeEnum } from 'config/api/selfTests/_types';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';

import useStyles from './RelatedContent.styles';

type Props = {
  content: ContentItem[];
  type: ContentTypeEnum;
};

const RelatedContent: React.FC<Props> = ({ content, type }) => {
  const styles = useStyles();
  if (!content?.length) return null;

  const resolveContent = () => {
    if (type === CONTENT_TYPES.GUIDE) {
      return <GuidesTilesGrid data={content} />;
    }
    if (type === CONTENT_TYPES.SELF_TEST) {
      return <SelfTestsTilesGrid data={content} />;
    }
    throw Error('Incorrect type');
  };

  return (
    <section className={styles.section}>
      <Container maxWidth='lg'>{resolveContent()}</Container>
    </section>
  );
};

export default RelatedContent;
