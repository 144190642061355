import React, { useMemo, useState } from 'react';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Skeleton } from '@material-ui/lab';
import { format, isToday, isTomorrow } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BookMeetingDetailsDialog from 'components/_dialogs/BookMeetingDetailsDialog';
import BookMeetingDialog from 'components/_dialogs/BookMeetingDialog';
import EditMeetingByAdvisorAbsentClientDialog from 'components/_dialogs/EditMeetingDialogs/EditMeetingByAdvisorAbsentClientDialog';
import EditMeetingByAdvisorBaseDialog from 'components/_dialogs/EditMeetingDialogs/EditMeetingByAdvisorBaseDialog';
import EditMeetingByAdvisorCancelDialog from 'components/_dialogs/EditMeetingDialogs/EditMeetingByAdvisorCancelDialog';
import EditMeetingByAdvisorChangeAdvisorDialog from 'components/_dialogs/EditMeetingDialogs/EditMeetingByAdvisorChangeAdvisorDialog';
import EditMeetingByAdvisorChangeDateDialog from 'components/_dialogs/EditMeetingDialogs/EditMeetingByAdvisorChangeDateDialog';
import ColoredTile from 'components/ColoredTile/ColoredTile';
import { ADVISOR_EDIT_DIALOGS, CUSTOMER_EDIT_DIALOGS } from 'config/constants/EDIT_MEETING_DIALOG_TYPES';
import ROLES from 'config/constants/ROLES';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';
import PATHS from 'router/PATHS';

const MeetingReportTimeTile = ({ advisor, meetingId, time, readOnly, advisorId, refreshData, reportExists, isMine }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isCustomer = usePermissions([ROLES.PS_ROLES.SECURITY_SUPERVISOR, ROLES.PS_ROLES.SECURITY_ONLINE]).every(role => !role);

  const date = useMemo(() => new Date(time), [time]);

  const getTitle = () => {
    if (!time) return <Skeleton height='1.2rem' width='120px' />;
    let day;
    if (isToday(date)) day = t(...general_messages.today);
    else if (isTomorrow(date)) day = t(...general_messages.tomorrow);
    else day = format(date, 'yyyy-MM-dd ');
    return `${day} @ ${format(date, 'H:mm')}`;
  };

  const [dialog, setDialog] = useState(null);

  const closeDialog = () => setDialog(null);

  const openEditCustomer = () => setDialog(CUSTOMER_EDIT_DIALOGS.EDIT);
  const openAdvisorBase = () => setDialog(ADVISOR_EDIT_DIALOGS.BASE);

  const onTileClick = () => {
    if (!readOnly) {
      if (isCustomer) setDialog(CUSTOMER_EDIT_DIALOGS.BASE);
      else openAdvisorBase();
    }
  };

  const onCancel = () => {
    enqueueSnackbar(t(...meeting_messages.edit_meeting_by_advisor.cancel.success_msg), { variant: 'success' });
    if (isCustomer) {
      history.push(PATHS.ROOT);
    } else {
      history.push(PATHS.ADVISORY_PAGE);
    }
  };

  return (
    <>
      <ColoredTile
        color='primary'
        description={`${advisor.first_name} ${advisor.last_name}`}
        disableRipple={readOnly}
        onClick={onTileClick}
        title={getTitle()}
      >
        {!readOnly && <EditOutlinedIcon />}
      </ColoredTile>
      <EditMeetingByAdvisorBaseDialog
        date={date}
        isMine={isMine}
        onClose={closeDialog}
        open={dialog === ADVISOR_EDIT_DIALOGS.BASE}
        openAnother={setDialog}
      />
      <EditMeetingByAdvisorChangeAdvisorDialog
        meetingId={meetingId}
        onClose={closeDialog}
        open={dialog === ADVISOR_EDIT_DIALOGS.ADVISOR}
        openBase={openAdvisorBase}
        refreshData={refreshData}
      />
      <EditMeetingByAdvisorChangeDateDialog
        advisorId={advisorId}
        meetingId={meetingId}
        onClose={closeDialog}
        open={dialog === ADVISOR_EDIT_DIALOGS.DATE}
        openBase={openAdvisorBase}
        refreshData={refreshData}
      />
      <EditMeetingByAdvisorAbsentClientDialog
        meetingId={meetingId}
        onClose={closeDialog}
        open={dialog === ADVISOR_EDIT_DIALOGS.ABSENT}
        openBase={openAdvisorBase}
        refreshData={refreshData}
        reportExists={reportExists}
      />
      <EditMeetingByAdvisorCancelDialog
        meetingId={meetingId}
        onCancel={onCancel}
        onClose={closeDialog}
        open={dialog === ADVISOR_EDIT_DIALOGS.CANCEL}
        openBase={openAdvisorBase}
      />
      <BookMeetingDialog
        date={new Date(time)}
        id={meetingId}
        isEdit
        onClose={closeDialog}
        open={dialog === CUSTOMER_EDIT_DIALOGS.EDIT}
        refresh={refreshData}
      />
      <BookMeetingDetailsDialog
        id={meetingId}
        onCancel={onCancel}
        onClose={closeDialog}
        open={dialog === CUSTOMER_EDIT_DIALOGS.BASE}
        openBookDialogFromDetails={openEditCustomer}
      />
    </>
  );
};

MeetingReportTimeTile.propTypes = {
  advisor: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  meetingId: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  advisorId: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  reportExists: PropTypes.bool.isRequired,
  isMine: PropTypes.bool.isRequired,
};

export default MeetingReportTimeTile;
