import { RiskScaleValue } from 'config/api/assetRisks/assetRisks.types';
import asset_risk_messages from 'messages/asset_risk_messages';
import { StaticTableFilter } from 'types/TableFilter';

const filters: { id: RiskScaleValue; name: string }[] = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
];

const riskQuadrantFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: asset_risk_messages.risk_table.calculated_risk_filter,
  filters,
  isSingleSelect: true,
};

export default riskQuadrantFilter;
