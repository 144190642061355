import QUESTION_STATUSES from 'config/constants/QUESTION_STATUSES';
import general_messages from 'messages/general_messages';
import questions_messages from 'messages/questions_messages';

const { status } = questions_messages;

const TRANSLATABLE_QUESTION_STATUSES_FALLBACKS = {
  ANSWERED: status.answered[1],
  UNANSWERED: status.unanswered[1],
  DRAFT: status.draft[1],
};

const TRANSLATABLE_QUESTION_STATUSES = {
  ANSWERED: [status.answered[0], TRANSLATABLE_QUESTION_STATUSES_FALLBACKS.ANSWERED],
  UNANSWERED: [status.unanswered[0], TRANSLATABLE_QUESTION_STATUSES_FALLBACKS.UNANSWERED],
  DRAFT: [status.draft[0], TRANSLATABLE_QUESTION_STATUSES_FALLBACKS.DRAFT],
};

export const QUESTION_STATUSES_FILTER = [
  { id: 'unanswered', name: TRANSLATABLE_QUESTION_STATUSES.UNANSWERED },
  { id: 'draft', name: TRANSLATABLE_QUESTION_STATUSES.DRAFT },
  { id: 'answered', name: TRANSLATABLE_QUESTION_STATUSES.ANSWERED },
];

export const questionStatusResolver = statusKey => {
  switch (statusKey) {
    case QUESTION_STATUSES.ANSWERED:
      return TRANSLATABLE_QUESTION_STATUSES.ANSWERED;
    case QUESTION_STATUSES.UNANSWERED:
      return TRANSLATABLE_QUESTION_STATUSES.UNANSWERED;
    case QUESTION_STATUSES.DRAFT:
      return TRANSLATABLE_QUESTION_STATUSES.DRAFT;
    default:
      return general_messages.no_data;
  }
};

export default TRANSLATABLE_QUESTION_STATUSES;
