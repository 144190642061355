import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  textLimitation: {
    // @ts-ignore
    maxWidth: theme.sizes.container.sm,
  },
}));
