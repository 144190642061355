import React from 'react';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormTextInput from 'components/FormTextInput';
import METHOD from 'config/api/_methods';
import meetingsEndpoints from 'config/api/meetings';
import useApiCall from 'hooks/useApiCall';
import meeting_messages from 'messages/meeting_messages';
import validation_messages from 'messages/validation_messages';

import EditMeetingDialogGrid from '../_components/EditMeetingDialogGrid';

const EditMeetingByAdvisorAbsentClientDialog = ({ meetingId, onClose, open, openBase, reportExists, refreshData }) => {
  const { t } = useTranslation();
  const { apiCall, loading } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: yup.object({
      comment: yup.string().required(t(...validation_messages.required)),
    }),
  });

  const onConfirm = async () => {
    const method = reportExists ? METHOD.PATCH : METHOD.POST;
    const { status } = await apiCall(meetingsEndpoints.absentCustomerAsAdviser(meetingId, method), {
      data: { absent_actions_taken: formik.values.comment },
    });
    if (status < 300) {
      enqueueSnackbar(t(...meeting_messages.edit_meeting_by_advisor.absent.success_msg), { variant: 'success' });
      refreshData();
      onClose();
    }
  };

  return (
    <EditMeetingDialogGrid
      disabled={!formik.isValid || !formik.dirty}
      loading={loading}
      onBack={openBase}
      onClose={onClose}
      onConfirm={onConfirm}
      open={open}
      title={t(...meeting_messages.edit_meeting_by_advisor.absent.title)}
    >
      <FormTextInput formik={formik} id='comment' label={t(...meeting_messages.edit_meeting_by_advisor.absent.comment_label)} />
    </EditMeetingDialogGrid>
  );
};

EditMeetingByAdvisorAbsentClientDialog.propTypes = {
  meetingId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openBase: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  reportExists: PropTypes.bool.isRequired,
};

export default EditMeetingByAdvisorAbsentClientDialog;
