import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import questionsEndpoints from 'config/api/questions';
import usePagination from 'hooks/usePagination';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';
import { COMMON_DIALOG_TYPES, useCommonDialogsContext } from 'reactContext/CommonDialogsContext';
import getDataMappingForQuestions from 'services/getDataMappingForQuestions/getDataMappingForQuestions';

const MeetingAdvicesPageQuestionsTable = () => {
  const { t } = useTranslation();
  const { openCommonDialog } = useCommonDialogsContext();

  const [sortQuestions, setSortQuestions] = useState({ key: null, direction: null });
  const onSortQuestions = (key, direction) => setSortQuestions({ key, direction });

  const {
    data: questionsData,
    hasNextPage: questionsHasNextPage,
    loading: questionsLoading,
    getData: questionsGetData,
  } = usePagination({
    endpointFunc: questionsEndpoints.getQuestionsSecurityOfficer,
    limits: [5, 5, 5],
    dependencies: [sortQuestions],
    sort: sortQuestions,
    isGet: true,
  });

  const onQuestionClick = (e, data) => {
    openCommonDialog(COMMON_DIALOG_TYPES.QUESTION_OVERVIEW, { questionData: data });
  };

  return (
    <Section paddingTop={4} smallPadding title={t(...meeting_messages.questions_title)} titleVariant='h3'>
      <Table
        data={questionsData}
        dataMapping={getDataMappingForQuestions(t)}
        onRowClick={onQuestionClick}
        onSort={onSortQuestions}
        sort={sortQuestions}
      />
      {questionsHasNextPage && (
        <FooterButton loading={questionsLoading} onClick={questionsGetData}>
          {t(...general_messages.show_more)}
        </FooterButton>
      )}
    </Section>
  );
};

export default MeetingAdvicesPageQuestionsTable;
