import GUIDE_STATUSES from 'config/constants/GUIDE_STATUSES';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';

const { status } = guide_messages;

const TRANSLATABLE_GUIDE_STATUSES_FALLBACKS = {
  IN_PROGRESS: status.in_progress[1],
  COMPLETED: status.completed[1],
};

const TRANSLATABLE_GUIDE_STATUSES = {
  IN_PROGRESS: [status.in_progress[0], TRANSLATABLE_GUIDE_STATUSES_FALLBACKS.IN_PROGRESS],
  COMPLETED: [status.completed[0], TRANSLATABLE_GUIDE_STATUSES_FALLBACKS.COMPLETED],
};

export const GUIDE_STATUSES_FILTER = [
  { id: 'in_progress', name: TRANSLATABLE_GUIDE_STATUSES.IN_PROGRESS },
  { id: 'completed', name: TRANSLATABLE_GUIDE_STATUSES.COMPLETED },
];

export const guideStatusResolver = statusKey => {
  switch (statusKey) {
    case GUIDE_STATUSES.IN_PROGRESS:
      return TRANSLATABLE_GUIDE_STATUSES.IN_PROGRESS;
    case GUIDE_STATUSES.COMPLETED:
      return TRANSLATABLE_GUIDE_STATUSES.COMPLETED;
    default:
      return general_messages.no_data;
  }
};

export default TRANSLATABLE_GUIDE_STATUSES;
