import React from 'react';

import { IconButton, makeStyles } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.common.white,
  },
}));

type Props = {
  id: string | number;
};

const SnackbarCloseButton: React.FC<Props> = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  const onClick = () => closeSnackbar(id);

  const styles = useStyles();
  return (
    <IconButton onClick={onClick} size='small'>
      <IconClose className={styles.icon} fontSize='small' />
    </IconButton>
  );
};

export default SnackbarCloseButton;
