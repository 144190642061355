import { SnackbarProviderProps } from 'notistack';

const snackBarConfig: Omit<SnackbarProviderProps, 'children'> = {
  hideIconVariant: true,
  preventDuplicate: true,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export default snackBarConfig;
