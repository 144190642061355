import { intersection } from 'lodash';

import OrganizationPaymentStages from './OrganizationPaymentStages';
import ProtectedFeatures from './ProtectedFeatures';
import RolesOrganization from './RolesOrganization';
import RolesPocketSafe from './RolesPocketSafe';

type Roles = {
  pocketSafe?: RolesPocketSafe[];
  organization?: RolesOrganization[];
  paymentStage?: OrganizationPaymentStages[];
};

type CheckProtectedFeatureAccess = (feature: ProtectedFeatures, roles: Roles) => { hasAccess: boolean };

/**
 * Function to check if particular feature is accessible for user
 * @param feature
 * @param roles
 */
const checkProtectedFeatureAccess: CheckProtectedFeatureAccess = (feature, roles) => {
  switch (feature) {
    case ProtectedFeatures.AskQuestion: {
      const allowedRoles = [RolesOrganization.SecurityOfficer];
      return { hasAccess: !!intersection(allowedRoles, roles.organization).length };
    }
    case ProtectedFeatures.MyOrganizationSections: {
      const allowedStages = [OrganizationPaymentStages.cardValid, OrganizationPaymentStages.trial, OrganizationPaymentStages.cardInvalid];
      return { hasAccess: !!intersection(allowedStages, roles.paymentStage).length };
    }
    case ProtectedFeatures.BookMeeting: {
      const allowedStages = [OrganizationPaymentStages.cardValid];
      return { hasAccess: !!intersection(allowedStages, roles.paymentStage).length };
    }
    case ProtectedFeatures.MyOrganizationInfo: {
      const allowedRoles = [RolesOrganization.AccountOwner];
      return { hasAccess: !!intersection(allowedRoles, roles.organization).length };
    }
    case ProtectedFeatures.EmergencyButton: {
      const allowedStages = [OrganizationPaymentStages.cardValid, OrganizationPaymentStages.trial, OrganizationPaymentStages.cardInvalid];
      const allowedRoles = [RolesOrganization.EmergencyButton];
      return {
        hasAccess: !!intersection(allowedRoles, roles.organization).length && !!intersection(allowedStages, roles.paymentStage).length,
      };
    }
    default:
      throw Error(`Incorrect feature type: ${feature}`);
  }
};

export default checkProtectedFeatureAccess;
