import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    fontSize: '1.5rem',
    lineHeight: '2.1rem',
    marginBottom: '2rem',
    fontFamily: theme.typography.fontFamilyArticle,
  },
}));
