import parse from 'html-react-parser';

import parseAnchor from 'services/htmlParse/parseAnchor';

const extractAnchorsFromHTML = (html, anchorTag = 'h2') => {
  if (!html) return null;
  const parsedHtml = parse(html);
  const nodes = Array.isArray(parsedHtml) ? parsedHtml : [parsedHtml];
  return nodes.filter(({ type }) => type === anchorTag).map(({ props }) => ({ label: props.children, key: parseAnchor(props.children) }));
};

export default extractAnchorsFromHTML;
