/**
 *
 * @param id
 * @param name
 * @param zeroIndexed = should start from 0
 * @param nameIsId
 * @returns {{name: *, id: *}[]}
 */

const resolveId = (id, name, zeroIndexed, nameIsId) => {
  if (!zeroIndexed && !nameIsId) return id + 1;
  if (nameIsId) return name[2].toLowerCase();
  return id;
};

const prepareDataForFilter = (object, zeroIndexed = true, nameIsId = false) =>
  Object.values(object).map((name, id) => ({ id: resolveId(id, name, zeroIndexed, nameIsId), name }));
export default prepareDataForFilter;
