import general_messages from 'messages/general_messages';

const EMPLOYEES_NUMBER = {
  ONE_MAN: 'one_man',
  MICRO: 'micro',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const TRANSLATABLE_EMPLOYEES_NUMBER_FALLBACKS = {
  [EMPLOYEES_NUMBER.ONE_MAN]: general_messages.employees_number.one_man[1],
  [EMPLOYEES_NUMBER.MICRO]: general_messages.employees_number.micro[1],
  [EMPLOYEES_NUMBER.SMALL]: general_messages.employees_number.small[1],
  [EMPLOYEES_NUMBER.MEDIUM]: general_messages.employees_number.medium[1],
  [EMPLOYEES_NUMBER.LARGE]: general_messages.employees_number.large[1],
};

const TRANSLATABLE_EMPLOYEES_NUMBER = {
  [EMPLOYEES_NUMBER.ONE_MAN]: [
    general_messages.employees_number.one_man[0],
    TRANSLATABLE_EMPLOYEES_NUMBER_FALLBACKS[EMPLOYEES_NUMBER.ONE_MAN],
  ],
  [EMPLOYEES_NUMBER.MICRO]: [general_messages.employees_number.micro[0], TRANSLATABLE_EMPLOYEES_NUMBER_FALLBACKS[EMPLOYEES_NUMBER.MICRO]],
  [EMPLOYEES_NUMBER.SMALL]: [general_messages.employees_number.small[0], TRANSLATABLE_EMPLOYEES_NUMBER_FALLBACKS[EMPLOYEES_NUMBER.SMALL]],
  [EMPLOYEES_NUMBER.MEDIUM]: [
    general_messages.employees_number.medium[0],
    TRANSLATABLE_EMPLOYEES_NUMBER_FALLBACKS[EMPLOYEES_NUMBER.MEDIUM],
  ],
  [EMPLOYEES_NUMBER.LARGE]: [general_messages.employees_number.large[0], TRANSLATABLE_EMPLOYEES_NUMBER_FALLBACKS[EMPLOYEES_NUMBER.LARGE]],
};

export const TRANSLATABLE_EMPLOYEES_NUMBER_ARRAY = Object.entries(TRANSLATABLE_EMPLOYEES_NUMBER);

export const TRANSLATABLE_EMPLOYEES_NUMBER_DICTIONARY = TRANSLATABLE_EMPLOYEES_NUMBER_ARRAY.map(([key, label]) => ({ label, key }));

export const employeesNumberResolver = value => {
  if (value) return TRANSLATABLE_EMPLOYEES_NUMBER[value];
  return general_messages.no_data;
};

export default TRANSLATABLE_EMPLOYEES_NUMBER;
