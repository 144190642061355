import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import MenuElementsList from 'components/Layout/_components/MenuElementsList/MenuElementsList';
import MenuSection from 'components/Layout/_components/MenuSection';
import checkAccess from 'config/permissions/checkAccess';
import compareWithRoutingPermissions from 'config/permissions/compareWithRoutingPermissions';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';
import menu_messages from 'messages/menu_messages';
import PATHS from 'router/PATHS';
import userDataStorage from 'storages/userDataStorage';

import menuElements from '../menuElements';

const OrganizationSection = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { ps_roles, roles, workplace_id } = userDataStorage.get() || { ps_roles: [], roles: [] };

  const { stage } = useOrganizationPaymentStageInfo();

  const elementsToDisplay = useMemo(() => {
    const userRoles = [...ps_roles, ...roles];
    const permissionChecked = menuElements.organization.filter(element => {
      if (element.to) {
        return compareWithRoutingPermissions(userRoles, element.to);
      }
      const allowedRoles = [...(element.allowedForOrganizationRoles || []), ...(element.allowedForPSRoles || [])];
      return checkAccess(userRoles, allowedRoles);
    });

    // edit workplace href when user has saved workplace
    const workplaceElementIndex = menuElements.organization.findIndex(({ to }) => to === PATHS.WORKPLACES);
    if (workplace_id && workplaceElementIndex !== -1) permissionChecked[workplaceElementIndex].to = `${PATHS.WORKPLACES}/${workplace_id}`;

    // check organization payment stage
    const result = permissionChecked.map(element => {
      const { allowedInStages } = element;
      if (!allowedInStages || !stage) {
        return element;
      }
      return { ...element, disabled: !checkAccess([stage], allowedInStages) };
    });

    return result;
  }, [ps_roles, roles, stage]);

  if (!elementsToDisplay.length) return null;

  return (
    // @ts-ignore
    <MenuSection title={t(...menu_messages.organization)}>
      <MenuElementsList elements={elementsToDisplay} />
    </MenuSection>
  );
};

export default OrganizationSection;
