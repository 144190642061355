import React from 'react';

import { Typography, TypographyProps } from '@material-ui/core';
import sanitizeHtml from 'sanitize-html';

type Props = TypographyProps & {
  children: string;
};

const TypographyWithHTML: React.FC<Props> = ({ children, ...props }) => (
  // eslint-disable-next-line react/no-danger
  <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(children) }} {...props} />
);

export default TypographyWithHTML;
