import asset_risk_messages from 'messages/asset_risk_messages';
import { StaticTableFilter } from 'types/TableFilter';

import booleanDictionary from '../../booleanDictionary';

const affectsIndividualsFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: asset_risk_messages.risk_table.affects_ppl_filter,
  filters: booleanDictionary,
  isSingleSelect: true,
};

export default affectsIndividualsFilter;
