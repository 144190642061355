import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  inline: {
    gridTemplateColumns: ({ length }) => `repeat(${length}, auto)`,
  },
  column: {},
}));

// TODO use it everywhere
const FormButtons = ({ children, inline }) => {
  const styles = useStyles({ length: children.length, inline });
  return <div className={clsx(styles.wrapper, inline ? styles.inline : styles.column)}>{children}</div>;
};

FormButtons.propTypes = {
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool,
};

FormButtons.defaultProps = {
  inline: false,
};

export default FormButtons;
