import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tile: {
    // @ts-ignore
    border: `thin solid ${theme.palette.secondary[100]}`,
    // @ts-ignore
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(1.25, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accordionTile: {
    // @ts-ignore
    border: `thin solid ${theme.palette.secondary[100]}`,
    // @ts-ignore
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(0.75, 2),
    '& .MuiAccordionSummary-content': {
      margin: '0',
    },
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    marginRight: 0,
  },
  label: {
    flexGrow: 1,
  },
  selected: {
    // @ts-ignore
    background: theme.palette.secondary[50],
  },
  tooltip: {
    maxWidth: '180px',
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: theme.palette.text.secondary,
    fontSize: '.825rem',
  },
  icon: {
    // @ts-ignore
    fill: theme.palette.secondary[900],
  },
  expanded: {
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'none',
    },
  },
}));

export default useStyles;
