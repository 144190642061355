import React from 'react';

import { Typography } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ColoredButton from 'components/ColoredButton';
import DescriptionText from 'components/DescriptionText';
import FormAutocomplete from 'components/FormAutocomplete';
import FormTextInput from 'components/FormTextInput';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import meeting_messages from 'messages/meeting_messages';
import PATHS from 'router/PATHS';
import relatedContentOptionsParser from 'services/autocompleteServices/relatedContentOptionsParser';
import relatedContentParamsGetter from 'services/autocompleteServices/relatedContentParamsGetter';
import relatedContentResultComponent from 'services/autocompleteServices/relatedContentResultComponent';

import useStyles from './MeetingReportAgendaItem.styles';

const MeetingReportAgendaItem = ({ isIncidentReport, id, formik, title, description, report_id, readOnly }) => {
  const { t } = useTranslation();

  const styles = useStyles();
  const renderContentComponent = () => {
    const organizationInput = get(formik.values, `${id}.organization_input`);
    const advice = get(formik.values, `${id}.advice`);
    const relatedContent = get(formik.values, `${id}.related_content`);
    if (readOnly) {
      return (
        <div className={styles.content}>
          {organizationInput && (
            <div>
              <Typography className={styles.bold} component='span'>{`${t(...meeting_messages.organization_input_label)}: `}</Typography>
              <DescriptionText component='p'>{get(formik.values, `${id}.organization_input`)}</DescriptionText>
            </div>
          )}
          {advice && (
            <div>
              <Typography className={styles.bold} component='span'>{`${t(...meeting_messages.my_advice_label)}: `}</Typography>
              <DescriptionText component='p'>{get(formik.values, `${id}.advice`)}</DescriptionText>
            </div>
          )}
          {relatedContent?.length ? (
            <div>
              <Typography className={styles.bold} component='span'>{`${t(...general_messages.related_content)}: `}</Typography>
              <ul className={styles.linkContainer}>
                {relatedContent.map(({ object_content_type, key, label, friendly_id, metadata }) => {
                  const href =
                    object_content_type === CONTENT_TYPES.ARTICLE
                      ? `${PATHS.ARTICLES}/${key}`
                      : `${PATHS.GUIDES}/${key}/${metadata?.version_id}`;
                  return (
                    <li key={key}>
                      <DescriptionText className={styles.link} component={Link} rel='noreferrer' target='_blank' to={href}>
                        {`${label} (${friendly_id})`}
                      </DescriptionText>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <>
        <FormTextInput
          formik={formik}
          id={`${id}.organization_input`}
          label={t(...meeting_messages.organization_input_label)}
          multiline
          rows={3}
        />
        <FormTextInput formik={formik} id={`${id}.advice`} label={t(...meeting_messages.my_advice_label)} multiline rows={3} />
        <FormAutocomplete
          apiCallParamsGetter={relatedContentParamsGetter}
          color='secondary'
          customizeLabel={relatedContentResultComponent}
          formik={formik}
          id={`${id}.related_content`}
          label={t(...general_messages.related_content)}
          labelClassName={styles.autocompleteLabel}
          optionsParser={relatedContentOptionsParser}
        />
      </>
    );
  };
  return (
    <>
      <div className={styles.grid}>
        {isIncidentReport ? (
          <div className={styles.incidentReportHeaderWrapper}>
            <div className={styles.incidentReportHeaderTitle}>
              <Typography className={styles.bold} component='h5' variant='h5'>
                {title}
              </Typography>
              <DescriptionText component='div'>{description}</DescriptionText>
            </div>
            <ColoredButton
              component={Link}
              customColor='secondary'
              target='_blank'
              to={`${PATHS.INCIDENT_REPORT}/${report_id}?redirect_url=${PATHS.ADVISORY_PAGE}&readOnly=true`}
            >
              {t(...guide_messages.open_report)}
            </ColoredButton>
          </div>
        ) : (
          <>
            <Typography className={styles.bold} component='h5' variant='h5'>
              {title}
            </Typography>
            <DescriptionText component='div'>{description}</DescriptionText>
          </>
        )}
        {renderContentComponent()}
      </div>
      <div className={styles.divider} />
    </>
  );
};

MeetingReportAgendaItem.propTypes = {
  isIncidentReport: PropTypes.bool,
  id: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
  }).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  report_id: PropTypes.string,
  readOnly: PropTypes.bool,
};

MeetingReportAgendaItem.defaultProps = {
  isIncidentReport: false,
  report_id: null,
  description: null,
  readOnly: false,
};

export default MeetingReportAgendaItem;
