import React from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import asset_risk_messages from 'messages/asset_risk_messages';

import useStyles from './RiskAnalysisIntro.styles';

const RiskAnalysisIntro: React.FC = () => {
  const { t } = useTranslation();

  const styles = useStyles();
  return (
    <section>
      <Typography className={styles.title} variant='h3'>
        {t(asset_risk_messages.details_page.intro_title)}
      </Typography>
      <TypographyWithHTML className={styles.textLimitation}>{t(asset_risk_messages.details_page.intro_content)}</TypographyWithHTML>
    </section>
  );
};

export default RiskAnalysisIntro;
