import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  templateCard: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: 'auto 1fr',
    cursor: 'pointer',
  },
  inPreparation: {
    cursor: 'auto',
  },
}));
