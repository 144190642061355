import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(2, 0, 3, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const FormTitle = ({ children, rightAdornment }) => {
  const styles = useStyles();
  return (
    <div className={styles.title}>
      <Typography component='h2' variant='h4'>
        {children}
      </Typography>
      {rightAdornment}
    </div>
  );
};

FormTitle.propTypes = {
  children: PropTypes.node.isRequired,
  rightAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

FormTitle.defaultProps = {
  rightAdornment: null,
};

export default FormTitle;
