import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import ColoredTile from 'components/ColoredTile/ColoredTile';
import meetingsEndpoints from 'config/api/meetings';
import ROLES from 'config/constants/ROLES';
import locale from 'config/locale';
import OrganizationPaymentStages from 'config/permissions/OrganizationPaymentStages';
import useApiCall from 'hooks/useApiCall';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';
import usePermissions from 'hooks/usePermissions';
import meeting_messages from 'messages/meeting_messages';
import PATHS from 'router/PATHS';
import userDataStorage from 'storages/userDataStorage';

import useStyles from './UpcomingMeetingsDashboardFeed.styles';

const UpcomingMeetingsDashboardFeed = () => {
  const { t, i18n } = useTranslation();
  const { apiCall } = useApiCall();
  const [meetingsData, setMeetingsData] = useState(null);
  const [isSecurityOfficer] = usePermissions([ROLES.ORGANIZATION.SECURITY_OFFICER]);
  const { stage } = useOrganizationPaymentStageInfo();
  const { id: adviser } = userDataStorage.get() || {};

  const getData = async () => {
    const { data } = await apiCall(
      meetingsEndpoints.getMeetingsAsSecurityOfficer({ time_slot__begin__gte: new Date().toISOString(), adviser }),
    );
    setMeetingsData(data);
  };

  useEffect(() => {
    const allowedStage = [OrganizationPaymentStages.trial, OrganizationPaymentStages.noCard, OrganizationPaymentStages.cardValid];
    if (isSecurityOfficer && allowedStage.includes(stage)) getData();
  }, [isSecurityOfficer, stage]);

  const getHref = ({ id }) => `${PATHS.MEETING_REPORT}/${id}`;

  const styles = useStyles({ isEmpty: !meetingsData?.length });
  return (
    <section className={styles.container}>
      <div className={styles.meetingsContainer}>
        {meetingsData?.length
          ? meetingsData.map(({ begin, ...rest }) => {
              const date = format(new Date(begin), 'yyyy-MM-dd, H:mm (eeee)', { locale: locale[i18n.language || 'en'] });
              return (
                <ColoredTile
                  key={begin}
                  color='primary'
                  description={date}
                  tileHref={getHref}
                  title={t(...meeting_messages.advisory_meeting)}
                  {...rest}
                />
              );
            })
          : null}
      </div>
    </section>
  );
};

export default UpcomingMeetingsDashboardFeed;
