import { TotpDevice, TotpDeviceDetail, TotpDeviceDetailDTO, TotpDeviceDTO } from './manageTotpDevices.types';

export const parseAllTotpDevicesForFE = (raw: TotpDeviceDTO[]): TotpDevice[] =>
  raw.map(
    (deviceDTO: TotpDeviceDTO): TotpDevice => ({
      id: deviceDTO.id,
      name: deviceDTO.name,
      isConfirmed: deviceDTO.confirmed,
      isDefault: deviceDTO.default,
    }),
  );

export const parseTotpDeviceDetailForFE = (deviceDTO: TotpDeviceDetailDTO): TotpDeviceDetail => ({
  id: deviceDTO.id,
  name: deviceDTO.name,
  isConfirmed: deviceDTO.confirmed,
  isDefault: deviceDTO.default,
  qrCode: deviceDTO.qr_code,
});
