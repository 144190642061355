import {
  AssetRisk,
  AssetRiskDetail,
  AssetRiskDTO,
  AssetRiskDetailDTO,
  CreateAssetRiskForm,
  CreateAssetRiskInput,
} from './assetRisks.types';

export const parseAllAssetRisksForFE = (dtos: AssetRiskDTO[]): AssetRisk[] =>
  dtos.map(dto => ({
    canBeDeleted: dto.can_be_deleted,
    consequence: dto.consequence,
    decision: dto.decision,
    description: dto.description,
    id: dto.id,
    name: dto.name,
    primaryConsequence: dto.primary_consequence,
    probability: dto.probability,
    riskAnalysis: dto.risk_analysis,
    riskQuadrant: dto.risk_quadrant,
    riskTasksCount: dto.risk_tasks_count,
    affectIndividuals: dto.affect_individuals,
    place: dto.place,
  }));

export const parseSingleAssetRisksForFE = (dto: AssetRiskDetailDTO): AssetRiskDetail => ({
  canBeDeleted: dto.can_be_deleted,
  consequence: dto.consequence,
  decision: dto.decision,
  description: dto.description,
  id: dto.id,
  name: dto.name,
  primaryConsequence: dto.primary_consequence,
  probability: dto.probability,
  riskAnalysis: dto.risk_analysis,
  riskQuadrant: dto.risk_quadrant,
  riskTasksCount: dto.risk_tasks_count,
  affectIndividuals: dto.affect_individuals,
  consequencesDescription: dto.consequences_description,
  riskIdentificationWay: dto.risk_identification_way,
  place: dto.place,
});

export const parseDataForCreateAsset = (formInput: CreateAssetRiskForm): CreateAssetRiskInput => ({
  affect_individuals: formInput.affectIndividuals,
  consequence: formInput.consequence,
  decision: formInput.decision,
  description: formInput.description,
  name: formInput.name,
  consequences_description: formInput.consequencesDescription,
  probability: formInput.probability,
  primary_consequence: formInput.primaryConsequence,
  risk_analysis: formInput.riskAnalysisId,
  risk_identification_way: formInput.riskIdentificationWay,
  place: formInput.place,
});
