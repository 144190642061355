import { intersection } from 'lodash';
import { matchPath } from 'react-router';

import routes from 'router/routes';

import RolesOrganization from './RolesOrganization';
import RolesPocketSafe from './RolesPocketSafe';

/**
 * Function to compare path permission according to permissions defined in App Router
 * @param userRoles
 * @param pathToCheck
 */
const compareWithRoutingPermissions = (userRoles: (RolesOrganization | RolesPocketSafe)[], pathToCheck: string) => {
  if (!pathToCheck) return true;
  const matchedRoute = routes.find(({ path }) => matchPath(path, pathToCheck));
  if (!matchedRoute?.routeConfig) return true;
  const { allowedForPSRoles, allowedForOrganizationRoles } = matchedRoute.routeConfig;
  const allowedRoles = [...allowedForPSRoles, ...allowedForOrganizationRoles];
  if (!allowedRoles.length) return true;
  return !!intersection(allowedRoles, userRoles).length;
};

export default compareWithRoutingPermissions;
