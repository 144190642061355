import React, { useState } from 'react';

import { add, isEqual, isFuture } from 'date-fns';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';

import ManageMeetingCalendar from 'components/ManageMeetingCalendar';
import Section from 'components/Section';
import meetingsEndpoints from 'config/api/meetings';
import EVENTS from 'config/events/pubsub';
import useApiCall from 'hooks/useApiCall';
import meeting_messages from 'messages/meeting_messages';
import { useAdvisorContext } from 'reactContext/AdvisorContext';

const ManageMeetingSlotsCalendar = () => {
  const { t } = useTranslation();
  const [loadingDates, setLoadingDates] = useState([]);
  const { apiCall } = useApiCall();
  const { advisor } = useAdvisorContext();

  const clearLoadingDate = ({ date: beginDate }) => {
    setLoadingDates(prev => prev.filter(date => !isEqual(date, beginDate)));
  };

  const onSlotClick = async ({ isBooked, isAvailable, slotId, date }) => {
    const isBlocked = loadingDates && loadingDates.some(loadingDate => isEqual(loadingDate, date));
    if (!isBlocked && !isBooked) {
      setLoadingDates(prevDates => [...prevDates, date]);
      let success;
      if (isAvailable) {
        const { status } = await apiCall(meetingsEndpoints.removeAvailableSlotAsAdviser(slotId));
        success = status < 300;
      } else {
        const data = {
          begin: date.toISOString(),
          end: add(date, { minutes: 30 }).toISOString(),
          adviser: advisor,
        };
        const { status } = await apiCall(meetingsEndpoints.setAvailableSlotAsAdviser(), { data });
        success = status < 300;
      }
      if (success) {
        PubSub.publish(EVENTS.AVAILABILITY_UPDATED, { date, advisor });
      }
    }
  };

  const isClickableCheck = ({ date }) => isFuture(date);
  return (
    <Section smallPadding title={t(...meeting_messages.calendar)}>
      <ManageMeetingCalendar
        clearLoadingDate={clearLoadingDate}
        isClickableCheck={isClickableCheck}
        loadingDates={loadingDates}
        onSlotClick={onSlotClick}
      />
    </Section>
  );
};

export default ManageMeetingSlotsCalendar;
