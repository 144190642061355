import { parseAssetInListForFE } from '../assets/assets.parsers';

import type { ActiveUserRiskAnalysis, ActiveUserRiskAnalysisDTO, RiskMatrixData, RiskMatrixDataDTO } from './activeUserRiskAnalyses.types';

export const parseSingleActiveUserRiskAnalysisDetailForFE = (dto: ActiveUserRiskAnalysisDTO): ActiveUserRiskAnalysis => ({
  id: dto.id,
  date: new Date(dto.date),
  status: dto.status,
  asset: parseAssetInListForFE(dto.asset),
  risksCount: dto.risks_count,
  riskTasksCount: dto.risk_tasks_count,
  canBeReopened: dto.can_be_reopened,
  canBeDeleted: dto.can_be_deleted,
  canBeClosed: dto.can_be_closed,
  participants: dto.participants,
  createdAt: new Date(dto.created_at || new Date()), // TODO remove it
  updatedAt: new Date(dto.updated_at || new Date()), // TODO remove it
});

export const parseAllActiveUserRiskAnalysesForFE = (dto: ActiveUserRiskAnalysisDTO[]): ActiveUserRiskAnalysis[] =>
  dto.map(parseSingleActiveUserRiskAnalysisDetailForFE);

export const parseRiskMatrixDataForFE = (dto: RiskMatrixDataDTO): RiskMatrixData => ({
  rows: dto.rows,
  columns: dto.columns,
  data: dto.data,
});
