import React, { createContext, useState, useContext, useCallback, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import useBoolState from 'hooks/useBoolState';

type Props = {
  children: React.ReactNode;
};

type ModalContent = { body: string; title: string };

type ShowHelpDialog = (content: ModalContent) => void;

type HelpDialogContextType = {
  showHelpDialog: ShowHelpDialog;
};

const HelpDialogContext = createContext({} as HelpDialogContextType);

const { Provider } = HelpDialogContext;

const HelpDialogProvider: React.FC<Props> = ({ children }) => {
  const { state: isOpen, setTrue: openDialog, setFalse: closeDialog } = useBoolState(false);
  const [modalContent, setModalContent] = useState<ModalContent>({ body: '', title: '' });
  const timeoutIdRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  const showHelpDialog: ShowHelpDialog = useCallback(content => {
    setModalContent(content);
    openDialog();
  }, []);

  const onClose = useCallback(() => {
    closeDialog();
    timeoutIdRef.current = setTimeout(() => setModalContent({ body: '', title: '' }), 1000);
  }, []);

  useEffect(
    () => () => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    },
    [],
  );

  return (
    <Provider value={{ showHelpDialog }}>
      {children}
      {/* @ts-ignore */}
      <DialogGrid onClose={onClose} open={isOpen} title={modalContent.title}>
        <TypographyWithHTML>{modalContent.body}</TypographyWithHTML>
      </DialogGrid>
    </Provider>
  );
};

HelpDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useHelpDialogContext = () => useContext(HelpDialogContext);

export default HelpDialogProvider;
