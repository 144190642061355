import { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import paymentsApi from 'config/api/payments/payments';
import useQueryParams from 'hooks/useQueryParams';
import auth_messages from 'messages/auth_messages';

const usePaymentFallbackInfo = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { queryParams } = useQueryParams() as unknown as { queryParams: { stripe_success: string; stripe_failure: string } };
  const updatePaymentMutation = useMutation(paymentsApi.updatePayment);

  const onFailure = () => {
    enqueueSnackbar(t(auth_messages.payment.failed), { variant: 'error' });
  };

  const onSuccess = async () => {
    await updatePaymentMutation.mutateAsync();
    enqueueSnackbar(t(auth_messages.payment.success), { variant: 'success' });
  };

  useEffect(() => {
    if (queryParams.stripe_success) onSuccess();
  }, [queryParams.stripe_success]);

  useEffect(() => {
    if (queryParams.stripe_failure) onFailure();
  }, [queryParams.stripe_failure]);
};

export default usePaymentFallbackInfo;
