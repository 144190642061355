import React from 'react';

import ContentArticleTile from 'components/ContentArticleTile/ContentArticleTile';
import ContentGuideTile from 'components/ContentGuideTile/ContentGuideTile';
import SelfTestTile from 'components/SelfTestTile/SelfTestTile';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import type { RecommendedSectionItem } from 'types/General';

type Props = { data: RecommendedSectionItem };

const RecommendedItem: React.FC<Props> = ({ data }) => {
  const { objectContentType } = data;

  const resolveContentItem = () => {
    if (objectContentType.includes(CONTENT_TYPES.GUIDE) || objectContentType.includes(CONTENT_TYPES.INCIDENT_GUIDE)) {
      return <ContentGuideTile data={data} />;
    }
    if (objectContentType === CONTENT_TYPES.SELF_TEST) {
      return <SelfTestTile data={data} />;
    }
    if (objectContentType === CONTENT_TYPES.ARTICLE) {
      return <ContentArticleTile data={data} withImage />;
    }
    throw Error('Incorrect content type');
  };

  return <>{resolveContentItem()}</>;
};

export default RecommendedItem;
