import React from 'react';

import { useQuery } from 'react-query';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import Loader from 'components/Loader';
import { commonApi } from 'config/api/common';
import QUERY_KEYS from 'config/api/QUERY_KEYS';

import RecommendedItem from '../RecommendedItem/RecommendedItem';

import useStyles from './RecommendedSection.styles';

const RecommendedSection: React.FC = () => {
  const { data, isLoading } = useQuery(QUERY_KEYS.GET_RECOMMENDED_CONTENT, commonApi.getRecommendedContent);
  const styles = useStyles({ length: data?.length });

  return (
    <CarouselWrapper>
      {isLoading ? (
        <Loader inner />
      ) : (
        <div className={styles.grid}>
          {data?.map(item => (
            <RecommendedItem key={item.id} data={item} />
          ))}
        </div>
      )}
    </CarouselWrapper>
  );
};

export default RecommendedSection;
