import { useQueries } from 'react-query';

import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { DynamicTableFilter, StaticTableFilter, TableFilter, TableFilterEntity } from 'types/TableFilter';

const usePrepareAvailableFilters = (availableFilters: { [key in string]: TableFilter }) => {
  const filtersToQuery = Object.entries<TableFilter>(availableFilters)
    .filter(entry => entry[1]?.isDynamic)
    .map(([key, value]) => {
      const { queryFn, queryKey, queryOptionsOverride } = value as DynamicTableFilter;
      return {
        queryFn,
        filterKey: key,
        queryKey: queryKey || key,
        queryOptionsOverride: queryOptionsOverride || {},
      };
    });

  const filterQueries = useQueries(
    filtersToQuery.map(({ queryFn, queryKey, queryOptionsOverride }) => ({
      queryKey: [QUERY_KEYS.TABLE_FILTER, queryKey],
      queryFn,
      staleTime: 1000 * 60 * 10,
      refetchOnWindowFocus: false,
      onError: () => {
        // eslint-disable-next-line no-console
        console.error(`Failed to fetch data for query key: ${queryKey}`);
      },
      ...queryOptionsOverride,
    })),
  );

  const isReady = filterQueries.every(({ isLoading }) => !isLoading);

  if (isReady) {
    const staticFilters = {} as { [key in string]: StaticTableFilter };
    const queriedFilters = filtersToQuery.reduce<{ [key in string]: TableFilterEntity[] }>(
      (accumulator, currentValue, currentIndex) => ({
        [currentValue.filterKey]: filterQueries[currentIndex].data as TableFilterEntity[],
        ...accumulator,
      }),
      {},
    );
    Object.entries(availableFilters).forEach(([key, value]) => {
      if (value.isDynamic) {
        const dynamicTableFilter = value as DynamicTableFilter;
        const { isSingleSelect, sectionName, dataParser } = dynamicTableFilter;
        staticFilters[key] = {
          isDynamic: false,
          isSingleSelect,
          sectionName,
          filters: typeof dataParser === 'function' ? dataParser(queriedFilters[key]) : queriedFilters[key],
        };
      } else {
        staticFilters[key] = value;
      }
    });
    return staticFilters;
  }

  return null;
};

export default usePrepareAvailableFilters;
