import axios from 'axios';

import {
  parseAllAssetCategoriesForFE,
  parseAllAssetCategoriesForSelect,
  parseAllAssetCategoriesForFilters,
} from './assetCategories.parsers';
import type AssetCategoriesApi from './assetCategories.types';

const endpoint = 'api/v1/asset_categories/';

const assetCategoriesApi: AssetCategoriesApi = {
  getAllAssetCategoriesForSelect: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllAssetCategoriesForSelect(data);
  },
  getAllAssetCategoriesForFilters: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllAssetCategoriesForFilters(data);
  },
  getAllAssetCategories: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllAssetCategoriesForFE(data);
  },
};

export default assetCategoriesApi;
