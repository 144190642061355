import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  article: {
    display: 'grid',
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(10),
    margin: '0 auto',
    // @ts-ignore
    maxWidth: theme.sizes.container.md,
    gridGap: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
      gridGap: theme.spacing(4),
    },

    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '10px',
      margin: '0 auto',
      top: 0,
      left: '50%',
      transitionDuration: theme.transitions.duration.standard,
      transitionTimingFunction: theme.transitions.easing.easeIn,
      transform: 'translate(-50%, 0)',
      // @ts-ignore
      background: theme.palette.primary[100],
      // @ts-ignore
      borderBottomLeftRadius: theme.shape.borderRadiusLarge,
      // @ts-ignore
      borderBottomRightRadius: theme.shape.borderRadiusLarge,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  contentGrid: {
    display: 'grid',
    gridGap: theme.spacing(8),
    // @ts-ignore
    gridTemplateColumns: `${theme.sizes.article.body}ch ${theme.sizes.article.navigation}ch`,
    justifyContent: 'center',
    [`@media screen and (max-width: 1470px)`]: {
      // @ts-ignore
      gridTemplateColumns: `${theme.sizes.article.body * 0.9}ch ${theme.sizes.article.navigation * 0.8}ch`,
    },
    [theme.breakpoints.down('md')]: {
      gridGap: theme.spacing(6),
    },
    // @ts-ignore
    [`@media screen and (max-width: ${theme.customBreakpoints.articleNavigation}px)`]: {
      gridTemplateColumns: '1fr',
      // @ts-ignore
      maxWidth: `${theme.sizes.article.body}ch`,
      margin: '0 auto',
    },

    '& .article-body1': {
      // @ts-ignore
      fontFamily: theme.typography.fontFamilyArticle,
      paddingBottom: theme.spacing(0.75),
    },
    '& .article-ul, .article-ol': {
      // @ts-ignore
      fontFamily: theme.typography.fontFamilyArticle,
    },
  },
  title: {
    maxWidth: `30ch`,
    margin: '0 -.5ch',
    // @ts-ignore
    [`@media screen and (max-width: ${theme.customBreakpoints.articleNavigation}px)`]: {
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      margin: '0 -5px',
    },
  },
}));

export default useStyles;
