import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.secondary[50],
    },
  },
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(5),
    justifyItems: 'center',
  },
  content: {
    width: '100%',
    padding: ({ noCardPadding }) => (noCardPadding ? 0 : theme.spacing(5)),
    maxWidth: ({ contentSize }) => theme.sizes.container[contentSize] || contentSize,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2.5),
    },
  },
}));
