import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import DialogViewWrapper from 'components/DialogViewWrapper';
import Loader from 'components/Loader';
import WorkplaceTemplateEditor from 'components/WorkplaceTemplateEditor/WorkplaceTemplateEditor';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { workplacesApi } from 'config/api/workplaces/workplaces';
import workplace_messages from 'messages/workplace_messages';

const WorkplacesTemplatePage: React.FC = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(QUERY_KEYS.GET_WORKPLACE_TEMPLATE, () => workplacesApi.getWorkplaceTemplate());

  return (
    <DialogViewWrapper title={t(workplace_messages.template.pageTitle)}>
      {isLoading ? <Loader inner /> : <WorkplaceTemplateEditor initialTemplate={data} />}
    </DialogViewWrapper>
  );
};

export default WorkplacesTemplatePage;
