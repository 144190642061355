import assetInformationChoicesApi from 'config/api/assetInformationChoices/assetInformationChoices';
import { AssetInformationFilters } from 'config/api/assetInformationChoices/assetInformationChoices.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import table_messages from 'messages/table_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const availabilityFilter: DynamicTableFilter<AssetInformationFilters> = {
  isDynamic: true,
  queryFn: assetInformationChoicesApi.getAssetInformationChoicesForFilters,
  dataParser: ({ availability }) => availability,
  sectionName: table_messages.assets_filters.availability,
  isSingleSelect: false,
  queryKey: QUERY_KEYS.ASSETS_INFORMATION_CHOICES,
};

export default availabilityFilter;
