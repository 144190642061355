import React, { useState } from 'react';

import { makeStyles, Typography, Container, IconButton } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { differenceInDays, differenceInHours } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import CarouselWrapper from 'components/CarouselWrapper/CarouselWrapper';
import ColoredTile from 'components/ColoredTile/ColoredTile';
import FooterButton from 'components/FooterButton';
import Jumbotron from 'components/Jumbotron';
import Loader from 'components/Loader';
import Table from 'components/Table';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import incidentReportsEndpoints, { incidentReportsApi } from 'config/api/incident_reports';
import INCIDENT_PHASES from 'config/constants/INCIDENT_PHASES';
import DIRECTIONS from 'config/constants/sortDirections';
import EVENTS from 'config/events/pubsub';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import { incidentPriorityResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PRIORITIES';
import parseDate from 'helpers/parseDate';
import usePagination from 'hooks/usePagination';
import useSubscription from 'hooks/useSubscription';
import general_messages from 'messages/general_messages';
import report_messages from 'messages/report_messages';
import PATHS from 'router/PATHS';
import isGdprFilter from 'services/_filters/parsers/isGdprFilter';
import isOngoingFilter from 'services/_filters/parsers/isOngoingFilter';
import phaseFilter from 'services/_filters/parsers/phaseFilter';
import priorityFilter from 'services/_filters/parsers/priorityFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';

const useStyles = makeStyles(theme => ({
  jumbotron: {
    marginBottom: theme.spacing(4),
  },
  accordion: {
    background: theme.palette.background.default,
  },
}));

const countTimePeriod = (t, date) => {
  if (!date) return null;
  const cratedAt = new Date(date);
  const now = new Date();
  const dayDiff = differenceInDays(cratedAt, now);
  if (dayDiff <= 0) return t(...general_messages.days_suffix, { days: -dayDiff });
  return t(...general_messages.hours_suffix, { hours: -differenceInHours(cratedAt, now) });
};

const availableFilters = {
  priority: priorityFilter,
  is_gdpr: isGdprFilter,
  is_ongoing: isOngoingFilter,
  phase: phaseFilter,
};

const defaultReportFilter = {
  phase: [
    {
      id: INCIDENT_PHASES.RECEIVE,
    },
    {
      id: INCIDENT_PHASES.MANAGE_THE_INCIDENT,
    },
    {
      id: INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK,
    },
  ],
};

const IncidentReportsPage = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(defaultReportFilter);
  const [sort, setSort] = useState({ key: 'created_at', direction: DIRECTIONS.ASC });
  const onSort = (key, direction) => setSort({ key, direction });

  const history = useHistory();

  const {
    data: incidentReports,
    loading,
    getData,
    refreshData,
    hasNextPage,
    initialLoading,
  } = usePagination({
    endpointFunc: incidentReportsEndpoints.getAllAsIncidentManagerWithFilters,
    limits: [15, 10, 5],
    dependencies: [sort, filters],
    filters: parseFiltersForRequest(filters),
    sort,
  });

  const { data: kpiData } = useQuery('get reports KPI', incidentReportsApi.getReportsKPI);

  const onNewTabOpen = (event, id) => {
    event.stopPropagation();
    window.open(`${PATHS.INCIDENT_REPORT}/${id}?redirect_url=${PATHS.INCIDENT_REPORTS}`, '_blank');
  };

  const dataMapping = [
    { id: 'name', label: t(...report_messages.table_header.name), get: data => data?.name, width: '14%' },
    {
      id: 'is_gdpr',
      label: t(...report_messages.table_header.is_gdpr),
      get: data => t(data?.is_gdpr ? general_messages.yes : general_messages.no),
      width: '5%',
    },
    {
      id: 'is_ongoing',
      label: t(...report_messages.table_header.is_ongoing),
      get: data => t(data?.is_ongoing ? general_messages.yes : general_messages.no),
      width: '7%',
    },
    {
      id: 'priority',
      label: t(...report_messages.table_header.priority),
      get: data => t(incidentPriorityResolver(data?.priority)),
      width: '8%',
    },
    {
      id: 'phase',
      label: t(...report_messages.table_header.phase),
      get: data => t(incidentPhaseResolver(data?.phase)),
      width: '12%',
    },
    { id: 'categorization', label: t(...report_messages.table_header.categorization), get: data => data?.categorization, width: '14%' },
    { id: 'created_at', label: t(...report_messages.table_header.created_at), get: data => parseDate(data?.created_at), width: '8%' },
    {
      id: 'created_at',
      label: t(...report_messages.table_header.created_time),
      get: data => countTimePeriod(t, data?.created_at),
      width: '8%',
      blockSorting: true,
    },
    {
      id: 'updated_by',
      label: t(...report_messages.table_header.last_edited_by),
      get: data =>
        data?.last_updated_by_profile ? (
          <UserAvatar
            lazy
            showName
            userData={{
              firstName: data.last_updated_by_profile?.first_name,
              lastName: data.last_updated_by_profile?.last_name,
              imageUrl: data.last_updated_by_profile?.image_url,
            }}
            userId={data?.last_updated_by_id}
          />
        ) : (
          t(...general_messages.no_data)
        ),
      width: '12%',
    },
    {
      id: 'author',
      label: t(...report_messages.table_header.author),
      get: data =>
        data?.author_profile ? (
          <UserAvatar
            lazy
            showName
            userData={{
              firstName: data?.author_profile.first_name,
              lastName: data?.author_profile.last_name,
              imageUrl: data?.author_profile.image_url,
            }}
            userId={data?.author_id}
          />
        ) : (
          t(...general_messages.no_data)
        ),
      width: '12%',
    },
    {
      id: 'context',
      label: '',
      get: data => (
        <IconButton onClick={event => onNewTabOpen(event, data?.id)}>
          <OpenInNew />
        </IconButton>
      ),
      isAddon: true,
      blockSorting: true,
    },
  ];

  const onRowClick = (event, { id }) => {
    history.push(`${PATHS.INCIDENT_REPORT}/${id}?redirect_url=${PATHS.INCIDENT_REPORTS}`);
  };

  useSubscription(EVENTS.INCIDENT_REPORTS_UPDATED, refreshData);

  const styles = useStyles();
  return (
    <Container maxWidth='lg'>
      <Jumbotron className={styles.jumbotron}>
        <Typography component='h1' variant='h1'>
          {t(...general_messages.incident_report_plural)}
        </Typography>
      </Jumbotron>
      <CarouselWrapper>
        {kpiData
          ? Object.entries(kpiData).map(([key, value]) => (
              <ColoredTile key={key} centered description={t(report_messages.incident_kpi[key])} disableRipple title={value} />
            ))
          : // eslint-disable-next-line prefer-spread
            Array.apply(null, Array(5)).map((_, index) => <ColoredTile key={index} centered isLoading />)}
      </CarouselWrapper>
      {initialLoading ? (
        <Loader inner />
      ) : (
        <Table
          data={incidentReports}
          dataMapping={dataMapping}
          filtersService={{ availableFilters, filters, setFilters }}
          fixedHeader
          onRowClick={onRowClick}
          onSort={onSort}
          refetching={loading && !initialLoading}
          sort={sort}
        />
      )}
      {hasNextPage && (
        <FooterButton loading={loading} onClick={getData}>
          {t(...general_messages.show_more)}
        </FooterButton>
      )}
    </Container>
  );
};

export default IncidentReportsPage;
