export type UserFeatureFlagsDTO = {
  display_task_table: boolean;
  can_filter_assets: boolean;
};

export const featureFlags = ['displayTaskTable', 'canFilterAssets'] as const;
export type FeatureFlags = typeof featureFlags[number];
export type UserFeatureFlags = Record<FeatureFlags, boolean>;

type FeatureFlagsApi = {
  getFeatureFlags: () => Promise<UserFeatureFlags>;
};

export default FeatureFlagsApi;
