import React, { useCallback, useEffect, useRef } from 'react';

import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import FormCheckbox from 'components/FormCheckbox';
import FormTextInput from 'components/FormTextInput';
import FormTitle from 'components/FormTitle';
import SimpleFormLayout from 'components/SimpleFormLayout';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import organizationsApi from 'config/api/organizations/organizations';
import { OrganizationCreateFormInput } from 'config/api/organizations/organizations.types';
import emailValidator from 'config/validators/emailValidator';
import useQueryParams from 'hooks/useQueryParams';
import auth_messages from 'messages/auth_messages';
import general_messages from 'messages/general_messages';
import label_messages from 'messages/label_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import validateCampaignCode from 'services/validateCampaignCode/validateCampaignCode';

type FormInput = Omit<OrganizationCreateFormInput, 'languageCode'>;

const RegisterForm = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { queryParams } = useQueryParams() as unknown as { queryParams: Record<string, string> };
  const history = useHistory();

  const emailInput = useRef<HTMLElement>(null);
  const campaignCodeInput = useRef<HTMLElement>(null);

  const createOrganizationMutation = useMutation(MUTATION_KEYS.CREATE_ORGANIZATION, organizationsApi.createOrganization, {
    onSuccess: data => {
      history.push(PATHS.AUTH_REGISTRATION_LINK_SENT, data);
    },
    onError: ({ response }) => {
      if (response.data.email) {
        enqueueSnackbar(t(validation_messages.email_in_use), { variant: 'error' });
        emailInput.current?.focus();
      } else {
        enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
      }
    },
  });

  const onSubmit = (formInput: FormInput) => {
    validateCampaignCode(formInput.campaignCode, {
      onSuccess: () => {
        createOrganizationMutation.mutate({ ...formInput, languageCode: i18n.language });
      },
      onError: message => {
        campaignCodeInput.current?.focus();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        formik.setFieldError('campaignCode', t(message));
      },
    });
  };

  const getCampaignCodeFromUrl = useCallback(
    () => queryParams.campaignCode || queryParams.CampaignCode || queryParams.campaign_code || queryParams.campaigncode,
    [queryParams],
  );

  const formik = useFormik<FormInput>({
    initialValues: {
      name: '',
      email: '',
      firstName: '',
      lastName: '',
      campaignCode: getCampaignCodeFromUrl() || '',
      terms: false,
      gdpr: false,
    },
    onSubmit,
    validationSchema: yup.object({
      firstName: yup.string().required(t(validation_messages.required)),
      lastName: yup.string().required(t(validation_messages.required)),
      email: emailValidator({
        required: t(validation_messages.required),
        valid: t(validation_messages.email_valid),
      }),
      name: yup.string().required(t(validation_messages.required)),
      gdpr: yup.boolean().oneOf([true], t(validation_messages.agreement_required)),
      terms: yup.boolean().oneOf([true], t(validation_messages.agreement_required)),
    }),
  });

  useEffect(() => {
    // validate on autofill
    formik.validateForm();
  }, []);

  return (
    <SimpleFormLayout
      disabled={!formik.isValid}
      onSubmit={formik.handleSubmit}
      sending={createOrganizationMutation.isLoading}
      submitLabel={t(auth_messages.create_account)}
      title={t(auth_messages.register_subheading)}
    >
      <TypographyWithHTML>{t(auth_messages.register_additional_info)}</TypographyWithHTML>
      <FormTitle>{t(auth_messages.register_sections.company)}</FormTitle>
      <FormTextInput formik={formik} id='name' label={t(label_messages.organization_name)} required />
      <FormTitle>{t(auth_messages.register_sections.personal)}</FormTitle>
      <FormTextInput formik={formik} id='firstName' label={t(label_messages.first_name)} required />
      <FormTextInput formik={formik} id='lastName' label={t(label_messages.last_name)} required />
      <FormTextInput formik={formik} id='email' inputRef={emailInput} label={t(label_messages.email)} required type='email' />
      <FormTitle>{t(auth_messages.campaign_code)}</FormTitle>
      <FormTextInput formik={formik} id='campaignCode' inputRef={campaignCodeInput} label={t(label_messages.campaign_code)} />

      <Box margin='2rem 0 0 0'>
        <FormCheckbox
          formik={formik}
          id='terms'
          label={
            <>
              <Trans i18nKey={auth_messages.accept_terms[0]}>I have read and accept</Trans>
              &nbsp;
              <a href={`/assets/documents/${i18n.language}/ps_user_terms_and_conditions.pdf`} rel='noreferrer' target='_blank'>
                <Trans i18nKey={auth_messages.terms[0]}>terms and conditions</Trans>
              </a>
              &nbsp;*
            </>
          }
        />
        <FormCheckbox
          formik={formik}
          id='gdpr'
          label={
            <>
              <Trans i18nKey={auth_messages.accept_gdpr[0]}>I have read and accept</Trans>
              &nbsp;
              <a href={`/assets/documents/${i18n.language}/ps_gdpr_policy.pdf`} rel='noreferrer' target='_blank'>
                <Trans i18nKey={auth_messages.gdpr[0]}>the GDPR privacy terms</Trans>
              </a>
              &nbsp;*
            </>
          }
        />
      </Box>
    </SimpleFormLayout>
  );
};

export default RegisterForm;
