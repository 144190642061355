import axios from 'axios';

import accessTokenStorage from 'storages/accessTokenStorage';
import refreshTokenStorage from 'storages/refreshTokenStorage';

const updateAuthTokens = ({ refresh, access }: { refresh: string; access: string }) => {
  axios.defaults.headers.common.Authorization = `Bearer ${access}`;
  refreshTokenStorage.set(refresh);
  accessTokenStorage.set(access);
};

export default updateAuthTokens;
