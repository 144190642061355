import React from 'react';

import { Chip, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(5, 0),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(1.5),
    alignItems: 'center',
  },
  title: { fontWeight: 500 },
}));

const ArticleTags = ({ tags }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  if (!tags || !tags.length) return null;
  return (
    <div className={styles.wrapper}>
      <Typography className={styles.title} component='span'>
        {t(...general_messages.tags)}
      </Typography>
      <div>
        {tags.map(tag => (
          <Chip key={tag} component={Link} label={tag} to={{ pathname: PATHS.ARTICLES, state: { preselectedFilters: { tags: [tag] } } }} />
        ))}
      </div>
    </div>
  );
};

ArticleTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArticleTags;
