import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  riskCell: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(0.5, 1.5),

    '@media print': {
      fontSize: '0.75rem',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
  },
  riskQuadrant: {
    gridRow: '1 / 3',
    padding: theme.spacing(1, 3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,

    '@media print': {
      padding: theme.spacing(1),
    },
  },
}));
