import React, { useEffect, useState } from 'react';

import { Container, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import BookMeetingDetailsDialog from 'components/_dialogs/BookMeetingDetailsDialog';
import BookMeetingDialog from 'components/_dialogs/BookMeetingDialog';
import FormDynamicSelect from 'components/FormDynamicSelect/FormDynamicSelect';
import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import meetingsEndpoints, { meetingsApi } from 'config/api/meetings';
import useApiCall from 'hooks/useApiCall';
import meeting_messages from 'messages/meeting_messages';
import knowledgeAreaParser from 'services/_dictionaries/parsers/knowledgeAreaParser';

import BookMeetingAllSlots from './_components/BookMeetingAllSlots';
import BookMeetingNextSlots from './_components/BookMeetingNextSlots';

const useStyles = makeStyles(theme => ({
  description: {
    maxWidth: theme.sizes.container.sm,
    margin: '0 auto',
    paddingTop: theme.spacing(6),
    fontFamily: theme.typography.fontFamilyArticle,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  dropdownSection: {
    marginTop: theme.spacing(4),
    maxWidth: '320px',
    width: '100%',
    display: 'grid',
    gridGap: theme.spacing(1),
  },
}));

const BOOK_DIALOG = {
  NEW: 'NEW',
  DETAILS: 'DETAILS',
};

const FORM = {
  KNOWLEDGE_AREA: 'knowledge_area',
};

const BookMeetingPage = () => {
  const theme = useTheme();
  const [safetyBookTime, setSafetyBookTime] = useState(null);
  const { apiCall } = useApiCall();

  const formik = useFormik({
    initialValues: {
      [FORM.KNOWLEDGE_AREA]: undefined,
    },
  });

  const getSafetyBookTime = async () => {
    const { data } = await apiCall(meetingsEndpoints.getTimePriorToBookAsSecurityOfficer(), {}, { cache: true });
    setSafetyBookTime(data.min_minutes_prior_to_time_slot);
  };

  useEffect(() => {
    getSafetyBookTime();
  }, []);

  const { t, i18n } = useTranslation();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const [bookDialog, setBookDialog] = useState({ date: null, isEdit: false, type: null, id: null });

  const closeDialog = () => setBookDialog({ date: null, isEdit: false, type: null, id: null });
  const openBookDialogFromDetails = () => setBookDialog(prev => ({ ...prev, isEdit: true, type: BOOK_DIALOG.NEW }));

  const onBook = ({ date, isBooked, id }) => {
    if (isBooked) setBookDialog({ date, id, isEdit: false, type: BOOK_DIALOG.DETAILS });
    else setBookDialog({ date, id, isEdit: false, type: BOOK_DIALOG.NEW });
  };

  const styles = useStyles();
  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {t(...meeting_messages.page_title)}
        </Typography>
      </Jumbotron>
      <MobileGuttersContainer>
        <TypographyWithHTML className={styles.description}>{t(...meeting_messages.page_description)}</TypographyWithHTML>
        <section className={styles.dropdownSection}>
          <FormDynamicSelect
            className={styles.dropdown}
            dataSource={{
              sourceFunction: meetingsApi.getKnowledgeAreas,
              dictionaryKey: `${FORM.KNOWLEDGE_AREA}_${i18n.language}`,
              parser: knowledgeAreaParser,
            }}
            formik={formik}
            id='knowledge_area'
            label={t(meeting_messages.knowledge_area_label)}
          />
          <Typography variant='caption'>{t(...meeting_messages.knowledge_area_info)}</Typography>
        </section>
      </MobileGuttersContainer>

      {formik.values[FORM.KNOWLEDGE_AREA] && (
        <>
          <BookMeetingNextSlots knowledgeArea={formik.values[FORM.KNOWLEDGE_AREA]} onBook={onBook} safetyBookTime={safetyBookTime} />
          <MobileGuttersContainer>
            <Section smallPadding title={t(...meeting_messages.find_time_for_meeting_title)}>
              <BookMeetingAllSlots knowledgeArea={formik.values[FORM.KNOWLEDGE_AREA]} onBook={onBook} safetyBookTime={safetyBookTime} />
            </Section>
          </MobileGuttersContainer>
        </>
      )}
      {bookDialog.type === BOOK_DIALOG.NEW && (
        <BookMeetingDialog knowledgeArea={formik.values[FORM.KNOWLEDGE_AREA]} {...bookDialog} onClose={closeDialog} open />
      )}
      {bookDialog.type === BOOK_DIALOG.DETAILS && (
        <BookMeetingDetailsDialog {...bookDialog} onClose={closeDialog} open openBookDialogFromDetails={openBookDialogFromDetails} />
      )}
    </Container>
  );
};

export default BookMeetingPage;
