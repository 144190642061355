import React from 'react';

import { ButtonBase, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { useCommonDialogsContext } from 'reactContext/CommonDialogsContext';
import useLayoutStore from 'storages/layoutStore';

import type { MenuElement } from '../sections/menuElements';

import useStyles from './MenuList.styles';

export type Props = {
  elements: MenuElement[];
};

const MenuElementsList: React.FC<Props> = ({ elements }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { isMenuOpen, closeMenu } = useLayoutStore();
  // @ts-ignore
  const { openCommonDialog } = useCommonDialogsContext();

  const onClick = (dialogType?: string) => {
    if (!upMd) closeMenu();
    if (dialogType) openCommonDialog(dialogType);
  };

  const styles = useStyles({ isMenuOpen: !!isMenuOpen });
  return (
    <List className={styles.list}>
      {elements.map(({ label, to, dialogType, disabled }) => (
        <ListItem
          key={label[0]}
          activeClassName={styles.active}
          button
          className={styles.item}
          component={to ? NavLink : ButtonBase}
          data-cy={to ? `menu_link_to-${to.replace('/', '')}` : `menu_action-${dialogType}`}
          disabled={disabled}
          disableRipple
          exact
          onClick={() => onClick(dialogType)}
          to={to}
        >
          <ListItemText classes={{ root: styles.itemText }} primary={t(label)} />
        </ListItem>
      ))}
    </List>
  );
};

export default MenuElementsList;
