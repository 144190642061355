import axios from 'axios';

import type PaymentsApi from './payments.types';

const endpoint = 'api/v1/payments/';

const paymentsApi: PaymentsApi = {
  getStripeUrl: async () => {
    const successUrl = `${window.location.origin}?stripe_success=true`;
    const cancelUrl = `${window.location.href}?stripe_failure=true`;
    const url = `${endpoint}get_stripe_update_payment_info_url?success_url=${successUrl}&cancel_url=${cancelUrl}`;

    const { data } = await axios.get<{ url: string }, Promise<{ data: { url: string } }>>(url);
    return data.url;
  },
  updatePayment: async () => axios.put(`${endpoint}update_org_payment_field`),
};

export default paymentsApi;
