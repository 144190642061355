import React, { useEffect, useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { add, eachDayOfInterval, isAfter, isEqual, isFuture } from 'date-fns';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import CalendarHeader from 'components/_calendar/CalendarHeader';
import MeetingsCalendar from 'components/_calendar/MeetingsCalendar';
import meetingsEndpoints from 'config/api/meetings';
import EVENTS from 'config/events/pubsub';
import generateDayRange from 'helpers/generateDayRange';
import pruneTimeFromDate from 'helpers/pruneTimeFromDate';
import useApiCall from 'hooks/useApiCall';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useLoadingState from 'hooks/useLoadingState';
import useSubscription from 'hooks/useSubscription';
import getDateRange from 'services/getDateRange';

import BookMeetingNextSlots from '../BookMeetingNextSlots';

const getSlotObj = (date, { availableSlots, bookedSlots }) => {
  const bookedSlot = bookedSlots?.find(bookedDate => isEqual(new Date(bookedDate.begin), date));
  return {
    date,
    isAvailable: availableSlots ? availableSlots.some(availableSlot => isEqual(new Date(availableSlot), date)) : true,
    isBooked: !!bookedSlot,
    id: bookedSlot?.id,
  };
};

const BookMeetingAllSlots = ({ onBook, safetyBookTime, knowledgeArea }) => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const showSingleDay = useMediaQuery(theme.breakpoints.down('xs'));

  const [availableSlots, setAvailableSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const { loading, setLoaded, setLoading } = useLoadingState(true);

  const [dateRange, setDateRange] = useState(getDateRange(new Date()));
  const [singleDay, setSingleDay] = useState(pruneTimeFromDate(new Date()));

  const getData = async single => {
    if (isNil(safetyBookTime)) return;
    setLoading();
    const { start, end } = generateDayRange(singleDay);
    const startDateString = single ? start.toISOString() : dateRange.start.toISOString();
    const endDateString = single ? end.toISOString() : dateRange.end.toISOString();
    const [{ data: availableSlotsFromAPI }, { data: bookedSlotsFromAPI }] = await Promise.all([
      apiCall(meetingsEndpoints.getFreeSlots({ begin__gte: startDateString, end__lte: endDateString, knowledge_area_id: knowledgeArea })),
      apiCall(
        meetingsEndpoints.getMeetingsAsSecurityOfficer({ time_slot__begin__gte: startDateString, time_slot__end_lte: endDateString }),
      ),
    ]);
    setAvailableSlots(
      availableSlotsFromAPI
        .map(({ begin }) => begin)
        .filter(date => !isAfter(add(new Date(), { minutes: safetyBookTime }), new Date(date))),
    );
    setBookedSlots(bookedSlotsFromAPI);
    setLoaded();
  };

  useDidUpdateEffect(() => {
    if (safetyBookTime) getData(showSingleDay);
  }, [dateRange, singleDay, showSingleDay, safetyBookTime]);

  useEffect(() => {
    const { values } = theme.breakpoints;
    const screenSize = window.innerWidth;
    getData(screenSize <= values.sm);
  }, [knowledgeArea]);

  const slotsMiddleware = (dates, { full, half }) =>
    dates.push(getSlotObj(full, { availableSlots, bookedSlots }), getSlotObj(half, { availableSlots, bookedSlots }));

  const isClickableCheck = ({ isAvailable, date, isBooked }) => isBooked || (isAvailable && isFuture(date));

  useSubscription(EVENTS.MEETINGS_UPDATED, () => getData(), [safetyBookTime]);

  return (
    <div>
      <CalendarHeader dateRange={dateRange} setDateRange={setDateRange} setSingleDay={setSingleDay} singleDay={singleDay} />
      <MeetingsCalendar
        days={eachDayOfInterval(dateRange)}
        isClickableCheck={isClickableCheck}
        loading={loading}
        onSlotClick={onBook}
        singleDay={singleDay}
        slotsMiddleware={slotsMiddleware}
      />
    </div>
  );
};

BookMeetingNextSlots.defaultProps = {
  safetyBookTime: null,
  knowledgeArea: null,
};

BookMeetingAllSlots.propTypes = {
  onBook: PropTypes.func.isRequired,
  safetyBookTime: PropTypes.number.isRequired,
  knowledgeArea: PropTypes.number.isRequired,
};

export default BookMeetingAllSlots;
