import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import MenuElementsList from 'components/Layout/_components/MenuElementsList/MenuElementsList';
import MenuSection from 'components/Layout/_components/MenuSection';
import commonEndpoints from 'config/api/common';
import useApiCall from 'hooks/useApiCall';
import menu_messages from 'messages/menu_messages';
import PATHS from 'router/PATHS';

import type { MenuElement } from '../menuElements';

const ServiceSection = () => {
  const { t, i18n } = useTranslation();
  const { apiCall } = useApiCall();
  const [rawElements, setRawElements] = useState([]);

  const getData = async () => {
    const { data, status } = await apiCall(commonEndpoints.serviceAreas());
    if (status < 300) setRawElements(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const elements: MenuElement[] = useMemo(
    () =>
      rawElements.map(({ id, name_translation_key, name }) => ({
        label: [name_translation_key, name],
        to: `${PATHS.CONTENT_BY_SERVICE_AREA}/${id}`,
      })),
    [rawElements, i18n.language],
  );

  if (!elements.length) return null;
  return (
    // @ts-ignore
    <MenuSection title={t(menu_messages.service)}>
      <MenuElementsList elements={elements} />
    </MenuSection>
  );
};

export default ServiceSection;
