import { makeStyles } from '@material-ui/core';

type StyleProps = {
  length: number;
};

export default makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  scrollableGrid: {
    [theme.breakpoints.down('xs')]: {
      // @ts-ignore
      gridTemplateColumns: ({ length }: StyleProps) => `repeat(${length}, 1fr)`,
      gridGap: theme.spacing(1.5),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
      maxWidth: '100vw',
      overflowX: 'scroll',

      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
  },
}));
