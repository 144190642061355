import { Option } from 'types/Option';
import { TableFilterEntity } from 'types/TableFilter';

import type { AssetInformationChoices, AssetInformationChoicesDTO, AssetInformationOption } from './assetInformationChoices.types';
import { AssetInformationFilters } from './assetInformationChoices.types';

const parseSingleOption = ({ value, label }: AssetInformationOption, prefix: string): Option => ({
  key: value,
  label: [`assets>>${prefix}>>${value}`, label],
});

const parseSingleFilter = ({ value, label }: AssetInformationOption, prefix: string): TableFilterEntity => ({
  id: value,
  name: [`assets>>${prefix}>>${value}`, label],
});

export const parseAssetInformationChoicesForFE = (raw: AssetInformationChoicesDTO): AssetInformationChoices => ({
  integrityChangeControl: raw.integrity_change_control.map(assetInformation =>
    parseSingleOption(assetInformation, 'information_choices_integrity_change_control'),
  ),
  assetsImportance: raw.assets_importance.map(assetInformation =>
    parseSingleOption(assetInformation, 'information_choices_assets_importance'),
  ),
  availability: raw.availability.map(assetInformation => parseSingleOption(assetInformation, 'information_choices_availability')),
  confidentiality: raw.confidentiality.map(assetInformation => parseSingleOption(assetInformation, 'information_choices_confidentiality')),
});

export const parseAssetInformationChoicesForFilters = (raw: AssetInformationChoicesDTO): AssetInformationFilters => ({
  integrityChangeControl: raw.integrity_change_control.map(assetInformation =>
    parseSingleFilter(assetInformation, 'information_choices_integrity_change_control'),
  ),
  assetsImportance: raw.assets_importance.map(assetInformation =>
    parseSingleFilter(assetInformation, 'information_choices_assets_importance'),
  ),
  availability: raw.availability.map(assetInformation => parseSingleFilter(assetInformation, 'information_choices_availability')),
  confidentiality: raw.confidentiality.map(assetInformation => parseSingleFilter(assetInformation, 'information_choices_confidentiality')),
});
