import React, { useEffect, useMemo, useState } from 'react';

import { Button, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { add, isAfter, isPast } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import DateTimeDetails from 'components/DateTimeDetails';
// eslint-disable-next-line import/extensions
import ExternalLinkTile from 'components/ExternalLinkTile/ExternalLinkTile.tsx';
import FormTitle from 'components/FormTitle';
import meetingsEndpoints from 'config/api/meetings';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import meeting_messages from 'messages/meeting_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';

import DialogGrid from '../_components/DialogGrid';

const useStyles = makeStyles(theme => ({
  incidentReportRow: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  bold: {
    fontWeight: 500,
  },
  agendaWrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  agendaElement: {
    paddingBottom: theme.spacing(2),
    borderBottom: `thin solid ${theme.palette.secondary[200]}`,
  },
}));

const BookMeetingDetailsDialog = ({ onClose, openBookDialogFromDetails, id, open, onCancel }) => {
  const { t } = useTranslation();
  const { loading, setLoaded } = useLoadingState(true);
  const { apiCall } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const [meetingData, setMeetingData] = useState();
  const [safetyCancelTime, setSafetyCancelTime] = useState(null);
  const [safetyEditTime, setSafetyEditTime] = useState(null);
  const { showConfirmationModal } = useConfirmationModalContext();
  const [isAdviser] = usePermissions([ROLES.PS_ROLES.SECURITY_ONLINE]);
  const [isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_SUPERVISOR]);

  const getInitialData = async () => {
    const [{ data: meetingDataFromAPI }, { data: safetyCancelTimeFromAPI }, { data: safetyEditTimeFromAPI }] = await (() => {
      if (isSupervisor) {
        return Promise.all([
          apiCall(meetingsEndpoints.getMeetingAsSupervisor(id)),
          apiCall(meetingsEndpoints.getTimePriorToCancelAsSupervisor(), {}, { cache: true }),
          apiCall(meetingsEndpoints.getTimePriorToEditAsSupervisor(), {}, { cache: true }),
        ]);
      }
      if (isAdviser) {
        return Promise.all([
          apiCall(meetingsEndpoints.getMeetingAsAdviser(id)),
          apiCall(meetingsEndpoints.getTimePriorToCancelAsAdviser(), {}, { cache: true }),
          apiCall(meetingsEndpoints.getTimePriorToEditAsAdviser(), {}, { cache: true }),
        ]);
      }
      return Promise.all([
        apiCall(meetingsEndpoints.getMeetingAsSecurityOfficer(id)),
        apiCall(meetingsEndpoints.getTimePriorToCancelAsSecurityOfficer(), {}, { cache: true }),
        apiCall(meetingsEndpoints.getTimePriorToEditAsSecurityOfficer(), {}, { cache: true }),
      ]);
    })();

    setMeetingData(meetingDataFromAPI);
    setSafetyCancelTime(safetyCancelTimeFromAPI);
    setSafetyEditTime(safetyEditTimeFromAPI);
    setLoaded();
  };

  const isPastOrCurrent = useMemo(() => {
    const date = new Date(meetingData?.begin);
    return isPast(date);
  }, [meetingData?.begin]);

  const cancelMeeting = async () => {
    const sure = await showConfirmationModal({
      title: t(...meeting_messages.cancel_meeting_confirm_title),
      body: t(...meeting_messages.cancel_meeting_confirm),
    });
    if (sure) {
      const { status } = await apiCall(meetingsEndpoints.cancelMeetingAsSecurityOfficer(id));
      if (status < 300) {
        enqueueSnackbar(t(...meeting_messages.meeting_canceled), { variant: 'success' });
        PubSub.publish(EVENTS.MEETINGS_UPDATED);
        if (onCancel) onCancel();
        onClose();
      }
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const isCancelDisabled = useMemo(
    () =>
      !safetyCancelTime ||
      isAfter(add(new Date(), { minutes: safetyCancelTime.min_minutes_prior_to_time_slot }), new Date(meetingData?.begin)),
    [safetyCancelTime, meetingData?.begin],
  );

  const isEditDisabled = useMemo(
    () =>
      !safetyEditTime || isAfter(add(new Date(), { minutes: safetyEditTime.min_minutes_prior_to_time_slot }), new Date(meetingData?.begin)),
    [safetyEditTime, meetingData?.begin],
  );

  const styles = useStyles();
  return (
    <DialogGrid
      dialogActions={
        isPastOrCurrent ? null : (
          <CenteredGrid gridGap={2} withoutPadding>
            <ColoredButton
              customColor='secondary'
              disabled={loading || isEditDisabled}
              onClick={openBookDialogFromDetails}
              variant='outlined'
            >
              {t(...meeting_messages.update_meeting)}
            </ColoredButton>
            <Button data-testid='cancel-meeting-button' disabled={isCancelDisabled} onClick={cancelMeeting} variant='outlined'>
              {t(...meeting_messages.cancel_meeting)}
            </Button>
          </CenteredGrid>
        )
      }
      fullWidth={false}
      maxWidth='sm'
      onClose={onClose}
      open={open}
      title={t(...meeting_messages.book_meeting_details_dialog_title)}
    >
      <CenteredGrid tight>
        <DateTimeDetails date={meetingData?.begin} duration={{ minutes: 30 }} loading={loading} />
      </CenteredGrid>
      <CenteredGrid gridGap={2} tight title={t(...meeting_messages.contact_data_section_title)}>
        <ExternalLinkTile
          label={t(...meeting_messages.meeting_link_label)}
          title={t(...meeting_messages.meeting_link_title)}
          url={meetingData?.video_link}
        />
        <Typography>
          {t(...meeting_messages.meeting_link_phone_fallback)}
          <br />
          {loading ? <Skeleton width='30%' /> : <Typography component='span'>{meetingData?.phone_number}</Typography>}
        </Typography>
      </CenteredGrid>
      <FormTitle>{t(...meeting_messages.agenda_section_title)}</FormTitle>
      <div className={styles.agendaWrapper}>
        {loading ? (
          <Skeleton height='100px' />
        ) : (
          meetingData.agenda_items.map(({ topic }, index) => (
            <Typography key={`${topic}-${index}`} className={styles.agendaElement}>
              {topic}
            </Typography>
          ))
        )}
      </div>
      <CenteredGrid withoutPadding>
        <Typography>{loading ? <Skeleton width='30%' /> : meetingData?.contact || null}</Typography>
        {loading ? (
          <Skeleton height='150px' />
        ) : (
          meetingData.incident_report_id && (
            <>
              <Typography className={clsx(styles.incidentReportRow, styles.bold)}>{t(...general_messages.incident_report)}</Typography>
              <div className={styles.incidentReportRow}>
                <div>
                  <Typography className={styles.incidentReportRow}>{meetingData.incident_report_name}</Typography>
                  <Typography className={styles.incidentReportRow}>{meetingData.advice_on}</Typography>
                </div>
                <ColoredButton
                  component={Link}
                  customColor='secondary'
                  target='_blank'
                  to={`${PATHS.INCIDENT_REPORT}/${meetingData.incident_report_id}?redirect_url=${PATHS.BOOK_MEETING}&readOnly=true`}
                >
                  {t(...guide_messages.open_report)}
                </ColoredButton>
              </div>
            </>
          )
        )}
      </CenteredGrid>
    </DialogGrid>
  );
};

BookMeetingDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  openBookDialogFromDetails: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};

BookMeetingDetailsDialog.defaultProps = {
  onCancel: null,
};

export default BookMeetingDetailsDialog;
