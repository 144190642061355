import React, { createContext, useState, useContext } from 'react';

const ArticleContext = createContext(null);

const { Provider } = ArticleContext;

const ArticleProvider = props => {
  const [intersectionQueue, setIntersectionQueue] = useState([]);
  const [cursor, setCursor] = useState(0);

  const onIntersectIn = id => {
    const intersectIndex = intersectionQueue.indexOf(id);
    setCursor(intersectIndex);
  };
  const onIntersectOut = () => {
    setCursor(prev => prev - 1);
  };

  return <Provider value={{ intersectionQueue, setIntersectionQueue, cursor, setCursor, onIntersectOut, onIntersectIn }} {...props} />;
};

export const useArticleContext = () => useContext(ArticleContext);

export default ArticleProvider;
