import assetInformationChoicesApi from 'config/api/assetInformationChoices/assetInformationChoices';
import { AssetInformationFilters } from 'config/api/assetInformationChoices/assetInformationChoices.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import table_messages from 'messages/table_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const assetsImportanceFilter: DynamicTableFilter<AssetInformationFilters> = {
  sectionName: table_messages.assets_filters.assets_importance,
  isDynamic: true,
  queryFn: assetInformationChoicesApi.getAssetInformationChoicesForFilters,
  dataParser: ({ assetsImportance }) => assetsImportance,
  isSingleSelect: false,
  queryKey: QUERY_KEYS.ASSETS_INFORMATION_CHOICES,
};

export default assetsImportanceFilter;
