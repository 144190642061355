import Cookies from 'js-cookie';

class CookiesService {
  constructor(cookieKey, { string } = {}) {
    this.cookieKey = cookieKey;
    this.string = string;
  }

  get = () => {
    let data;
    try {
      data = Cookies.get(this.cookieKey);
      if (data === null) {
        return undefined;
      }
      return this.string ? data : JSON.parse(data);
    } catch {
      return data || undefined;
    }
  };

  set = data => {
    try {
      const dataJSON = this.string ? data : JSON.stringify(data);
      Cookies.set(this.cookieKey, dataJSON);
      return dataJSON;
    } catch {
      return undefined;
    }
  };

  destroy = () => {
    Cookies.remove(this.cookieKey);
    return true;
  };
}

export default CookiesService;
