import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';

import { parseSingleYearPlannerActivityForFE, parseYearPlannerActivityCreateInputForBE } from './yearPlannerActivities.parsers';
import type YearPlannerActivitiesApi from './yearPlannerActivities.types';

const endpoint = 'api/v1/year_planner_activities/';

// TODO: remove if react-query
const yearPlannerActivities: Record<string, (params: any) => { url: string; method: string }> = {
  getAllAssets: params => ({ url: createUrlWithParams(endpoint, params), method: METHOD.GET }),
};

export const yearPlannerActivitiesApi: YearPlannerActivitiesApi = {
  getSingleYearPlannerActivity: async id => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleYearPlannerActivityForFE(data);
  },
  removeYearPlannerActivity: async id => axios.delete(`${endpoint}${id}/`),
  createYearPlannerActivity: async data => axios.post(endpoint, parseYearPlannerActivityCreateInputForBE(data)),
  updateYearPlannerActivity: async ({ id, data }) => axios.patch(`${endpoint}${id}/`, parseYearPlannerActivityCreateInputForBE(data)),
};

export default yearPlannerActivities;
