import React, { FC } from 'react';

import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { RiskScaleValue } from 'config/api/assetRisks/assetRisks.types';
import asset_risk_messages from 'messages/asset_risk_messages';
import resolveRiskQuadrantColor from 'services/resolveRiskQuadrantColor/resolveRiskQuadrantColor';

import useStyles from './RiskQuadrantCell.styles';

type Props = {
  riskQuadrant: RiskScaleValue;
  consequence: RiskScaleValue;
  probability: RiskScaleValue;
  shrink?: boolean;
};

const RiskQuadrantCell: FC<Props> = ({ riskQuadrant, probability, consequence, shrink }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const styles = useStyles();

  return (
    <div className={styles.riskCell}>
      <div className={styles.riskQuadrant} style={{ backgroundColor: resolveRiskQuadrantColor(riskQuadrant, theme.palette) }}>
        {riskQuadrant}
      </div>
      <div>
        {shrink
          ? t(asset_risk_messages.risk_table.priority_cell_consequence_label_short)
          : t(asset_risk_messages.risk_table.priority_cell_consequence_label)}
        :&nbsp;{consequence}
      </div>
      <div>
        {shrink
          ? t(asset_risk_messages.risk_table.priority_cell_probability_label_short)
          : t(asset_risk_messages.risk_table.priority_cell_probability_label)}
        :&nbsp;{probability}
      </div>
    </div>
  );
};

export default RiskQuadrantCell;
