import React from 'react';

import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const CustomDayPicker = ({ label, value, onChange, ...rest }) => (
  <DatePicker
    autoOk
    data-testid={`${label}-datepicker`}
    disableToolbar
    inputVariant='outlined'
    label={label}
    onChange={onChange}
    value={value}
    variant='inline'
    {...rest}
  />
);

CustomDayPicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({}).isRequired,
};

CustomDayPicker.defaultProps = {
  label: null,
};

export default CustomDayPicker;
