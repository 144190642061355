import DEEP_LEVELS from 'config/translatableConstants/DEEP_LEVELS';
import general_messages from 'messages/general_messages';
import prepareDataForFilter from 'services/prepareDataForFilter';

const depthLevelFilter = {
  sectionName: general_messages.depth_level,
  filters: prepareDataForFilter(DEEP_LEVELS),
};

export default depthLevelFilter;
