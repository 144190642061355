import React from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';

import useStyles from './DetailsCardBaseSection.styles';

type Props = {
  title?: string;
  subtitle?: string;
  elements?: {
    key: string | number;
    name: string;
    value: React.ReactNode;
    wideLabel?: boolean;
  }[];
  children?: React.ReactNode;
  showDivider?: boolean;
};

const DetailsCardBaseSection: React.FC<Props> = ({ elements, title, subtitle, children, showDivider }) => {
  const styles = useStyles({ showDivider: !!showDivider });
  return (
    <div className={styles.section}>
      {!!title && <Typography variant='h3'>{title}</Typography>}
      {!!subtitle && <TypographyWithHTML variant='caption'>{subtitle}</TypographyWithHTML>}
      {elements && (
        <div className={styles.grid}>
          {elements.map(({ key, name, value, wideLabel }) => {
            if (!value) return null;
            return (
              <div key={key} className={clsx(styles.row, wideLabel && styles.wideLabel)}>
                <Typography>{name}</Typography>
                {typeof value === 'string' ? <TypographyWithHTML>{value}</TypographyWithHTML> : <div>{value}</div>}
              </div>
            );
          })}
        </div>
      )}
      {children}
    </div>
  );
};

export default DetailsCardBaseSection;
