import React, { useMemo } from 'react';

import { Divider, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import ButtonsGrid from 'components/_dialogs/_components/ButtonsGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import DetailsCardHeader from 'components/DetailsCardHeader/DetailsCardHeader';
import FormAutocomplete from 'components/FormAutocomplete';
import FormCheckboxTile from 'components/FormCheckboxTile';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import InputInfoWrapper from 'components/InputInfoWrapper/InputInfoWrapper';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import assetCategoriesApi from 'config/api/assetCategories/assetCategories';
import assetInformationChoicesApi from 'config/api/assetInformationChoices/assetInformationChoices';
import { assetsApi } from 'config/api/assets/assets';
import { AssetDetails, AssetInfo } from 'config/api/assets/assets.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import ROLES from 'config/constants/ROLES';
import safeT from 'helpers/safeT/safeT';
import usePermissions from 'hooks/usePermissions';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import coworkerOptionsParser from 'services/autocompleteServices/coworkerOptionsParser/coworkerOptionsParser';
import coworkerParamsGetter from 'services/autocompleteServices/coworkerParamsGetter/coworkerParamsGetter';
import coworkerResultComponent from 'services/autocompleteServices/coworkerResultComponent/coworkerResultComponent';
import { Id } from 'types/Id';

import useStyles from './AssetEditForm.styles';

type Props = {
  initialData: AssetDetails;
  id: Id;
};

export type EditAssetFormInput = {
  name: string;
  categoryId: Id;
  owner: { key: Id; fullName: string }[];
  info: AssetInfo & {
    legalDemandsCheckbox: boolean;
    identifiableInformationCheckbox: boolean;
    sensitiveIndividualInformationCheckbox: boolean;
  };
};

const FORM_ID = 'AssetEditForm';

const AssetEditForm: React.FC<Props> = ({ id, initialData }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isAssetManager] = usePermissions([ROLES.ORGANIZATION.ASSETS_MANAGER]);
  // in other case is asset owner

  const onClose = () => history.goBack();

  const mutation = useMutation(QUERY_KEYS.ASSETS_UPDATE, assetsApi.updateAsset, {
    onSuccess: () => {
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
      onClose();
    },
  });

  const onSubmit = (formData: EditAssetFormInput) => {
    mutation.mutate({ id, data: formData });
  };

  const { data: categoriesOptions } = useQuery(QUERY_KEYS.ASSETS_CATEGORIES, assetCategoriesApi.getAllAssetCategoriesForSelect);
  const { data: assetsInformationChoices } = useQuery(
    QUERY_KEYS.ASSETS_INFORMATION_CHOICES,
    assetInformationChoicesApi.getAssetInformationChoices,
  );

  const formik = useFormik<EditAssetFormInput>({
    onSubmit,
    initialValues: {
      name: initialData.name || '',
      categoryId: initialData.category.id || '',
      owner: initialData.owner ? [{ key: initialData.owner.id, fullName: initialData.owner.profile.fullName }] : [],
      info: {
        internalAssetIdentity: initialData.info.internalAssetIdentity || '',
        assetSubcategory: initialData.info.assetSubcategory || '',
        assetDescription: initialData.info.assetDescription || '',
        confidentiality: initialData.info.confidentiality || '',
        integrityChangeControl: initialData.info.integrityChangeControl || '',
        assetsImportance: initialData.info.assetsImportance || '',
        availability: initialData.info.availability || '',
        placementOfAsset: initialData.info.placementOfAsset || '',
        currentStatusOfAsset: initialData.info.currentStatusOfAsset || '',
        economicalValueOfAsset: initialData.info.economicalValueOfAsset || '',
        legalDemandsCheckbox: !!initialData.info.legalDemands,
        legalDemands: initialData.info.legalDemands || '',
        identifiableInformation: initialData.info.identifiableInformation || '',
        identifiableInformationCheckbox: !!initialData.info.identifiableInformation,
        sensitiveIndividualInformation: initialData.info.sensitiveIndividualInformation || '',
        sensitiveIndividualInformationCheckbox: !!initialData.info.sensitiveIndividualInformation,
      },
    },
  });

  const styles = useStyles();
  const { information_label: formMessages } = assets_messages;
  const sectionTitles = useMemo(
    () => ({
      section1: t(formMessages.section_title_1),
      section2: t(formMessages.section_title_2),
      section3: t(formMessages.section_title_3),
      section4: t(formMessages.section_title_4),
    }),
    [i18n.language],
  );

  return (
    <>
      <DetailsCardHeader
        onClose={onClose}
        subtitle={`${t(formMessages.friendly_id_label)}: ${initialData.key}`}
        title={t(initialData.name)}
      />
      <CenteredGrid className={styles.paddingSection} gridGap={1}>
        <Typography>{safeT(t, initialData.category.nameTranslationKey, initialData.category.name)}</Typography>
        <form className={styles.form} id={FORM_ID} onSubmit={formik.handleSubmit}>
          {/* @ts-ignore */}
          <CenteredGrid gridGap={2.5}>
            {sectionTitles.section1 && <Typography variant='h4'>{sectionTitles.section1}</Typography>}
            {isAssetManager && (
              <>
                <InputInfoWrapper description={t(formMessages.name_description)}>
                  <FormTextInput formik={formik} id='name' label={t(formMessages.name)} size='small' />
                </InputInfoWrapper>
                <InputInfoWrapper description={t(formMessages.category_description)}>
                  <FormSelect
                    formik={formik}
                    id='categoryId'
                    label={t(formMessages.category)}
                    options={categoriesOptions || []}
                    size='small'
                  />
                </InputInfoWrapper>
                <Divider />
              </>
            )}
            {sectionTitles.section2 && <Typography variant='h4'>{sectionTitles.section2}</Typography>}
            <InputInfoWrapper description={t(formMessages.internal_asset_identity_description)}>
              <FormTextInput formik={formik} id='info.internalAssetIdentity' label={t(formMessages.internal_asset_identity)} size='small' />
            </InputInfoWrapper>
            <InputInfoWrapper description={t(formMessages.asset_sub_category_description)}>
              <FormTextInput formik={formik} id='info.assetSubcategory' label={t(formMessages.asset_sub_category)} size='small' />
            </InputInfoWrapper>
            <InputInfoWrapper description={t(formMessages.asset_description_description)}>
              <FormTextInput
                formik={formik}
                id='info.assetDescription'
                label={t(formMessages.asset_description)}
                multiline
                rows={3}
                size='small'
              />
            </InputInfoWrapper>
            <Divider />
            {sectionTitles.section3 && <Typography variant='h4'>{sectionTitles.section3}</Typography>}
            <InputInfoWrapper description={t(formMessages.confidentiality_description)}>
              <FormSelect
                formik={formik}
                id='info.confidentiality'
                label={t(formMessages.confidentiality)}
                options={assetsInformationChoices?.confidentiality || []}
                size='small'
              />
            </InputInfoWrapper>
            <InputInfoWrapper description={t(formMessages.integrity_change_control_description)}>
              <FormSelect
                formik={formik}
                id='info.integrityChangeControl'
                label={t(formMessages.integrity_change_control)}
                options={assetsInformationChoices?.integrityChangeControl || []}
                size='small'
              />
            </InputInfoWrapper>
            <InputInfoWrapper description={t(formMessages.availability_description)}>
              <FormSelect
                formik={formik}
                id='info.availability'
                label={t(formMessages.availability)}
                options={assetsInformationChoices?.availability || []}
                size='small'
              />
            </InputInfoWrapper>
            <InputInfoWrapper description={t(formMessages.assets_importance_description)}>
              <FormSelect
                formik={formik}
                id='info.assetsImportance'
                label={t(formMessages.assets_importance)}
                options={assetsInformationChoices?.assetsImportance || []}
                size='small'
              />
            </InputInfoWrapper>
            <Divider />
            {sectionTitles.section4 && <Typography variant='h4'>{sectionTitles.section4}</Typography>}
            <InputInfoWrapper description={t(formMessages.placement_of_asset_description)}>
              <FormTextInput formik={formik} id='info.placementOfAsset' label={t(formMessages.placement_of_asset)} size='small' />
            </InputInfoWrapper>
            <InputInfoWrapper description={t(formMessages.current_status_of_asset_description)}>
              <FormTextInput formik={formik} id='info.currentStatusOfAsset' label={t(formMessages.current_status_of_asset)} size='small' />
            </InputInfoWrapper>
            <InputInfoWrapper description={t(formMessages.economical_value_of_asset_description)}>
              <FormTextInput
                formik={formik}
                id='info.economicalValueOfAsset'
                label={t(formMessages.economical_value_of_asset)}
                size='small'
              />
            </InputInfoWrapper>
            <Divider />
            <Typography variant='h4'>{t(formMessages.legal_title)}</Typography>
            <FormCheckboxTile
              description={t(formMessages.legal_demands_checkbox_description)}
              expandable
              formik={formik}
              id='info.legalDemandsCheckbox'
              label={t(formMessages.legal_demands_checkbox)}
              required={false}
            />
            <FormTextInput
              disabled={!formik.values.info.legalDemandsCheckbox}
              formik={formik}
              id='info.legalDemands'
              label={t(formMessages.legal_demands_input_label)}
              multiline
              rows={3}
              size='small'
            />
            <Divider />
            <Typography variant='h4'>{t(formMessages.gdpr_title)}</Typography>
            <FormCheckboxTile
              description={t(formMessages.identifiable_information_checkbox_description)}
              expandable
              formik={formik}
              id='info.identifiableInformationCheckbox'
              label={t(formMessages.identifiable_information_checkbox)}
              required={false}
            />
            <FormTextInput
              disabled={!formik.values.info.identifiableInformationCheckbox}
              formik={formik}
              id='info.identifiableInformation'
              label={t(formMessages.identifiable_information_input_label)}
              multiline
              rows={3}
              size='small'
            />
            <FormCheckboxTile
              description={t(formMessages.sensitive_individual_checkbox_description)}
              expandable
              formik={formik}
              id='info.sensitiveIndividualInformationCheckbox'
              label={t(formMessages.sensitive_individual_checkbox)}
              required={false}
            />
            <FormTextInput
              disabled={!formik.values.info.sensitiveIndividualInformationCheckbox}
              formik={formik}
              id='info.sensitiveIndividualInformation'
              label={t(formMessages.sensitive_individual_input_label)}
              multiline
              rows={3}
              size='small'
            />
            <Divider />
            {isAssetManager ? (
              <FormAutocomplete
                apiCallParamsGetter={coworkerParamsGetter}
                customizeLabel={coworkerResultComponent}
                formik={formik}
                id='owner'
                label={t(formMessages.owner)}
                multiple={false}
                optionsParser={coworkerOptionsParser}
              />
            ) : (
              <>
                <Typography variant='h4'>{t(formMessages.owner)}</Typography>
                <div className={styles.ownerTile}>
                  <UserAvatar size='m' userId={`${initialData.owner.id}`} />
                  <div className={styles.ownerName}>
                    <Typography>{initialData.owner.profile.fullName}</Typography>
                    <Typography>{initialData.owner.email}</Typography>
                  </div>
                </div>
              </>
            )}
          </CenteredGrid>
        </form>
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      </CenteredGrid>
    </>
  );
};

export default AssetEditForm;
