import { makeStyles } from '@material-ui/core';

type StylesProps = {
  linesToShow: number;
};

export default makeStyles(() => ({
  root: {
    display: '-webkit-box',
    '-webkit-line-clamp': (props: StylesProps) => props.linesToShow,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  tooltip: {
    maxWidth: '680px',
  },
}));
