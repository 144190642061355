import table_messages from 'messages/table_messages';

import booleanDictionary from '../booleanDictionary';

const userHasTwoFAFilter = {
  sectionName: table_messages.use_two_fa,
  filters: booleanDictionary,
  isSingleSelect: true,
};

export default userHasTwoFAFilter;
