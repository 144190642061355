import React from 'react';

import InfoBox, { Props as InfoBoxProps } from 'components/InfoBox/InfoBox';
import SpecialMessageWrapper from 'components/SpecialMessageWrapper';

type Props = InfoBoxProps;

const PageWithInfoBox: React.FC<Props> = infoBoxProps => (
  <SpecialMessageWrapper>
    <InfoBox {...infoBoxProps} />
  </SpecialMessageWrapper>
);

export default PageWithInfoBox;
