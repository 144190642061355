const stringShortener = (string: string, maxLength = 0, keepExtension = false) => {
  if (!string || Number.isNaN(maxLength)) return '';
  if (typeof string !== 'string') return '';
  if (string.length <= maxLength) return string;

  if (keepExtension) {
    const extension = string.split('.').pop();
    if (!extension) return string.substring(0, maxLength);
    return `${string.substring(0, maxLength - extension.length).trim()}(...).${extension}`;
  }

  return `${string.substring(0, maxLength).trim()}...`;
};

export default stringShortener;
