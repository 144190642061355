import React, { useEffect, useMemo } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import ButtonsGrid from 'components/_dialogs/_components/ButtonsGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import DetailsCardHeader from 'components/DetailsCardHeader/DetailsCardHeader';
import DialogViewWrapper from 'components/DialogViewWrapper';
import FormCheckboxTile from 'components/FormCheckboxTile';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import Loader from 'components/Loader';
import TimePeriodPickerForPlanner from 'components/TimePeriodPickerForPlanner/TimePeriodPickerForPlanner';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import YearPlannerActivityDownloads from 'components/YearPlannerActivityDownloads/YearPlannerActivityDownloads';
import YearPlannerActivityRelatedContent from 'components/YearPlannerActivityRelatedContent/YearPlannerActivityRelatedContent';
import assetActivitiesApi from 'config/api/assetActivities/assetActivities';
import assetCategoriesApi from 'config/api/assetCategories/assetCategories';
import { AssetCategory } from 'config/api/assetCategories/assetCategories.types';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { yearPlannerActivitiesApi } from 'config/api/yearPlannerActivities/yearPlannerActivities';
import { YearPlannerActivityDetails, YearPlannerActivityInput } from 'config/api/yearPlannerActivities/yearPlannerActivities.types';
import safeT from 'helpers/safeT/safeT';
import useYearPlannerAssetActivityQuery from 'hooks/_query/useYearPlannerAssetActivityQuery/useYearPlannerAssetActivityQuery';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import { Id } from 'types/Id';

import useStyles from './SingleYearPlannerAssetActivityEditPage.styles';

const FORM_ID = 'EditAssetActivityDialogForm';

type YearPlannerActivityFormInput = YearPlannerActivityInput;

const SingleYearPlannerAssetActivityEditPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { yearPlannerActivity, yearPlannerActivityIsLoading, activityId } = useYearPlannerAssetActivityQuery();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(MUTATION_KEYS.ASSETS_YEAR_PLANNER_UPDATE, yearPlannerActivitiesApi.updateYearPlannerActivity, {
    onSuccess: () => {
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    },
  });

  const onSubmit = async (formData: YearPlannerActivityFormInput) => {
    await mutation.mutateAsync({ id: activityId as Id, data: formData });
  };

  const { data: activitiesOptions } = useQuery(
    QUERY_KEYS.ASSETS_YEAR_PLANNER_ACTIVITIES,
    assetActivitiesApi.getAllAssetActivitiesForSelect,
  );
  const { data: categories } = useQuery(QUERY_KEYS.ASSETS_CATEGORIES, assetCategoriesApi.getAllAssetCategories);

  const formik = useFormik<YearPlannerActivityFormInput>({
    initialValues: {
      activityId: '',
      categoriesIds: {},
      end: { day: 1, month: 0 },
      begin: { day: 1, month: 0 },
      companyNotes: '',
    },
    onSubmit,
    validationSchema: yup.object({
      activityId: yup.string().required(t(validation_messages.required)),
      categoriesIds: yup
        .object()
        .test('category', t(validation_messages.year_activity_needs_one_category), (value: Record<number, boolean>): boolean =>
          Object.values(value).some(v => v),
        ),
      end: yup
        .object()
        .test(
          'end_date',
          t(validation_messages.required),
          (value: Record<string, boolean>): boolean => !isNil(value.day) && !isNil(value.month),
        ),
      begin: yup
        .object()
        .test(
          'end_date',
          t(validation_messages.required),
          (value: Record<string, boolean>): boolean => !isNil(value.day) && !isNil(value.month),
        ),
    }),
  });

  const fillFormWithInitialData = (
    { activity, end, begin, companyNotes, categories: selectedCategories }: YearPlannerActivityDetails,
    categoriesDictionary: AssetCategory[],
  ) => {
    formik.setValues({
      activityId: activity.id,
      categoriesIds: categoriesDictionary.reduce<Record<Id, boolean>>((accumulator, currentValue) => {
        accumulator[currentValue.id] = selectedCategories.some(({ id }) => id === currentValue.id);
        return accumulator;
      }, {}),
      end: { day: end.day, month: end.month - 1 },
      begin: { day: begin.day, month: begin.month - 1 },
      companyNotes,
    });
  };

  useEffect(() => {
    if (yearPlannerActivity && categories) fillFormWithInitialData(yearPlannerActivity, categories);
  }, [yearPlannerActivity, categories]);

  const selectedActivity = useMemo(() => {
    const chosenValue = formik.values.activityId;
    if (!chosenValue || !activitiesOptions) return null;
    const match = activitiesOptions.find(({ key }) => key === chosenValue);
    return match || null;
  }, [formik.values.activityId, activitiesOptions]);

  const onClose = () => {
    history.push(PATHS.ASSETS);
  };

  const styles = useStyles();
  return (
    <DialogViewWrapper contentSize='640px' noCardPadding title={t(assets_messages.details_activities_page.title)}>
      {yearPlannerActivityIsLoading || !yearPlannerActivity ? (
        <Loader inner />
      ) : (
        <>
          <div>
            <DetailsCardHeader
              onClose={onClose}
              title={safeT(t, yearPlannerActivity.activity.nameTranslationKey, yearPlannerActivity.activity.name)}
            />
            <form className={styles.paddingSection} id={FORM_ID} onSubmit={formik.handleSubmit}>
              <CenteredGrid gridGap={4} withoutPadding>
                <div className={styles.heading}>
                  <Typography variant='h3'>
                    {safeT(t, yearPlannerActivity.activity.nameTranslationKey, yearPlannerActivity.activity.name)}
                  </Typography>

                  <IconButton component={Link} to={PATHS.ASSETS}>
                    <CloseIcon color='secondary' />
                  </IconButton>
                </div>
                <div className={styles.section}>
                  <FormSelect
                    formik={formik}
                    id='activityId'
                    label={t(assets_messages.add_year_activity_asset_modal.activity)}
                    options={activitiesOptions || []}
                  />
                  {selectedActivity && (
                    <>
                      <Typography variant='h5'>
                        {t(assets_messages.year_activity_asset.activity_description_title, {
                          activityName: safeT(t, ...((selectedActivity?.label as string[] | undefined) || [])),
                        })}
                      </Typography>

                      <TypographyWithHTML>{safeT(t, ...(selectedActivity.metaData?.description || []))}</TypographyWithHTML>
                    </>
                  )}
                </div>
                <div className={styles.section}>
                  <Typography variant='h5'>{t(assets_messages.year_activity_asset.company_notes_title)}</Typography>
                  <FormTextInput
                    formik={formik}
                    id='companyNotes'
                    label={t(assets_messages.add_year_activity_asset_modal.company_notes)}
                    multiline
                    rows={3}
                  />
                </div>

                <div className={styles.section}>
                  <Typography variant='h5'>{t(assets_messages.year_activity_asset.dates_title)}</Typography>
                  <div className={styles.twoColumnSection}>
                    <TimePeriodPickerForPlanner formik={formik} id='begin' label={t(assets_messages.year_activity_asset.begin_date)} />
                    <TimePeriodPickerForPlanner formik={formik} id='end' label={t(assets_messages.year_activity_asset.end_date)} />
                  </div>
                </div>

                <div className={styles.section}>
                  <Typography variant='h5'>{t(assets_messages.year_activity_asset.categories_title)}</Typography>
                  <div className={styles.section}>
                    {categories?.map(({ name, nameTranslationKey, id }) => (
                      <FormCheckboxTile
                        key={id}
                        formik={formik}
                        id={`categoriesIds.${id}`}
                        label={safeT(t, nameTranslationKey, name)}
                        required={false}
                      />
                    ))}
                  </div>
                </div>
                <div className={styles.section}>
                  <Typography variant='h5'>{t(assets_messages.year_activity_asset.templates_title)}</Typography>
                  <YearPlannerActivityDownloads elements={yearPlannerActivity.activity.templates} />
                </div>
              </CenteredGrid>
            </form>
            <div className={styles.section}>
              <YearPlannerActivityRelatedContent
                relatedArticles={yearPlannerActivity.activity.relatedArticles}
                relatedGuides={yearPlannerActivity.activity.relatedGuides}
                relatedSelfTests={yearPlannerActivity.activity.relatedSelfTests}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <ButtonsGrid>
              {/* @ts-ignore */}
              <ColoredButton component={Link} customColor='none' to={PATHS.ASSETS} variant='outlined'>
                {t(general_messages.cancel)}
              </ColoredButton>
              {/* @ts-ignore */}
              <ColoredButton customColor='secondary' form={FORM_ID} type='submit' variant='outlined'>
                {t(general_messages.save)}
              </ColoredButton>
            </ButtonsGrid>
          </div>
        </>
      )}
    </DialogViewWrapper>
  );
};

export default SingleYearPlannerAssetActivityEditPage;
