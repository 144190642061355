import { SimpleUserProfile, UserProfileDTO } from 'types/UserProfile';

// eslint-disable-next-line import/prefer-default-export
export const parseUserProfileForFE = (dto: UserProfileDTO): SimpleUserProfile => ({
  email: dto.email,
  firstName: dto.first_name,
  lastName: dto.last_name,
  fullName: [dto.first_name, dto.last_name].filter(Boolean).join(' '),
  imageUrl: dto.image_url,
});
