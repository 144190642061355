import { useMemo } from 'react';

import { useQuery, useQueryClient } from 'react-query';

import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { TemplateSection, TemplateSectionWithValue, Workplace, WorkplaceTemplateValue } from 'config/api/workplaces/_types';
import { workplacesApi } from 'config/api/workplaces/workplaces';

type PrepareData = (
  baseData?: Workplace,
  template?: TemplateSection[],
  templateValues?: WorkplaceTemplateValue[],
) => { workplaceBaseData: Workplace; templateValues: TemplateSectionWithValue[] } | undefined;

const prepareData: PrepareData = (workplaceBaseData, template, templateValuesRawData) => {
  if (!workplaceBaseData || !template || !templateValuesRawData) return undefined;
  const templateValues: TemplateSectionWithValue[] = template.map(({ sectionName, contentElements }) => ({
    sectionName,
    contentElements: contentElements.map(({ id, name }) => ({
      name,
      id: +id,
      templateValueId: templateValuesRawData.find(({ content }) => +id === content)?.id as number,
      value: templateValuesRawData.find(({ content }) => +id === content)?.value || '',
    })),
  }));
  return {
    workplaceBaseData,
    templateValues,
  };
};
const useGetWorkplaceFullData = (workplaceId: number) => {
  const queryClient = useQueryClient();

  const baseWorkplaceQuery = useQuery([QUERY_KEYS.GET_WORKPLACE, workplaceId], () => workplacesApi.getWorkplaceInfo(workplaceId));
  const templateQuery = useQuery([QUERY_KEYS.GET_WORKPLACE_TEMPLATE, workplaceId], () => workplacesApi.getWorkplaceTemplate());
  const templateValuesQuery = useQuery([QUERY_KEYS.GET_WORKPLACE_TEMPLATE_VALUES, workplaceId], () =>
    workplacesApi.getWorkplaceTemplateValues(workplaceId),
  );

  const refreshData = () =>
    Promise.all([
      queryClient.invalidateQueries([QUERY_KEYS.GET_WORKPLACE, workplaceId]),
      queryClient.invalidateQueries([QUERY_KEYS.GET_WORKPLACE_TEMPLATE_VALUES, workplaceId]),
    ]);

  const { data, isLoading } = useMemo(
    (): { data?: { workplaceBaseData: Workplace; templateValues: TemplateSectionWithValue[] }; isLoading: boolean } => ({
      data: prepareData(baseWorkplaceQuery.data, templateQuery.data, templateValuesQuery.data),
      isLoading: baseWorkplaceQuery.isLoading || templateValuesQuery.isLoading || templateQuery.isLoading,
    }),
    [
      baseWorkplaceQuery.data,
      templateValuesQuery.data,
      templateQuery.data,
      baseWorkplaceQuery.isLoading,
      templateValuesQuery.isLoading,
      templateQuery.isLoading,
    ],
  );

  return { data, isLoading, refreshData };
};

export default useGetWorkplaceFullData;
