import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 240px',
    gridGap: theme.spacing(5),
    gridTemplateAreas: `
      'header manage'
      'body _'
    `,

    [theme.breakpoints.down('sm')]: {
      gridGap: theme.spacing(8),
      gridTemplateColumns: 'auto',
      gridTemplateAreas: `
      'header'
      'manage'
      'body'
    `,
    },
  },
}));
