import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import parseDate from 'helpers/parseDate';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';
import PATHS from 'router/PATHS';

import useStyles from './MeetingReportRightPanel.styles';

const PAGE_SIZE = 5;

const MeetingReportRightPanel = ({ data }) => {
  const { t } = useTranslation();
  const [resultsNum, setResultsNum] = useState(PAGE_SIZE);
  const showMore = () => setResultsNum(prev => prev + PAGE_SIZE);
  const showLess = () => setResultsNum(PAGE_SIZE);

  const advicesCount = data?.previous_meetings.length;

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <section>
        <Typography className={styles.secondaryHeader} component='h3' variant='h4'>
          {t(...meeting_messages.previous_advice, { advice_number: advicesCount || 0 })}
        </Typography>
        <div className={styles.contentBlock}>
          {data &&
            data.previous_meetings.slice(0, resultsNum).map(({ id, begin, adviser_profile }) => (
              <Link key={id} className={styles.link} target='_blank' to={`${PATHS.MEETING_REPORT}/${id}`}>
                {`${parseDate(begin)}, ${adviser_profile.first_name} ${adviser_profile.last_name}`}
              </Link>
            ))}
          {advicesCount + 1 >= resultsNum && (
            <button className={styles.linkButton} onClick={showMore} type='button'>
              {t(...general_messages.show_more)}
            </button>
          )}
          {resultsNum > PAGE_SIZE && (
            <button className={styles.linkButton} onClick={showLess} type='button'>
              {t(...general_messages.hide)}
            </button>
          )}
        </div>
      </section>
      <section>
        <Typography component='h3' variant='h4'>
          {t(...meeting_messages.meeting_with)}
        </Typography>
        <div className={styles.contentBlock}>
          {data?.org_officer && (
            <>
              <Typography>{`${data.org_officer.first_name} ${data.org_officer.last_name}`}</Typography>
              <Typography>{data.org_officer.email}</Typography>
            </>
          )}
          <Typography>{data?.phone_number}</Typography>
        </div>
      </section>
    </div>
  );
};

MeetingReportRightPanel.propTypes = {
  data: PropTypes.shape({
    org_officer: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    }),
    phone_number: PropTypes.string,
    previous_meetings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        begin: PropTypes.string.isRequired,
        adviser_profile: PropTypes.shape({
          last_name: PropTypes.string,
          first_name: PropTypes.string,
        }).isRequired,
      }),
    ).isRequired,
  }),
};

MeetingReportRightPanel.defaultProps = {
  data: null,
};
export default MeetingReportRightPanel;
