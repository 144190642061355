import table_messages from 'messages/table_messages';
import { StaticTableFilter } from 'types/TableFilter';

import booleanDictionary from '../../booleanDictionary';

const sensitiveIndividualInformationFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: table_messages.assets_filters.sensitive_individual_information,
  filters: booleanDictionary,
  isSingleSelect: true,
};

export default sensitiveIndividualInformationFilter;
