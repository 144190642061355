import React, { useEffect, useState } from 'react';

import { Skeleton } from '@material-ui/lab';
import { add, isFuture, set } from 'date-fns';
import { useTranslation } from 'react-i18next';

import TimeSlotsVerticalContainer from 'components/_calendar/TimeSlotsVerticalContainer';
import Section from 'components/Section';
import TimeSlot from 'components/TimeSlot/TimeSlot';
import meetingsEndpoints from 'config/api/meetings';
import ROLES from 'config/constants/ROLES';
import removeTimezoneFromTime from 'helpers/removeTimezoneFromTime';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import usePermissions from 'hooks/usePermissions';
import meeting_messages from 'messages/meeting_messages';
import { useAdvisorContext } from 'reactContext/AdvisorContext';
import PATHS from 'router/PATHS';

const LIMIT = 7;

const startDate = set(new Date(), { hours: 0, minutes: 0 });
const endDate = add(set(new Date(), { hours: 23, minutes: 59 }), { days: 30 });
removeTimezoneFromTime(startDate);
removeTimezoneFromTime(endDate);

const UpcomingMeetings = () => {
  const { t } = useTranslation();
  const { apiCall } = useApiCall();
  const [meetings, setMeetings] = useState(null);
  const { loading, setLoaded, setLoading } = useLoadingState(true);
  const { advisor } = useAdvisorContext();
  const [isAdviser, isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_ONLINE, ROLES.PS_ROLES.SECURITY_SUPERVISOR]);

  const getData = async () => {
    setLoading();
    const { data } = await (() => {
      if (isSupervisor) {
        return apiCall(
          meetingsEndpoints.getMeetingsAsSupervisor({
            time_slot__begin__gte: startDate.toISOString(),
            time_slot__end__lte: endDate.toISOString(),
            limit: LIMIT,
            adviser: advisor,
          }),
        );
      }
      if (isAdviser) {
        return apiCall(
          meetingsEndpoints.getMeetingsAsAdviser({
            time_slot__begin__gte: startDate.toISOString(),
            time_slot__end__lte: endDate.toISOString(),
            limit: LIMIT,
            adviser: advisor,
          }),
        );
      }
      return apiCall(
        meetingsEndpoints.getMeetingsAsSecurityOfficer({
          time_slot__begin__gte: startDate.toISOString(),
          time_slot__end__lte: endDate.toISOString(),
          limit: LIMIT,
          adviser: advisor,
        }),
      );
    })();
    const parsedMeetings = data.results
      .map(({ begin, ...rest }) => ({ date: new Date(begin), ...rest }))
      .filter(({ date }) => isFuture(date));
    setMeetings(parsedMeetings);
    setLoaded();
  };

  useEffect(() => {
    getData();
  }, [advisor]);

  const getHref = id => `${PATHS.MEETING_REPORT}/${id}`;

  if (!meetings?.length) return null;

  return (
    <Section gutters title={t(...meeting_messages.upcoming_meetings)}>
      {loading ? (
        <Skeleton height='125px' variant='rect' />
      ) : (
        <TimeSlotsVerticalContainer>
          {meetings.map(({ date, id }) => (
            <TimeSlot key={id} advisorView date={date} isAvailable showDate tileHref={getHref(id)} />
          ))}
        </TimeSlotsVerticalContainer>
      )}
    </Section>
  );
};

export default UpcomingMeetings;
