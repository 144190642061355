import React, { useEffect } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import ColoredButton from 'components/ColoredButton';
import DialogViewWrapper from 'components/DialogViewWrapper';
import FormSelect from 'components/FormSelect';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import organizationsApi from 'config/api/organizations/organizations';
import paymentsApi from 'config/api/payments/payments';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { TRANSLATABLE_ORGANIZATION_COUNTRIES_DICTIONARY } from 'config/translatableConstants/TRANSLATABLE_ORGANIZATION_COUNTRIES';
import usePaymentFallbackInfo from 'hooks/usePaymentFalbackInfo/usePaymentFallbackInfo';
import label_messages from 'messages/label_messages';
import payment_messages from 'messages/payment_messages';
import validation_messages from 'messages/validation_messages';
import userDataStorage from 'storages/userDataStorage';

import useStyles from './PrePaymentPage.styles';

type FormData = {
  country: string;
};

const PrePaymentPage: React.FC = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const { organization } = userDataStorage.get() || {};

  const queryClient = useQueryClient();
  const gotToStripe = async () => {
    const stripeUrl = await queryClient.fetchQuery(QUERY_KEYS.GET_STRIPE_URL, paymentsApi.getStripeUrl);
    window.location.replace(stripeUrl);
  };

  const organizationMutation = useMutation(MUTATION_KEYS.UPDATE_ORGANIZATION, organizationsApi.updatePartialOrganization);

  const updateCountry = async (newCountry: string) => {
    try {
      await organizationMutation.mutateAsync({ id: organization, inputData: { country: newCountry } });
      return true;
    } catch (e) {
      return false;
    }
  };

  const onSubmit = async (data: FormData) => {
    const updated = await updateCountry(data.country);
    if (updated) gotToStripe();
  };

  const formik = useFormik<FormData>({
    initialValues: {
      country: '',
    },
    onSubmit,
    validationSchema: yup.object({
      country: yup.string().required(t(validation_messages.required)),
    }),
  });

  const { data: organizationData } = useQuery([QUERY_KEYS.GET_ORGANIZATION_INFO, organization], () =>
    organizationsApi.getSingleOrganization(organization),
  );

  useEffect(() => {
    if (organizationData) {
      formik.setFieldValue('country', organizationData.country);
    }
  }, [organizationData]);

  usePaymentFallbackInfo();

  const styles = useStyles();
  return (
    <DialogViewWrapper title={t(payment_messages.heading)}>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <TypographyWithHTML>{t(payment_messages.description)}</TypographyWithHTML>
        <FormSelect
          formik={formik}
          id='country'
          label={t(label_messages.country)}
          options={TRANSLATABLE_ORGANIZATION_COUNTRIES_DICTIONARY}
          required
        />
        {/* @ts-ignore */}
        <ColoredButton customColor='secondary' disabled={!formik.isValid} type='submit'>
          {t(payment_messages.button_label)}
        </ColoredButton>
      </form>
    </DialogViewWrapper>
  );
};

export default PrePaymentPage;
