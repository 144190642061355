import { makeStyles } from '@material-ui/core';

type StylesProps = {
  sticky: boolean;
};

export default makeStyles(theme => ({
  container: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3, 4),
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gridGap: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    top: 0,
    zIndex: 10,
    position: ({ sticky }: StylesProps) => (sticky ? 'sticky' : 'static'),
  },
  icon: {
    // @ts-ignore
    color: theme.palette.secondary[900],
    position: 'relative',

    '&:not(:last-child):before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '30px',
      top: '20%',
      right: `-${theme.spacing(0.5) + 1}px`,
      // @ts-ignore
      background: theme.palette.secondary[800],
    },
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));
