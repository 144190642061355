import React from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import general_messages from 'messages/general_messages';
import questions_messages from 'messages/questions_messages';

import DialogGrid from '../_components/DialogGrid';

const AskQuestionSuccessDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid withoutPadding>
          <ColoredButton customColor='secondary' onClick={onClose} variant='outlined'>
            {t(...general_messages.close)}
          </ColoredButton>
        </CenteredGrid>
      }
      maxWidth='xs'
      onClose={onClose}
      open={open}
      title={t(...questions_messages.dialog_success_title)}
    >
      <Typography>{t(...questions_messages.dialog_success_description)}</Typography>
    </DialogGrid>
  );
};

export default AskQuestionSuccessDialog;
