import React from 'react';

import { makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: ({ elementsCount }) => `repeat(${elementsCount}, 1fr)`,
    background: theme.palette.primary[50],
    '& > *': {
      position: 'relative',
      padding: theme.spacing(5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4),
      },
    },
    '& > *:not(:last-child):after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: `calc(100% - ${theme.spacing(6)}px)`,
      top: theme.spacing(3),
      right: 0,
      width: '1px',
      background: theme.palette.primary[200],
      [theme.breakpoints.down('xs')]: {
        width: `calc(100% - ${theme.spacing(6)}px)`,
        height: '1px',
        bottom: 0,
        top: 'unset',
        left: theme.spacing(3),
      },
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: () => '1fr',
    },
  },
}));

const TilesGroup = ({ children }) => {
  const styles = useStyles({ elementsCount: children.filter(element => !!element).length || 1 });
  return (
    <Paper className={styles.wrapper} elevation={0}>
      {children}
    </Paper>
  );
};

TilesGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TilesGroup;
