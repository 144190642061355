import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  button: {
    padding: theme.spacing(0.25, 1),
    fontSize: '1rem',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
    // @ts-ignore
    fontFamily: theme.typography.fontFamilyBase,
    fontWeight: 700,
    textDecoration: 'underline',
  },
}));
