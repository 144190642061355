import ensureIsArray from 'helpers/ensureIsArray';
import userDataStorage from 'storages/userDataStorage';

// TODO refactor it to use ENUMS
const usePermissions = (rolesToCheck = []) => {
  const rolesToCheckArray = ensureIsArray(rolesToCheck);
  const { ps_roles, roles } = userDataStorage.get() || { ps_roles: [], roles: [] };

  return rolesToCheckArray.map(role => ps_roles.includes(role) || roles.includes(role));
};

export default usePermissions;
