import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import TRANSLATABLE_ACTIVITY_TYPES from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import general_messages from 'messages/general_messages';

export const contentTypeFilterFallback = [CONTENT_TYPES.GUIDE, CONTENT_TYPES.ARTICLE, CONTENT_TYPES.SELF_TEST];

const contentTypeFilter = {
  sectionName: general_messages.content_type,
  filters: Object.entries(CONTENT_TYPES).map(([key, id]) => ({ id, name: TRANSLATABLE_ACTIVITY_TYPES[key] })),
};

export default contentTypeFilter;
