import React, { FC, ReactElement } from 'react';

import { Button, ButtonProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import general_messages from 'messages/general_messages';

import useStyles from './PrintComponent.styles';

type Props = {
  templateComponent: ReactElement;
  ctaLabel?: React.ReactNode | string;
  ctaProps?: Omit<ButtonProps, 'children' | 'onClick'>;
  documentTitle?: string;
  ctaRenderer?: ({ onPrint }: { onPrint: ReturnType<typeof useReactToPrint> }) => ReactElement;
};

const PrintComponent: FC<Props> = ({ templateComponent, ctaLabel, ctaProps = {}, documentTitle = 'PocketSafe', ctaRenderer }) => {
  const { t } = useTranslation();
  const templateRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
    documentTitle,
  });

  const styles = useStyles();
  return (
    <>
      {ctaRenderer ? (
        ctaRenderer({ onPrint: handlePrint })
      ) : (
        <Button onClick={handlePrint} startIcon={<PrintIcon />} variant='contained' {...ctaProps}>
          {ctaLabel || t(general_messages.print)}
        </Button>
      )}
      <div className={styles.templateWrapper}>
        <div ref={templateRef}>{templateComponent}</div>
      </div>
    </>
  );
};

export default PrintComponent;
