import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import SimpleFormLayout from 'components/SimpleFormLayout';
import authEndpoints from 'config/api/auth';
import useApiCall from 'hooks/useApiCall';
import auth_messages from 'messages/auth_messages';
import PATHS from 'router/PATHS';

const PasswordResetSent = () => {
  const { t } = useTranslation();
  const { apiCall, sending } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const onSubmit = async e => {
    e.preventDefault();
    const { status } = await apiCall(authEndpoints.reset_password_request(), { data: { email: history.location.state?.email } });
    if (status === 204)
      enqueueSnackbar(t(...auth_messages.resend_request_email_notification), {
        variant: 'success',
      });
  };

  if (!history.location.state?.email) {
    history.push({
      pathname: PATHS.AUTH,
    });
  }

  return (
    <SimpleFormLayout
      buttonVariant='outlined'
      onSubmit={onSubmit}
      sending={sending}
      submitLabel={t(...auth_messages.resend_request_email)}
      title={t(...auth_messages.reset_password_sent)}
    >
      <Typography component='h3' variant='h3'>
        {t(...auth_messages.check_email)}
      </Typography>
      <Box pt={1}>
        <Typography variant='body1'>{t(...auth_messages.password_reset_link)}</Typography>
      </Box>
    </SimpleFormLayout>
  );
};

PasswordResetSent.propTypes = {};

export default PasswordResetSent;
