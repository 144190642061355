import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { yearPlannerActivitiesApi } from 'config/api/yearPlannerActivities/yearPlannerActivities';
import { Id } from 'types/Id';

const useYearPlannerAssetActivityQuery = () => {
  const { activityId } = useParams() as { activityId: Id };
  const { data, isLoading } = useQuery([QUERY_KEYS.ASSETS_YEAR_PLANNER_ACTIVITY, activityId], () =>
    yearPlannerActivitiesApi.getSingleYearPlannerActivity(activityId),
  );
  return { activityId, yearPlannerActivity: data, yearPlannerActivityIsLoading: isLoading };
};

export default useYearPlannerAssetActivityQuery;
