import React, { useState } from 'react';

import { ButtonBase, Collapse, List, makeStyles, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useLayoutStore from 'storages/layoutStore';

const useStyles = makeStyles(theme => ({
  section: {
    transition: '.2s',
    padding: ({ isMenuOpen }) => theme.spacing(isMenuOpen ? 2 : 1),
    display: ({ isMenuOpen, keepVisible }) => (isMenuOpen || keepVisible ? 'block' : 'none'),
    borderBottom: ({ divider }) => divider && `2px solid ${theme.palette.accents.separator}`,
    [theme.breakpoints.down('sm')]: {
      padding: () => theme.spacing(1),
    },
  },
  sectionTitleButton: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 30px',
  },
  title: {
    fontSize: '.875rem',
    textTransform: 'uppercase',
    padding: theme.spacing(1, 2),
    fontWeight: 500,
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  chevron: {
    transition: '.2s',
    transform: ({ isSectionOpen }) => (isSectionOpen ? 'rotate(180deg)' : 'rotate(0)'),
  },
}));

const MenuSection = ({ children, className, title, divider, collapsible, keepVisible, ...props }) => {
  const [isSectionOpen, setIsSectionOpen] = useState(!collapsible);
  const toggle = () => setIsSectionOpen(prev => !prev);
  const { isMenuOpen } = useLayoutStore();

  const styles = useStyles({ isMenuOpen, divider, keepVisible, isSectionOpen });

  if (!children) return null;
  if (!collapsible) {
    return (
      <div className={clsx(styles.section, className)} {...props}>
        {title && (
          <Typography className={styles.title} component='h4'>
            {title}
          </Typography>
        )}
        {children}
      </div>
    );
  }
  return (
    <List className={clsx(styles.section, className)} {...props}>
      {title && (
        <ButtonBase className={styles.sectionTitleButton} disableRipple onClick={toggle}>
          <Typography className={styles.title} component='h4'>
            {title}
          </Typography>
          <KeyboardArrowDownIcon className={styles.chevron} />
        </ButtonBase>
      )}
      <Collapse in={isSectionOpen} timeout='auto'>
        {children}
      </Collapse>
    </List>
  );
};

MenuSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  divider: PropTypes.bool,
  collapsible: PropTypes.bool,
  keepVisible: PropTypes.bool,
};

MenuSection.defaultProps = {
  className: null,
  title: null,
  divider: true,
  collapsible: true,
  keepVisible: false,
};

export default MenuSection;
