import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from './_methods';

const meetingsEndpoints = {
  getFreeSlots: params => ({ url: createUrlWithParams('api/v1/free_slots/', params), method: METHOD.GET }),

  // PS supervisor
  getAvailableSlotsAsSupervisor: params => ({ url: createUrlWithParams('api/v1/supervisor/time_slots/', params), method: METHOD.GET }),
  getAvailableSlotDetailsAsSupervisor: slot_id => ({ url: `api/v1/supervisor/time_slots/${slot_id}/`, method: METHOD.POST }),
  setAvailableSlotAsSupervisor: () => ({ url: 'api/v1/supervisor/time_slots/', method: METHOD.POST }),
  removeAvailableSlotAsSupervisor: slot_id => ({ url: `api/v1/supervisor/time_slots/${slot_id}/`, method: METHOD.DELETE }),

  changeAdvisorAsSupervisor: slot_id => ({ url: `/api/v1/supervisor/meetings/${slot_id}/change_adviser/`, method: METHOD.POST }),
  cancelMeetingAsSupervisor: meeting_id => ({ url: `api/v1/supervisor/meetings/${meeting_id}/`, method: METHOD.DELETE }),
  getMeetingsAsSupervisor: params => ({ url: createUrlWithParams('api/v1/supervisor/meetings/', params), method: METHOD.GET }),
  getMeetingAsSupervisor: meeting_id => ({ url: `api/v1/supervisor/meetings/${meeting_id}/`, method: METHOD.GET }),
  getReportAsSupervisor: meeting_id => ({ url: `api/v1/supervisor/meetings/${meeting_id}/report/`, method: METHOD.GET }),
  getAvailableAdvisorsAsSupervisor: slot_id => ({ url: `/api/v1/supervisor/meetings/${slot_id}/available_advisers/`, method: METHOD.GET }),
  getTimePriorToBookAsSupervisor: () => ({ url: '/api/v1/supervisor/meetings/time_prior_to_slot_to_book/', method: METHOD.GET }),
  getTimePriorToEditAsSupervisor: () => ({ url: '/api/v1/supervisor/meetings/time_prior_to_slot_to_edit/', method: METHOD.GET }),
  getTimePriorToCancelAsSupervisor: () => ({ url: '/api/v1/supervisor/meetings/time_prior_to_slot_to_cancel/', method: METHOD.GET }),

  getAllReportsAsSupervisor: params => ({ url: createUrlWithParams('/api/v1/supervisor/advice_reports/', params), method: METHOD.GET }),

  // PS advisor
  getAvailableSlotsAsAdviser: params => ({ url: createUrlWithParams('api/v1/adviser/time_slots/', params), method: METHOD.GET }),
  getAvailableSlotDetailsAsAdviser: slot_id => ({ url: `api/v1/adviser/time_slots/${slot_id}/`, method: METHOD.POST }),
  setAvailableSlotAsAdviser: () => ({ url: 'api/v1/adviser/time_slots/', method: METHOD.POST }),
  removeAvailableSlotAsAdviser: slot_id => ({ url: `api/v1/adviser/time_slots/${slot_id}/`, method: METHOD.DELETE }),

  getMeetingsAsAdviser: params => ({ url: createUrlWithParams('api/v1/adviser/meetings/', params), method: METHOD.GET }),
  getMeetingAsAdviser: meeting_id => ({ url: `api/v1/adviser/meetings/${meeting_id}/`, method: METHOD.GET }),
  getReportAsAdviser: meeting_id => ({ url: `api/v1/adviser/meetings/${meeting_id}/report/`, method: METHOD.GET }),
  getAvailableAdvisorsAsAdviser: slot_id => ({ url: `/api/v1/adviser/meetings/${slot_id}/available_advisers/`, method: METHOD.GET }),
  getTimePriorToBookAsAdviser: () => ({ url: '/api/v1/adviser/meetings/time_prior_to_slot_to_book/', method: METHOD.GET }),
  getTimePriorToEditAsAdviser: () => ({ url: '/api/v1/adviser/meetings/time_prior_to_slot_to_edit/', method: METHOD.GET }),
  getTimePriorToCancelAsAdviser: () => ({ url: '/api/v1/adviser/meetings/time_prior_to_slot_to_cancel/', method: METHOD.GET }),
  moveMeetingAsAdviser: slot_id => ({ url: `/api/v1/adviser/meetings/${slot_id}/change_time_slot/`, method: METHOD.POST }),
  createReportAsAdviser: meeting_id => ({ url: `api/v1/adviser/meetings/${meeting_id}/report/`, method: METHOD.POST }),
  updateReportAsAdviser: meeting_id => ({ url: `api/v1/adviser/meetings/${meeting_id}/report/`, method: METHOD.PUT }),
  publishReportAsAdviser: meeting_id => ({ url: `/api/v1/adviser/meetings/${meeting_id}/publish_report/`, method: METHOD.POST }),
  absentCustomerAsAdviser: (meeting_id, overrideMethod) => ({
    url: `api/v1/adviser/meetings/${meeting_id}/customer_absent/`,
    method: overrideMethod || METHOD.POST,
  }),

  getAllReportsAsAdviser: params => ({ url: createUrlWithParams('/api/v1/adviser/advice_reports/', params), method: METHOD.GET }),

  // security officer
  createMeetingAsSecurityOfficer: () => ({ url: `api/v1/security_officer/meetings/`, method: METHOD.POST }),
  cancelMeetingAsSecurityOfficer: meeting_id => ({ url: `api/v1/security_officer/meetings/${meeting_id}/`, method: METHOD.DELETE }),
  updateMeetingAsSecurityOfficer: meeting_id => ({ url: `api/v1/security_officer/meetings/${meeting_id}/`, method: METHOD.PATCH }),
  getMeetingsAsSecurityOfficer: params => ({ url: createUrlWithParams('api/v1/security_officer/meetings/', params), method: METHOD.GET }),
  getMeetingAsSecurityOfficer: meeting_id => ({ url: `api/v1/security_officer/meetings/${meeting_id}/`, method: METHOD.GET }),
  getReportAsSecurityOfficer: meeting_id => ({ url: `api/v1/security_officer/meetings/${meeting_id}/report/`, method: METHOD.GET }),
  getTimePriorToBookAsSecurityOfficer: () => ({ url: '/api/v1/security_officer/meetings/time_prior_to_slot_to_book/', method: METHOD.GET }),
  getTimePriorToEditAsSecurityOfficer: () => ({ url: '/api/v1/security_officer/meetings/time_prior_to_slot_to_edit/', method: METHOD.GET }),
  getTimePriorToCancelAsSecurityOfficer: () => ({
    url: '/api/v1/security_officer/meetings/time_prior_to_slot_to_cancel/',
    method: METHOD.GET,
  }),

  getAllReportsAsSecurityOfficer: params => ({
    url: createUrlWithParams('/api/v1/security_officer/advice_reports/', params),
    method: METHOD.GET,
  }),
};

// usage for react-query
export const meetingsApi = {
  getKnowledgeAreas: () => axios.get('/api/v1/common/knowledge_areas'),
};

export default meetingsEndpoints;
