import asset_risk_messages from 'messages/asset_risk_messages';

import { RiskScaleValue } from '../api/assetRisks/assetRisks.types';

const RISK_ANALYSIS_PROBABILITY_SELECT: { key: RiskScaleValue; label: string[] }[] = [
  { key: 1, label: asset_risk_messages.risk_probability_scale[1] },
  { key: 2, label: asset_risk_messages.risk_probability_scale[2] },
  { key: 3, label: asset_risk_messages.risk_probability_scale[3] },
  { key: 4, label: asset_risk_messages.risk_probability_scale[4] },
];

export default RISK_ANALYSIS_PROBABILITY_SELECT;
