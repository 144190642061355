import { logObjects } from 'config/constants/logs';

const removeTail = (path, elements) => {
  const parts = path.split('/');
  parts.length -= elements;
  return parts.join('/');
};

const getParam = (path, root, index) => {
  const [, params] = path.split(root);
  return params.split('/')[index + 1];
};

const resolveLogData = (pathname, event) => {
  // remove query parameters
  let id;
  const [trimmedPath] = pathname.split('?');
  const logObject = Object.values(logObjects).find(({ paths }) => {
    let isRootTheSame = false;
    if (!paths) return false;
    paths.forEach(path => {
      const [root, ...params] = path.split('/:');
      const trimmedPathRoot = removeTail(trimmedPath, params.length);
      if (root === trimmedPathRoot) {
        isRootTheSame = true;
        const idIndex = params.findIndex(param => param === 'id');
        id = getParam(trimmedPath, trimmedPathRoot, idIndex);
      }
    });
    return isRootTheSame;
  });
  if (!logObject) return null;
  return {
    log_object_type: logObject.code,
    log_event: event,
    ...(id && { log_object_id: id }),
  };
};

export default resolveLogData;
