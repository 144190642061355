import axios from 'axios';

import logEndpoints from 'config/api/log';
import { events } from 'config/constants/logs';

const logEvent = ({ code }, id, event = events.OPENED) => {
  const data = {
    log_object_type: code,
    log_event: event,
    ...(id && { log_object_id: id }),
  };
  return axios(logEndpoints.fullLogEndpoint, { method: 'POST', data, headers: { 'Content-Type': 'application/json' } });
};

export default logEvent;
