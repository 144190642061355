import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  form: {
    paddingTop: theme.spacing(1.5),
  },
  ownerTile: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'auto 1fr',
  },
  ownerName: {
    display: 'grid',
  },
  paddingSection: {
    padding: theme.spacing(4),
  },
}));
