import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const resolveBackground = (theme, color) => {
  switch (color) {
    case 'white': {
      return theme.palette.common.white;
    }
    default: {
      return theme.palette.primary[50];
    }
  }
};

const useStyles = makeStyles(theme => ({
  header: {
    background: ({ background }) => resolveBackground(theme, background),
    minHeight: '132px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: theme.shape.borderRadiusLarge,
    borderBottomRightRadius: theme.shape.borderRadiusLarge,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
    padding: theme.spacing(0, 2),
    textAlign: 'center',
    position: 'relative',
    width: '100%',
  },
}));

const Jumbotron = ({ children, className, background }) => {
  const styles = useStyles({ background });
  return <header className={clsx(styles.header, className)}>{children}</header>;
};

Jumbotron.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  background: PropTypes.oneOf(['white', 'primary']),
};

Jumbotron.defaultProps = {
  children: null,
  className: '',
  background: 'primary',
};

export default Jumbotron;
