import axios from 'axios';

import { parseFeatureFlagsForFE } from './featureFlags.parsers';
import type FeatureFlagsApi from './featureFlags.types';

const endpoint = 'api/v1/feature_flags';

const featureFlagsApi: FeatureFlagsApi = {
  getFeatureFlags: async () => {
    const { data } = await axios.get(endpoint);
    return parseFeatureFlagsForFE(data);
  },
};

export default featureFlagsApi;
