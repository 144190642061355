import commonEndpoints from 'config/api/common';

const prepareTags = ({ tags }) => tags.map(tag => ({ key: tag, label: tag }));

const tagDictionary = {
  url: commonEndpoints.tags(),
  dataParser: (filter, result) => ({ ...result, tags: prepareTags(filter) }),
};

export default tagDictionary;
