import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  body: {
    padding: theme.spacing(5),
  },
  ownerTile: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(3),
  },
  ownerName: {
    display: 'grid',
  },
  documentRow: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto 32px',
    columnGap: theme.spacing(1),
  },
  details: {
    gridColumn: '1/-1',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contacts: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  contactRow: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr auto auto',
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
  },
  contactCell: {
    display: 'grid',
    gap: theme.spacing(1),
  },
  contactButtons: {
    display: 'grid',
    gridTemplateColumns: '32px 32px',
    gap: theme.spacing(1),
  },
}));
