import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PersonalInfoEditDialog from 'components/_dialogs/PersonalInfoEditDialog';
import PictureDialog from 'components/_dialogs/PictureDialog/PictureDialog';
import TilesGroup from 'components/TilesGroup';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import CONTACT_TYPES from 'config/constants/CONTACT_TYPES';
import useBoolState from 'hooks/useBoolState';
import general_messages from 'messages/general_messages';
import personal_info_messages from 'messages/personal_info_messages';

import userDataStorage from '../../../../storages/userDataStorage';

const useStyles = makeStyles(theme => ({
  tile: {
    display: 'grid',
    gridGap: theme.spacing(2),
    alignContent: 'start',
  },
  tileCenter: {
    alignContent: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  button: {
    padding: theme.spacing(0.25, 1),
    fontSize: '1rem',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamilyBase,
    fontWeight: 700,
    textDecoration: 'underline',
  },
}));

const SettingsTilesGroup = ({ data, type, FORM, refreshData }) => {
  const { id: userId } = userDataStorage.get() || {};
  const { t } = useTranslation();
  const { state: isPersonalInfoDialogOpen, setFalse: closePersonalInfoDialog, setTrue: openPersonalInfoDialog } = useBoolState(false);
  const { state: isPictureDialogOpen, setFalse: closePictureDialog, setTrue: openPictureDialog } = useBoolState(false);

  const styles = useStyles();
  const configured = !!data && (!!data.last_name || !!data.first_name);
  const hasPicture = !!data?.image_url;
  return (
    <>
      <TilesGroup>
        <article className={clsx(styles.tile, styles.tileCenter)}>
          {type !== CONTACT_TYPES.USER ? (
            <Typography align='center' variant='h2'>
              {t(...personal_info_messages.contact_roles[type])}
            </Typography>
          ) : (
            <UserAvatar disableUserCard size='l' userId={userId} />
          )}
          {type === CONTACT_TYPES.USER && (
            <button className={styles.button} onClick={openPictureDialog} type='button'>
              {hasPicture ? t(...personal_info_messages.edit_picture) : t(...personal_info_messages.edit_picture)}
            </button>
          )}
          <button className={styles.button} onClick={openPersonalInfoDialog} type='button'>
            {configured ? t(...general_messages.edit) : t(...general_messages.add)}
          </button>
        </article>
        {configured && (
          <article className={styles.tile}>
            <Typography component='h3' variant='subtitle2'>
              {type === CONTACT_TYPES.EMERGENCY ? t(...personal_info_messages.name_relation) : t(...personal_info_messages.name_title)}
            </Typography>
            <Typography variant='h3'>{data ? `${data.first_name} ${data.last_name}` : <Skeleton height='2rem' width='80%' />}</Typography>
            <Typography>{data ? data.title : <Skeleton width='50%' />}</Typography>
          </article>
        )}
        {configured && (
          <article className={styles.tile}>
            <Typography component='h3' variant='subtitle2'>
              {t(...personal_info_messages.address)}
            </Typography>
            <Typography>{data ? data.email : <Skeleton width='80%' />}</Typography>
            <Typography>{data ? data.phone_number : <Skeleton width='50%' />}</Typography>
          </article>
        )}
      </TilesGroup>
      {isPersonalInfoDialogOpen && (
        <PersonalInfoEditDialog
          FORM={FORM}
          initialData={data}
          onClose={closePersonalInfoDialog}
          open={isPersonalInfoDialogOpen}
          refreshData={refreshData}
          type={type}
        />
      )}
      {isPictureDialogOpen && <PictureDialog initialPicture={data.image_url} onClose={closePictureDialog} open={isPictureDialogOpen} />}
    </>
  );
};

SettingsTilesGroup.propTypes = {
  data: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    title: PropTypes.string,
    relation: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    role: PropTypes.string,
  }),
  type: PropTypes.oneOf(Object.values(CONTACT_TYPES)).isRequired,
  FORM: PropTypes.shape({
    FIRST_NAME: PropTypes.string.isRequired,
    LAST_NAME: PropTypes.string.isRequired,
    EMAIL: PropTypes.string.isRequired,
    PHONE_NUMBER: PropTypes.string.isRequired,
    TITLE: PropTypes.string.isRequired,
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
};

SettingsTilesGroup.defaultProps = {
  data: null,
};

export default SettingsTilesGroup;
