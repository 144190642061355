import { makeStyles } from '@material-ui/core';

const debug = false;

export default makeStyles(theme => ({
  templateWrapper: debug
    ? {
        position: 'absolute',
        top: '80px',
        left: 0,
        width: '100%',
        border: '1px solid red',
        padding: theme.spacing(2),
        background: '#fff',
        zIndex: 1000000,
      }
    : {
        display: 'none',
      },
}));
