import { makeStyles } from '@material-ui/core';

type StylesProps = {
  clickable: boolean;
};

export default makeStyles(theme => ({
  container: {
    // @ts-ignore
    border: `thin solid ${theme.palette.secondary[100]}`,
    padding: theme.spacing(2),

    cursor: ({ clickable }: StylesProps) => (clickable ? 'pointer' : 'auto'),
  },
}));
