import OrganizationPaymentStages from 'config/permissions/OrganizationPaymentStages';
import RolesOrganization from 'config/permissions/RolesOrganization';
import RolesPocketSafe from 'config/permissions/RolesPocketSafe';
import STAGES_WITH_ENABLED_FUNCTIONALITIES from 'config/permissions/STAGES_WITH_ENABLED_FUNCTIONALITIES';
import menu_messages from 'messages/menu_messages';
import { COMMON_DIALOG_TYPES } from 'reactContext/CommonDialogsContext';
import PATHS from 'router/PATHS';

type MenuSections = 'organization' | 'content' | 'userAuthorized' | 'userUnauthorized' | 'pocketSafeAdmin' | 'dashboards';

export type MenuElement = {
  label: string[];
  to?: string;
  allowedForPSRoles?: RolesPocketSafe[];
  allowedForOrganizationRoles?: RolesOrganization[];
  allowedInStages?: OrganizationPaymentStages[];
  dialogType?: string;
  disabled?: boolean;
};

const menuElements: Record<MenuSections, MenuElement[]> = {
  content: [
    { label: menu_messages.articles, to: PATHS.ARTICLES },
    {
      label: menu_messages.guides,
      to: PATHS.GUIDES,
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
    },
    {
      label: menu_messages.self_tests,
      to: PATHS.SELF_TESTS,
    },
  ],
  organization: [
    { label: menu_messages.workplaces, to: PATHS.WORKPLACES, allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES },
    {
      label: menu_messages.incident_management,
      to: PATHS.INCIDENT_REPORTS,
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
    },
    { label: menu_messages.coworkers, to: PATHS.USERS, allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES },
    { label: menu_messages.book_meeting, to: PATHS.BOOK_MEETING, allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES },
    {
      label: menu_messages.ask_question,
      dialogType: COMMON_DIALOG_TYPES.ASK_QUESTION,
      allowedForOrganizationRoles: [RolesOrganization.SecurityOfficer],
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
    },
    { label: menu_messages.meeting_advices, to: PATHS.MEETING_ADVICES, allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES },
    { label: menu_messages.assets, to: PATHS.ASSETS, allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES },
    { label: menu_messages.organization_administration, to: PATHS.ORGANIZATION_ADMINISTRATION },
  ],
  dashboards: [
    {
      label: menu_messages.asset_task_dashboard,
      to: PATHS.ASSET_TASKS_DASHBOARD,
      allowedInStages: STAGES_WITH_ENABLED_FUNCTIONALITIES,
      allowedForOrganizationRoles: [RolesOrganization.AssetsManager, RolesOrganization.AssetTaskDashboardAccess],
    },
  ],
  userAuthorized: [{ label: menu_messages.profile, to: PATHS.MY_PROFILE }],
  userUnauthorized: [{ label: menu_messages.sign_up, to: PATHS.AUTH_REGISTER }],
  pocketSafeAdmin: [
    { label: menu_messages.manage_content, to: PATHS.MANAGE_CONTENT },
    { label: menu_messages.meeting_time_slots, to: PATHS.MANAGE_MEETING_SLOTS },
    { label: menu_messages.advisory_page, to: PATHS.ADVISORY_PAGE },
    { label: menu_messages.questions, to: PATHS.QUESTIONS },
  ],
};

export default menuElements;
