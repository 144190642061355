import { makeStyles } from '@material-ui/core';

const resolveColor = (color, colorPalette) => {
  switch (color) {
    case 'primary':
      return colorPalette.primary[100];
    case 'secondary':
    default:
      return colorPalette.secondary[50];
  }
};

export default makeStyles(theme => ({
  container: {
    borderRadius: theme.shape.borderRadiusLarge,
    background: ({ color }) => resolveColor(color, theme.palette),
    padding: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: ({ centered }) => centered && 'center',
    width: '100%',
    cursor: ({ disableRipple }) => disableRipple && 'auto',
  },
  tile: {
    display: 'grid',
    gridGap: theme.spacing(2),
    justifyContent: 'stretch',
    minWidth: '220px',
  },
  children: {
    marginLeft: theme.spacing(4),
  },
}));
