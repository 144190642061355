import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import general_messages from 'messages/general_messages';

import useStyles from './CookiesInfo.styles';

type Props = {
  onClose: () => void;
};

const CookiesInfo: React.FC<Props> = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.content} variant='caption'>
        <Trans i18nKey={general_messages.cookie_consent[0]}>
          We use cookies on this site to improve performance. By browsering this site you are agreeing to this. For more information see our
        </Trans>
        &nbsp;
        <a href={`/assets/documents/${i18n.language}/ps_gdpr_policy.pdf`} rel='noreferrer' target='_blank'>
          <Trans i18nKey={general_messages.cookie_privacy_policy[0]}>Privacy policy</Trans>
        </a>
      </Typography>
      <Button color='primary' onClick={onClose} size='small' variant='contained'>
        {t(general_messages.understand)}
      </Button>
    </div>
  );
};

export default CookiesInfo;
