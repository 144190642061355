import table_messages from 'messages/table_messages';
import { StaticTableFilter } from 'types/TableFilter';

import booleanDictionary from '../../booleanDictionary';

const legalDemandsFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: table_messages.assets_filters.legal_demands,
  filters: booleanDictionary,
  isSingleSelect: true,
};

export default legalDemandsFilter;
