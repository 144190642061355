import React, { useCallback, useMemo, useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { publish } from 'pubsub-js';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import WorkplaceDocumentDialog from 'components/_dialogs/WorkplaceDocumentDialog/WorkplaceDocumentDialog';
import ColoredButton from 'components/ColoredButton';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import Table from 'components/Table';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import workplaceDocumentsApi, { legacyWorkplaceDocumentsApi } from 'config/api/workplaceDocuments/workplaceDocuments';
import { WorkplaceDocument } from 'config/api/workplaceDocuments/workplaceDocuments.types';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import usePagination from 'hooks/usePagination';
import usePermissions from 'hooks/usePermissions';
import useSubscription from 'hooks/useSubscription';
import general_messages from 'messages/general_messages';
import workplace_messages from 'messages/workplace_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import type { TableDataMappingRow } from 'types/Table';

import useStyles from './WorkplacesDocumentsTable.styles';

const WorkplacesDocumentsTable: React.FC = () => {
  const { t } = useTranslation();
  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);
  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();
  const { enqueueSnackbar } = useSnackbar();
  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    refreshData,
  } = usePagination({
    endpointFunc: legacyWorkplaceDocumentsApi.getAllWorkplaceDocuments,
    limits: [5, 5, 5],
    dependencies: [sort],
    sort,
    isGet: true,
  });

  useSubscription(EVENTS.WORKPLACE_DOCUMENTS_UPDATED, refreshData);

  const [documentDialog, setDocumentDialog] = useState<{ id: number | null; open: boolean }>({ id: null, open: false });
  const closeDocumentDialog = useCallback(() => setDocumentDialog({ id: null, open: false }), [setDocumentDialog]);

  const onOpenDocumentDialog = (id: number | null) => () => {
    setDocumentDialog({ id, open: true });
  };

  const onDeleteWorkplace = (workplaceDocument: WorkplaceDocument) => async () => {
    const confirmation = await showConfirmationModal({
      title: t(workplace_messages.remove_document.confirmation_title),
      body: t(workplace_messages.remove_document.confirmation_body, { documentName: workplaceDocument.attachment.name }),
    });
    if (confirmation) {
      try {
        await workplaceDocumentsApi.removeWorkplaceDocument(workplaceDocument.id);
        publish(EVENTS.WORKPLACE_DOCUMENTS_UPDATED);
      } catch (e) {
        enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
      }
    }
  };

  const renderContextMenu = (data: WorkplaceDocument) => {
    const editMenuItem = { label: t(general_messages.edit), onClick: onOpenDocumentDialog(data.id) };
    const deleteMenuItem = { label: t(general_messages.delete), onClick: onDeleteWorkplace(data) };

    const menuItems = [editMenuItem, deleteMenuItem];

    return (
      <ContextMenu elements={menuItems} id={`workplace-table-row-${data.id}`}>
        <IconButton>
          <MoreIcon />
        </IconButton>
      </ContextMenu>
    );
  };

  const styles = useStyles();

  const dataMapping = useMemo(() => {
    const base: TableDataMappingRow<WorkplaceDocument>[] = [
      {
        id: 'attachment__name',
        width: isAccountOwner ? '24%' : '30%',
        label: t(workplace_messages.documents.table_label_filename),
        get: ({ attachment }) => (
          <Typography className={styles.link} component='a' href={attachment.url} rel='noreferrer' target='_blank'>
            {attachment.name}
          </Typography>
        ),
      },
      {
        id: 'description',
        width: '70%',
        label: t(workplace_messages.documents.table_label_description),
        get: ({ description }) => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{description}</TypographyWithHTML>
          </TrimHTMLText>
        ),
      },
    ];

    if (isAccountOwner) {
      base.push({
        id: 'context',
        width: '6%',
        label: '',
        isAddon: true,
        get: (data): React.ReactNode => renderContextMenu(data),
      });
    }
    return base;
  }, [t, isAccountOwner]);

  return (
    <div className={styles.root}>
      <TypographyWithHTML>{t(workplace_messages.documents.description)}</TypographyWithHTML>
      {isAccountOwner && (
        // @ts-ignore
        <ColoredButton className={styles.button} customColor='secondary' onClick={onOpenDocumentDialog(null)} variant='outlined'>
          {t(workplace_messages.documents.button_add_document)}
        </ColoredButton>
      )}
      <Table data={tableData} dataMapping={dataMapping} onSort={onSort} refetching={loading} sort={sort} />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
      {documentDialog.open && (
        <WorkplaceDocumentDialog onClose={closeDocumentDialog} open={documentDialog.open} workplaceDocumentId={documentDialog.id} />
      )}
    </div>
  );
};

export default WorkplacesDocumentsTable;
