import assets_messages from 'messages/assets_messages';
import { StaticTableFilter } from 'types/TableFilter';

import booleanDictionary from '../../booleanDictionary';

const isOverdueTaskFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: assets_messages.asset_tasks_table.asset_overdue_filter_name,
  filters: booleanDictionary,
  isSingleSelect: true,
};

export default isOverdueTaskFilter;
