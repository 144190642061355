import create from 'zustand';

import { AnsweredQuestion } from 'config/api/selfTests/_types';

type SelfTestQuestionsStorage = {
  questionInstances: AnsweredQuestion[];
  setQuestion: (question: AnsweredQuestion) => void;
  updateQuestion: (question: AnsweredQuestion) => void;
  resetQuestions: () => void;
};

const useSelfTestQuestionStore = create<SelfTestQuestionsStorage>()(set => ({
  questionInstances: [],
  setQuestion: newQuestion =>
    set(state => {
      const shouldUpdate = !state.questionInstances?.some(existingQuestion => newQuestion.questionId === existingQuestion.questionId);
      if (shouldUpdate) {
        return { questionInstances: [...state.questionInstances, newQuestion] };
      }
      return state;
    }),
  updateQuestion: question =>
    set(state => {
      const updatedQuestionIndex = state.questionInstances.findIndex(existing => existing.id === question.id);
      const currentQuestions = [...state.questionInstances];
      currentQuestions[updatedQuestionIndex] = question;
      return {
        questionInstances: currentQuestions,
      };
    }),
  resetQuestions: () => set(() => ({ questionInstances: [] })),
}));

export default useSelfTestQuestionStore;
