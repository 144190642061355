import { makeStyles } from '@material-ui/core';

type StyleProps = {
  length: number | undefined;
};

export default makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: ({ length }: StyleProps) => `repeat(${length}, 270px)`,
    gridGap: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
