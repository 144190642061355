import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    top: theme.spacing(2),
    gridArea: 'info',
  },
  secondaryHeader: {
    color: theme.palette.secondary[900],
  },
  contentBlock: {
    margin: theme.spacing(2, 0),
    '& > *': {
      marginBottom: theme.spacing(1),
      fontSize: '14px',
    },
  },
  link: {
    display: 'block',
    color: theme.palette.text.primary,
  },
  linkButton: {
    display: 'block',
    color: theme.palette.text.secondaryColor,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: 0,
  },
}));
