import { UserKpiStatistics, UserKpiStatisticsDTO, UserKpiStatisticsKeys } from './_types';

export const parseUserKpiStatisticsForFE = (raw: UserKpiStatisticsDTO): UserKpiStatistics => ({
  userId: raw.user_id,
  beginDate: new Date(raw.begin_date),
  reportedIncidents: raw.reported_incidents,
  completedGuides: raw.completed_guides,
  notCompletedGuides: raw.not_completed_guides,
  completedSelfTests: raw.completed_self_tests,
  notCompletedSelfTests: raw.not_completed_self_tests,
});

export const parseKpiDataForUI = (kpiData: UserKpiStatistics): UserKpiStatisticsKeys => ({
  reportedIncidents: kpiData.reportedIncidents,
  completedGuides: kpiData.completedGuides,
  notCompletedGuides: kpiData.notCompletedGuides,
  completedSelfTests: kpiData.completedSelfTests,
  notCompletedSelfTests: kpiData.notCompletedSelfTests,
});
