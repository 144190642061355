import React from 'react';

import { Checkbox, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormCheckboxTile from 'components/FormCheckboxTile';
import FormTextInput from 'components/FormTextInput';
import usersEndpoints from 'config/api/users/users';
import useApiCall from 'hooks/useApiCall';
import organization_administration_messages from 'messages/organization_administration_messages';
import validation_messages from 'messages/validation_messages';
import { useAuthContext } from 'reactContext/AuthContext';
import userDataStorage from 'storages/userDataStorage';

import DialogGrid from '../_components/DialogGrid';

const FORM = {
  REASON: 'reason',
  AGREEMENT: 'agreement',
};

const TerminateAccountDialog = ({ onClose, open }) => {
  const { organization } = userDataStorage.get() || {};

  const { t } = useTranslation();

  const { apiCall, sending } = useApiCall();
  const { onLogout } = useAuthContext();

  const formik = useFormik({
    initialValues: {
      agreement: false,
    },
    isInitialValid: false,
    validationSchema: yup.object({
      [FORM.REASON]: yup.string().required(t(...validation_messages.required)),
      [FORM.AGREEMENT]: yup.bool().oneOf([true], t(...validation_messages.required)),
    }),
  });

  const terminate = async () => {
    const { status } = await apiCall(usersEndpoints.deactivateOrganization(organization), { data: { reason: formik.values[FORM.REASON] } });
    if (status < 300) {
      await onLogout({ omitAPICall: true });
    }
  };

  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid withoutPadding>
          <ColoredButton customColor='danger' disabled={!formik.isValid} onClick={terminate} showLoader={sending} variant='contained'>
            {t(...organization_administration_messages.terminate_dialog.button_label)}
          </ColoredButton>
        </CenteredGrid>
      }
      fullWidth={false}
      onClose={onClose}
      open={open}
      title={t(...organization_administration_messages.terminate_dialog.title)}
    >
      <CenteredGrid gridGap={4} withoutPadding>
        <Typography>{t(...organization_administration_messages.terminate_dialog.content)}</Typography>
        <FormTextInput formik={formik} id={FORM.REASON} label={t(...organization_administration_messages.reason)} required />
        <FormCheckboxTile
          control={<Checkbox data-testid='agreement-checkbox' />}
          formik={formik}
          id={FORM.AGREEMENT}
          label={t(...organization_administration_messages.terminate_dialog.agreement)}
          required
        />
      </CenteredGrid>
    </DialogGrid>
  );
};

TerminateAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TerminateAccountDialog;
