import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  card: {
    padding: theme.spacing(3, 2),
    // @ts-ignore
    backgroundColor: theme.palette.warning.light,
    display: 'grid',
    gridGap: theme.spacing(4),
  },
  button: {
    justifySelf: 'center',
  },
}));
