import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ColoredButton from 'components/ColoredButton';
import Loader from 'components/Loader';
import Section from 'components/Section';
import TextButton from 'components/TextButton/TextButton';
import { RelatedAssetInIncidentReport } from 'config/api/incident_reports.types';
import useBoolState from 'hooks/useBoolState';
import assets_messages from 'messages/assets_messages';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import { Id } from 'types/Id';

import AssetIncidentReportDialog from '../AssetIncidentReportDialog/AssetIncidentReportDialog';
import AssetsInIncidentReportTable from '../AssetsInIncidentReportTable/AssetsInIncidentReportTable';

import useStyles from './AssetsInIncidentReport.styles';

type Props = {
  incidentReportId: Id;
  relatedAssets: RelatedAssetInIncidentReport[];
  isLoading: boolean;
};

const AssetsInIncidentReport: React.FC<Props> = ({ incidentReportId, relatedAssets, isLoading }) => {
  const { t } = useTranslation();
  const [assetToEdit, setAssetToEdit] = useState<RelatedAssetInIncidentReport | null>(null);
  const { state: isAssetReportDialogOpen, setTrue: openAssetReportDialog, setFalse: onCloseAssetReportDialog } = useBoolState(false);
  const { showHelpDialog } = useHelpDialogContext();
  const showInvolvedAssetsHelpDialog = () => {
    showHelpDialog({
      title: t(assets_messages.asset_involved_assets_introduction.title),
      body: t(assets_messages.asset_involved_assets_introduction.body),
    });
  };

  const closeAssetReportDialog = () => {
    onCloseAssetReportDialog();
    setTimeout(() => setAssetToEdit(null), 100);
  };

  const openEditDialog = (assetInIncidentReport: RelatedAssetInIncidentReport) => {
    setAssetToEdit(assetInIncidentReport);
  };

  useEffect(() => {
    if (assetToEdit) openAssetReportDialog();
  }, [assetToEdit]);

  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Section
        showSeparator
        smallPadding
        title={guide_messages.involved_assets}
        titleLineAddon={[
          <TextButton key='introduction' onClick={showInvolvedAssetsHelpDialog}>
            {t(assets_messages.asset_tasks_introduction.button)}
          </TextButton>,
          // @ts-ignore
          <ColoredButton customColor='secondary' onClick={openAssetReportDialog}>
            {t(general_messages.add)}
          </ColoredButton>,
        ]}
        titleVariant='h3'
      >
        {isLoading ? <Loader /> : <AssetsInIncidentReportTable openEditDialog={openEditDialog} tableData={relatedAssets} />}
        {isAssetReportDialogOpen && (
          <AssetIncidentReportDialog
            assetToEdit={assetToEdit}
            incidentReportId={incidentReportId}
            onClose={closeAssetReportDialog}
            open={isAssetReportDialogOpen}
          />
        )}
      </Section>
    </div>
  );
};

export default AssetsInIncidentReport;
