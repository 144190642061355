import React from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { RelatedAssetInIncidentReport } from 'config/api/incident_reports.types';
import { incidentPhaseInOrder, IncidentPhaseT } from 'config/constants/INCIDENT_PHASES';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import { incidentPriorityResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PRIORITIES';
import general_messages from 'messages/general_messages';
import print_template_messages from 'messages/print_template_messages';

import useStyles from '../PrintTemplate.styles';

export type PhaseInfoT = {
  id: number;
  heading: string;
  fields: { id: number; label: string; value: string }[];
};

export type IncidentReportPrintTemplateProps = {
  name: string;
  createdAt: Date;
  updatedAt: Date;
  categorization: string;
  isGdpr: boolean;
  isOngoing: boolean;
  priority?: string;
  steps: {
    heading: string;
    description: string;
    comment: string;
    id: string;
  }[];
  phasesInfo: Record<IncidentPhaseT, PhaseInfoT[]>;
  logs: {
    id: number;
    contacted: string;
    date_time: Date;
    phase: IncidentPhaseT;
    notes: string;
    actions: string;
  }[];
  relatedAssets: RelatedAssetInIncidentReport[];
};
// Forwards ref is necessary for proper work of react-to-print
const IncidentReportPrintTemplate: React.FC<IncidentReportPrintTemplateProps> = React.forwardRef(
  ({ name, categorization, priority, isOngoing, isGdpr, createdAt, updatedAt, logs, relatedAssets, steps, phasesInfo }) => {
    const { t } = useTranslation();

    const styles = useStyles();

    return (
      <main className={styles.root}>
        <section>
          <h2>{t(print_template_messages.incident_report.title)}</h2>
          <h3>{t(print_template_messages.incident_report.information_subtitle)}</h3>
          <dl>
            <div>
              <dt>{t(print_template_messages.incident_report.start)}</dt>
              <dd>{format(createdAt, 'yyyy-MM-dd HH:mm')}</dd>
            </div>
            <div>
              <dt>{t(print_template_messages.incident_report.last_edit)}</dt>
              <dd>{format(updatedAt, 'yyyy-MM-dd HH:mm')}</dd>
            </div>
          </dl>
          <dl>
            <div>
              <dt>{t(print_template_messages.incident_report.name)}</dt>
              <dd>{name}</dd>
            </div>
          </dl>
          <dl>
            <div>
              <dt>{t(print_template_messages.incident_report.categorization)}</dt>
              <dd>{categorization || t(general_messages.no_data)}</dd>
            </div>
          </dl>
          {isGdpr && <p className={styles.checkMark}>{t(print_template_messages.incident_report.is_gdpr)}</p>}
          {isOngoing && <p className={styles.checkMark}>{t(print_template_messages.incident_report.is_ongoing)}</p>}
          <dl>
            <div>
              <dt>{t(print_template_messages.incident_report.priority)}</dt>
              <dd>{t(incidentPriorityResolver(priority))}</dd>
            </div>
          </dl>
          <h3>{t(print_template_messages.incident_report.assets_subtitle)}</h3>
          {relatedAssets.length ? (
            <table>
              <thead>
                <tr>
                  <th>{t(print_template_messages.incident_report.table_asset_name)}</th>
                  <th>{t(print_template_messages.incident_report.table_asset_description)}</th>
                  <th>{t(print_template_messages.incident_report.table_asset_owner)}</th>
                </tr>
              </thead>
              <tbody>
                {relatedAssets.map(({ description, asset, id }) => (
                  <tr key={id}>
                    <td style={{ minWidth: '125px' }}>{asset.name}</td>
                    <td>{description}</td>
                    <td style={{ minWidth: '125px' }}>{[asset.owner.profile.first_name, asset.owner.profile.last_name].join(' ')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t(general_messages.no_data)}</p>
          )}
          <h3>{t(print_template_messages.incident_report.logs_subtitle)}</h3>
          {logs.length ? (
            <table>
              <thead>
                <tr>
                  <th>{t(print_template_messages.incident_report.table_head_contact)}</th>
                  <th>{t(print_template_messages.incident_report.table_head_date)}</th>
                  <th>{t(print_template_messages.incident_report.table_head_phase)}</th>
                  <th>{t(print_template_messages.incident_report.table_actions)}</th>
                  <th>{t(print_template_messages.incident_report.table_notes)}</th>
                </tr>
              </thead>
              <tbody>
                {logs.map(({ contacted, date_time, phase, id, actions, notes }) => (
                  <tr key={id}>
                    <td>{contacted}</td>
                    <td>{format(date_time, 'yyyy-MM-dd h:mm')}</td>
                    <td>{t(incidentPhaseResolver(phase))}</td>
                    <td>{actions}</td>
                    <td>{notes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>{t(general_messages.no_data)}</p>
          )}
        </section>
        <section>
          <h2>{t(print_template_messages.incident_report.steps_subtitle)}</h2>
          {steps.map(({ heading, description, comment, id }) => (
            <article key={id}>
              <h3>{heading}</h3>
              <dl>
                <div>
                  <dt>{t(print_template_messages.incident_report.step_description)}</dt>
                  <dd>{description}</dd>
                </div>
              </dl>
              <dl>
                <div>
                  <dt>{t(print_template_messages.incident_report.step_comment)}</dt>
                  <dd>{comment}</dd>
                </div>
              </dl>
            </article>
          ))}
        </section>
        <section>
          <h2>{t(print_template_messages.incident_report.phases_subtitle)}</h2>
          {incidentPhaseInOrder.map((phase: IncidentPhaseT) => (
            <article key={phase} className={styles.pt}>
              <h3>{t(incidentPhaseResolver(phase))}</h3>
              {phasesInfo[phase].length ? (
                phasesInfo[phase].map(({ id: phaseInfoId, heading, fields }) => (
                  <div key={phaseInfoId}>
                    <h4>{heading}</h4>
                    {fields.map(({ id: fieldId, label, value }) => (
                      <div key={fieldId}>
                        <dl>
                          <div>
                            <dt>{label}</dt>
                            <dd>{value || t(general_messages.no_data)}</dd>
                          </div>
                        </dl>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p>{t(general_messages.no_data)}</p>
              )}
            </article>
          ))}
        </section>
      </main>
    );
  },
);

export default IncidentReportPrintTemplate;
