import { makeStyles } from '@material-ui/core';

type StylesProps = {
  withImage: boolean;
  smallTile?: boolean;
};
// @ts-ignore
export default makeStyles(theme => ({
  paper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: ({ smallTile }: StylesProps) => (smallTile ? theme.shadows[1] : 'none'),
    opacity: '0.98',
    cursor: 'pointer',
    minWidth: ({ smallTile }: StylesProps) => (smallTile ? '180px' : '270px'),
    '&:hover': {
      opacity: '1',
      boxShadow: theme.shadows[2],
    },
    position: 'relative',
    paddingBottom: '50px',
  },
  link: {
    textDecoration: 'none',
    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '45px',
      background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
      bottom: '51px',
    },
  },
  heading: {
    color: theme.palette.text.primary,
  },
  imageWrapper: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    paddingBottom: '56%',
  },
  image: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    minHeight: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: ({ smallTile }: StylesProps) => (smallTile ? theme.spacing(2, 1, 0, 1) : theme.spacing(3, 3, 0, 3)),
    gridTemplateRows: ({ withImage }: StylesProps) => (withImage ? 'auto auto auto 1fr' : 'auto auto auto'),
  },
  greenText: {
    textTransform: 'uppercase',
    // @ts-ignore
    color: theme.palette.text.secondaryColor,
    letterSpacing: '1px',
    fontSize: ({ smallTile }: StylesProps) => (smallTile ? '.65rem' : '.75rem'),
    fontWeight: 500,
  },
  teaser: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    position: 'relative',
    maxHeight: ({ smallTile }: StylesProps) => (smallTile ? '115px' : '175px'),
    '& p': {
      margin: 0,
    },
  },
  footer: {
    // @ts-ignore
    borderTop: `thin solid ${theme.palette.secondary[100]}`,
    padding: theme.spacing(2, 3),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridGap: theme.spacing(3),
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}));
