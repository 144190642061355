import React from 'react';

import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';

const FormSwitch = ({ formik, id, label, disabled, className }) => {
  const { values, handleChange } = formik;

  return (
    <FormControl className={className} component='fieldset'>
      <FormControlLabel
        control={<Switch checked={values[id]} data-testid={`${id}-switch`} disabled={disabled} name={id} onChange={handleChange} />}
        label={label}
      />
    </FormControl>
  );
};

FormSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  formik: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({}),
  }).isRequired,
};

FormSwitch.defaultProps = {
  disabled: false,
  className: '',
};

export default FormSwitch;
