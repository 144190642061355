import React from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import article_messages from 'messages/article_messages';
import auth_messages from 'messages/auth_messages';
import PATHS from 'router/PATHS';

import useStyles from './ArticleLoginInfo.styles';

const ArticleLoginInfo = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <section>
      <Typography className='article-h2' component='h2' variant='h2'>
        {t(...article_messages.limited_access_title)}
      </Typography>
      <Typography className='article-body1' component='p'>
        {t(...article_messages.limited_access_body)}
      </Typography>
      <Link className={styles.link} to={{ pathname: PATHS.AUTH_LOGIN, state: { redirectedFrom: window.location.pathname } }}>
        {t(...auth_messages.navigation.login)}
      </Link>
      <Link className={styles.link} to={{ pathname: PATHS.AUTH_REGISTER, state: { redirectedFrom: window.location.pathname } }}>
        {t(...auth_messages.navigation.register)}
      </Link>
    </section>
  );
};

export default ArticleLoginInfo;
