import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const MAX_VALUE = 7;

const useStyles = makeStyles(theme => ({
  timeSlotsContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(${MAX_VALUE}, 1fr)`,
    gridGap: theme.spacing(1.5),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
    maxWidth: '100vw',
    overflowX: 'scroll',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
  },
}));

const TimeSlotsVerticalContainer = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.timeSlotsContainer}>{children}</div>;
};

TimeSlotsVerticalContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TimeSlotsVerticalContainer;
