import React from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './DescriptionText.styles';

const DescriptionText = ({ children, className, ...rest }) => {
  const styles = useStyles();
  return (
    <Typography className={clsx(styles.container, className)} {...rest}>
      {children}
    </Typography>
  );
};

DescriptionText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

DescriptionText.defaultProps = {
  className: null,
};

export default DescriptionText;
