import INCIDENT_PHASES from 'config/constants/INCIDENT_PHASES';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';

const { incident_phases } = guide_messages;

const TRANSLATABLE_INCIDENT_PHASES_FALLBACKS = {
  IDENTIFY_AND_REPORT: incident_phases.identify_and_report[1],
  RECEIVE: incident_phases.receive[1],
  MANAGE_THE_INCIDENT: incident_phases.manage_the_incident[1],
  CLOSE_AND_SEND_FEEDBACK: incident_phases.close_and_send_feedback[1],
  CLOSED: incident_phases.closed[1],
};

const TRANSLATABLE_INCIDENT_PHASES = {
  IDENTIFY_AND_REPORT: [incident_phases.identify_and_report[0], TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.IDENTIFY_AND_REPORT],
  RECEIVE: [incident_phases.receive[0], TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.RECEIVE],
  MANAGE_THE_INCIDENT: [incident_phases.manage_the_incident[0], TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.MANAGE_THE_INCIDENT],
  CLOSE_AND_SEND_FEEDBACK: [incident_phases.close_and_send_feedback[0], TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.CLOSE_AND_SEND_FEEDBACK],
  CLOSED: [incident_phases.closed[0], TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.CLOSED],
};

export const TRANSLATABLE_INCIDENT_PHASES_STRING_KEYS = {
  IDENTIFY_AND_REPORT: [
    incident_phases.identify_and_report[0],
    TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.IDENTIFY_AND_REPORT,
    INCIDENT_PHASES.IDENTIFY_AND_REPORT,
  ],
  RECEIVE: [incident_phases.receive[0], TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.RECEIVE, INCIDENT_PHASES.RECEIVE],
  MANAGE_THE_INCIDENT: [
    incident_phases.manage_the_incident[0],
    TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.MANAGE_THE_INCIDENT,
    INCIDENT_PHASES.MANAGE_THE_INCIDENT,
  ],
  CLOSE_AND_SEND_FEEDBACK: [
    incident_phases.close_and_send_feedback[0],
    TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.CLOSE_AND_SEND_FEEDBACK,
    INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK,
  ],
  CLOSED: [incident_phases.closed[0], TRANSLATABLE_INCIDENT_PHASES_FALLBACKS.CLOSED, INCIDENT_PHASES.CLOSED],
};

export const incidentPhaseResolver = key => {
  switch (key) {
    case INCIDENT_PHASES.IDENTIFY_AND_REPORT:
      return TRANSLATABLE_INCIDENT_PHASES.IDENTIFY_AND_REPORT;
    case INCIDENT_PHASES.RECEIVE:
      return TRANSLATABLE_INCIDENT_PHASES.RECEIVE;
    case INCIDENT_PHASES.MANAGE_THE_INCIDENT:
      return TRANSLATABLE_INCIDENT_PHASES.MANAGE_THE_INCIDENT;
    case INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK:
      return TRANSLATABLE_INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK;
    case INCIDENT_PHASES.CLOSED:
      return TRANSLATABLE_INCIDENT_PHASES.CLOSED;
    default:
      return general_messages.no_data;
  }
};

export default TRANSLATABLE_INCIDENT_PHASES;
