import { useCallback } from 'react';

import userDataStorage from 'storages/userDataStorage';
import { Id } from 'types/Id';

export const useIsOwner = () => {
  // @ts-ignore
  const { id } = userDataStorage.get() || { id: null };

  const checkIsOwner = useCallback((ownerId: Id) => ownerId === id, [id]);

  return { checkIsOwner };
};

export default useIsOwner;
