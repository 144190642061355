import React, { ReactElement } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';

import PrintComponent from '../_print/PrintComponent/PrintComponent';
import ContextMenu, { ContextMenuElement } from '../ContextMenu/ContextMenu';

import useStyles from './DetailsCardHeader.styles';

type Props = {
  title: string;
  subtitle?: string;
  onClose: () => void;
  onEdit?: () => void;
  printTemplate?: ReactElement;
  printDisabled?: boolean;
  contextMenu?: ContextMenuElement[];
  sticky?: boolean;
};

const DetailsCardHeader: React.FC<Props> = ({ title, onEdit, subtitle, onClose, printTemplate, contextMenu, printDisabled, sticky }) => {
  const styles = useStyles({ sticky: !!sticky });
  return (
    <div className={styles.container}>
      <div>
        <Typography variant='h2'>{title}</Typography>
        {subtitle && <Typography>{subtitle}</Typography>}
      </div>
      <div className={styles.buttons}>
        {!!printTemplate && (
          <PrintComponent
            ctaRenderer={({ onPrint }) => (
              <IconButton className={styles.icon} disabled={printDisabled} onClick={onPrint}>
                <PrintIcon />
              </IconButton>
            )}
            templateComponent={printTemplate}
          />
        )}
        {!!onEdit && (
          <IconButton className={styles.icon} onClick={onEdit}>
            <PenIcon />
          </IconButton>
        )}
        {!!contextMenu && (
          <div className={styles.icon}>
            <ContextMenu elements={contextMenu}>
              <IconButton>
                <MoreIcon />
              </IconButton>
            </ContextMenu>
          </div>
        )}
        <IconButton onClick={onClose}>
          <CloseIcon className={styles.icon} />
        </IconButton>
      </div>
    </div>
  );
};

export default DetailsCardHeader;
