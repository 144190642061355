import React from 'react';

import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import i18n from 'config/i18n';
import auth_messages from 'messages/auth_messages';
import RegisterEmailSent from 'pages/AuthPage/_components/RegisterEmailSent/RegisterEmailSent';
import PATHS, { AUTH_PATHS } from 'router/PATHS';

import ForgotPasswordForm from './_components/ForgotPasswordForm';
import LoginForm from './_components/LoginForm';
import PasswordChanged from './_components/PasswordChanged';
import PasswordResetForm from './_components/PasswordResetForm/PasswordResetForm';
import PasswordResetSent from './_components/PasswordResetSent';
import RegisterForm from './_components/RegisterForm/RegisterForm';

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    margin: `${theme.spacing(5)}px auto`,
    maxWidth: theme.sizes.container.sm,
  },
  formWrapper: {
    padding: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  nav: {
    padding: theme.spacing(1),
    borderTop: `thin solid ${theme.palette.primary.main}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 45%)',
    gridGap: '10%',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      padding: theme.spacing(2, 1),
    },
  },
}));

const resolveComponent = type => {
  switch (type) {
    case AUTH_PATHS.LOGIN:
      return <LoginForm />;
    case AUTH_PATHS.REGISTER:
      return process.env.REACT_APP_REGISTRATION_AVAILABLE === 'true' ? <RegisterForm /> : <Redirect to={AUTH_PATHS.LOGIN} />;
    case AUTH_PATHS.FORGOT_PASSWORD:
      return <ForgotPasswordForm />;
    case AUTH_PATHS.PASSWORD_RESET_LINK_SENT:
      return <PasswordResetSent />;
    case AUTH_PATHS.PASSWORD_RESET:
      return <PasswordResetForm />;
    case AUTH_PATHS.PASSWORD_CHANGED:
      return <PasswordChanged />;
    case AUTH_PATHS.ACCOUNT_ACTIVATE:
      return <PasswordResetForm activation />;
    case AUTH_PATHS.REGISTRATION_LINK_SENT:
      return <RegisterEmailSent />;
    default:
      return <LoginForm />;
  }
};

const resolveNavButtons = type => {
  const registrationAvailable = process.env.REACT_APP_REGISTRATION_AVAILABLE === 'true';
  const forgotPasswordButton = {
    path: AUTH_PATHS.FORGOT_PASSWORD,
    label: i18n.t(...auth_messages.navigation.forgot_password),
  };
  const registerButton = registrationAvailable
    ? {
        path: AUTH_PATHS.REGISTER,
        label: i18n.t(...auth_messages.navigation.register),
      }
    : {};
  const loginButton = {
    path: AUTH_PATHS.LOGIN,
    label: i18n.t(...auth_messages.navigation.login),
  };
  switch (type) {
    case AUTH_PATHS.LOGIN:
      return [forgotPasswordButton, registerButton];
    case AUTH_PATHS.REGISTER:
    case AUTH_PATHS.REGISTRATION_LINK_SENT:
      return [forgotPasswordButton, loginButton];
    case AUTH_PATHS.FORGOT_PASSWORD:
    case AUTH_PATHS.PASSWORD_RESET_LINK_SENT:
    case AUTH_PATHS.ACCOUNT_ACTIVATE:
    case AUTH_PATHS.PASSWORD_RESET:
    case AUTH_PATHS.PASSWORD_CHANGED:
      return [registerButton, loginButton];
    default:
      return [forgotPasswordButton, registerButton];
  }
};

const AuthTile = ({ type }) => {
  const navButtons = resolveNavButtons(type);
  const styles = useStyles();
  return (
    <Paper className={styles.paper} elevation={0}>
      <div className={styles.formWrapper}>{resolveComponent(type)}</div>
      <nav className={styles.nav}>
        {navButtons.map(({ label, path }) => {
          if (!label || !path) return null;
          return (
            <Button key={path} component={Link} size='small' to={`${PATHS.AUTH}/${path}`}>
              {label}
            </Button>
          );
        })}
      </nav>
    </Paper>
  );
};

AuthTile.propTypes = {
  type: PropTypes.string,
};

AuthTile.defaultProps = {
  type: 'login',
};

export default AuthTile;
