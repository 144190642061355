import React, { FC, useCallback, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { RiskMatrixData } from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses.types';
import asset_risk_messages from 'messages/asset_risk_messages';

import useStyles from './RiskMatrix.styles';

const SCALE = 4; // values from 1 to 4

const rowsBaseArray = Array.from({ length: SCALE }, (_, index) => index);

type Props = {
  matrixData?: RiskMatrixData;
};

const RiskMatrix: FC<Props> = ({ matrixData }) => {
  const { t } = useTranslation();

  const mappedRowData = useMemo(() => {
    if (!matrixData) return null;
    const groupedByConsequence = groupBy(matrixData.data, 'consequence');
    // BE response is sorted by probability, so sorting step is not needed
    return Object.values(groupedByConsequence).map(row => row.map(({ count }) => count));
  }, [matrixData]);

  const styles = useStyles();

  const resolveQuadrantClassname = useCallback(
    (i: number, j: number) => {
      const middle = Math.floor(SCALE / 2);
      if (i >= middle && j >= middle) return styles.matrixCellQuadrant1;
      if (i >= middle && j < middle) return styles.matrixCellQuadrant2;
      if (i < middle && j >= middle) return styles.matrixCellQuadrant3;
      if (i < middle && j < middle) return styles.matrixCellQuadrant4;

      return '';
    },
    [styles],
  );

  return (
    <table className={styles.matrix}>
      <tbody>
        <tr>
          <th rowSpan={5} style={{ width: '40px' }}>
            <Typography className={styles.yAxisLabel} variant='subtitle2'>
              {t(asset_risk_messages.matrix.consequence_label)}
            </Typography>
          </th>
        </tr>
        {[...rowsBaseArray].reverse().map(rowIndex => (
          <tr key={rowIndex}>
            <th scope='row' style={{ width: '40px' }}>
              <Typography className={clsx(styles.matrixHeader, styles.matrixHeaderY)}>
                {/* @ts-ignore */}
                {t(asset_risk_messages.risk_consequence_scale[rowIndex + 1])}
              </Typography>
            </th>
            {mappedRowData
              ? rowsBaseArray.map(cellIndex => (
                  <td
                    key={`${rowIndex}-${cellIndex}`}
                    className={clsx(styles.matrixCell, resolveQuadrantClassname(rowIndex, cellIndex))}
                    style={{ width: '23%' }}
                  >
                    <Typography align='center'>{mappedRowData[rowIndex][cellIndex]}</Typography>
                  </td>
                ))
              : Array.from({ length: SCALE }, (_, idx) => (
                  <td key={idx} className={styles.matrixCell} style={{ width: '23%' }}>
                    <Typography align='center'>0</Typography>
                  </td>
                ))}
          </tr>
        ))}

        <tr>
          <th className={styles.matrixCellBlank} colSpan={2} rowSpan={2}>
            _
          </th>
          {rowsBaseArray.map(cellIndex => (
            <th key={cellIndex} className={clsx(styles.matrixHeader, styles.matrixHeaderX)}>
              {/* @ts-ignore */}
              <Typography>{t(asset_risk_messages.risk_probability_scale[cellIndex + 1])}</Typography>
            </th>
          ))}
        </tr>
        <tr>
          <th colSpan={4}>
            <Typography className={styles.xAxisLabel} variant='subtitle2'>
              {t(asset_risk_messages.matrix.probability_label)}
            </Typography>
          </th>
        </tr>
      </tbody>
    </table>
  );

  // return (
  //   <div className={styles.matrixWrapper}>
  //     <Typography className={styles.yAxisLabel} variant='subtitle2'>
  //       {t(asset_risk_messages.matrix.consequence_label)}
  //     </Typography>
  //     <div className={styles.matrix}>
  //       {[...rowsBaseArray].reverse().map(rowIndex => (
  //         <>
  //           <div key={rowIndex} className={clsx(styles.matrixHeader, styles.matrixHeaderY)}>
  //             {/* @ts-ignore */}
  //             <Typography>{t(asset_risk_messages.risk_consequence_scale[rowIndex])}</Typography>
  //           </div>
  //           {mappedRowData
  //             ? rowsBaseArray.map(cellIndex => (
  //                 <div key={`${rowIndex}-${cellIndex}`} className={clsx(styles.matrixCell, resolveQuadrantClassname(rowIndex, cellIndex))}>
  //                   <Typography>{mappedRowData[rowIndex][cellIndex]}</Typography>
  //                 </div>
  //               ))
  //             : Array.from({ length: SCALE }, () => (
  //                 <div className={styles.matrixCell}>
  //                   <Typography>0</Typography>
  //                 </div>
  //               ))}
  //         </>
  //       ))}
  //       <>
  //         <div className={clsx(styles.matrixCell, styles.matrixCellBlank)} />
  //         {rowsBaseArray.map(cellIndex => (
  //           <div key={cellIndex} className={clsx(styles.matrixHeader, styles.matrixHeaderX)}>
  //             {/* @ts-ignore */}
  //             <Typography>{t(asset_risk_messages.risk_probability_scale[cellIndex])}</Typography>
  //           </div>
  //         ))}
  //       </>
  //     </div>
  //     <Typography className={styles.xAxisLabel} variant='subtitle2'>
  //       {t(asset_risk_messages.matrix.probability_label)}
  //     </Typography>
  //   </div>
  // );
};

export default RiskMatrix;
