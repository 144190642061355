import { set, isMonday, previousMonday, isSunday, nextSunday } from 'date-fns';

import removeTimezoneFromTime from 'helpers/removeTimezoneFromTime';

const generateWeekRange = date => {
  const start = isMonday(date) ? date : previousMonday(date);
  const end = isSunday(date) ? date : nextSunday(date);
  return {
    start: removeTimezoneFromTime(set(start, { hours: 0, minutes: 0 })),
    end: removeTimezoneFromTime(set(end, { hours: 23, minutes: 59 })),
  };
};

export default generateWeekRange;
