import React from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { ContentItem } from 'config/api/selfTests/_types';
import { contentTypeResolver } from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import PATHS from 'router/PATHS';

import useStyles from './ContentGuideTile.styles';

type Props = {
  data: ContentItem;
  minimal?: boolean;
  darker?: boolean;
  onClose?: undefined;
  // TODO remove after UI unification
  unified?: boolean;
};

const GuideTileLoader = () => {
  // @ts-ignore
  const styles = useStyles();

  return (
    <ButtonBase className={styles.tile} color='secondary'>
      <Skeleton height='2rem' width='60%' />
      <Skeleton height='4rem' width='100%' />
      <div className={styles.footer}>
        <Skeleton height='1.35rem' width='40%' />
        <Skeleton height='1.35rem' width='10%' />
      </div>
    </ButtonBase>
  );
};

const ContentGuideTile: React.FC<Props> = ({ data, darker, minimal, onClose, unified }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id, metadata, title, heading, description, serviceArea, language, objectContentType, versionId } = data;

  const url = `${PATHS.GUIDES}/${id}/${metadata?.versionId || versionId}?redirect_url=${location.pathname}`;
  const styles = useStyles({ darker, unified });

  return (
    <ButtonBase className={styles.tile} color='secondary' component={Link} data-testid={`${id}-guide`} onClick={onClose} to={url}>
      <Typography align='left' component='h2' variant='h3'>
        {title || heading}
      </Typography>
      <Typography align='left' component='p' variant='body1'>
        {description}
      </Typography>
      {!minimal && (
        <div className={styles.footer}>
          <Typography className={styles.footerFont} component='span'>{`${t(contentTypeResolver(objectContentType))}: ${t(
            serviceArea?.nameTranslationKey,
            serviceArea?.name,
          )}`}</Typography>
          <Typography className={styles.footerFont} component='span'>
            {language.languageCode}
          </Typography>
        </div>
      )}
    </ButtonBase>
  );
};

export { GuideTileLoader };
export default ContentGuideTile;
