import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import EditWorkplaceDialog from 'components/_dialogs/EditWorkplaceDialog/EditWorkplaceDialog';
import DetailsCardBaseSection from 'components/DetailsCardBaseSection/DetailsCardBaseSection';
import DetailsCardHeader from 'components/DetailsCardHeader/DetailsCardHeader';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { TemplateSectionWithValue } from 'config/api/workplaces/_types';
import type { Workplace } from 'config/api/workplaces/_types';
import { workplacesApi } from 'config/api/workplaces/workplaces';
import ROLES from 'config/constants/ROLES';
import useBoolState from 'hooks/useBoolState';
import usePermissions from 'hooks/usePermissions';
import useResponsiblePermission from 'hooks/useResponsiblePremission';
import workplace_messages from 'messages/workplace_messages';
import { useAuthContext } from 'reactContext/AuthContext';
import PATHS from 'router/PATHS';

import useStyles from './WorkplaceDetailsCard.styles';

type Props = {
  workplaceBaseData: Workplace;
  templateValues: TemplateSectionWithValue[];
  refreshData: () => Promise<any>;
};

const WorkplaceDetailsCard: React.FC<Props> = ({ workplaceBaseData, templateValues, refreshData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // @ts-ignore
  const { updateUserData } = useAuthContext();
  const isUserResponsible = useResponsiblePermission(workplaceBaseData.responsibles);
  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);

  const isAllowedToEdit = isUserResponsible || isAccountOwner;

  const defaultWorkplaceMutation = useMutation(QUERY_KEYS.SET_DEFAULT_WORKPLACE, workplacesApi.setUserDefaultWorkspace);

  useEffect(() => {
    (async () => {
      await defaultWorkplaceMutation.mutateAsync(workplaceBaseData.id);
      await updateUserData();
    })();
  }, []);

  const { state: editDialog, setTrue: openEditDialog, setFalse: closeEditDialog } = useBoolState(false);

  const onClose = async () => {
    await defaultWorkplaceMutation.mutateAsync(null);
    await updateUserData();
    history.push(PATHS.WORKPLACES);
  };

  const styles = useStyles();
  return (
    <>
      <DetailsCardHeader
        onClose={onClose}
        onEdit={isAllowedToEdit ? openEditDialog : undefined}
        subtitle={workplaceBaseData.location}
        title={workplaceBaseData.name}
      />
      <div className={styles.body}>
        <div>
          {templateValues.map(({ sectionName, contentElements }) => {
            if (!contentElements.length && !isAllowedToEdit) return null;
            return (
              <DetailsCardBaseSection
                key={sectionName}
                elements={contentElements
                  .filter(({ value }) => value || isAllowedToEdit)
                  .map(({ name, id, value }) => ({ name, value, key: id }))}
                title={sectionName}
              />
            );
          })}
        </div>
        <DetailsCardBaseSection showDivider title={t(workplace_messages.singleWorkplace.responsibles)}>
          <div className={styles.responsiblesGrid}>
            {workplaceBaseData.responsibles.length
              ? workplaceBaseData.responsibles.map(({ email, profile, id }) => (
                  <div key={id} className={styles.responsibleTile}>
                    <UserAvatar size='m' userData={profile} userId={`${id}`} />
                    <div className={styles.responsibleName}>
                      <Typography>{profile.fullName}</Typography>
                      <Typography>{email}</Typography>
                    </div>
                  </div>
                ))
              : t(workplace_messages.singleWorkplace.emptyResponsibles)}
          </div>
        </DetailsCardBaseSection>
      </div>
      <EditWorkplaceDialog onClose={closeEditDialog} open={editDialog} refreshData={refreshData} workplaceId={workplaceBaseData.id} />
    </>
  );
};

export default WorkplaceDetailsCard;
