import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(5),
  },
  contentWrapper: {
    display: 'grid',
    gridGap: theme.spacing(4),
  },
}));
