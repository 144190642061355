import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  autocompleteLabel: {
    display: 'grid',
    gridTemplateColumns: '70px 140px 1fr',
    gridGap: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '18px',
  },
}));
