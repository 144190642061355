import React from 'react';

import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import { ReactComponent as StepIcon } from 'assets/icons/step.svg';
import { ReactComponent as SubStepIcon } from 'assets/icons/substep.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import general_messages from 'messages/general_messages';

const NESTING_DEPTH = 32;

const useStyles = makeStyles(theme => ({
  stepRow: {
    padding: theme.spacing(0, 4),
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  heading: {
    paddingLeft: theme.spacing(2),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  curvedLine: {
    display: ({ nestLevel }) => (nestLevel ? 'block' : 'none'),
    position: 'absolute',
    borderBottomLeftRadius: '14px',
    borderBottom: `1px solid ${theme.palette.secondary[900]}`,
    borderLeft: `1px solid ${theme.palette.secondary[900]}`,
    width: `${NESTING_DEPTH}px`,
    height: 'calc(50% + 2px)',
    top: '-1px',
    left: `-${NESTING_DEPTH - 3}px`,
  },
  dot: {
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 'calc(50% - 3px)',
    height: '7px',
    width: '7px',
    borderRadius: '100%',
    background: theme.palette.secondary[900],
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: ({ isFirst }) => `repeat(${isFirst ? 3 : 4}, auto)`,
    gridGap: theme.spacing(1),
  },
  line: {
    width: '3000px',
    height: '1px',
    position: 'absolute',
    background: theme.palette.secondary[100],
    left: '-50%',
    bottom: 0,
  },
  icon: {
    color: theme.palette.secondary[900],
  },
}));

const StepRow = ({ step, nestLevel, updaters, levels, hasChildren }) => {
  const { t } = useTranslation();
  const { heading } = step;
  const onRemove = event => updaters.onRemove(event, levels);
  const addChildren = () => updaters.addChildren(levels);
  const addSibling = () => updaters.addSibling(levels);
  const edit = () => updaters.edit(levels);
  const isFirst = levels.length === 1 && levels[0] === 0;

  const styles = useStyles({ nestLevel, isFirst });
  return (
    <div className={styles.stepRow}>
      <div className={styles.heading}>
        {/* eslint-disable-next-line react/no-unknown-property */}
        <span className={styles.dot} data-dot={nestLevel} data-has_children={hasChildren} />
        <span className={styles.curvedLine} data-line={nestLevel} />
        {heading}
      </div>
      <div className={styles.buttons}>
        {!isFirst && (
          <Tooltip enterDelay={500} title={t(...general_messages.delete_with_ctrl)}>
            <IconButton onClick={onRemove}>
              <TrashIcon className={styles.icon} />
            </IconButton>
          </Tooltip>
        )}
        <IconButton disabled={isFirst} onClick={addSibling}>
          <StepIcon className={styles.icon} />
        </IconButton>
        <IconButton onClick={addChildren}>
          <SubStepIcon className={styles.icon} />
        </IconButton>
        <IconButton onClick={edit}>
          <PenIcon className={styles.icon} />
        </IconButton>
      </div>
      <span className={styles.line} />
    </div>
  );
};

StepRow.propTypes = {
  step: PropTypes.shape({
    heading: PropTypes.string.isRequired,
  }).isRequired,
  nestLevel: PropTypes.number.isRequired,
  updaters: PropTypes.shape({
    onRemove: PropTypes.func.isRequired,
    addChildren: PropTypes.func.isRequired,
    addSibling: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
  }).isRequired,
  levels: PropTypes.array.isRequired,
  hasChildren: PropTypes.bool.isRequired,
};

export default StepRow;
