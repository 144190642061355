import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: { visibility: 'hidden', display: 'inline', position: 'absolute', height: 0, width: 0, left: '-99999999999px' },
  mainContent: {
    display: 'grid',
    gridTemplateColumns: '240px auto',
    gridGap: theme.spacing(8),
    minHeight: '240px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '240px',
      gridTemplateRows: '240px auto',
      justifyContent: 'center',
    },
  },
  textButton: {
    background: 'none',
    padding: 0,
    cursor: 'pointer',
    border: 'none',
  },
  settings: {
    display: 'grid',
    gridGap: theme.spacing(3),
    alignContent: 'start',
    justifyItems: 'flex-start',
  },
  settingsRow: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '50px 1fr',
    gridGap: theme.spacing(2),
    justifySelf: 'stretch',
  },
  addButton: {
    display: 'grid',
    gridGap: theme.spacing(2),
    justifyItems: 'center',
    padding: theme.spacing(8, 0),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.secondary[100]}`,
  },
  image: {
    borderRadius: '100%',
  },
}));

export default useStyles;
