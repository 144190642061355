const hashLinkScroll = () => {
  const { hash } = window.location;

  if (hash !== '') {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView(true);
    }, 0);
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
};

export default hashLinkScroll;
