import React from 'react';

import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import meeting_messages from 'messages/meeting_messages';
import { useCommonDialogsContext, COMMON_DIALOG_TYPES } from 'reactContext/CommonDialogsContext';
import PATHS from 'router/PATHS';

import useStyles from './MeetingAdvicesPageRightAdornment.styles';

const MeetingAdvicesPageRightAdornment = ({ isBookMeetingAvailable }) => {
  const { t } = useTranslation();
  const { openCommonDialog } = useCommonDialogsContext();

  const openAskQuestionDialog = () => openCommonDialog(COMMON_DIALOG_TYPES.ASK_QUESTION);

  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Button color='primary' component={Link} disabled={!isBookMeetingAvailable} size='small' to={PATHS.BOOK_MEETING} variant='contained'>
        {t(meeting_messages.book_meeting)}
      </Button>
      <Button color='primary' onClick={openAskQuestionDialog} size='small' variant='contained'>
        {t(meeting_messages.ask_question)}
      </Button>
    </div>
  );
};

export default MeetingAdvicesPageRightAdornment;
