import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';

import type { AnsweredQuestion, SelfTests } from './_types';
import {
  parseAnswerForBE,
  parseAnswerForFe,
  parseProgressForFE,
  parseSingleSelfTestDetailsForFE,
  parseSelfTestIdForBE,
  SelfTestProgressId,
  parseSelfTestProgressInstanceForFE,
  parseTestResultForFE,
  parseFeedbackTextForFE,
} from './parsers';

const endpoint = 'api/v1/self_tests/';
const progressEndpoint = 'api/v1/self_test_progress/';
const questionInstanceEndpoint = '/api/v1/self_test_question_instances/';

const selfTests: SelfTests = {
  getSelfTests: params => ({ url: createUrlWithParams(endpoint, params), method: METHOD.GET }),
  getAddTestLink: () => ({ url: `${endpoint}link_add/`, method: METHOD.GET }),
};

export const selfTestApi = {
  getSelfTestData: async (id: string) => {
    const { data } = await axios.get(`${endpoint}${id}/`);
    return parseSingleSelfTestDetailsForFE(data);
  },
  createSelfTestInstance: async (selfTestData: SelfTestProgressId) => {
    const { data } = await axios.post(progressEndpoint, parseSelfTestIdForBE(selfTestData.selfTestId));
    return parseProgressForFE(data);
  },
  createQuestionInstance: async (data: AnsweredQuestion) => {
    const result = await axios.post(questionInstanceEndpoint, parseAnswerForBE(data));
    return parseAnswerForFe(result.data);
  },
  editQuestionInstance: async (data: AnsweredQuestion) => {
    const dataToSend = {
      answerId: data.answerId,
      userSelfTestId: data.userSelfTestId,
    };
    const result = await axios.patch(`${questionInstanceEndpoint}${data.id}/`, parseAnswerForBE(dataToSend));
    return parseAnswerForFe(result.data);
  },
  getSelfTestProgress: async (id: number | null) => {
    const result = await axios.get(`${progressEndpoint}${id}/`);
    return parseSelfTestProgressInstanceForFE(result.data);
  },
  getTestResult: async (newInstanceId: number | null, existingInstanceId: number | string | null) => {
    const id = newInstanceId || existingInstanceId;
    const result = await axios.get(`${progressEndpoint}${id}/result/`);
    return parseFeedbackTextForFE(result.data);
  },
  getProgressDataAfterFinishingTest: async (newInstanceId: number | null, existingInstanceId: number | string | null) => {
    const id = newInstanceId || existingInstanceId;
    const result = await axios.get(`${progressEndpoint}${id}/`);
    return parseTestResultForFE(result.data);
  },
};

export default selfTests;
