import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import ColoredTile from 'components/ColoredTile/ColoredTile';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { parseKpiDataForUI } from 'config/api/users/parsers';
import { UserKpiValues } from 'config/api/users/UserKpiValues';
import { usersApi } from 'config/api/users/users';
import user_messages from 'messages/user_messages';

const UserKpiSection = () => {
  const { data, isLoading } = useQuery(QUERY_KEYS.GET_USER_KPI, usersApi.getUserKPI);
  const { t } = useTranslation();
  const userKpiDataForUI = data && parseKpiDataForUI(data);

  if (isLoading) {
    return (
      <>
        {/* eslint-disable-next-line no-array-constructor,prefer-spread */}
        {Array.apply(null, Array(5)).map((_, index) => (
          <ColoredTile key={index} centered disableRipple isLoading />
        ))}
      </>
    );
  }

  return (
    <>
      {userKpiDataForUI &&
        Object.entries(userKpiDataForUI).map(([key, value]) => (
          <ColoredTile key={key} centered description={t(user_messages.user_kpi[key as UserKpiValues])} disableRipple title={value} />
        ))}
    </>
  );
};

export default UserKpiSection;
