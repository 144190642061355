import { useState } from 'react';

const useLoadingState = init => {
  const [loading, setLoadingState] = useState(init);
  const setLoaded = () => setLoadingState(false);
  const setLoading = () => setLoadingState(true);
  return { loading, setLoading, setLoaded };
};

export default useLoadingState;
