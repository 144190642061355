import React, { useCallback, useMemo } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { publish } from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { generatePath } from 'react-router-dom';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import Table from 'components/Table';
import TrimHTMLText from 'components/TrimHTMLText/TrimHTMLText';
import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import { parseUserProfileForFE } from 'config/api/_parsers/parseUserProfile';
import { incidentReportsApi } from 'config/api/incident_reports';
import { RelatedAssetInIncidentReport } from 'config/api/incident_reports.types';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import useIsOwner from 'hooks/useIsOwner/useIsOwner';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import report_messages from 'messages/report_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

import useStyles from './AssetsInIncidentReportTable.styles';

type Props = {
  tableData: RelatedAssetInIncidentReport[];
  openEditDialog: (assetId: RelatedAssetInIncidentReport) => void;
};

const AssetsInIncidentReportTable: React.FC<Props> = ({ tableData, openEditDialog }) => {
  const { t } = useTranslation();
  const [isIncidentManager] = usePermissions([ROLES.ORGANIZATION.INCIDENT_MANAGER]);
  const { checkIsOwner } = useIsOwner();

  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();

  const removeMutation = useMutation(incidentReportsApi.removeRelatedAsset);

  const onRemove = async (assetInIncidentReportId: Id) => {
    const confirmed = await showConfirmationModal({
      title: t(general_messages.are_you_sure),
      body: t(general_messages.operation_irreversible),
    });
    if (confirmed) {
      await removeMutation.mutateAsync(assetInIncidentReportId);
      publish(EVENTS.INCIDENT_GUIDE_RELATED_ASSETS_UPDATED, null);
    }
  };

  const onEdit = (assetInIncidentReport: RelatedAssetInIncidentReport) => {
    openEditDialog(assetInIncidentReport);
  };

  const renderContextMenu = useCallback(
    (assetInIncidentReport: RelatedAssetInIncidentReport) => {
      const elements = [
        { label: t(general_messages.edit), onClick: () => onEdit(assetInIncidentReport) },
        { label: t(general_messages.delete), onClick: () => onRemove(assetInIncidentReport.id) },
      ];

      if (!isIncidentManager) return null;
      return (
        <ContextMenu elements={elements} id={`assets-table-row-${assetInIncidentReport.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [tableData],
  );

  const dataMapping: TableDataMappingRow<RelatedAssetInIncidentReport>[] = useMemo(
    () => [
      {
        label: t(report_messages.related_assets_table.name_table_header),
        get: data => data.asset.name,
        id: 'asset__name',
        width: '20%',
        blockSorting: true,
      },
      {
        label: t(report_messages.related_assets_table.description_table_header),
        get: ({ description }) => (
          <TrimHTMLText linesToShow={2}>
            <TypographyWithHTML>{description}</TypographyWithHTML>
          </TrimHTMLText>
        ),
        id: 'description',
        width: '46%',
        blockSorting: true,
      },
      {
        label: t(report_messages.related_assets_table.owner_table_header),
        get: ({ asset }) => <UserAvatar lazy showName userData={parseUserProfileForFE(asset.owner.profile)} userId={asset.owner.id} />,
        id: 'owner',
        width: '28%',
        blockSorting: true,
      },
      {
        id: 'context',
        width: '6%',
        label: '',
        isAddon: true,
        get: renderContextMenu,
        blockSorting: true,
      },
    ],
    [],
  );

  const styles = useStyles();

  if (!tableData) {
    return (
      <Typography align='center' className={styles.emptyFeedback}>
        {t(report_messages.related_assets_table.empty_list)}
      </Typography>
    );
  }

  const getRowHref = ({ asset }: RelatedAssetInIncidentReport) => {
    const canView = checkIsOwner(asset.owner.id) || isIncidentManager;
    return canView ? generatePath(`${PATHS.ASSETS}/:assetId`, { assetId: asset.id }) : null;
  };

  return (
    <>
      <Table clickableRow data={tableData} dataMapping={dataMapping} getRowHref={getRowHref} refetching={false} />
    </>
  );
};

export default AssetsInIncidentReportTable;
