import axios from 'axios';

import { parseAllActiveUserRiskAnalysesAssetsForCreateForFE } from './activeUserRiskAnalysesAssetsForCreate.parsers';
import type ActiveUserRiskAnalysesAssetsForCreateApi from './activeUserRiskAnalysesAssetsForCreate.types';

const endpoint = 'api/v1/active_user/risk_analyses/assets_for_create/';

const activeUserRiskAnalysesAssetsForCreateApi: ActiveUserRiskAnalysesAssetsForCreateApi = {
  getAllActiveUserRiskAnalysesAssetsForCreate: async () => {
    const { data } = await axios.get(endpoint);
    return parseAllActiveUserRiskAnalysesAssetsForCreateForFE(data);
  },
};

export default activeUserRiskAnalysesAssetsForCreateApi;
