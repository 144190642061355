import general_messages from 'messages/general_messages';

const CUSTOMER_LEVELS_FALLBACKS = {
  FREE: general_messages.customer_levels.free[1],
  STANDARD: general_messages.customer_levels.standard[1],
  PREMIUM: general_messages.customer_levels.premium[1],
};

const CUSTOMER_LEVELS = {
  FREE: [general_messages.customer_levels.free[0], CUSTOMER_LEVELS_FALLBACKS.FREE],
  STANDARD: [general_messages.customer_levels.standard[0], CUSTOMER_LEVELS_FALLBACKS.STANDARD],
  PREMIUM: [general_messages.customer_levels.premium[0], CUSTOMER_LEVELS_FALLBACKS.PREMIUM],
};

export const CUSTOMER_LEVELS_ARRAY = Object.values(CUSTOMER_LEVELS);

export const CUSTOMER_LEVELS_DICTIONARY = CUSTOMER_LEVELS_ARRAY.map((label, key) => ({ label, key }));

export default CUSTOMER_LEVELS;
