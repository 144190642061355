const print_template_messages = {
  incident_report: {
    title: ['print_template>>incident_report>>title', 'Report incident'],
    information_subtitle: ['print_template>>information_subtitle', 'Information'],
    logs_subtitle: ['print_template>>incident_report>>logs_subtitle', 'Incident management logs'],
    assets_subtitle: ['print_template>>incident_report>>assets_subtitle', 'Involved assets'],
    steps_subtitle: ['print_template>>incident_report>>steps_subtitle', 'Steps taken during incident guide'],
    phases_subtitle: ['print_template>>incident_report>>phases_subtitle', 'Incident management phases'],

    start: ['print_template>>incident_report>>start', 'Start'],
    name: ['print_template>>incident_report>>name', 'Name'],
    last_edit: ['print_template>>incident_report>>last_edit', 'Last edit'],
    categorization: ['print_template>>incident_report>>categorization', 'Categorization'],
    is_gdpr: ['print_template>>incident_report>>is_gdpr', 'Has the Incident exposed information about an individual (GDPR)'],
    is_ongoing: ['print_template>>incident_report>>is_ongoing', 'Is the incident ongoing'],
    priority: ['print_template>>incident_report>>priority', 'What is the priority of the incident'],
    step_description: ['print_template>>incident_report>>step_description', 'Description'],
    step_comment: ['print_template>>incident_report>>step_comment', 'Comment'],
    // logs
    table_head_contact: ['print_template>>incident_report>>table_head_contact', 'Contacted'],
    table_head_date: ['print_template>>incident_report>>table_head_date', 'Date'],
    table_head_phase: ['print_template>>incident_report>>table_head_phase', 'Phase'],
    table_actions: ['print_template>>incident_report>>table_actions', 'Actions'],
    table_notes: ['print_template>>incident_report>>table_notes', 'Notes'],

    //   assets
    table_asset_name: ['print_template>>incident_report>>table_asset_name', 'Asset name'],
    table_asset_description: ['print_template>>incident_report>>table_asset_description', 'Asset description'],
    table_asset_owner: ['print_template>>incident_report>>table_asset_owner', 'Asset owner'],
  },
};

export default print_template_messages;
