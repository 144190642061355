import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '200px 36px 36px',
  },
}));
