import { RiskAnalysisStatus } from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses.types';
import asset_risk_messages from 'messages/asset_risk_messages';
import assets_messages from 'messages/assets_messages';
import { StaticTableFilter } from 'types/TableFilter';

const filters: { id: RiskAnalysisStatus; name: string[] }[] = [
  { id: 'completed', name: asset_risk_messages.status.completed },
  { id: 'in_progress', name: asset_risk_messages.status.in_progress },
];

const riskAnalysisStatusFilter: StaticTableFilter = {
  isDynamic: false,
  sectionName: assets_messages.asset_tasks_table.status,
  filters,
  isSingleSelect: true,
};

export default riskAnalysisStatusFilter;
