import React from 'react';

import sanitizeHtml from 'sanitize-html';

import TypographyWithHTML from 'components/TypographyWithHTML/TypographyWithHTML';
import stringShortener from 'helpers/stringShortener';
import uppercaseFirstLetter from 'helpers/uppercaseFirstLetter';

const relatedContentResultComponent = ({ label, friendly_id, object_content_type }) => (
  <>
    <span>ID {friendly_id}</span>
    <span>{uppercaseFirstLetter(object_content_type)}</span>
    <TypographyWithHTML component='span'>{stringShortener(sanitizeHtml(label), 40)}</TypographyWithHTML>
  </>
);

export default relatedContentResultComponent;
