import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    border: `thin solid ${theme.palette.secondary[50]}`,
    fontSize: '1.2rem',
  },
  td: {
    padding: theme.spacing(2, 1),
    textAlign: 'center',
    border: `thin solid ${theme.palette.secondary[50]}`,
  },
  caption: {
    color: theme.palette.grey[700],
    fontStyle: 'italic',
    marginTop: theme.spacing(0.5),
  },
  header: {
    padding: theme.spacing(1),
    fontWeight: 500,
    background: theme.palette.secondary[50],
  },
}));
