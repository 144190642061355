import React from 'react';

import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { generatePath } from 'react-router-dom';
import * as yup from 'yup';

import activeUserRiskAnalysesApi from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import { CreateRiskAnalysisInput } from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses.types';
import activeUserRiskAnalysesAssetsForCreateApi from 'config/api/activeUserRiskAnalysesAssetsForCreate/activeUserRiskAnalysesAssetsForCreate';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import EVENTS from 'config/events/pubsub';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';

import CenteredGrid from '../../CenteredGrid/CenteredGrid';
import ColoredButton from '../../ColoredButton';
import FormCheckbox from '../../FormCheckbox';
import FormSelect from '../../FormSelect';
import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
};

const FORM_ID = 'AddNewRiskAnalysisForm';

type FormInput = CreateRiskAnalysisInput | { asset: ''; copy_risks: false };

const queryKey = QUERY_KEYS.GET_ASSETS_AVAILABLE_FOR_RISK_ANALYSIS;

const AddNewRiskAnalysisDialog: React.FC<Props> = ({ onClose, open }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createMutation = useMutation(activeUserRiskAnalysesApi.createRiskAnalysis);

  const onSubmit = async (formInput: FormInput) => {
    // value is numeric, it's checked in form validation
    // @ts-ignore
    const { id } = await createMutation.mutateAsync(formInput);
    if (id) {
      queryClient.invalidateQueries(queryKey);
      PubSub.publish(EVENTS.MY_ASSET_RISKS_UPDATED);
      onClose();
      window.open(generatePath(PATHS.RISK_ANALYSIS_DETAIL, { riskAnalysisId: id }), '_blank');
    }
  };

  const { data: availableAssetsOptions, isLoading: isAvailableAssetsLoading } = useQuery({
    queryKey,
    queryFn: activeUserRiskAnalysesAssetsForCreateApi.getAllActiveUserRiskAnalysesAssetsForCreate,
    enabled: open,
  });

  const formik = useFormik<FormInput>({
    initialValues: {
      asset: '',
      copy_risks: false,
    },
    onSubmit,

    validationSchema: yup.object({
      asset: yup.number().required(t(validation_messages.required)),
    }),
  });

  const hasOptions = !isAvailableAssetsLoading && !!availableAssetsOptions?.length;
  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={!hasOptions} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      }
      loading={isAvailableAssetsLoading}
      onClose={onClose}
      open={open}
      title={t(asset_risk_messages.add_new_risk_analysis.dialog_title)}
    >
      <CenteredGrid gridGap={4}>
        <Typography>{t(asset_risk_messages.add_new_risk_analysis.dialog_description)}</Typography>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <CenteredGrid gridGap={2} withoutPadding>
            <CenteredGrid withoutPadding>
              <FormSelect
                disabled={!hasOptions}
                formik={formik}
                id='asset'
                label={t(asset_risk_messages.add_new_risk_analysis.dialog_asset_select_label)}
                options={availableAssetsOptions}
              />
              {!hasOptions && (
                <Typography color='error' variant='caption'>
                  {t(asset_risk_messages.add_new_risk_analysis.no_assets_to_analyse_info)}
                </Typography>
              )}
            </CenteredGrid>
            <FormCheckbox
              disabled={!hasOptions}
              formik={formik}
              id='copy_risks'
              label={t(asset_risk_messages.add_new_risk_analysis.should_copy_risks_label)}
            />
          </CenteredGrid>
        </form>
      </CenteredGrid>
    </DialogGrid>
  );
};

export default AddNewRiskAnalysisDialog;
