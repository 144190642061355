import React from 'react';

import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

import ColoredButton from 'components/ColoredButton';
import StepRow from 'pages/EditGuidePage/_components/StepRow';

const useStyles = makeStyles(theme => ({
  rowGroup: {
    marginLeft: theme.spacing(4),
    position: 'relative',
  },
  addFirstButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const GuideSchemaBuilder = ({ steps, updaters, nestLevel, levels }) => {
  const styles = useStyles();
  if (!steps.length) {
    const addFirstStep = () => updaters.addSibling(levels);
    return (
      <div className={styles.addFirstButtonWrapper}>
        <ColoredButton customColor='secondary' icon onClick={addFirstStep}>
          <AddIcon />
        </ColoredButton>
      </div>
    );
  }

  return steps.map((step, index) => (
    <div key={`${nestLevel}-${step.heading}`}>
      <StepRow hasChildren={!!step.children.length} levels={[...levels, index]} nestLevel={nestLevel} step={step} updaters={updaters} />
      {step.children.length ? (
        <div className={styles.rowGroup}>
          <GuideSchemaBuilder levels={[...levels, index]} nestLevel={nestLevel + 1} steps={step.children} updaters={updaters} />
        </div>
      ) : null}
    </div>
  ));
};

GuideSchemaBuilder.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updaters: PropTypes.objectOf(PropTypes.func).isRequired,
  nestLevel: PropTypes.number.isRequired,
  levels: PropTypes.array.isRequired,
};

GuideSchemaBuilder.defaultProps = {
  nestLevel: 0,
  levels: [],
};

export default GuideSchemaBuilder;
