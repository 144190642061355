import React, { useEffect } from 'react';

import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import { publish } from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormFileInput from 'components/FormFileInput';
import FormTextInput from 'components/FormTextInput';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import workplaceDocumentsApi from 'config/api/workplaceDocuments/workplaceDocuments';
import { WorkplaceDocumentsFormData } from 'config/api/workplaceDocuments/workplaceDocuments.types';
import EVENTS from 'config/events/pubsub';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import workplace_messages from 'messages/workplace_messages';
import { Id } from 'types/Id';

import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
  workplaceDocumentId: Id | null;
};

const FORM_ID = 'workplace-document-form';

const WorkplaceDocumentDialog: React.FC<Props> = ({ open, onClose, workplaceDocumentId }) => {
  const isEdit = Boolean(workplaceDocumentId);
  const { t } = useTranslation();

  const mutation = useMutation(
    isEdit ? workplaceDocumentsApi.editWorkplaceDocument(workplaceDocumentId as Id) : workplaceDocumentsApi.addWorkplaceDocument(),
    {
      onSuccess: () => {
        publish(EVENTS.WORKPLACE_DOCUMENTS_UPDATED);
      },
    },
  );

  const { data: initialData } = useQuery(
    QUERY_KEYS.GET_WORKPLACE_DOCUMENT,
    () => workplaceDocumentsApi.getSingleWorkplaceDocument(workplaceDocumentId as Id),
    {
      enabled: isEdit,
    },
  );

  const onSubmit = (formData: WorkplaceDocumentsFormData) => {
    mutation.mutate(formData);
    onClose();
  };

  const formik = useFormik<WorkplaceDocumentsFormData>({
    onSubmit,
    initialValues: {
      file: initialData?.attachment.name || '',
      description: initialData?.description || '',
    },
    validationSchema: yup.object({
      file: isEdit ? yup.string() : yup.string().required(t(validation_messages.required)),
      description: yup.string().required(t(validation_messages.required)),
    }),
  });

  useEffect(() => {
    if (initialData) {
      formik.setValues({
        file: initialData.attachment.name,
        description: initialData.description,
      });
    }
  }, [initialData]);

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={!formik.isValid} form={FORM_ID} type='submit' variant='outlined'>
            {t(isEdit ? general_messages.save : general_messages.add)}
          </ColoredButton>
          <Button onClick={onClose} size='small'>
            {t(general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      title={t(isEdit ? workplace_messages.documents.edit_dialog_title : workplace_messages.documents.add_dialog_title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={2} withoutPadding>
          <FormFileInput
            disabled={isEdit}
            formik={formik}
            id='file'
            label={t(workplace_messages.documents.table_label_filename)}
            required
          />
          <FormTextInput
            formik={formik}
            id='description'
            label={t(workplace_messages.documents.table_label_description)}
            multiline
            required
            rows={3}
          />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default WorkplaceDocumentDialog;
