import React from 'react';

import { makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckboxWithInfo from 'components/CheckboxWithInfo/CheckboxWithInfo';
import general_messages from 'messages/general_messages';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}));

const GuideSteps = ({ availableSteps, currentStep, formik, isRoot }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, validateForm } = formik;

  const setNextStep = value => () => {
    setFieldValue('nextStep', value);
  };

  const styles = useStyles();
  if (currentStep?.is_leaf) return null;
  return (
    <div className={styles.wrapper}>
      {!isRoot && (
        <>
          <Typography component='h2' variant='h3'>
            {t(...general_messages.next_step)}
          </Typography>
          <Typography variant='body1'>{currentStep?.next_step_description}</Typography>
        </>
      )}
      <RadioGroup
        className={styles.wrapper}
        name='nextStepId'
        onChange={event => {
          setFieldValue('nextStepId', event.currentTarget.value);
          setTimeout(validateForm, 0);
        }}
        value={values.nextStepId}
      >
        {availableSteps.map(step => (
          <CheckboxWithInfo
            key={step.id}
            control={<Radio />}
            label={step.heading}
            onChange={setNextStep(step)}
            selected={step.id === values.nextStepId}
            value={step.id}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

GuideSteps.propTypes = {
  availableSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isRoot: PropTypes.bool.isRequired,
  currentStep: PropTypes.shape({
    next_step_description: PropTypes.string,
    is_leaf: PropTypes.bool,
  }),
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
  }).isRequired,
};

GuideSteps.defaultProps = {
  currentStep: null,
};

export default GuideSteps;
