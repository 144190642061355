import { useState, useEffect } from 'react';

const useDebounce = (value, delay = 100) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export default useDebounce;

/** * Usage example:

 const SearchInput = () => {
   const [searchTerm, setSearchTerm] = useState('');
   const [results, setResults] = useState([]);
   const debouncedSearchTerm = useDebounce(searchTerm, 500);

   useEffect(
       () => {
         if (debouncedSearchTerm) {
           setIsSearching(true);
           searchCharactersSomewhere(debouncedSearchTerm).then(res => {
             setResults(res);
           });
         } else {
           setResults([]);
         }
       },
       [debouncedSearchTerm]  // Only call effect if debounced search term changes
     );
   return (
     <div>
        <input
         placeholder="Search Marvel Comics"
         onChange={e => setSearchTerm(e.target.value)}
       />
       {isSearching && <div>Searching ...</div>}
       {results && results.map(r => <marquee>{r}</marquee>)}
     </div>
   )
 }

 ** */
