import { useHistory, useLocation } from 'react-router-dom';

import PATHS from 'router/PATHS';

type Config = {
  redirectKey?: string;
  fallback?: string;
  moveBack?: boolean;
};

const defaultConfig: Config = {
  redirectKey: 'redirect_url',
  fallback: PATHS.ROOT,
  moveBack: false,
};

const useRedirectFromQuery = () => {
  const { search } = useLocation();
  const history = useHistory();

  const getRedirectValue = (config = defaultConfig) => {
    const { redirectKey, fallback } = { ...defaultConfig, ...config };
    const urlSearchParams = new URLSearchParams(search);
    return urlSearchParams.get(redirectKey as string) || fallback || PATHS.ROOT;
  };

  const redirect = (config = defaultConfig) => {
    if (config.moveBack) return history.goBack();
    const redirectUrl = getRedirectValue(config);
    return history.replace(redirectUrl);
  };

  return { redirect, getRedirectValue };
};

export default useRedirectFromQuery;
