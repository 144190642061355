import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const MAX_VALUE = 7;

const useStyles = makeStyles(theme => ({
  calendarWrapper: {
    display: ({ showSingleDay }) => (showSingleDay ? 'block' : 'grid'),
    gridTemplateColumns: `repeat(${MAX_VALUE}, 1fr)`,
    gridGap: theme.spacing(1.5),
    position: 'relative',
    minHeight: '300px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));

const CalendarGrid = ({ children, showSingleDay }) => {
  const styles = useStyles({ showSingleDay });
  return <div className={styles.calendarWrapper}>{children}</div>;
};

CalendarGrid.propTypes = {
  children: PropTypes.node.isRequired,
  showSingleDay: PropTypes.bool,
};

CalendarGrid.defaultProps = {
  showSingleDay: false,
};

export default CalendarGrid;
