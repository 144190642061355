import activeUserAssetFilterDataApi from 'config/api/activeUserAssetsFilterData/activeUserAssetsFilterData';
import {
  ActiveUserAssetsFilterDataParams,
  ActiveUserAssetFilterBaseEndpoint,
} from 'config/api/activeUserAssetsFilterData/activeUserAssetsFilterData.types';
import { AssetInformationFilters } from 'config/api/assetInformationChoices/assetInformationChoices.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import assets_messages from 'messages/assets_messages';
import { DynamicTableFilter } from 'types/TableFilter';

const userAssetsWithTasksFilterFactory = (
  endpoint: ActiveUserAssetFilterBaseEndpoint,
  params: ActiveUserAssetsFilterDataParams,
): DynamicTableFilter<AssetInformationFilters> => ({
  isDynamic: true,
  queryFn: () => activeUserAssetFilterDataApi.getActiveUserAssetFilterData(endpoint)(params),
  sectionName: assets_messages.asset_tasks_table.asset_filter_name,
  isSingleSelect: false,
  queryKey: [QUERY_KEYS.GET_ALL_ASSET_TASKS_BY_OWNER, endpoint],
});

export default userAssetsWithTasksFilterFactory;
