import React, { useMemo, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ColoredButton from 'components/ColoredButton';

const useStyles = makeStyles(theme => ({
  accordion: {
    '&.MuiPaper-root:before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      background: 'unset',
      '& p': {
        fontSize: '1.2rem !important',
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(1, 0, 2),
    },
    '& .MuiIconButton-root': {
      padding: '6px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0px 0 0',
    },
  },
}));

const nonInteractiveTags = ['INPUT', 'LI', 'TEXTAREA'];

const CustomAccordion = ({ id, heading, children, accordionClassName, contentClassName, iconsSet, summaryClasses = {} }) => {
  const [expanded, setExpanded] = useState(false);
  const onExpand = (e, isExpanded) => {
    if (!nonInteractiveTags.includes(e.nativeEvent.target.tagName)) setExpanded(isExpanded);
  };

  const Icon = useMemo(() => {
    if (!iconsSet) return <ExpandMoreIcon fontSize='small' />;
    if (expanded) return iconsSet.expanded;
    return iconsSet.notExpanded;
  }, [iconsSet, expanded]);

  const styles = useStyles();
  return (
    <Accordion
      className={clsx(styles.accordion, accordionClassName)}
      data-testid={`${id}-accordion`}
      elevation={0}
      expanded={expanded}
      onChange={onExpand}
    >
      <AccordionSummary
        classes={summaryClasses}
        expandIcon={
          <ColoredButton customColor={expanded ? 'secondary' : 'none'} icon>
            {Icon}
          </ColoredButton>
        }
        id={`${id}`}
      >
        {heading}
      </AccordionSummary>
      <AccordionDetails className={contentClassName}>{children}</AccordionDetails>
    </Accordion>
  );
};

CustomAccordion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  accordionClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
};

CustomAccordion.defaultProps = {
  accordionClassName: null,
  contentClassName: null,
};

export default CustomAccordion;
