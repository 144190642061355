const { makeStyles } = require('@material-ui/core');

// @ts-ignore
export default makeStyles(theme => ({
  description: {
    maxWidth: theme.sizes.container.sm,
    margin: '0 auto',
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  guides: {
    display: 'grid',
    gridGap: theme.spacing(3, 5),
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
    },
  },
}));
