import { get } from 'lodash';

import type {
  TemplateSection,
  UnsavedTemplateSectionDTO,
  Workplace,
  WorkplaceCreate,
  WorkplaceDTO,
  WorkplaceTemplateValue,
  WorkplaceTemplateValueDTO,
} from './_types';
import { TemplateSectionDTO, WorkplaceUpdateDTO } from './_types';

export const parseWorkplaceForFE = (raw: WorkplaceDTO): Workplace => ({
  id: raw.id,
  name: raw.name,
  location: raw.location,
  organizationId: raw.organization_id,
  responsibles: raw.responsibles.map(({ id, is_active, profile, email }) => ({
    id,
    isActive: is_active,
    email,
    profile: {
      email: profile.email,
      firstName: profile.first_name,
      lastName: profile.last_name,
      imageUrl: profile.image_url,
      fullName: `${profile.first_name} ${profile.last_name}`,
    },
  })),
});

export const parseMultipleWorkplacesForFE = (raw: WorkplaceDTO[]): Workplace[] => raw.map(parseWorkplaceForFE);

export const parseCreateWorkplaceForBE = (formData: WorkplaceCreate): Omit<WorkplaceUpdateDTO, 'id'> => ({
  name: formData.name,
  location: formData.location,
  responsibles: formData.responsibles.map(({ key }) => key),
});

export const parseEditBaseWorkplaceForBE = (formData: {
  id: number;
  name: string;
  location: string;
  responsibles: { key: number }[];
}): WorkplaceUpdateDTO => ({
  id: formData.id,
  name: formData.name,
  location: formData.location,
  responsibles: formData.responsibles.map(({ key }) => key),
});

export const prepareTemplateForFE = (raw: TemplateSectionDTO[]): TemplateSection[] => {
  const sortByOrder = (a: { order: number }, b: { order: number }) => (a.order > b.order ? 1 : -1);
  return raw.sort(sortByOrder).map(({ id, workplace_contents, name, order }) => ({
    id: `${id}`,
    order,
    sectionName: name,
    contentElements: workplace_contents.sort(sortByOrder).map(({ id: contentId, ...rest }) => ({ id: `${contentId}`, ...rest })),
  }));
};

type FormData = {
  section: {
    [key: string]: string;
  };
  content: {
    [key: string]: string;
  };
};
export const prepareTemplateSectionForBE = (
  formData: FormData,
  template: TemplateSection[],
  initial: TemplateSection[],
): { toCreate: UnsavedTemplateSectionDTO[]; toUpdate: TemplateSectionDTO[]; toDelete: number[] } => {
  const toCreate: UnsavedTemplateSectionDTO[] = [];
  const toUpdate: TemplateSectionDTO[] = [];
  const toDelete: number[] = initial.filter(({ id: initialId }) => !template.find(({ id }) => id === initialId)).map(({ id }) => +id);

  template.forEach((section, index) => {
    const shouldUpdate = initial.some(({ id }) => id === section.id);
    if (shouldUpdate) {
      const sectionForBE: TemplateSectionDTO = {
        id: +section.id,
        name: get(formData, `section.${section.id}`),
        order: index,
        // @ts-ignore
        workplace_contents: section.contentElements.map(({ id }, contentIndex) => {
          const result = {
            name: get(formData, `content.${id}`),
            order: contentIndex,
          };
          return +id ? { id: +id, ...result } : result;
        }),
      };
      toUpdate.push(sectionForBE);
    } else {
      const sectionForBE: UnsavedTemplateSectionDTO = {
        name: get(formData, `section.${section.id}`),
        order: index,
        workplace_contents: section.contentElements.map(({ id }, contentIndex) => ({
          name: get(formData, `content.${id}`),
          order: contentIndex,
        })),
      };
      toCreate.push(sectionForBE);
    }
  });

  return { toCreate, toUpdate, toDelete };
};

export const parseWorkplaceTemplateValuesForFE = (raw: WorkplaceTemplateValueDTO[]): WorkplaceTemplateValue[] =>
  raw.map(({ workplace_id, ...rest }) => ({
    workplaceId: workplace_id,
    ...rest,
  }));

export const parseWorkplaceTemplateValueForBE = (formData: {
  content: number;
  value: string;
  workplaceId: number;
}): { value: string; workplace_id: number; content_id: number } => ({
  value: formData.value,
  workplace_id: formData.workplaceId,
  content_id: formData.content,
});
