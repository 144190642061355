import general_messages from 'messages/general_messages';

const DEEP_LEVELS_FALLBACKS = {
  FLYOVER: general_messages.deep_levels.flyover[1],
  BASE: general_messages.deep_levels.base[1],
  IN_DEPTH: general_messages.deep_levels['in-dept'][1],
};

const DEEP_LEVELS = {
  FLYOVER: [general_messages.deep_levels.flyover[0], DEEP_LEVELS_FALLBACKS.FLYOVER],
  BASE: [general_messages.deep_levels.base[0], DEEP_LEVELS_FALLBACKS.BASE],
  IN_DEPTH: [general_messages.deep_levels['in-dept'][0], DEEP_LEVELS_FALLBACKS.IN_DEPTH],
};

export const DEEP_LEVELS_ARRAY = Object.values(DEEP_LEVELS);

export const DEEP_LEVELS_DICTIONARY = DEEP_LEVELS_ARRAY.map((label, key) => ({ label, key }));

export default DEEP_LEVELS;
