import { makeStyles } from '@material-ui/core';

type StylesProps = {
  responsive: boolean;
  timelinePosition: number;
  bgColor: string;
  clickable: boolean;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    position: 'relative',
    width: '100%',
    cursor: ({ clickable }: StylesProps) => (clickable ? 'pointer' : 'auto'),
    background: ({ bgColor }: StylesProps) => bgColor,
    // @ts-ignore
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(2.5),
    display: 'grid',
    gridGap: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      gridGap: theme.spacing(0.5),
      padding: ({ responsive }: StylesProps) => responsive && theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: ({ responsive }: StylesProps) => responsive && 'auto auto',
      gridGap: ({ responsive }: StylesProps) => responsive && theme.spacing(5),
      minWidth: ({ responsive }: StylesProps) => !responsive && '115px',
    },
  },
  disabled: {
    opacity: '0.3',
  },
  time: {
    fontSize: '1.5rem',
    fontWeight: 700,
    [theme.breakpoints.only('sm')]: {
      fontSize: ({ responsive }: StylesProps) => responsive && '1.25rem',
    },
  },
  status: {
    fontWeight: 500,
    fontSize: '1.15rem',
    [theme.breakpoints.only('sm')]: {
      fontSize: ({ responsive }: StylesProps) => responsive && '0.875rem',
    },
  },
  timeIndicator: {
    position: 'absolute',
    width: '100%',
    height: '2px',
    // @ts-ignore
    background: theme.palette.primary[300],
    top: ({ timelinePosition }: StylesProps) => `${timelinePosition}%`,
    transition: '2s',
  },
  loading: {
    opacity: '0.3',
  },
}));

export default useStyles;
