import { passwordRules } from 'config/validators/passwordRules';

const validation_messages = {
  required: ['validation>>general>>required', 'Required field'],
  agreement_required: ['validation>>general>>agreement_required', 'Agreement is required'],
  agreements_required_general: ['validation>>general>>agreements_required_general', 'Check required agreements'],
  password_required: ['validation>>password>>required', 'Enter your password'],
  password_match: ['validation>>password>>match', "Passwords don't match"],
  email_required: ['validation>>email>>required', 'Enter your email'],
  email_valid: ['validation>>email>>valid', 'Enter a valid email'],
  missing_required_snackbar: ['validation>>missing_required_snackbar', 'Fill all required fields'],
  email_in_use: ['validation>>email>>email_in_use', 'Email is already registered'],
  guide_need_one_step: ['validation>>guide>>guide_need_one_step', 'Guide needs at least one step'],
  year_activity_needs_one_category: ['validation>>year_activity>>year_activity_needs_one_category', 'Activity needs at least one category'],
  errors: {
    credentials: ['validation>>errors>>credentials', 'Incorrect credentials'],
    too_many_attempts: ['validation>>errors>>to_many_attempts', 'To many attempts, account temporarily locked'],
    whitespaces: ['validation>>password>>whitespaces', 'whitespaces are not allowed'],
    lowercase_letter: ['validation>>password>>lowercase_letter', 'at least 1 lowercase letter'],
    uppercase_letter: ['validation>>password>>uppercase_letter', 'at least 1 uppercase letter'],
    special_character: ['validation>>password>>special_character', 'at least 1 special character, !@#$%&*()-+=^.'],
    digit: ['validation>>password>>digit', 'at least 1 digit'],
    match: ['validation>>password>>match', "Passwords don't match"],
    range: ['validation>>password>>range', 'from {{min}} to {{max}} characters long', passwordRules],
  },
};

export default validation_messages;
