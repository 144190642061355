import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format, add } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import locale from 'config/locale';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  row: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(2),
    minHeight: '2rem',
    alignItems: 'center',
  },
}));

const DateTimeDetails = ({ date, duration, loading }) => {
  const { i18n } = useTranslation();

  const startTime = new Date(date);
  const endTime = add(new Date(date), duration);

  const styles = useStyles();
  if (!date) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <CalendarIcon />
        <Typography>
          {loading ? <Skeleton width='30%' /> : format(startTime, 'yyyy - MMMM - d, iiii', { locale: locale[i18n.language || 'en'] })}
        </Typography>
      </div>
      <div className={styles.row}>
        <ClockIcon />
        <Typography>
          {loading ? (
            <Skeleton width='30%' />
          ) : (
            `${format(startTime, 'HH:mm', { locale: locale[i18n.language || 'en'] })} - ${format(endTime, 'HH:mm, OOO', {
              locale: locale[i18n.language || 'en'],
            })}`
          )}
        </Typography>
      </div>
    </div>
  );
};

DateTimeDetails.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  duration: PropTypes.objectOf(PropTypes.number).isRequired,
  loading: PropTypes.bool,
};

DateTimeDetails.defaultProps = {
  date: null,
  loading: false,
};

export default DateTimeDetails;
