import React from 'react';

import { useTranslation } from 'react-i18next';

import general_messages from 'messages/general_messages';

import useStyles from '../PrintTemplate.styles';

type Section = {
  title: string;
  elements: { name: string; value: React.ReactNode }[];
};

type CustomSection = {
  title: string;
  customElement: React.ReactNode | React.ReactNode[];
};

type Props = {
  title: string;
  sections: (Section | CustomSection)[];
};

const isCustomSectionGuard = (section: Section | CustomSection): section is CustomSection =>
  (section as CustomSection).customElement !== undefined;

const AssetPrintTemplate: React.FC<Props> = ({ title, sections }) => {
  const { t } = useTranslation();

  const styles = useStyles();

  return (
    <main className={styles.root}>
      <section>
        <h2>{title}</h2>
        {sections.map((section, index) => (
          <div key={section.title + index.toString()}>
            <h3>{section.title}</h3>
            {isCustomSectionGuard(section)
              ? section.customElement
              : section.elements.map(({ name, value }, elIndex) => (
                  <dl key={name + elIndex.toString()}>
                    <div>
                      <dt>{name}</dt>
                      <dd>{value ?? t(general_messages.no_data)}</dd>
                    </div>
                  </dl>
                ))}
            {index !== sections.length - 1 && <div className={styles.divider} />}
          </div>
        ))}
      </section>
    </main>
  );
};

export default AssetPrintTemplate;
