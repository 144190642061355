import React, { useState } from 'react';

import { Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AddNewWorkplaceDialog from 'components/_dialogs/AddNewWorkplaceDialog/AddNewWorkplaceDialog';
import ColoredButton from 'components/ColoredButton';
import ManagePageHeader from 'components/ManagePageHeader/ManagePageHeader';
import TextButton from 'components/TextButton/TextButton';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import useBoolState from 'hooks/useBoolState';
import usePermissions from 'hooks/usePermissions';
import useSubscription from 'hooks/useSubscription';
import general_messages from 'messages/general_messages';
import workplace_messages from 'messages/workplace_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import PATHS from 'router/PATHS';

import WorkplacesDocumentsTable from './_components/WorkplacesDocumentsTable/WorkplacesDocumentsTable';
import WorkplacesTable from './_components/WorkplacesTable/WorkplacesTable';

const WorkplacesPage: React.FC = () => {
  const { t } = useTranslation();
  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const { state: addNewDialog, setTrue: openAddNewDialog, setFalse: closeAddNewDialog } = useBoolState(false);
  const { showHelpDialog } = useHelpDialogContext();

  useSubscription(EVENTS.WORKPLACES_TOTAL_COUNT_CHANGED, (event: string, newCount: number) => {
    setTotalCount(newCount);
  });

  const openHelpDialog = () => {
    showHelpDialog({ title: t(workplace_messages.help.title), body: t(workplace_messages.help.body) });
  };

  return (
    <Container>
      <ManagePageHeader
        rightAdornment={
          <>
            <TextButton onClick={openHelpDialog}>{t(general_messages.how_it_works)}</TextButton>
            {isAccountOwner && (
              <>
                {/* @ts-ignore */}
                <ColoredButton component={Link} customColor='secondary' to={PATHS.WORKPLACES_TEMPLATE} variant='outlined'>
                  {t(workplace_messages.buttons.edit_categories)}
                </ColoredButton>
                {/* @ts-ignore */}
                <ColoredButton customColor='secondary' onClick={openAddNewDialog} variant='outlined'>
                  {t(workplace_messages.buttons.new_workplace)}
                </ColoredButton>
              </>
            )}
          </>
        }
      >
        {totalCount === null ? '' : t(workplace_messages.workplaces_page_header, { workplacesCount: totalCount })}
      </ManagePageHeader>
      <WorkplacesDocumentsTable />
      <WorkplacesTable />
      {addNewDialog && <AddNewWorkplaceDialog onClose={closeAddNewDialog} open={addNewDialog} />}
    </Container>
  );
};

export default WorkplacesPage;
