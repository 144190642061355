import React from 'react';

import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { add, eachDayOfInterval, sub } from 'date-fns';
import PropTypes from 'prop-types';

import CalendarHeaderDates from 'components/_calendar/CalendarHeaderDates';
import ColoredButton from 'components/ColoredButton';
import CustomDayPicker from 'components/CustomDayPicker';
import WeekPicker from 'components/WeekPicker';
import getDateRange from 'services/getDateRange';

const useStyles = makeStyles(theme => ({
  stickyHeader: {
    position: 'sticky',
    top: 0,
    padding: theme.spacing(2, 0, 1),
    background: theme.palette.background.default,
    zIndex: '1',
  },
  navigationPanel: {
    display: 'grid',
    gridTemplateColumns: '180px 36px 36px',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    position: 'sticky',
    top: 0,
  },
}));

const CalendarHeader = ({ dateRange, setDateRange, singleDay, setSingleDay, forceSingleDay }) => {
  const theme = useTheme();
  const downXs = forceSingleDay || useMediaQuery(theme.breakpoints.down('xs'));

  const onChange = date => {
    if (downXs) setSingleDay(date);
    else setDateRange(getDateRange(date));
  };

  const onBack = () => {
    if (downXs) setSingleDay(prevDay => sub(prevDay, { days: 1 }));
    else setDateRange(prevDateRange => getDateRange(sub(prevDateRange.start, { days: 1 })));
  };

  const onNext = () => {
    if (downXs) setSingleDay(prevDay => add(prevDay, { days: 1 }));
    else setDateRange(prevDateRange => getDateRange(add(prevDateRange.end, { days: 1 })));
  };

  const styles = useStyles();
  return (
    <div className={styles.stickyHeader}>
      <div className={styles.navigationPanel}>
        {downXs ? <CustomDayPicker onChange={onChange} value={singleDay} /> : <WeekPicker onChange={onChange} value={dateRange.start} />}
        <ColoredButton customColor='secondary' icon onClick={onBack} size='small'>
          <ChevronLeftIcon fontSize='small' />
        </ColoredButton>
        <ColoredButton customColor='secondary' icon onClick={onNext}>
          <ChevronRightIcon fontSize='small' />
        </ColoredButton>
      </div>
      {!downXs && <CalendarHeaderDates days={eachDayOfInterval(dateRange)} />}
    </div>
  );
};

CalendarHeader.propTypes = {
  dateRange: PropTypes.shape({ start: PropTypes.shape({}), end: PropTypes.shape({}) }).isRequired,
  setDateRange: PropTypes.func.isRequired,
  singleDay: PropTypes.shape({}).isRequired,
  setSingleDay: PropTypes.func.isRequired,
  forceSingleDay: PropTypes.bool,
};

CalendarHeader.defaultProps = {
  forceSingleDay: false,
};

export default CalendarHeader;
