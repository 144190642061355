import React, { createContext, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SelectPaginated from 'components/SelectPaginated';
import commonEndpoints from 'config/api/common';
import ROLES from 'config/constants/ROLES';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import prepareDataForSelect from 'services/prepareDataForSelect';
import userDataStorage from 'storages/userDataStorage';

const AdvisorContext = createContext(null);

const { Provider } = AdvisorContext;

const useStyles = makeStyles(theme => ({
  advisorsSelect: {
    minWidth: '300px',
    marginTop: theme.spacing(5),
  },
}));

const advisorsSelectParser = data => prepareDataForSelect(data, 'id', ({ profile }) => `${profile.first_name} ${profile.last_name}`);
const prepareMeOption = data => {
  const me = { ...data };
  me.key = me.id;
  me.label = `${me.first_name} ${me.last_name}`;
  return me;
};

const AdvisorProvider = ({ children }) => {
  const { t } = useTranslation();
  const userData = userDataStorage.get();
  const [isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_SUPERVISOR]);
  const [advisor, setAdvisor] = useState(userData?.id);
  const onChange = ({ target }) => setAdvisor(target.value);

  const styles = useStyles({ isSupervisor });
  return (
    <Provider value={{ advisor, setAdvisor, onChange, isSupervisor, isReviewingCoworker: advisor !== userData?.id }}>
      {isSupervisor && (
        <SelectPaginated
          className={styles.advisorsSelect}
          dataParser={advisorsSelectParser}
          endpointFunc={commonEndpoints.getAdvisers}
          initialOptions={[prepareMeOption(userData)]}
          label={t(...general_messages.advisor)}
          onChange={onChange}
          uniqueValues
          value={advisor}
        />
      )}
      {children}
    </Provider>
  );
};

AdvisorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAdvisorContext = () => useContext(AdvisorContext);

export default AdvisorProvider;
