export type FileInDb = {
  isFromDb: true;
  id: number;
  name: string;
  url: string;
};

export type FileInForm = {
  isFromDb: false;
  id: string;
  name: string;
  file: File;
};

export const isFileInFormGuard = (file: any): file is FileInForm =>
  !(file as FileInForm).isFromDb && (file as FileInForm).file instanceof File && !!(file as FileInForm).name && !!(file as FileInForm).id;

export const isFileInDbGuard = (file: any): file is FileInDb =>
  (file as FileInForm).isFromDb && !!(file as FileInDb).id && !!(file as FileInDb).name && !!(file as FileInDb).url;
