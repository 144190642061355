import TRANSLATABLE_ACTIVITY_TYPES, { ACTIVITY_TYPES } from 'config/translatableConstants/TRANSLATABLE_ACTIVITY_TYPES';
import general_messages from 'messages/general_messages';

export const activityTypeFilterFallback = Object.values(ACTIVITY_TYPES);

const activityTypeFilter = {
  sectionName: general_messages.content_type,
  filters: Object.entries(ACTIVITY_TYPES).map(([key, id]) => ({ id, name: TRANSLATABLE_ACTIVITY_TYPES[key] })),
};

export default activityTypeFilter;
