import user_messages from 'messages/user_messages';

const { roles } = user_messages;

const USER_ROLES_FALLBACKS = {
  ACCOUNT_OWNER: roles.account_owner[1],
  INCIDENT_MANAGER: roles.incident_manager[1],
  USER_ADMINISTRATOR: roles.user_administrator[1],
  SECURITY_OFFICER: roles.security_officer[1],
  CONTRACT_ADMINISTRATOR: roles.contract_administrator[1],
  ACTIVE_USER: roles.active_user[1],
  ASSETS_MANAGER: roles.assets_manager[1],
};

const USER_ROLES = {
  ACCOUNT_OWNER: [roles.account_owner[0], USER_ROLES_FALLBACKS.ACCOUNT_OWNER],
  INCIDENT_MANAGER: [roles.incident_manager[0], USER_ROLES_FALLBACKS.INCIDENT_MANAGER],
  USER_ADMINISTRATOR: [roles.user_administrator[0], USER_ROLES_FALLBACKS.USER_ADMINISTRATOR],
  SECURITY_OFFICER: [roles.security_officer[0], USER_ROLES_FALLBACKS.SECURITY_OFFICER],
  CONTRACT_ADMINISTRATOR: [roles.contract_administrator[0], USER_ROLES_FALLBACKS.CONTRACT_ADMINISTRATOR],
  ACTIVE_USER: [roles.active_user[0], USER_ROLES_FALLBACKS.ACTIVE_USER],
  ASSETS_MANAGER: [roles.assets_manager[0], USER_ROLES_FALLBACKS.ASSETS_MANAGER],
};

export const USER_ROLES_ARRAY = Object.values(USER_ROLES);

export const USER_ROLES_DICTIONARY = USER_ROLES_ARRAY.map((label, key) => ({ label, key }));

export default USER_ROLES;
