import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import FooterButton from 'components/FooterButton';
import FullHeightContainer from 'components/FullHeightContainer';
import ManagePageHeader from 'components/ManagePageHeader/ManagePageHeader';
import Table from 'components/Table';
import questionsEndpoints from 'config/api/questions';
import QUESTION_STATUSES from 'config/constants/QUESTION_STATUSES';
import usePagination from 'hooks/usePagination';
import general_messages from 'messages/general_messages';
import questions_messages from 'messages/questions_messages';
import { useCommonDialogsContext, COMMON_DIALOG_TYPES } from 'reactContext/CommonDialogsContext';
import getDataMappingForQuestions from 'services/getDataMappingForQuestions/getDataMappingForQuestions';

const QuestionsPage = () => {
  const { t } = useTranslation();
  const { openCommonDialog } = useCommonDialogsContext();

  const [sortQuestions, setSortQuestions] = useState({ key: null, direction: null });
  const onSortQuestions = (key, direction) => setSortQuestions({ key, direction });

  const {
    data: questionsData,
    hasNextPage: questionsHasNextPage,
    loading: questionsLoading,
    getData: questionsGetData,
    refreshData,
  } = usePagination({
    endpointFunc: questionsEndpoints.getQuestionsPSOnline,
    limits: [15, 10, 5],
    dependencies: [],
    sort: sortQuestions,
    isGet: true,
  });

  const onQuestionClick = (e, data) => {
    if (data.progress_status === QUESTION_STATUSES.ANSWERED) {
      openCommonDialog(COMMON_DIALOG_TYPES.QUESTION_OVERVIEW, { questionData: data, isPS: true });
    } else {
      openCommonDialog(COMMON_DIALOG_TYPES.QUESTION_EDIT, { questionData: data, refreshData });
    }
  };

  return (
    <FullHeightContainer>
      <ManagePageHeader>{t(...questions_messages.page_title)}</ManagePageHeader>
      <Table
        data={questionsData}
        dataMapping={getDataMappingForQuestions(t)}
        onRowClick={onQuestionClick}
        onSort={onSortQuestions}
        sort={sortQuestions}
      />
      {questionsHasNextPage && (
        <FooterButton loading={questionsLoading} onClick={questionsGetData}>
          {t(...general_messages.show_more)}
        </FooterButton>
      )}
    </FullHeightContainer>
  );
};

export default QuestionsPage;
