import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import general_messages from 'messages/general_messages';

const { content_types, no_data } = general_messages;

const TRANSLATABLE_CONTENT_TYPES_FALLBACKS = {
  ARTICLE: content_types.article[1],
  ARTICLES: content_types.articles[1],
  GUIDE: content_types.guide[1],
  GUIDES: content_types.guides[1],
  INCIDENT_GUIDE: content_types.incident_guide[1],
  INCIDENT_REPORT: content_types.incident_report[1],
  SELF_TEST: content_types.self_test[1],
  SELF_TESTS: content_types.self_tests[1],
};

const TRANSLATABLE_CONTENT_TYPES = {
  ARTICLE: [content_types.article[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.ARTICLE],
  ARTICLES: [content_types.articles[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.ARTICLES],
  GUIDE: [content_types.guide[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.GUIDE],
  GUIDES: [content_types.guides[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.GUIDES],
  INCIDENT_GUIDE: [content_types.incident_guide[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.INCIDENT_GUIDE],
  INCIDENT_REPORT: [content_types.incident_report[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.INCIDENT_REPORT],
  SELF_TEST: [content_types.self_test[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.SELF_TEST],
  SELF_TESTS: [content_types.self_tests[0], TRANSLATABLE_CONTENT_TYPES_FALLBACKS.SELF_TESTS],
};

export const contentTypeResolver = (typeKey, plural = false) => {
  switch (typeKey) {
    case CONTENT_TYPES.ARTICLE:
      return plural ? TRANSLATABLE_CONTENT_TYPES.ARTICLES : TRANSLATABLE_CONTENT_TYPES.ARTICLE;
    case CONTENT_TYPES.GUIDE:
      return plural ? TRANSLATABLE_CONTENT_TYPES.GUIDES : TRANSLATABLE_CONTENT_TYPES.GUIDE;
    case CONTENT_TYPES.INCIDENT_GUIDE:
      return TRANSLATABLE_CONTENT_TYPES.INCIDENT_GUIDE;
    case CONTENT_TYPES.INCIDENT_REPORT:
      return TRANSLATABLE_CONTENT_TYPES.INCIDENT_REPORT;
    case CONTENT_TYPES.SELF_TEST:
      return plural ? TRANSLATABLE_CONTENT_TYPES.SELF_TESTS : TRANSLATABLE_CONTENT_TYPES.SELF_TEST;
    default:
      return no_data;
  }
};

export default TRANSLATABLE_CONTENT_TYPES;
