import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  section: {
    padding: theme.spacing(6, 0),
    // @ts-ignore
    background: theme.palette.primary[50],
  },
}));

export default useStyles;
