import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  skeleton: {
    marginTop: theme.spacing(2),
  },
  noData: {
    padding: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableWrapper: ({ refetching }) =>
    refetching
      ? {
          opacity: 0.35,
          pointerEvents: 'none',
          position: 'relative',
        }
      : {},
  loader: {
    position: 'absolute',
    top: '50%',
    left: 'calc(50% - 30px)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '@media screen and (max-width: 55em)': {
      width: '100%',
    },
  },
  thead: {
    background: theme.palette.background.default,
    position: ({ fixedHeader, isPivoted }) => (fixedHeader || isPivoted ? 'relative' : 'sticky'),
    zIndex: '1',
    top: '-1px',

    '&:after': {
      content: '""',
      display: ({ isPivoted }) => (isPivoted ? 'none' : 'block'),
      position: 'absolute',
      width: '100%',
      height: '2px',
      background: theme.palette.secondary.main,
      bottom: 0,
    },
  },
  th: {
    background: theme.palette.background.default,
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '.85rem',
    padding: theme.spacing(2, 0),
    cursor: 'pointer',
    userSelect: 'none',
    letterSpacing: '.5px',
    position: 'relative',
  },
  filterTh: {
    width: '41px',
  },
  sort: {
    fontWeight: 600,
    letterSpacing: '.8px',
    '&:before': {
      content: '"•"',
      display: 'block',
      position: 'absolute',
      left: '-10px',
      top: '35%',
    },
  },
  thContent: {
    display: 'flex',
    alignItems: 'center',
  },
  sortIconWrapper: {
    flexShrink: '0',
    flexGrow: '0',
    maxWidth: '16px',
    maxHeight: '16px',
    background: theme.palette.secondary[50],
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },
  sortIcon: {
    width: '16px',
    height: '16px',
  },
  additionalInfo: {
    marginLeft: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.text.secondary,
    fontSize: '.875rem',
  },
  tr: {},
  pivotedRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(1, 2),
    borderBottom: `thin solid ${theme.palette.secondary[100]}`,
    padding: theme.spacing(3, 1),
    alignItems: 'center',
    position: 'relative',
  },
  pivotedLabel: {
    fontWeight: 500,
    textAlign: 'right',
  },
  clickableTr: {
    transition: '.1s',
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        background: theme.palette.secondary[50],
      },
      '&:active': {
        background: theme.palette.secondary[100],
      },
    },
  },
  td: {
    borderBottom: `thin solid ${theme.palette.secondary[100]}`,
    padding: theme.spacing(1, 1, 1, 0),
    wordWrap: 'break-word',
    zIndex: '-1',

    '& p': {
      margin: theme.spacing(0.5, 0),
    },
  },
  addonTd: {
    '@media screen and (max-width: 55em)': {
      position: 'absolute !important',
      top: '10px',
      right: 0,
      zIndex: 1,
    },
  },
  filtersButton: {
    padding: theme.spacing(1, 0),
    position: 'sticky',
    top: 'calc(100% - 80px)',
    zIndex: 1,
    height: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  blockSorting: {
    cursor: 'auto',
  },
  tdContent: {
    minHeight: '47px',
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    '@media screen and (max-width: 55em)': {
      minHeight: 'unset',
    },
  },
  rowWrapper: {
    display: 'contents',
    color: theme.palette.text.primary,
  },
  searchBar: {
    background: '',
  },
  thSearch: {
    zIndex: '1',
  },
  searchInput: {},
}));

export default useStyles;
