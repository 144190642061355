import { isNil, omitBy } from 'lodash';

import type {
  Organization,
  OrganizationCreateFormInput,
  OrganizationDTO,
  OrganizationPaymentState,
  OrganizationPaymentStateDTO,
  OrganizationRemainingRetentionDays,
  OrganizationRemainingRetentionDaysDTO,
  OrganizationRemainingTrialDays,
  OrganizationRemainingTrialDaysDTO,
  OrganizationUpdateFormInput,
} from './organizations.types';
import { InfosDTO, OrganizationCreateDTO } from './organizations.types';

const filterFunction = (value: any) => value === '' || isNil(value);

export const parseSingleOrganizationForFE = (raw: OrganizationDTO): Organization => ({
  campaignCode: raw.campaign_code,
  country: raw.country,
  organizationNumber: raw.organization_number,
  name: raw.name,
  organizationPaymentStage: raw.payment_method_state,
  info: {
    businessType: raw.infos?.business_type,
    organizationSize: raw.infos?.organization_size,
    contactFirstName: raw.infos?.contact_first_name,
    contactLastName: raw.infos?.contact_last_name,
    contactFullName: [raw.infos?.contact_first_name, raw.infos?.contact_last_name].filter(value => !!value).join(' '),
    contactEmail: raw.infos?.contact_email,
  },
  onboardingModalShown: raw.onboarding_modal_shown,
});

export const parseCreatedOrganizationForFE = ({ organization, profile }: any): OrganizationCreateFormInput => ({
  lastName: profile.last_name,
  firstName: profile.first_name,
  email: profile.email,
  campaignCode: organization.campaign_code,
  name: organization.name,
});

export const parseOrganizationRemainingTrialDaysForFE = (raw: OrganizationRemainingTrialDaysDTO): OrganizationRemainingTrialDays => ({
  remainingDays: raw.trial_period_days_left,
});

export const parseOrganizationRemainingRetentionDaysForFE = (
  raw: OrganizationRemainingRetentionDaysDTO,
): OrganizationRemainingRetentionDays => ({
  remainingDays: raw.retention_period_days_left,
});

export const parsePartialFormOrganizationDataForBE = (formData: Partial<OrganizationUpdateFormInput>): Partial<OrganizationDTO> => {
  const infos = omitBy(
    {
      business_type: formData.businessType,
      organization_size: formData.organizationSize,
      contact_first_name: formData.firstName,
      contact_last_name: formData.lastName,
      contact_email: formData.email,
    },
    filterFunction,
  ) as InfosDTO;
  const result: Partial<OrganizationDTO> = {
    campaign_code: formData.campaignCode || null,
    country: formData.country,
    organization_number: formData.organizationNumber,
    onboarding_modal_shown: formData.onboardingModalShown,
    infos: Object.entries(infos).length ? infos : undefined,
  };
  return omitBy(result, filterFunction);
};

export const parseCreateFormOrganizationDataForBE = (formData: OrganizationCreateFormInput): OrganizationCreateDTO => ({
  profile: {
    email: formData.email,
    first_name: formData.firstName,
    last_name: formData.lastName,
    language_code: formData.languageCode || 'sv',
  },
  organization: {
    name: formData.name,
    campaign_code: formData.campaignCode || null,
    infos: {
      contact_email: formData.email,
    },
  },
});

export const parseOrganizationToFormInput = (
  organization: Organization,
): Omit<OrganizationUpdateFormInput, 'gdpr' | 'terms' | 'onboardingModalShown'> & { name: string } => ({
  name: organization.name,
  organizationNumber: organization.organizationNumber,
  country: organization.country,
  businessType: organization.info.businessType,
  organizationSize: organization.info.organizationSize,
  firstName: organization.info.contactFirstName,
  lastName: organization.info.contactLastName,
  email: organization.info.contactEmail,
  campaignCode: organization.campaignCode,
});

export const parseResendActivationLinkForBE = (formInput: { email: string }) => formInput;

export const parseOrganizationPaymentStageForFE = (raw: OrganizationPaymentStateDTO): OrganizationPaymentState => ({
  organizationPaymentStage: raw.payment_method_state,
});
