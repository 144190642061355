import React from 'react';

import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { publish } from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import ButtonsGrid from 'components/_dialogs/_components/ButtonsGrid';
import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormAutocomplete from 'components/FormAutocomplete';
import FormTextInput from 'components/FormTextInput';
import { incidentReportsApi } from 'config/api/incident_reports';
import { RelatedAssetInIncidentReport } from 'config/api/incident_reports.types';
import EVENTS from 'config/events/pubsub';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import validation_messages from 'messages/validation_messages';
import assetOptionsParser from 'services/autocompleteServices/assetOptionsParser/assetOptionsParser';
import assetParamsGetter from 'services/autocompleteServices/assetParamsGetter/assetParamsGetter';
import assetResultComponent from 'services/autocompleteServices/assetResultComponent/assetResultComponent';
import { Id } from 'types/Id';

type Props = {
  incidentReportId: Id;
  open: boolean;
  onClose: () => void;
  assetToEdit: RelatedAssetInIncidentReport | null;
};

const FORM_ID = 'AssetIncidentReportAddDialogForm';

type FormInputT = {
  asset:
    | {
        id: Id;
        key: Id;
        name: string;
        category: [string, string];
      }[]
    | null;
  description: string;
};

const AssetIncidentReportDialog: React.FC<Props> = ({ open, onClose, incidentReportId, assetToEdit }) => {
  const isEdit = !!assetToEdit;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(
    isEdit ? incidentReportsApi.updateRelatedAsset(assetToEdit?.id as Id) : incidentReportsApi.addRelatedAsset(incidentReportId),
    { onError: () => {} },
  );

  const onSubmit = async ({ asset, description }: FormInputT) => {
    const assetId = asset?.[0].id!;
    try {
      await mutation.mutateAsync({ asset: assetId, description });
      publish(EVENTS.INCIDENT_GUIDE_RELATED_ASSETS_UPDATED, null);
      enqueueSnackbar(t(isEdit ? guide_messages.related_assets.edited_successfully : guide_messages.related_assets.added_successfully), {
        variant: 'success',
      });
      onClose();
    } catch (e: unknown) {
      const { response } = e as AxiosError;
      if (response?.data && typeof response.data === 'object') {
        Object.values(response.data).forEach((errors: string[]) => {
          errors.forEach(error => {
            enqueueSnackbar(error, { variant: 'error' });
          });
        });
      }
    }
  };

  const formik = useFormik<FormInputT>({
    initialValues: {
      asset: assetToEdit
        ? [
            {
              id: assetToEdit.asset.id,
              key: assetToEdit.asset.id,
              name: assetToEdit.asset.name,
              category: [assetToEdit.asset.category.name_translation_key, assetToEdit.asset.category.name],
            },
          ]
        : [],
      description: assetToEdit?.description || '',
    },
    validationSchema: yup.object().shape({
      asset: yup.array().min(1, t(validation_messages.required)),
      description: yup.string().required(t(validation_messages.required)),
    }),
    onSubmit,
  });

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={mutation.isLoading} onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={mutation.isLoading} form={FORM_ID} type='submit' variant='outlined'>
            {isEdit ? t(general_messages.save) : t(general_messages.add)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(isEdit ? guide_messages.related_assets.edit_dialog_title : guide_messages.related_assets.add_dialog_title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={2}>
          <FormAutocomplete
            apiCallParamsGetter={assetParamsGetter}
            customizeLabel={assetResultComponent}
            formik={formik}
            id='asset'
            label={t(guide_messages.related_assets.asset_input_label)}
            multiple={false}
            optionsParser={assetOptionsParser}
          />
          <FormTextInput
            formik={formik}
            id='description'
            label={t(guide_messages.related_assets.description_input_label)}
            multiline
            rows={4}
          />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default AssetIncidentReportDialog;
