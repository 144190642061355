import React, { createContext, useContext } from 'react';

import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import useLoadingState from 'hooks/useLoadingState';

const LoaderContext = createContext(null);

const { Provider } = LoaderContext;

const LoaderProvider = ({ children }) => {
  const { loading, setLoaded, setLoading } = useLoadingState(false);

  return (
    <Provider value={{ loading, setLoading, setLoaded }}>
      <Loader open={loading} />
      {children}
    </Provider>
  );
};

LoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLoaderContext = () => useContext(LoaderContext);

export default LoaderProvider;
