import { useMemo } from 'react';

import ROLES from 'config/constants/ROLES';
import useIsOwner from 'hooks/useIsOwner/useIsOwner';
import usePermissions from 'hooks/usePermissions';

const useDynamicAssetPageAccessCheck = (assetData: any) => {
  const [isAssetManager] = usePermissions([ROLES.ORGANIZATION.ASSETS_MANAGER]);
  const { checkIsOwner } = useIsOwner();
  const hasOwnerPermission = useMemo(() => {
    if (!assetData?.owner) return false;
    return checkIsOwner(assetData.owner.id);
  }, [assetData]);

  return isAssetManager || hasOwnerPermission;
};

export default useDynamicAssetPageAccessCheck;
