import React, { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import MetadataDialog from 'components/_dialogs/MetadataDialog';
import ColoredButton from 'components/ColoredButton';
import FooterButton from 'components/FooterButton';
import ManagePageHeader from 'components/ManagePageHeader/ManagePageHeader';
import Table from 'components/Table';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import articleEndpoints from 'config/api/article/article';
import commonEndpoints from 'config/api/common';
import selfTests from 'config/api/selfTests/selfTests';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import DIRECTIONS from 'config/constants/sortDirections';
import { contentTypeResolver } from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import useApiCall from 'hooks/useApiCall';
import useMultipleApiCall from 'hooks/useMultipleApiCall';
import usePagination from 'hooks/usePagination';
import general_messages from 'messages/general_messages';
import table_messages from 'messages/table_messages';
import title_messages from 'messages/title_messages';
import { parseSelfTestDjangoAdminRedirectUrl } from 'parsers/generalParser';
import PATHS from 'router/PATHS';
import audienceFilter from 'services/_filters/parsers/audienceFilter';
import authorFilter from 'services/_filters/parsers/authorFilter';
import contentTypeFilter, { contentTypeFilterFallback } from 'services/_filters/parsers/contentTypeFilter';
import customerLevelFilter from 'services/_filters/parsers/customerLevelFilter';
import depthLevelFilter from 'services/_filters/parsers/depthLevelFilter';
import getTagFilter from 'services/_filters/parsers/getTagFilter';
import languageFilter from 'services/_filters/parsers/languageFilter';
import notEdited from 'services/_filters/parsers/notEditedFilter';
import publishStatusFilter from 'services/_filters/parsers/publishStatusFilter';
import serviceAreaFilter from 'services/_filters/parsers/serviceAreaFilter';
import parseFiltersForRequest from 'services/parseFiltersForRequest';

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridGap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      gridGap: theme.spacing(1),
    },
  },
  showMoreButton: {
    width: '100%',
  },
}));

const DATES = {
  LE: 'LE',
  CR: 'CR',
};

const staticFilters = {
  not_edited_in_days: notEdited,
  audience: audienceFilter,
  object_content_type: contentTypeFilter,
  customer_level: customerLevelFilter,
  depth_level: depthLevelFilter,
  language: languageFilter,
  publish_status: publishStatusFilter,
};

const filtersUrls = {
  serviceAreaFilter,
  tagsFilter: getTagFilter(),
  authorFilter,
};

const ManageContentPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { apiCall } = useApiCall();
  const { multipleApiCall } = useMultipleApiCall();

  const [filters, setFilters] = useState({});
  const [availableFilters, setAvailableFilters] = useState(null);
  const getAvailableFilters = async () => {
    const result = await multipleApiCall(filtersUrls);
    const { author, service_area, tags } = result;
    const { not_edited_in_days, audience, object_content_type, customer_level, depth_level, language, publish_status } = staticFilters;
    setAvailableFilters({
      not_edited_in_days,
      object_content_type,
      service_area,
      tags,
      publish_status,
      author,
      customer_level,
      audience,
      depth_level,
      language,
    });
  };

  const [sort, setSort] = useState({ key: null, direction: null });
  const onSort = (key, direction) => {
    setSort({ key, direction });
  };
  const sortDates = key => {
    setSort(prevSort => {
      const labelLE = t(...table_messages.last_edit_date_shortcut);
      const labelCR = t(...table_messages.creation_date_shortcut);
      const { CR, LE } = DATES;
      const internalKeyCR = 'created_at';
      const internalKeyLE = 'updated_at';
      if (prevSort?.key !== key) {
        return { key, direction: DIRECTIONS.ASC, additionalInfo: labelLE, secondarySort: LE, internalKey: internalKeyLE };
      }
      const { direction, secondarySort } = prevSort;
      switch (direction) {
        case DIRECTIONS.ASC: {
          if (secondarySort === LE)
            return { key, direction: DIRECTIONS.DESC, secondarySort: LE, additionalInfo: LE, internalKey: internalKeyLE };
          return { key, direction: DIRECTIONS.DESC, secondarySort: CR, additionalInfo: labelCR, internalKey: internalKeyCR };
        }
        case DIRECTIONS.DESC:
          if (secondarySort === LE)
            return { key, direction: DIRECTIONS.ASC, secondarySort: CR, additionalInfo: CR, internalKey: internalKeyCR };
          return { key: null, direction: null };
        default:
          return { key, direction: DIRECTIONS.ASC, secondarySort: LE, additionalInfo: labelLE, internalKey: internalKeyLE };
      }
    });
  };

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
  } = usePagination({
    endpointFunc: commonEndpoints.search,
    limits: [30, 20, 10],
    dependencies: [filters],
    filters: parseFiltersForRequest(filters, { object_content_type: contentTypeFilterFallback }),
    sort,
    sticky: true,
  });

  useEffect(() => {
    getAvailableFilters();
  }, []);

  const dataMapping = [
    {
      id: 'author',
      width: '25%',
      label: t(...table_messages.author),
      get: data =>
        data?.author ? (
          <UserAvatar
            lazy
            showName
            userData={{ firstName: data.author.first_name, lastName: data.author.last_name, imageUrl: data.author.image_url }}
            userId={data.author.id}
          />
        ) : null,
    },
    {
      id: 'object_content_type',
      width: '12%',
      label: t(...general_messages.content_type),
      get: data => (data?.object_content_type !== undefined ? t(...contentTypeResolver(data?.object_content_type)) : null),
    },
    { id: 'title', width: '35%', label: t(...table_messages.titleH1), get: data => data?.title || data?.heading },
    {
      id: 'date',
      width: '15%',
      label: t(...table_messages.date),
      // eslint-disable-next-line react/prop-types
      get: data => (
        <div>
          {/* eslint-disable react/destructuring-assignment */}
          <p>{`${t(...table_messages.creation_date_shortcut)}: ${data?.created_at && format(new Date(data.created_at), 'yyyy-MM-dd')}`}</p>
          <p>{`${t(...table_messages.last_edit_date_shortcut)}: ${data?.updated_at && format(new Date(data.updated_at), 'yyyy-MM-dd')}`}</p>
          {/* eslint-enable react/destructuring-assignment */}
        </div>
      ),
      customSort: sortDates,
    },
    { id: 'service_area', width: '13%', label: t(...table_messages.service_area), get: data => data?.service_area.name },
  ];

  const goToArticlesCreator = async () => {
    const { data } = await apiCall(articleEndpoints.getLink(), { data: { parent_page_id: process.env.REACT_APP_WAGTAIL_PARENT_PAGE_ID } });
    if (data?.add_url) window.open(data.add_url, '_blank');
  };

  const goToArticlesEditor = async id => {
    const { data } = await apiCall(articleEndpoints.getEditLink(id), {
      data: { parent_page_id: process.env.REACT_APP_WAGTAIL_PARENT_PAGE_ID },
    });
    if (data?.edit_url) window.open(data.edit_url, '_blank');
  };

  const goToSelfTestCreator = async (event, id) => {
    const { data } = await apiCall(selfTests.getAddTestLink());
    const redirectUrlData = parseSelfTestDjangoAdminRedirectUrl(data);
    if (redirectUrlData) {
      const editSelfTestRedirect = redirectUrlData.addUrl.replace('add', id);
      const redirectUrl = !id ? redirectUrlData.addUrl : editSelfTestRedirect;
      window.open(redirectUrl, '_blank');
    }
  };

  const onRowClick = (event, { id, metadata, object_content_type }) => {
    switch (object_content_type) {
      case CONTENT_TYPES.INCIDENT_GUIDE:
      case CONTENT_TYPES.GUIDE:
        return history.push(`${PATHS.EDIT_GUIDE}/${metadata.version_id}`);
      case CONTENT_TYPES.ARTICLE:
        return goToArticlesEditor(id);
      case CONTENT_TYPES.SELF_TEST:
        return goToSelfTestCreator(event, metadata.version_id);
      default:
        return null;
    }
  };

  const [metadataDialog, setMetadataDialog] = useState(null);

  const openGuideMetadataDialog = () => setMetadataDialog(CONTENT_TYPES.GUIDE);
  const closeMetadataDialog = () => setMetadataDialog(null);

  const styles = useStyles();
  return (
    <Container>
      <ManagePageHeader
        rightAdornment={
          <div className={styles.buttonWrapper}>
            <ColoredButton customColor='secondary' endIcon={<Add />} onClick={goToArticlesCreator} variant='outlined'>
              {`${t(...general_messages.new)} ${t(...general_messages.article)}`}
            </ColoredButton>
            <ColoredButton customColor='secondary' endIcon={<Add />} onClick={openGuideMetadataDialog} variant='outlined'>
              {`${t(...general_messages.new)} ${t(...general_messages.guide)}`}
            </ColoredButton>
            <ColoredButton customColor='secondary' endIcon={<Add />} onClick={goToSelfTestCreator} variant='outlined'>
              {`${t(...general_messages.new)} ${t(...general_messages.self_test)}`}
            </ColoredButton>
          </div>
        }
      >
        {t(...title_messages.manage_content)}
      </ManagePageHeader>
      <Table
        data={tableData}
        dataMapping={dataMapping}
        filtersService={{ availableFilters, filters, setFilters }}
        onRowClick={onRowClick}
        onSort={onSort}
        sort={sort}
      />
      {hasNextPage && (
        <FooterButton className={styles.showMoreButton} loading={loading} onClick={getTableData}>
          {t(...general_messages.show_more)}
        </FooterButton>
      )}
      <MetadataDialog onClose={closeMetadataDialog} open={!!metadataDialog} type={metadataDialog} />
    </Container>
  );
};

export default ManageContentPage;
