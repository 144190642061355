// import { useEffect } from 'react';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import METHOD from 'config/api/_methods';
import instances from 'config/constants/instances';
import useLoadingState from 'hooks/useLoadingState';
import general_messages from 'messages/general_messages';

const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;

const cachedRequests = {};

let source;
const cancelRequest = msg => {
  if (source) source.cancel(msg);
};

const useApiCall = data => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { loading, setLoading, setLoaded } = useLoadingState(false);

  // useEffect(() => cancelRequest, []);
  if (data) {
    const apiCall = (...args) => {
      // eslint-disable-next-line no-console
      console.log(args);
      setLoading();
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(data);
          setLoaded();
        }, 2000);
      });
    };
    return { apiCall, loading };
  }

  const getCancelToken = () => axios.CancelToken.source();

  /**
   *
   * @param {Object} baseConfig - base configuration for request
   * @param {string} baseConfig.url - url for request
   * @param {string} [baseConfig.method=GET] - method for request
   * @param {Object} [rest] - axios config
   * @param {Object} [additionalConfig] - API call config
   * @param {string} [additionalConfig.errorMessage] - custom error message
   * @param {boolean} [additionalConfig.showError=true] - should show error messages
   * @param {boolean} [additionalConfig.cache=false] - should cache request
   * @returns {Promise<AxiosResponse<any>|any>} - axios response
   */
  const apiCall = async (
    { url, method = METHOD.GET },
    rest = {},
    { errorMessage, showError = true, tokenSource = getCancelToken(), cache } = {},
  ) => {
    source = tokenSource;
    const errorMessageFallback = t(...general_messages.api_error);
    const shouldShowSnackbar = error => {
      if (error?.response?.status === 401) return false;
      return !axios.isCancel(error) && showError;
    };
    try {
      setLoading();
      if (cache && cachedRequests[url]) {
        return new Promise(resolve => resolve(cachedRequests[url]));
      }
      const call = await axios(url, { method, cancelToken: source.token, ...rest });
      if (axios.isAxiosError(call) && call?.response?.status !== 401 && showError) {
        snackbar?.enqueueSnackbar(errorMessage || errorMessageFallback, { variant: 'error' });
      } else if (cache) {
        cachedRequests[url] = call;
      }
      return call;
    } catch (error) {
      // eslint-disable-next-line no-console
      if (!isProduction) console.error(error);
      if (shouldShowSnackbar(error)) snackbar?.enqueueSnackbar(errorMessage || errorMessageFallback, { variant: 'error' });
      return error.response;
    } finally {
      setLoaded();
    }
  };

  return { apiCall, cancelRequest, loading, sending: loading, getCancelToken };
};

export default useApiCall;
