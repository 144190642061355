import React from 'react';

import clsx from 'clsx';

import type { ContentItem } from 'config/api/selfTests/_types';

import SelfTestTile, { SelfTestTileLoader } from '../SelfTestTile/SelfTestTile';

import useStyles from './SelfTestsTilesGrid.styles';

type Props = {
  data: ContentItem[];
  placeholdersNumber?: number;
  scrollable?: boolean;
};

const SelfTestsTilesGrid: React.FC<Props> = ({ data, placeholdersNumber = 3, scrollable }) => {
  const styles = useStyles({ length: data?.length });
  if (!data)
    return (
      <div className={clsx(styles.grid, scrollable && styles.scrollableGrid)}>
        {Array.from(Array(placeholdersNumber).keys()).map(key => (
          <SelfTestTileLoader key={key} />
        ))}
      </div>
    );

  return (
    <div className={clsx(styles.grid, scrollable && styles.scrollableGrid)}>
      {data.map(content => (
        <SelfTestTile key={content.id} data={content} />
      ))}
    </div>
  );
};

export default SelfTestsTilesGrid;
