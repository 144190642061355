import { alpha, makeStyles } from '@material-ui/core';

type StylesProps = {
  isPreview: boolean;
};

export default makeStyles(theme => ({
  uploadButton: {
    border: `2px dotted ${theme.palette.primary.main}`,
    padding: theme.spacing(2.5, 2),
    borderRadius: '6px',
    textDecoration: 'underline',
    // @ts-ignore
    color: theme.palette.secondary[900],
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    '&:hover': {
      // @ts-ignore
      backgroundColor: alpha(theme.palette.primary[50], 0.3),
    },
  },
  fileRow: {
    display: 'grid',
    gridTemplateColumns: ({ isPreview }: StylesProps) => (isPreview ? '1fr auto' : '1fr auto auto'),
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    position: 'relative',
    '&::after': {
      content: '""',
      height: '1px',
      width: '100%',
      // @ts-ignore
      backgroundColor: theme.palette.secondary[200],
      position: 'absolute',
      bottom: 0,
    },
  },
}));
