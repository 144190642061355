import { Responsible } from 'config/api/workplaces/_types';
import userDataStorage from 'storages/userDataStorage';

const useResponsiblePermission = (responsibles?: Responsible[]) => {
  // @ts-ignore
  const { id } = userDataStorage.get() || { id: null };
  const findIfIsResponsible = responsibles?.some(person => person.id === id);
  return !!findIfIsResponsible;
};

export default useResponsiblePermission;
