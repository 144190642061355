import React from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './ArticleTable.styles';

const ArticleTable = ({ data, first_col_is_header, first_row_is_table_header, table_caption }) => {
  let head = null;
  if (first_col_is_header) {
    head = data.shift();
  }

  const styles = useStyles();
  return (
    <table className={styles.table}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <caption align='bottom'>
        <Typography className={styles.caption} variant='body2'>
          {table_caption}
        </Typography>
      </caption>
      {head && (
        <thead>
          <tr>
            {head.map((headCol, index) => (
              <th key={`${headCol}-${index}`} className={styles.header}>
                {headCol}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((row, index) => (
          <tr key={`${row[0]}-${index}`}>
            {row.map((col, colIndex) => (
              <td key={`${col}-${colIndex}`} className={clsx(styles.td, first_row_is_table_header && index === 0 && styles.header)}>
                {col}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

ArticleTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  first_col_is_header: PropTypes.bool.isRequired,
  first_row_is_table_header: PropTypes.bool.isRequired,
  table_caption: PropTypes.string,
};

ArticleTable.defaultProps = {
  table_caption: null,
};

export default ArticleTable;
