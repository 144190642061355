import React from 'react';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import SomethingWentWrong from 'components/ErrorBoundary/_components/SomethingWentWrong';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <SomethingWentWrong /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default withRouter(ErrorBoundary);
