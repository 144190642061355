import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(4),
    display: 'grid',
    gap: theme.spacing(2),
  },

  newDefaultDeviceRoot: {
    display: 'grid',
    gap: theme.spacing(2),
  },

  newDefaultDeviceRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    // @ts-ignore
    maxWidth: theme.sizes.container.xs,
    margin: '0 auto',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    padding: theme.spacing(1, 0),
  },
}));
