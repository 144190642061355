const getRelatedContentInitialValues = ({ related_guides, related_articles, related_self_tests }) => {
  const initialValues = [];
  if (related_guides) initialValues.push(...related_guides);
  if (related_articles) initialValues.push(...related_articles);
  if (related_self_tests) initialValues.push(...related_self_tests);
  return initialValues.map(({ friendly_id, id, name, object_content_type, heading, title }) => ({
    friendly_id,
    key: id,
    label: name || heading || title,
    object_content_type,
  }));
};

export default getRelatedContentInitialValues;
