const onboarding_messages = {
  heading: ['onboarding>>heading', 'You are now ready to start using your free trial'],
  description: [
    'onboarding>>description',
    `<p>Invite your colleagues to your organization and try out all the functions. Whenever you feel ready to take the full step or if you want to book an advisory meeting you only need to register your credit card. You never pay anything during your trial period except for booked meetings. When the trial period ends your subscription starts, but you can end that at any time under organization administration in the menu to the left</p> <p>So let us start explore, we recommend to start on your dashboard and run the two self tests there.</p>`,
  ],
  button_label: ['onboarding>>button_label', 'Go to my dashboard'],
};

export default onboarding_messages;
