import create from 'zustand';
import { persist } from 'zustand/middleware';

type LayoutStore = {
  isMenuOpen?: boolean;
  closeMenu: () => void;
  openMenu: () => void;
  toggleMenu: () => void;
};

const store = persist<LayoutStore>(
  set => ({
    isMenuOpen: true,
    closeMenu: () => set({ isMenuOpen: false }),
    openMenu: () => set({ isMenuOpen: true }),
    toggleMenu: () => set(state => ({ isMenuOpen: !state.isMenuOpen })),
  }),
  { name: 'LayoutStore', getStorage: () => sessionStorage },
);

const useLayoutStore = create(store);

export default useLayoutStore;
