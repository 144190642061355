import React from 'react';

import { Typography } from '@material-ui/core';

import UserAvatar, { type Props as UserAvatarProps } from '../UserAvatar/UserAvatar';

import useStyles from './UserAvatarWithDescription.styles';

type Props = {
  userAvatarProps: UserAvatarProps;
  description?: string[];
  children?: React.ReactNode | React.ReactNode[];
};

const UserAvatarWithDescription: React.FC<Props> = ({ userAvatarProps, description, children }) => {
  const styles = useStyles();
  return (
    <div className={styles.tile}>
      <UserAvatar {...userAvatarProps} />
      <div className={styles.description}>
        {description && description.map((text, index) => <Typography key={index}>{text}</Typography>)}
        {children}
      </div>
    </div>
  );
};

export default UserAvatarWithDescription;
