import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import ColoredButton from 'components/ColoredButton';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import organizationsApi from 'config/api/organizations/organizations';
import onboarding_messages from 'messages/onboarding_messages';
import userDataStorage from 'storages/userDataStorage';

import TypographyWithHTML from '../../TypographyWithHTML/TypographyWithHTML';
import DialogGrid from '../_components/DialogGrid';

import useStyles from './OnboardingDialog.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};

const OnboardingDialog: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  // @ts-ignore
  const { organization } = userDataStorage.get() || {};

  const organizationMutation = useMutation(MUTATION_KEYS.UPDATE_ORGANIZATION, organizationsApi.updatePartialOrganization);

  useEffect(() => {
    organizationMutation.mutate({ id: organization, inputData: { onboardingModalShown: true } });
  }, []);

  const styles = useStyles();
  return (
    // @ts-ignore
    <DialogGrid onClose={onClose} open={open} title={t(onboarding_messages.heading)}>
      <div className={styles.container}>
        <TypographyWithHTML>{t(onboarding_messages.description)}</TypographyWithHTML>
        {/* @ts-ignore */}
        <ColoredButton customColor='secondary' onClick={onClose}>
          {t(onboarding_messages.button_label)}
        </ColoredButton>
      </div>
    </DialogGrid>
  );
};

export default OnboardingDialog;
