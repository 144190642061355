import React from 'react';

import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonsGrid from 'components/_dialogs/_components/ButtonsGrid';
import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import general_messages from 'messages/general_messages';

const EditMeetingDialogGrid = ({ children, onClose, open, onBack, disabled, onConfirm, loading, title }) => {
  const { t } = useTranslation();

  return (
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          <Button onClick={onBack} variant='outlined'>
            {t(...general_messages.back)}
          </Button>
          <ColoredButton customColor='secondary' disabled={disabled} onClick={onConfirm} showLoader={loading} variant='outlined'>
            {t(...general_messages.confirm)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={title}
    >
      <CenteredGrid gridGap={1.5} withoutPadding>
        {children}
      </CenteredGrid>
    </DialogGrid>
  );
};

EditMeetingDialogGrid.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default EditMeetingDialogGrid;
