import { makeStyles } from '@material-ui/core';

type StyleProps = {
  isEmpty: boolean;
};
export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
  },
  footer: {
    borderTop: ({ isEmpty }: StyleProps) => (isEmpty ? 'none' : `thin solid ${theme.palette.primary.main}`),
    display: 'grid',
    gridGap: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
    width: '100%',
  },
}));
