import React, { useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import ROLES from 'config/constants/ROLES';
import OrganizationPaymentStages from 'config/permissions/OrganizationPaymentStages';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';
import usePermissions from 'hooks/usePermissions';
import PATHS from 'router/PATHS';

import DashboardInfoBoxCardInvalid from '../DashboardInfoBoxCardInvalid/DashboardInfoBoxCardInvalid';
import DashboardInfoBoxRetention from '../DashboardInfoBoxRetention/DashboardInfoBoxRetention';
import DashboardInfoBoxRetentionForAccountOwner from '../DashboardInfoBoxRetentionForAccountOwner/DashboardInfoBoxRetentionForAccountOwner';
import DashboardInfoBoxTrial from '../DashboardInfoBoxTrial/DashboardInfoBoxTrial';

import useStyles from './DashboardInfoBoxResolver.styles';

const DashboardInfoBoxResolver: React.FC = () => {
  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);

  const { stage } = useOrganizationPaymentStageInfo();

  const history = useHistory();
  const onActionClick = async () => {
    history.push(PATHS.PRE_PAYMENT);
  };

  const styles = useStyles();

  const infoBox = useMemo(() => {
    if (!stage) return null;
    switch (stage) {
      case OrganizationPaymentStages.trial: {
        if (!isAccountOwner) return null;
        return <DashboardInfoBoxTrial action={onActionClick} />;
      }
      case OrganizationPaymentStages.retention: {
        return isAccountOwner ? <DashboardInfoBoxRetentionForAccountOwner action={onActionClick} /> : <DashboardInfoBoxRetention />;
      }
      case OrganizationPaymentStages.cardInvalid: {
        if (!isAccountOwner) return null;
        return <DashboardInfoBoxCardInvalid action={onActionClick} />;
      }
      default: {
        return null;
      }
    }
  }, [stage]);

  if (!infoBox) return null;

  return <div className={styles.container}>{infoBox}</div>;
};

export default DashboardInfoBoxResolver;
