import METHOD from 'config/api/_methods';
import createUrlWithParams from 'helpers/createUrlWithParams';

const guidesEndpoints = {
  // GUIDE CREATION
  create: () => ({ url: '/api/v1/guides/', method: METHOD.POST }),
  uploadImage: guide_version_id => ({ url: `api/v1/guides/${guide_version_id}/image/`, method: METHOD.POST }),
  update: guide_version_id => ({ url: `/api/v1/guides/${guide_version_id}/`, method: METHOD.PATCH }),
  getTemplates: () => ({ url: '/api/v1/report-templates/', method: METHOD.GET }),
  setGuidePublishStatus: guide_version_id => ({ url: `api/v1/guides/${guide_version_id}/publish_status/`, method: METHOD.POST }),

  // GUIDES LISTING
  getAll: () => ({ url: '/api/v1/guides/', method: METHOD.GET }),
  get: guide_id => ({ url: `/api/v1/guides/${guide_id}/`, method: METHOD.GET }),
  getIncidentGuides: () => ({ url: '/api/v1/guides/?is_incident=true', method: METHOD.GET }),
  getGuideSchema: id => ({ url: `/api/v1/guides/${id}/guide_schema/`, method: METHOD.GET }),

  // GUIDE PROGRESS
  getProgress: guide_progress_id => ({ url: `/api/v1/guide-progress/${guide_progress_id}/`, method: METHOD.GET }),
  getProgressSteps: guide_progress_id => ({ url: `/api/v1/guide-progress/${guide_progress_id}/steps/`, method: METHOD.GET }),
  getAllProgress: params => ({ url: createUrlWithParams(`/api/v1/guide-progress/`, params), method: METHOD.GET }),
  createProgress: () => ({ url: '/api/v1/guide-progress/', method: METHOD.POST }),
  updateProgress: guide_progress_id => ({ url: `/api/v1/guide-progress/${guide_progress_id}/`, method: METHOD.PUT }),
  deleteProgress: guide_progress_id => ({ url: `/api/v1/guide-progress/${guide_progress_id}/`, method: METHOD.DELETE }),

  // GUIDE USER DATA
  updateAction: () => ({ url: '/api/v1/guide-action-data/', method: METHOD.POST }),
  removeAction: guide_action_id => ({ url: `/api/v1/guide-action-data/${guide_action_id}/`, method: METHOD.DELETE }),
  getStep: (guide_step_id, params = {}) => ({ url: createUrlWithParams(`/api/v1/steps/${guide_step_id}/`, params), method: METHOD.GET }),
  updateStep: () => ({ url: '/api/v1/guide-step-data/', method: METHOD.POST }),
};

export default guidesEndpoints;
