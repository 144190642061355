import { TRANSLATABLE_INCIDENT_PHASES_STRING_KEYS } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import table_messages from 'messages/table_messages';
import prepareDataForFilter from 'services/prepareDataForFilter';

const phase = {
  sectionName: table_messages.phase,
  filters: prepareDataForFilter(TRANSLATABLE_INCIDENT_PHASES_STRING_KEYS, false, true),
  isSingleSelect: false,
};

export default phase;
