import { makeStyles } from '@material-ui/core';

import resolveRiskQuadrantColor from 'services/resolveRiskQuadrantColor/resolveRiskQuadrantColor';

export default makeStyles(theme => ({
  yAxisLabel: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'center',

    padding: theme.spacing(1),
    '@media print': {
      padding: theme.spacing(0),
    },
  },
  xAxisLabel: {
    textAlign: 'center',
    padding: theme.spacing(1, 4),
  },
  matrix: {
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.palette.grey[500]}`,
    '& th, td': {
      border: `1px solid ${theme.palette.grey[500]}`,
    },
    '& th': {
      background: 'white !important',
    },
  },
  matrixHeader: {
    padding: theme.spacing(3, 2),
  },
  matrixHeaderY: {
    textAlign: 'right',
  },
  matrixHeaderX: {
    textAlign: 'center',
  },
  matrixCell: {
    padding: theme.spacing(3, 2),
  },
  matrixCellQuadrant1: {
    background: resolveRiskQuadrantColor(1, theme.palette),
  },
  matrixCellQuadrant2: {
    // @ts-ignore
    background: resolveRiskQuadrantColor(2, theme.palette),
  },
  matrixCellQuadrant3: {
    // @ts-ignore
    background: resolveRiskQuadrantColor(3, theme.palette),
  },
  matrixCellQuadrant4: {
    background: resolveRiskQuadrantColor(4, theme.palette),
  },
  matrixCellBlank: {
    fontSize: 0,
    border: 'none',
  },
}));
