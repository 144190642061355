import convertObjectToFormData from 'helpers/convertObjectToFormData';

import type { AssetDocument, AssetDocumentCreateInput, AssetDocumentDTO } from './assetDocuments.types';

export const parseSingleAssetDocumentForFE = (raw: AssetDocumentDTO): AssetDocument => ({
  ...raw,
  createdAt: new Date(raw.created_at),
  updatedAt: new Date(raw.updated_at),
});

export const parseAllAssetDocumentsForFE = (raw: AssetDocumentDTO[]): AssetDocument[] => raw.map(parseSingleAssetDocumentForFE);

export const parseAssetDocumentToCreate = (formObject: AssetDocumentCreateInput): FormData => convertObjectToFormData(formObject);
