import guide_messages from 'messages/guide_messages';

const TRANSLATABLE_GUIDE_TYPES_FALLBACKS = {
  INCIDENT: guide_messages.incident_guide[1],
  GENERAL: guide_messages.guide[1],
};

const TRANSLATABLE_GUIDE_TYPES = {
  INCIDENT: [guide_messages.incident_guide[0], TRANSLATABLE_GUIDE_TYPES_FALLBACKS.INCIDENT],
  GENERAL: [guide_messages.guide[0], TRANSLATABLE_GUIDE_TYPES_FALLBACKS.GENERAL],
};

export const GUIDE_TYPES_ARRAY = Object.values(TRANSLATABLE_GUIDE_TYPES);

export const GUIDE_TYPES_DICTIONARY = GUIDE_TYPES_ARRAY.map((label, key) => ({ label, key }));

export const guideTypesResolver = isIncident => (isIncident ? TRANSLATABLE_GUIDE_TYPES.INCIDENT : TRANSLATABLE_GUIDE_TYPES.GENERAL);

export default TRANSLATABLE_GUIDE_TYPES;
