import React, { useEffect, useMemo, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { add, isAfter, isEqual, isFuture, isToday, isTomorrow, set } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TimeSlotsVerticalContainer from 'components/_calendar/TimeSlotsVerticalContainer';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import TimeSlot from 'components/TimeSlot/TimeSlot';
import meetingsEndpoints from 'config/api/meetings';
import EVENTS from 'config/events/pubsub';
import removeTimezoneFromTime from 'helpers/removeTimezoneFromTime';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import useSubscription from 'hooks/useSubscription';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';

const todayDate = set(new Date(), { hours: 0, minutes: 0 });
const tomorrowDate = add(set(new Date(), { hours: 23, minutes: 59 }), { days: 1 });
removeTimezoneFromTime(todayDate);
removeTimezoneFromTime(tomorrowDate);

const MAX_VALUE = 7;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}));

const BookMeetingNextSlots = ({ onBook, safetyBookTime, knowledgeArea }) => {
  const { t } = useTranslation();
  const { apiCall } = useApiCall();
  const { loading, setLoaded } = useLoadingState(true);

  const [availableSlots, setAvailableSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);

  const getData = async () => {
    const [{ data: availableSlotsFromAPI }, { data: bookedSlotsFromAPI }] = await Promise.all([
      apiCall(
        meetingsEndpoints.getFreeSlots({
          begin__gte: todayDate.toISOString(),
          end__lte: tomorrowDate.toISOString(),
          knowledge_area_id: knowledgeArea,
        }),
      ),
      apiCall(
        meetingsEndpoints.getMeetingsAsSecurityOfficer({ begin__gte: todayDate.toISOString(), end__lte: tomorrowDate.toISOString() }),
      ),
    ]);
    setAvailableSlots(availableSlotsFromAPI.map(({ begin }) => begin));
    setBookedSlots(bookedSlotsFromAPI.map(({ begin }) => begin));
    setLoaded();
  };

  useEffect(() => {
    getData();
  }, [knowledgeArea]);

  const { today, tomorrow } = useMemo(() => {
    const todayArr = [];
    const tomorrowArr = [];
    if (availableSlots && bookedSlots) {
      availableSlots.forEach(date => {
        const dateObj = new Date(date);
        if (isAfter(add(new Date(), { minutes: safetyBookTime }), dateObj)) return;
        const isBooked = bookedSlots.some(bookedSlot => isEqual(new Date(bookedSlot), dateObj));
        if (isToday(dateObj) && todayArr.length < MAX_VALUE && !isBooked && isFuture(dateObj)) todayArr.push(dateObj);
        if (isTomorrow(dateObj) && tomorrowArr.length < MAX_VALUE && !isBooked) tomorrowArr.push(dateObj);
      });
    }

    return { today: todayArr.sort(), tomorrow: tomorrowArr.sort() };
  }, [availableSlots, bookedSlots, safetyBookTime]);

  useSubscription(EVENTS.MEETINGS_UPDATED, () => getData());

  const styles = useStyles();
  if (availableSlots && !today.length && !tomorrow.length && !loading) return null;
  return (
    <Section gutters title={t(...meeting_messages.next_available_time_title)}>
      {loading ? (
        <Skeleton height='200px' variant='rect' />
      ) : (
        <div className={styles.container}>
          {today.length ? (
            <>
              <MobileGuttersContainer>
                <Typography component='h3' variant='h3'>
                  {t(...general_messages.today)}
                </Typography>
              </MobileGuttersContainer>
              <TimeSlotsVerticalContainer>
                {today.map((date, index) => (
                  <TimeSlot key={index} date={date} isAvailable onClick={onBook} />
                ))}
              </TimeSlotsVerticalContainer>
            </>
          ) : null}
          {tomorrow.length ? (
            <>
              <MobileGuttersContainer>
                <Typography component='h3' variant='h3'>
                  {t(...general_messages.tomorrow)}
                </Typography>
              </MobileGuttersContainer>
              <TimeSlotsVerticalContainer>
                {tomorrow.map((date, index) => (
                  <TimeSlot key={index} date={date} isAvailable onClick={onBook} />
                ))}
              </TimeSlotsVerticalContainer>
            </>
          ) : null}
        </div>
      )}
    </Section>
  );
};

BookMeetingNextSlots.defaultProps = {
  safetyBookTime: null,
  knowledgeArea: null,
};

BookMeetingNextSlots.propTypes = {
  onBook: PropTypes.func.isRequired,
  safetyBookTime: PropTypes.number,
  knowledgeArea: PropTypes.number,
};

export default BookMeetingNextSlots;
