import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  tilesContainer: {
    display: 'grid',
    gridTemplateColumns: '240px 240px',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      gridGap: theme.spacing(1),
    },
  },
  summary: {
    fontFamily: theme.typography.fontFamilyArticle,
    fontSize: '1.2rem',
  },
  header: {
    gridArea: 'header',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  body: {
    gridArea: 'body',
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'contents',
    },
  },
}));
