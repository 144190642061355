import React, { useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import incidentReportsEndpoints from 'config/api/incident_reports';
import { IncidentReportInListDTO } from 'config/api/incident_reports.types';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import { incidentPriorityResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PRIORITIES';
import usePagination from 'hooks/usePagination';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import { useHelpDialogContext } from 'reactContext/HelpDialogContext/HelpDialogContext';
import { Id } from 'types/Id';
import { TableDataMappingRow } from 'types/Table';

type Props = {
  assetId: Id;
};

const RiskAnalysisReportsTable: React.FC<Props> = ({ assetId }) => {
  const { t } = useTranslation();

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
  } = usePagination({
    endpointFunc: incidentReportsEndpoints.getAllAsActiveUser,
    limits: [5, 5, 5],
    isGet: true,
    sort,
    queryParams: { asset: assetId },
  });

  const { showHelpDialog } = useHelpDialogContext();
  const showRiskAnalysisRisksTableIntroductionDialog = () => {
    showHelpDialog({
      title: t(asset_risk_messages.asset_reports_table.introduction_title),
      body: t(asset_risk_messages.asset_reports_table.introduction_body),
    });
  };

  const dataMapping: TableDataMappingRow<IncidentReportInListDTO>[] = useMemo(
    () => [
      { label: t(asset_risk_messages.asset_reports_table.name_column), id: 'name', get: data => data.name, width: '20%' },
      {
        label: t(asset_risk_messages.asset_reports_table.is_gdpr_column),
        id: 'is_gdpr',
        get: data => t(data.is_gdpr ? general_messages.yes : general_messages.no),
        width: '10%',
      },
      {
        label: t(asset_risk_messages.asset_reports_table.is_ongoing_column),
        id: 'is_ongoing',
        get: data => t(data.is_ongoing ? general_messages.yes : general_messages.no),
        width: '10%',
      },
      {
        label: t(asset_risk_messages.asset_reports_table.priority_column),
        id: 'priority',
        get: data => t(incidentPriorityResolver(data.priority)),
        width: '15%',
      },
      {
        label: t(asset_risk_messages.asset_reports_table.phase_column),
        id: 'phase',
        get: data => t(incidentPhaseResolver(data.phase)),
        width: '15%',
      },
      {
        label: t(asset_risk_messages.asset_reports_table.category_column),
        id: 'categorization',
        get: data => data.categorization || t(general_messages.no_data),
        width: '15%',
      },
      {
        label: t(asset_risk_messages.asset_reports_table.incident_date_column),
        id: 'created_at',
        get: data => format(new Date(data.created_at), DATE_FORMAT.defaultDateWithTime),
        width: '15%',
      },
    ],
    [t],
  );

  return (
    <Section
      paddingTop={3}
      showSeparator
      smallPadding
      title={asset_risk_messages.asset_reports_table.title}
      titleLineAddon={[
        <TextButton key='introduction' onClick={showRiskAnalysisRisksTableIntroductionDialog}>
          {t(asset_risk_messages.asset_reports_table.introduction_button)}
        </TextButton>,
      ]}
      titleVariant='h3'
    >
      <Table data={tableData} dataMapping={dataMapping} onSort={onSort} refetching={loading} sort={sort} />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
    </Section>
  );
};

export default RiskAnalysisReportsTable;
