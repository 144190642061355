import React, { ChangeEventHandler, useEffect, useState } from 'react';

import { FormControl, FormControlProps, InputLabel, OutlinedInput } from '@material-ui/core';

import useDebounce from 'hooks/useDebounce';

type Props = {
  label: string;
  action: (param: any) => void;
  formControl?: FormControlProps;
  className?: string;
};

const InputWithDebouncedAction: React.FC<Props> = ({ label, action, className, formControl = {} }) => {
  const [value, setValue] = useState('');
  const updateValue: ChangeEventHandler<HTMLInputElement> = ({ target }) => setValue(target.value);

  const debouncedValue = useDebounce(value, 500);

  const performAction = action;

  useEffect(() => {
    performAction(debouncedValue);
  }, [debouncedValue]);

  return (
    <FormControl className={className} fullWidth variant='outlined' {...formControl}>
      <InputLabel color='secondary' htmlFor='search'>
        {label}
      </InputLabel>
      <OutlinedInput color='secondary' data-testid='debounced-search' id='search' label={label} onChange={updateValue} value={value} />
    </FormControl>
  );
};

export default InputWithDebouncedAction;
