const MUTATION_KEYS = {
  UPDATE_PICTURE: 'update picture',
  DELETE_PICTURE: 'delete picture',
  UPDATE_USER: 'update user',
  CREATE_ORGANIZATION: 'create organization',
  RESEND_ORGANIZATION_ACTIVATION_LINK: 'resend activation link',
  UPDATE_ORGANIZATION: 'update organization',
  UPDATE_WORKPLACE: 'update workplace',
  UPDATE_WORKPLACE_TEMPLATE: 'update workplace template',
  ASSETS_YEAR_PLANNER_CREATE: 'create year planner',
  ASSETS_YEAR_PLANNER_UPDATE: 'update year planner',
};

export default MUTATION_KEYS;
