import { format } from 'date-fns';

import locale from 'config/locale';

/**
 *
 * @param date - date object
 * @param formatString - format
 * @param langCode - lang code
 * @returns {string}
 */
const formatDateWithLocale = (date, formatString, langCode) => {
  if (!date) return '';
  return format(date, formatString, { locale: locale[langCode || 'en'], weekStartsOn: 1 });
};

export default formatDateWithLocale;
