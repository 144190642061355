import { isToday } from 'date-fns';

export const isCurrentSlot = (date: Date): boolean => {
  if (!isToday(date)) return false;

  const now = new Date();
  if (date.getHours() !== now.getHours()) return false;
  return now.getMinutes() >= 30 ? date.getMinutes() >= 30 : date.getMinutes() < 30;
};

export const getMissingMilliseconds = (): number => {
  const seconds = new Date().getSeconds();
  return (60 - seconds) * 1000;
};

export const calculateTimeLinePosition = (): number => {
  let minutes = new Date().getMinutes();
  if (minutes > 30) minutes -= 30;
  return (minutes / 30) * 100;
};
