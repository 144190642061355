import { parseLanguageForFE, parseServiceAreaForFE } from '../../../parsers/generalParser';
import { parseContentItemForFE } from '../selfTests/parsers';

import { Article, ArticleDTO } from './article.types';

// eslint-disable-next-line import/prefer-default-export
export const parseArticleForFE = (raw: ArticleDTO): Article => ({
  ...raw,
  author: {
    id: raw.author.id,
    firstName: raw.author.first_name,
    lastName: raw.author.last_name,
  },
  body: raw.body,
  createdAt: new Date(raw.created_at),
  updatedAt: new Date(raw.updated_at),
  customerLevel: raw.customer_level,
  depthLevel: raw.depth_level,
  estimatedTime: raw.estimated_time,
  friendlyId: raw.friendly_id,
  imageUrl: raw.image_url,
  isSaved: raw.is_saved,
  language: parseLanguageForFE(raw.language),
  metadata: { isSaved: raw?.metadata?.is_saved },
  objectContentType: raw.object_content_type,
  publishStatus: raw.publish_status,
  relatedArticles: parseContentItemForFE(raw.related_articles),
  relatedGuides: parseContentItemForFE(raw.related_guides),
  relatedSelfTests: parseContentItemForFE(raw.related_self_tests),
  serviceArea: parseServiceAreaForFE(raw.service_area),
});
