const report_messages = {
  log_labels: {
    date_and_time: ['report>>log_labels>>date_and_time', 'Date and time'],
    activity: ['report>>log_labels>>activity', 'Activity'],
    contacted: ['report>>log_labels>>contacted', 'Contacted'],
    notes: ['report>>log_labels>>notes', 'Notes'],
  },
  log_table_headers: {
    contacted: ['log_table_headers>>contacted', 'Contacted'],
    date: ['log_table_headers>>date', 'Date'],
    phase: ['log_table_headers>>phase', 'Phase'],
    actions: ['log_table_headers>>actions', 'Actions'],
    notes: ['log_table_headers>>notes', 'Notes'],
  },
  log_details_dialog_title: ['report>>log_details_dialog_title', 'Log details'],
  report_page_title: ['report>>report_page_title', 'In process we trust'],
  incident_kpi: {
    incidents_closed: ['report>>incident_kpi>>incidents_closed', 'Closed incidents'],
    new_incidents_received: ['report>>incident_kpi>>new_incidents_received', 'New received'],
    processing_incidents_gdpr: ['report>>incident_kpi>>processing_incidents_gdpr', 'GDPR incidents'],
    processing_incidents_high_priority: ['report>>incident_kpi>>processing_incidents_high_priority', 'High priority incidents'],
    processing_incidents_highest_priority: ['report>>incident_kpi>>processing_incidents_highest_priority', 'Highest priority incidents'],
    processing_incidents_low_priority: ['report>>incident_kpi>>processing_incidents_low_priority', 'Low priority incidents'],
    processing_incidents_medium_priority: ['report>>incident_kpi>>processing_incidents_medium_priority', 'Medium priority incidents'],
  },
  table_header: {
    name: ['report>>table_header>>name', 'Name'],
    is_gdpr: ['report>>table_header>>is_gdpr', 'GDPR'],
    is_ongoing: ['report>>table_header>>is_ongoing', 'Ongoing'],
    priority: ['report>>table_header>>priority', 'Priority'],
    categorization: ['report>>table_header>>categorization', 'Categorization'],
    created_at: ['report>>table_header>>created_at', 'Created at'],
    created_time: ['report>>table_header>>created_time', 'Created time'],
    last_edited_by: ['report>>table_header>>last_edited_by', 'Last edited by'],
    author: ['report>>table_header>>author', 'Author'],
    phase: ['report>>table_header>>phase', 'Phase'],
  },
  related_assets_table: {
    empty_list: ['report>>related_assets_table>>empty_list', 'No related assets'],
    name_table_header: ['report>>related_assets_table>>name_table_header', 'Asset'],
    description_table_header: ['report>>related_assets_table>>description_table_header', 'Description'],
    owner_table_header: ['report>>related_assets_table>>owner_table_header', 'Asset owner'],
  },
};

export default report_messages;
