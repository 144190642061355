import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from './_methods';

const endpointSecurityOfficer = 'api/v1/security_officer/questions/';
const endpointPSOnline = 'api/v1/pocketsafe_officer/questions/';

const answerEndpoint = 'api/v1/answers/';

const questionsEndpoints = {
  getQuestionsSecurityOfficer: params => ({
    url: createUrlWithParams(endpointSecurityOfficer, params),
    method: METHOD.GET,
  }),

  getQuestionsPSOnline: params => ({
    url: createUrlWithParams(endpointPSOnline, params),
    method: METHOD.GET,
  }),
};

// usage for react-query
export const questionsApi = {
  // SECURITY OFFICER
  postQuestionSecurityOfficer: data => axios.post(endpointSecurityOfficer, data),
  getQuestionsSecurityOfficer: () => axios.get(endpointSecurityOfficer),
  getQuestionSecurityOfficer: id => axios.get(`${endpointSecurityOfficer}${id}`),
  getAnswerSecurityOfficer: id => axios.get(`${endpointSecurityOfficer}${id}/get_answer/`),

  // PS SECURITY ONLINE
  getAnswerPSOnline: id => axios.get(`${endpointPSOnline}${id}/get_answer/`),
  createAnswer: data => axios.post(answerEndpoint, data),
  publishAnswer: ({ id, data }) => axios.post(`${answerEndpoint}${id}/publish_answer/`, data),
  updateAnswer: ({ id, data }) => axios.put(`${answerEndpoint}${id}/`, data),
};

export default questionsEndpoints;
