import React, { useCallback, useMemo, useState } from 'react';

import { Button, IconButton, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import OTPMethodDialog, { DynamicOTPMethodDialogProps } from 'components/_dialogs/OTPMethodDialog/OTPMethodDialog';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import Section from 'components/Section';
import Table from 'components/Table';
import manageTotpDevicesApi from 'config/api/manageTotpDevices/manageTotpDevices';
import { TotpDevice } from 'config/api/manageTotpDevices/manageTotpDevices.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import useTwoFASwitch from 'hooks/useTwoFASwitch/useTwoFASwitch';
import general_messages from 'messages/general_messages';
import otp_messages from 'messages/otp_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';
import { useOTPAuthModalContext } from 'reactContext/OTPAuthModalContext/OTPAuthModalContext';
import updateAuthTokens from 'services/updateAuthTokens/updateAuthTokens';
import { TableDataMappingRow } from 'types/Table';

import ManageOTPSectionButtons from '../ManageOTPSectionButtons/ManageOTPSectionButtons';

import useStyles from './ManageOTPSection.styles';

const ManageOTPSection: React.FC = () => {
  const styles = useStyles();

  // DIALOG
  const [dialogProps, setDialogProps] = useState<DynamicOTPMethodDialogProps | null>(null);
  const isOTPDialogOpen = useMemo(() => !!dialogProps, [dialogProps]);
  const { isTwoFAEnabled, enableTwoFA, refetchTwoFAStatus } = useTwoFASwitch();
  const { enqueueSnackbar } = useSnackbar();
  const { showOTPAuthModal } = useOTPAuthModalContext();
  const { showConfirmationModal, closeConfirmationModal } = useConfirmationModalContext();

  const queryClient = useQueryClient();

  const closeOTPDialog = useCallback(async (withActivation?: boolean) => {
    if (withActivation && !isTwoFAEnabled) await enableTwoFA();
    setDialogProps(null);
  }, []);

  const { t } = useTranslation();
  const { data: availableDevices, refetch } = useQuery(QUERY_KEYS.GET_OTP_METHODS, manageTotpDevicesApi.getAllTotpDevices);

  const deleteMutation = useMutation('delete OTP device', manageTotpDevicesApi.removeTotpDevice, {
    onSuccess: () => {
      refetchTwoFAStatus();
      refetch();
    },
    onError: () => {
      enqueueSnackbar(t(otp_messages.otp_auth_failed), { variant: 'error' });
    },
  });

  const setAsDefaultMutation = useMutation('set OTP device as default', manageTotpDevicesApi.setTotpDeviceAsDefault, {
    onSuccess: () => {
      refetch();
    },
  });

  const onSetAsDefault = async (id: number) => {
    await setAsDefaultMutation.mutateAsync(id);
    enqueueSnackbar(t(otp_messages.set_as_default_success), { variant: 'success' });
  };

  const chooseNextDefaultDevice = async (devicesToChooseFrom: TotpDevice[]): Promise<null | number | 'abort'> => {
    let nextDefault: null | number = null;
    const nextDefaultSetter = (newValue: number) => () => {
      nextDefault = newValue;
      closeConfirmationModal();
    };

    await showConfirmationModal({
      title: t(otp_messages.otp_new_default_dialog.title),
      hideYesButton: true,
      noLabel: t(otp_messages.otp_new_default_dialog.cancel_button_label),
      body: (
        <div className={styles.newDefaultDeviceRoot}>
          <Typography>{t(otp_messages.otp_new_default_dialog.body)}</Typography>
          <div>
            {devicesToChooseFrom?.map((device: TotpDevice) => (
              <div key={device.id} className={styles.newDefaultDeviceRow}>
                <Typography>{device.name}</Typography>
                <Button onClick={nextDefaultSetter(device.id)} size='small' variant='text'>
                  {t(otp_messages.otp_new_default_dialog.select_device_button_label)}
                </Button>
              </div>
            ))}
          </div>
        </div>
      ),
    });

    return nextDefault || 'abort';
  };

  const onDelete = useCallback(
    async (device: TotpDevice) => {
      const getNextDefaultToSet = async (): Promise<null | number | 'abort'> => {
        if (!device.isDefault) return null;
        const devicesToChooseFrom = availableDevices?.filter(({ id, isConfirmed }) => device.id !== id && isConfirmed) || [];
        if (devicesToChooseFrom.length === 0) return null;
        if (devicesToChooseFrom.length === 1) return devicesToChooseFrom[0].id;

        return chooseNextDefaultDevice(devicesToChooseFrom);
      };

      const nextDefaultToSet = await getNextDefaultToSet();
      if (nextDefaultToSet === 'abort') return;
      const otpData = await showOTPAuthModal();

      if (otpData) {
        if (nextDefaultToSet !== null) await onSetAsDefault(nextDefaultToSet);
        const { data: deleteData } = await deleteMutation.mutateAsync({
          deviceId: device.id,
          otpAuthData: { token: otpData.otpToken, deviceId: otpData.deviceId },
        });
        if (deleteData?.access && deleteData?.refresh) updateAuthTokens({ access: deleteData.access, refresh: deleteData.refresh });
        await queryClient.invalidateQueries(QUERY_KEYS.GET_OTP_METHODS);
      }
    },
    [availableDevices],
  );

  const renderContextMenu = useCallback(
    (deviceInfo: TotpDevice) => {
      const elements: { label: string; onClick: () => void }[] = [
        { label: t(general_messages.edit), onClick: () => setDialogProps({ deviceId: deviceInfo.id, isEditMode: true }) },
        { label: t(general_messages.delete), onClick: () => onDelete(deviceInfo) },
      ];

      if (!deviceInfo.isDefault && deviceInfo.isConfirmed) {
        elements.push({ label: t(otp_messages.set_as_default), onClick: () => onSetAsDefault(deviceInfo.id) });
      }

      if (!deviceInfo.isConfirmed) {
        elements.push({ label: t(otp_messages.confirm), onClick: () => setDialogProps({ initialStep: 3, deviceId: deviceInfo.id }) });
      }

      return (
        <ContextMenu elements={elements} id={`assets-table-row-${deviceInfo.id}`}>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ContextMenu>
      );
    },
    [availableDevices, onDelete, onSetAsDefault, setDialogProps],
  );

  const dataMapping: TableDataMappingRow<TotpDevice>[] = useMemo(
    () => [
      {
        label: t(otp_messages.table.description),
        id: 'description',
        blockSorting: true,
        get: ({ name }) => name,
        width: '70%',
      },
      {
        label: t(otp_messages.table.is_confirmed),
        id: 'confirmed',
        blockSorting: true,
        get: ({ isConfirmed }) => (isConfirmed ? t(general_messages.yes) : t(general_messages.no)),
        width: '12%',
      },
      {
        label: t(otp_messages.table.is_default),
        id: 'default',
        blockSorting: true,
        get: ({ isDefault }) => (isDefault ? t(general_messages.yes) : t(general_messages.no)),
        width: '12%',
      },
      {
        id: 'context',
        width: '6%',
        label: '',
        isAddon: true,
        get: deviceInfo => renderContextMenu(deviceInfo) || ' ',
        blockSorting: true,
      },
    ],
    [t, renderContextMenu],
  );

  const manageOTPSectionButtonsProps = useMemo(
    () => ({
      otpDevices: availableDevices || [],
      hasActiveOtpMethods: availableDevices?.some(({ isConfirmed }) => isConfirmed) || false,
    }),
    [availableDevices],
  );

  return (
    <MobileGuttersContainer>
      <Section title={t(otp_messages.section_title)}>
        <ManageOTPSectionButtons
          hasActiveOtpDevice={manageOTPSectionButtonsProps.hasActiveOtpMethods}
          otpDevices={manageOTPSectionButtonsProps.otpDevices}
          setDialogProps={setDialogProps}
        />
        <div className={styles.table}>
          <Typography variant='h3'>{t(otp_messages.authenticators_list_title)}</Typography>
          <Table data={availableDevices} dataMapping={dataMapping} refetching={false} />
        </div>
        {dialogProps && <OTPMethodDialog onClose={closeOTPDialog} open={isOTPDialogOpen} {...dialogProps} />}
      </Section>
    </MobileGuttersContainer>
  );
};

export default ManageOTPSection;
