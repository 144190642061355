import React from 'react';

import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';

import ColoredButton from '../ColoredButton';

import useStyles from './RedirectButton.styles';

type ITranslate = {
  t: TFunction;
};

type Props = {
  pathname: string;
  state?: any;
  message?: ITranslate;
};

const RedirectButton: React.FC<Props> = ({ pathname, state, message }) => {
  const styles = useStyles();
  return (
    <ColoredButton
      className={styles.redirectButton}
      component={Link}
      customColor='secondary'
      disabled={undefined}
      icon={undefined}
      showLoader={undefined}
      textLike={undefined}
      to={{ pathname, state }}
      variant='outlined'
    >
      {message}
    </ColoredButton>
  );
};

export default RedirectButton;
