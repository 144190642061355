import general_messages from 'messages/general_messages';

const USER_STATUSES_FALLBACKS = {
  NOT_VERIFIED: general_messages.user_statuses.not_verified[1],
  ACTIVE: general_messages.user_statuses.active[1],
};

const USER_STATUSES = {
  NOT_VERIFIED: [general_messages.user_statuses.not_verified[0], USER_STATUSES_FALLBACKS.NOT_VERIFIED],
  ACTIVE: [general_messages.user_statuses.active[0], USER_STATUSES_FALLBACKS.ACTIVE],
};

export const USER_STATUSES_ARRAY = Object.values(USER_STATUSES);

export const USER_STATUSES_DICTIONARY = USER_STATUSES_ARRAY.map((label, key) => ({ label, key }));

export default USER_STATUSES;
