export const passwordRules = {
  max: 50,
  min: 10,
};

export const passwordTests = {
  length: value => value.length >= passwordRules.min && value.length <= passwordRules.max,
  hasWhitespace: value => !/\s/g.test(value),
  lowercaseLetter: value => /[a-z]/g.test(value),
  uppercaseLetter: value => /[A-Z]/g.test(value),
  specialCharacter: value => /[`!@#$%^&*()_+\-=]/g.test(value),
  digit: value => /[0-9]/g.test(value),
};

export default { passwordRules, passwordTests };
