import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import CookiesInfo from 'components/CookiesInfo/CookiesInfo.tsx';
import Loader from 'components/Loader';
import hashLinkScroll from 'helpers/hashLinkScroll';
import { useAuthContext } from 'reactContext/AuthContext';
import PATHS from 'router/PATHS';
import accessTokenStorage from 'storages/accessTokenStorage';
import refreshTokenStorage from 'storages/refreshTokenStorage';

const COOKIE_NAME = 'cookie_consent';

const PublicRoute = ({ redirectIfAuthorized, ...rest }) => {
  const authContext = useAuthContext();
  const { state } = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(null);

  const [hideCookiesInfo, setHideCookiesInfo] = useState(Cookies.get(COOKIE_NAME));

  const hideCookies = () => {
    setHideCookiesInfo(true);
    Cookies.set(COOKIE_NAME, true);
  };

  useEffect(() => {
    hashLinkScroll();
  }, []);

  useEffect(() => {
    const initialAccessToken = accessTokenStorage.get();
    const initialRefreshToken = refreshTokenStorage.get();
    setIsAuthorized((initialAccessToken && initialRefreshToken) || authContext.isAuthorized);
  }, [authContext]);

  if (isAuthorized === null) return <Loader />;
  if (isAuthorized && redirectIfAuthorized) return <Redirect to={{ pathname: state?.redirectedFrom || PATHS.ROOT }} />;
  return (
    <>
      <Route {...rest} />
      {!hideCookiesInfo && <CookiesInfo onClose={hideCookies} />}
    </>
  );
};

PublicRoute.propTypes = {
  redirectIfAuthorized: PropTypes.bool.isRequired,
};

export default PublicRoute;
