import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  navigationPanel: {
    display: 'grid',
    gridTemplateColumns: 'auto 36px 36px',
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
  results: {
    display: 'grid',
    gridGap: theme.spacing(1.5),
    marginTop: theme.spacing(2),
  },
  tile: {
    background: theme.palette.secondary[50],
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: theme.typography.fontFamilyBase,
    fontSize: '1rem',
    fontWeight: 500,
  },
  time: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  tileSelected: {
    background: theme.palette.secondary[300],
  },
}));
