import asset_risk_messages from 'messages/asset_risk_messages';

import { RiskAnalysisStatus } from '../api/activeUserRiskAnalyses/activeUserRiskAnalyses.types';

const RISK_ANALYSIS_STATUS_SELECT: { key: RiskAnalysisStatus; label: string[] }[] = [
  { key: 'completed', label: asset_risk_messages.status.completed },
  { key: 'in_progress', label: asset_risk_messages.status.in_progress },
];

export default RISK_ANALYSIS_STATUS_SELECT;
