const self_test_messages = {
  self_test_title: ['self_test>>self_test_title', 'Self Test'],
  self_test_actions: {
    next: ['self_test>>self_test_action>>next', 'next'],
    previous: ['self_test>>self_test_action>>previous', 'previous'],
    finish_test: ['self_test>>self_test_action>>finish_test', 'finish test'],
  },
  recommendations: ['self_test>>self_test_recommendations', 'Recommendations'],
  results: ['self_test>>self_test_results', 'Results'],
  score_message: ['self_test>>self_test_sore_message', 'Score {{currentScore}} out of {{maxScore}}'],
  get_started: ['self_test>>self_test_get_started', 'Get started'],
};

export default self_test_messages;
