import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Route, Redirect } from 'react-router-dom';

import Loader from 'components/Loader';
import authEndpoints from 'config/api/auth';
import organizationsApi from 'config/api/organizations/organizations';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import checkAccess from 'config/permissions/checkAccess';
import checkProtectedFeatureAccess from 'config/permissions/checkProtectedFeatureAccess';
import ProtectedFeatures from 'config/permissions/ProtectedFeatures';
import hashLinkScroll from 'helpers/hashLinkScroll';
import useApiCall from 'hooks/useApiCall';
import useOrganizationPaymentStageInfo from 'hooks/useOrganizationPaymentStageInfo/useOrganizationPaymentStageInfo';
import usePaymentFallbackInfo from 'hooks/usePaymentFalbackInfo/usePaymentFallbackInfo';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import { useAuthContext } from 'reactContext/AuthContext';
import { useCommonDialogsContext, COMMON_DIALOG_TYPES } from 'reactContext/CommonDialogsContext';
import PATHS from 'router/PATHS';
import userDataStorage from 'storages/userDataStorage';

const PrivateRoute = ({ routeConfig, ...rest }) => {
  const { apiCall } = useApiCall();
  const { isAuthorized } = useAuthContext();
  const intervalRef = useRef();
  const userData = userDataStorage.get() || {};
  const { openCommonDialog } = useCommonDialogsContext();

  const { stage } = useOrganizationPaymentStageInfo();

  const { data: organizationData } = useQuery(
    [QUERY_KEYS.GET_ORGANIZATION_INFO, userData.organization],
    () => organizationsApi.getSingleOrganization(userData.organization),
    { enabled: checkProtectedFeatureAccess(ProtectedFeatures.MyOrganizationInfo, { organization: userData.roles }).hasAccess },
  );

  const refreshSession = () => {
    // refresh token on focus
    if (document.hasFocus()) apiCall(authEndpoints.me());
  };

  useEffect(() => {
    hashLinkScroll();
  }, []);

  useEffect(() => {
    window.addEventListener('focus', refreshSession);
    intervalRef.current = setInterval(refreshSession, 2 * 60 * 1000); // 5 mins
    return () => {
      window.removeEventListener('focus', refreshSession);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (organizationData && !organizationData?.onboardingModalShown) openCommonDialog(COMMON_DIALOG_TYPES.ONBOARDING);
  }, [organizationData]);

  usePaymentFallbackInfo();

  if (!isAuthorized) return <Redirect to={{ pathname: PATHS.AUTH, state: { redirectedFrom: window.location.pathname } }} />;
  if (!userData || !stage) return <Loader inner />;

  if (routeConfig) {
    const pocketSafeAccess = checkAccess(userData.ps_roles, routeConfig.allowedForPSRoles);
    const organizationAccess = checkAccess(userData.roles, routeConfig.allowedForOrganizationRoles);
    if (!pocketSafeAccess && !organizationAccess) return <UnauthorizedPage />;

    if (routeConfig.allowedInStages) {
      const paymentStageAccess = checkAccess([stage], routeConfig.allowedInStages);

      if (!paymentStageAccess)
        return (
          <Redirect
            to={{
              pathname: routeConfig.redirectPath || PATHS.ROOT,
              state: { redirectedFrom: window.location.pathname },
            }}
          />
        );
    }
  }

  return <Route {...rest} />;
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;
