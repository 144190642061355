import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(5),
    color: theme.palette.text.primary,
  },
  mainSection: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(3),
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
}));
