import React from 'react';

import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { format, endOfWeek, isSameDay, isValid, isWithinInterval, startOfWeek } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import locale from 'config/locale';
import formatDateWithLocale from 'helpers/formatDateWithLocale';

const useStyles = makeStyles(theme => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary[100]}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.secondary[100],
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}));

const WeekPicker = ({ label, value, onChange }) => {
  const { i18n } = useTranslation();

  const formatWeekSelectLabel = (date, invalidLabel) =>
    date && isValid(date)
      ? `${formatDateWithLocale(startOfWeek(date, { weekStartsOn: 1 }), 'dd MMM', i18n.language)} - ${formatDateWithLocale(
          endOfWeek(date, { weekStartsOn: 1 }),
          'dd MMM',
          i18n.language,
        )}`
      : invalidLabel;

  const styles = useStyles();

  const renderWrappedWeekDay = (date, pickedDate, dayInCurrentMonth) => {
    const start = startOfWeek(pickedDate, { locale: locale[i18n.language] });
    const end = endOfWeek(pickedDate, { locale: locale[i18n.language] });

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    const wrapperClassName = clsx({
      [styles.highlight]: dayIsBetween,
      [styles.firstHighlight]: isFirstDay,
      [styles.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(styles.day, {
      [styles.nonCurrentMonthDay]: !dayInCurrentMonth,
      [styles.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(date, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <DatePicker
      autoOk
      data-testid='weekpicker'
      disableToolbar
      inputVariant='outlined'
      label={label}
      labelFunc={formatWeekSelectLabel}
      onChange={onChange}
      renderDay={renderWrappedWeekDay}
      value={value}
      variant='inline'
    />
  );
};

WeekPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

WeekPicker.defaultProps = {
  label: undefined,
};

export default WeekPicker;
