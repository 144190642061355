enum OrganizationPaymentStages {
  trial = 1, // no credit card supplied but within trial period.
  retention = 2, // no credit card supplied but after ended trial period but within retention period.
  noCard = 3, // no credit card supplied, after retention period.
  cardValid = 4, // credit card supplied.
  cardInvalid = 5, // after validation of credit card, if a customer has a card we can’t get payments from.
}

export const EVERY_ORGANIZATION_PAYMENT_STAGE = Object.values(OrganizationPaymentStages);

export default OrganizationPaymentStages;
