import axios from 'axios';

import createUrlWithParams from 'helpers/createUrlWithParams';

import METHOD from '../_methods';
import { BasePaginatedQueryParams } from '../_types/BasePaginatedQueryParams';

import {
  parseSingleAssetRiskTasksForFE,
  parseAllAssetRiskTasksForFE,
  parseCreateAssetRiskFormInput,
  parseUpdateAssetRiskFormInput,
} from './assetRiskTasks.parsers';
import AssetRiskTasksApi, { AssetRiskEndpoints } from './assetRiskTasks.types';

const endpointsDict: Record<AssetRiskEndpoints, string> = {
  general: 'api/v1/asset_risk_tasks/',
  user: 'api/v1/active_user/asset_risk_tasks/',
  organization: 'api/v1/organization/asset_risk_tasks/',
};

export const legacyAssetRiskTasksApi = {
  getAllAssetRiskTasks:
    (endpoint: AssetRiskEndpoints = 'general') =>
    (params: BasePaginatedQueryParams) => ({
      url: createUrlWithParams(endpointsDict[endpoint], params),
      method: METHOD.GET,
    }),
};

const assetRiskTasksApi: AssetRiskTasksApi = {
  getAllAssetRiskTasks: async (riskAnalysisId, endpoint = 'general') => {
    const { data } = await axios.get(createUrlWithParams(endpointsDict[endpoint], { risk__risk_analysis: riskAnalysisId }));
    return parseAllAssetRiskTasksForFE(data);
  },
  getSingleAssetRiskTasks: async (id, endpoint = 'general') => {
    const { data } = await axios.get(`${endpointsDict[endpoint]}${id}/`);
    return parseSingleAssetRiskTasksForFE(data);
  },
  removeAssetRiskTask: id => axios.delete(`${endpointsDict.general}${id}/`),
  createAssetRiskTask: data => axios.post(endpointsDict.general, parseCreateAssetRiskFormInput(data)),
  updateAssetRiskTask: ({ id, data, endpoint = 'general' }) => {
    const dataToUpdate = parseUpdateAssetRiskFormInput(data);
    if (!dataToUpdate) return Promise.resolve();
    return axios.patch(`${endpointsDict[endpoint]}${id}/`, dataToUpdate);
  },
};

export default assetRiskTasksApi;
