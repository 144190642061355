import React from 'react';

import { IconButton, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import FormTextInput from 'components/FormTextInput';
import general_messages from 'messages/general_messages';

import useStyles from './ContactRowContent.styles';

type ContactRowContentProps = {
  content?: string;
  isEdit: boolean;
  formik: FormikProps<any>;
  id: string;
  label: string;
  isLoading: boolean;
};

const ContactRowContent: React.FC<ContactRowContentProps> = ({ content, isEdit, formik, id, label, isLoading }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = async () => {
    if (content) {
      // eslint-disable-next-line no-undef
      await navigator.clipboard.writeText(content);
      enqueueSnackbar(t(general_messages.content_copied), { variant: 'success' });
    }
  };

  const styles = useStyles();

  if (isEdit)
    return (
      <div className={styles.contactRow}>
        <FormTextInput className={styles.input} formik={formik} id={id} label={label} required size='small' />
      </div>
    );

  return (
    <div className={styles.contactRow}>
      <Typography className={styles.value}>{isLoading ? <Skeleton /> : content}</Typography>
      <IconButton data-testid='copy-to-clipboard' onClick={copyToClipboard} type='button'>
        <CopyIcon />
      </IconButton>
    </div>
  );
};

export default ContactRowContent;
