import React, { useEffect, useState } from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import articleEndpoints from 'config/api/article/article';
import type { Article } from 'config/api/article/article.types';
import hashLinkScroll from 'helpers/hashLinkScroll';
import stripHTMLTags from 'helpers/stripHTMLTags';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import ArticleProvider from 'reactContext/ArticleContext';
import { Id } from 'types/Id';

import { parseArticleForFE } from '../../config/api/article/article.parsers';
import { ContentTypeEnum } from '../../config/api/selfTests/_types';

import ArticleBody from './_components/ArticleBody';
import ArticleHeader from './_components/ArticleHeader';
import ArticleNavigation from './_components/ArticleNavigation';
import ArticleTags from './_components/ArticleTags';
import RelatedArticles from './_components/RelatedArticles';
import RelatedContent from './_components/RelatedContent/RelatedContent';
import useStyles from './SingleArticlePage.styles';

const SingleArticlePage = () => {
  const { id } = useParams() as { id: Id };
  const { apiCall } = useApiCall();
  const { loading, setLoaded } = useLoadingState(true);
  const [articleData, setArticleData] = useState<null | Article>(null);
  const theme = useTheme();
  // @ts-ignore
  const showNavigation = useMediaQuery(`@media screen and (min-width: ${theme.customBreakpoints.articleNavigation}px)`);
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const getArticleData = async () => {
    const { data } = await apiCall(articleEndpoints.get(id));
    setArticleData(parseArticleForFE(data));
    setLoaded();
    hashLinkScroll();
  };

  useEffect(() => {
    getArticleData();
  }, [id]);

  const styles = useStyles();
  if (loading) return <Loader color='primary' inner />;
  if (!articleData) throw Error('Missing article data');
  const { body, teaser, heading, imageUrl, estimatedTime, isSaved, relatedArticles, relatedGuides, relatedSelfTests } = articleData;
  const hasRelatedArticles = () => {
    if (!relatedArticles) return false;
    return Array.isArray(relatedArticles) && relatedArticles.length;
  };

  return (
    <ArticleProvider>
      <Container className={styles.article} disableGutters={!upMd}>
        <MobileGuttersContainer>
          <Typography className={styles.title} component='h1' variant='h1'>
            {stripHTMLTags(heading)}
          </Typography>
        </MobileGuttersContainer>
        <div>
          <div className={styles.contentGrid}>
            <ArticleHeader estimated_time={estimatedTime} id={id} is_saved={isSaved} teaser={teaser} />
          </div>
          <MobileGuttersContainer>
            <div className={styles.contentGrid}>
              {/* @ts-ignore */}
              <ArticleBody bodyStream={body} image_url={imageUrl} />
              {/* @ts-ignore */}
              {showNavigation && body && <ArticleNavigation bodyStream={body} />}
            </div>
          </MobileGuttersContainer>
        </div>
        <MobileGuttersContainer>
          {/* TODO: use in next iteration */}
          {/* <div className={styles.contentGrid}> */}
          {/*  <RateArticle id={id} /> */}
          {/* </div> */}
          <div className={styles.contentGrid}>
            <ArticleTags tags={articleData?.tags} />
          </div>
        </MobileGuttersContainer>
      </Container>
      <RelatedContent content={relatedGuides} type={ContentTypeEnum.GUIDE} />
      {hasRelatedArticles() ? <RelatedArticles articles={relatedArticles} innerClassName={styles.contentGrid} /> : null}
      <RelatedContent content={relatedSelfTests} type={ContentTypeEnum.SELF_TEST} />
    </ArticleProvider>
  );
};

export default SingleArticlePage;
