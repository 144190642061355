import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import SimpleFormLayout from 'components/SimpleFormLayout';
import auth_messages from 'messages/auth_messages';
import PATHS from 'router/PATHS';

const PasswordChanged = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = e => {
    e.preventDefault();
    history.push(PATHS.AUTH);
  };

  return (
    <SimpleFormLayout
      buttonVariant='outlined'
      onSubmit={onSubmit}
      submitLabel={t(...auth_messages.go_to_login)}
      title={t(...auth_messages.password_changed_heading)}
    >
      <Typography component='h3' variant='h3'>
        {t(...auth_messages.password_changed_body)}
      </Typography>
      <Box pt={1}>
        <Typography variant='body1'>{t(...auth_messages.login_after_password_change)}</Typography>
      </Box>
    </SimpleFormLayout>
  );
};

PasswordChanged.propTypes = {};

export default PasswordChanged;
