import { Theme } from '@material-ui/core';

import exhaustiveGuard from 'helpers/exhaustiveGuard/exhaustiveGuard';

const resolveRiskQuadrantColor = (riskQuadrant: 1 | 2 | 3 | 4, palette: Theme['palette']): string => {
  switch (riskQuadrant) {
    case 1:
      // @ts-ignore
      return palette.riskColors[1];
    case 2:
      // @ts-ignore
      return palette.riskColors[2];
    case 3:
      // @ts-ignore
      return palette.riskColors[3];
    case 4:
      // @ts-ignore
      return palette.riskColors[4];
    default:
      return exhaustiveGuard(riskQuadrant);
  }
};

export default resolveRiskQuadrantColor;
