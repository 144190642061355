import CUSTOMER_LEVELS from 'config/translatableConstants/CUSTOMER_LEVELS';
import general_messages from 'messages/general_messages';
import prepareDataForFilter from 'services/prepareDataForFilter';

const customerLevelFilter = {
  sectionName: general_messages.customer_level,
  filters: prepareDataForFilter(CUSTOMER_LEVELS),
};

export default customerLevelFilter;
