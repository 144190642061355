import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { format, isToday } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import locale from 'config/locale';

const MAX_VALUE = 7;

const useStyles = makeStyles(theme => ({
  row: {
    display: 'grid',
    gridTemplateColumns: `repeat(${MAX_VALUE}, 1fr)`,
    gridGap: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
  },
  day: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '1.05rem',
  },
  today: {
    color: theme.palette.primary[300],
  },
}));

const CalendarHeaderDates = ({ days }) => {
  const { i18n } = useTranslation();

  const styles = useStyles();
  return (
    <div className={styles.row}>
      {days.map((day, index) => (
        <div key={index} className={clsx(styles.day, isToday(day) && styles.today)}>
          <div>{format(day, 'eee', { locale: locale[i18n.language || 'en'] })}</div>
          <div>{format(day, 'dd LLL', { locale: locale[i18n.language || 'en'] })}</div>
        </div>
      ))}
    </div>
  );
};

CalendarHeaderDates.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CalendarHeaderDates;
