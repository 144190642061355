import React from 'react';

import { Card } from '@material-ui/core';

import useStyles from './BorderedCard.styles';

type Props = {
  children: React.ReactElement;
  onClick?: () => void;
};

const BorderedCard: React.FC<Props> = ({ children, onClick }) => {
  const styles = useStyles({ clickable: !!onClick });
  return (
    <Card className={styles.container} elevation={0} onClick={onClick}>
      {children}
    </Card>
  );
};

export default BorderedCard;
