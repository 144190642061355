import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  grid: {
    display: 'grid',
    maxWidth: '80ch',
    gridGap: theme.spacing(2),
    padding: theme.spacing(5, 0, 3),
  },
  divider: {
    background: theme.palette.primary[100],
    width: '100%',
    height: '3px',
  },
  bold: {
    fontWeight: 'bold',
  },
  incidentReportHeaderWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridGap: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    alignItems: 'flex-start',
  },
  incidentReportHeaderTitle: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  autocompleteLabel: {
    display: 'grid',
    gridTemplateColumns: '80px 60px 1fr',
    gridGap: theme.spacing(1),
  },
  content: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  linkContainer: {},
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));
