import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  link: {
    display: 'block',
    fontFamily: theme.typography.fontFamilyArticle,
    color: theme.palette.text.primary,
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
  },
}));
