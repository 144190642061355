const emergency_messages = {
  dialog: {
    title: ['emergency>>dialog>>title', 'Emergency support'],
    information_section_title: ['emergency>>dialog>>information_label', 'Information'],
    information: [
      'emergency>>dialog>>information',
      'If you have an urgent emergency you need help with from PocketSafe, please fill out this form and send it to us. Our operators will contact you for starting to handle the problem. Please observe that depending on your problem and your organizations agreement with PocketSafe this might be billed to your organization',
    ],
    contact_section_title: ['emergency>>dialog>>contact_section_title', 'Main contact person'],
    contact_person_first_name_label: ['emergency>>dialog>>contact_person_first_name_label', 'First name'],
    contact_person_last_name_label: ['emergency>>dialog>>contact_person_last_name_label', 'Last name'],
    contact_person_email_label: ['emergency>>dialog>>contact_person_email_label', 'Email'],
    contact_details_phone_label: ['emergency>>dialog>>contact_details_phone_label', 'Phone number'],
    problem_description_section_title: ['emergency>>dialog>>problem_description_section_title', 'Short description of situation'],
    problem_description_label: ['emergency>>dialog>>problem_description_label', 'Description'],
    send_button_label: ['emergency>>dialog>>send_button_label', 'Report emergency'],
    success_message: ['emergency>>dialog>>success_message', 'Emergency has been reported'],
  },
  table: {
    title: ['emergency>>table>>table_title', 'Emergency requests'],
    description_label: ['emergency>>table>>description_label', 'Problem description'],
    contact_person_label: ['emergency>>table>>contact_person_label', 'Contact person'],
    contact_details_label: ['emergency>>table>>contact_details_label', 'Contact details'],
    reported_date_label: ['emergency>>table>>reported_date_label', 'Reported date'],
    reported_by_label: ['emergency>>table>>reported_by_label', 'Reported by'],
  },
};

export default emergency_messages;
