import React, { useEffect, useMemo } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import activeUserRiskAnalysesApi from 'config/api/activeUserRiskAnalyses/activeUserRiskAnalyses';
import assetRisksApi from 'config/api/assetRisks/assetRisks';
import assetRiskTasksApi from 'config/api/assetRiskTasks/assetRiskTasks';
import { incidentReportsApi } from 'config/api/incident_reports';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import DATE_FORMAT from 'config/constants/DATE_FORMAT';
import EVENTS from 'config/events/pubsub';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import { incidentPriorityResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PRIORITIES';
import useSubscription from 'hooks/useSubscription';
import asset_risk_messages from 'messages/asset_risk_messages';
import general_messages from 'messages/general_messages';
import RiskMatrix from 'pages/SingleRiskAnalysisPage/_components/RiskAnalysisMatrix/_components/RiskMatrix/RiskMatrix';
import RiskQuadrantCell from 'pages/SingleRiskAnalysisPage/_components/RiskQuadrantCell/RiskQuadrantCell';

import useStyles from '../PrintTemplate.styles';

type Props = {
  onReady: () => void;
  riskAnalysisId: string;
};

const RiskAnalysisPrintTemplate: React.FC<Props> = ({ onReady, riskAnalysisId }) => {
  const { t } = useTranslation();

  const { data: baseRiskData, isLoading: baseRiskDataLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_SINGLE_RISK_ANALYSIS, riskAnalysisId],
    queryFn: () => activeUserRiskAnalysesApi.getSingleActiveUserRiskAnalysis(riskAnalysisId),
    retry: 0,
  });

  const { data: matrixData, isLoading: matrixDataLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_RISK_ANALYSIS_MATRIX, riskAnalysisId],
    queryFn: () => activeUserRiskAnalysesApi.getRiskMatrixData(riskAnalysisId),
    enabled: !!riskAnalysisId,
  });

  const {
    data: assetRisksData,
    isLoading: assetRisksDataLoading,
    refetch: assetRisksDataRefetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_ASSET_RISKS, riskAnalysisId],
    queryFn: () => assetRisksApi.getAllAssetRisks({ limit: 100, offset: 0, risk_analysis: riskAnalysisId }),
  });
  useSubscription(EVENTS.RISK_ANALYSIS_RISKS_UPDATED, assetRisksDataRefetch);

  const {
    data: incidentsData,
    isLoading: incidentsDataLoading,
    refetch: incidentsDataRefetch,
  } = useQuery(
    [QUERY_KEYS.GET_ALL_INCIDENT_FOR_USER, baseRiskData?.asset.id],
    () => incidentReportsApi.getAllAsActiveUser({ asset: baseRiskData?.asset.id! }),
    { enabled: !!baseRiskData },
  );
  useSubscription(EVENTS.INCIDENTS_FOR_USER_UPDATED, incidentsDataRefetch);

  const {
    data: riskTaskData,
    isLoading: riskTaskDataLoading,
    refetch: riskTaskDataRefetch,
  } = useQuery([QUERY_KEYS.GET_ALL_ASSET_RISK_TASKS, riskAnalysisId], () => assetRiskTasksApi.getAllAssetRiskTasks(riskAnalysisId), {
    enabled: !!riskAnalysisId,
  });
  useSubscription(EVENTS.RISK_ANALYSIS_TASK_UPDATED, riskTaskDataRefetch);

  const isReady = useMemo(
    () => !baseRiskDataLoading && !matrixDataLoading && !assetRisksDataLoading && !incidentsDataLoading && !riskTaskDataLoading,
    [baseRiskDataLoading, matrixDataLoading, assetRisksDataLoading, incidentsData, riskTaskDataLoading],
  );

  useEffect(() => {
    if (isReady) onReady();
  }, [isReady]);

  const styles = useStyles();

  if (!isReady || !baseRiskData || !matrixData || !assetRisksData || !incidentsData || !riskTaskData) return <div>Data not loaded...</div>;

  return (
    <main className={styles.root}>
      <h2>{t(asset_risk_messages.details_page.subtitle, { assetName: baseRiskData?.asset.name })}</h2>
      <section>
        <h3>{t(asset_risk_messages.details_page.metadata_title)}</h3>
        <dl>
          <div>
            <dt>{t(asset_risk_messages.details_page.creation_date_label)}</dt>
            <dd>{format(baseRiskData.createdAt, DATE_FORMAT.defaultDateWithTime)}</dd>
          </div>
          <div>
            <dt>{t(asset_risk_messages.details_page.last_update_label)}</dt>
            <dd>{format(baseRiskData.updatedAt, DATE_FORMAT.defaultDateWithTime)}</dd>
          </div>
          <div>
            <dt>{t(asset_risk_messages.details_page.date_of_analysis_label)}</dt>
            <dd>{format(baseRiskData.date, DATE_FORMAT.defaultDate)}</dd>
          </div>
        </dl>
        <dl>
          <div>
            <dt>{t(asset_risk_messages.details_page.status_label)}</dt>
            <dd>{t(asset_risk_messages.status[baseRiskData.status])}</dd>
          </div>
          <div>
            <dt>{t(asset_risk_messages.details_page.participants_label)}</dt>
            <dd>{baseRiskData.participants}</dd>
          </div>
        </dl>
      </section>
      <section>
        <h3>{t(asset_risk_messages.details_page.matrix_title)}</h3>
        <RiskMatrix matrixData={matrixData} />
      </section>
      <div className={styles.pageBreak} />
      <section>
        <h3>{t(asset_risk_messages.risk_table.title)}</h3>
        {assetRisksData.length ? (
          <table>
            <thead>
              <tr>
                <th>{t(asset_risk_messages.risk_table.risk_label)}</th>
                <th>{t(asset_risk_messages.risk_table.consequence_label)}</th>
                <th>{t(asset_risk_messages.risk_table.priority_label)}</th>
                <th>{t(asset_risk_messages.risk_table.decision_label)}</th>
                <th>{t(asset_risk_messages.risk_table.affects_ppl_label)}</th>
                <th>{t(asset_risk_messages.risk_table.tasks_label)}</th>
              </tr>
            </thead>
            <tbody>
              {assetRisksData.map(
                ({ id, name, consequence, riskQuadrant, primaryConsequence, probability, decision, affectIndividuals, riskTasksCount }) => (
                  <tr key={id}>
                    <td>{name}</td>
                    <td>{primaryConsequence}</td>
                    <td>
                      <RiskQuadrantCell consequence={consequence} probability={probability} riskQuadrant={riskQuadrant} shrink />
                    </td>
                    <td>{t(asset_risk_messages.risk_decision[decision])}</td>
                    <td>{affectIndividuals ? t(general_messages.yes) : t(general_messages.no)}</td>
                    <td>{riskTasksCount}</td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        ) : (
          <p>{t(general_messages.no_data)}</p>
        )}
      </section>
      <section>
        <h3>{t(asset_risk_messages.risk_task_table.title)}</h3>
        {riskTaskData.length ? (
          <table>
            <thead>
              <tr>
                <th>{t(asset_risk_messages.risk_task_table.risk_name_column)}</th>
                <th>{t(asset_risk_messages.risk_task_table.risk_quadrant_column)}</th>
                <th>{t(asset_risk_messages.risk_task_table.planned_action_column)}</th>
                <th>{t(asset_risk_messages.risk_task_table.assigned_to_column)}</th>
                <th>{t(asset_risk_messages.risk_task_table.due_date_column)}</th>
                <th>{t(asset_risk_messages.risk_task_table.status_column)}</th>
                <th>{t(asset_risk_messages.risk_task_table.last_status_date_column)}</th>
              </tr>
            </thead>
            <tbody>
              {riskTaskData.map(({ id, lastStatusDate, status, dueDate, plannedAction, risk, assignedTo }) => (
                <tr key={id}>
                  <td>{risk.name}</td>
                  <td>
                    <RiskQuadrantCell
                      consequence={risk.consequence}
                      probability={risk.probability}
                      riskQuadrant={risk.riskQuadrant}
                      shrink
                    />
                  </td>
                  <td>{plannedAction}</td>
                  <td>
                    {assignedTo.profile.fullName}
                    <br />
                    {assignedTo.profile.email}
                  </td>
                  <td>{format(new Date(dueDate), DATE_FORMAT.defaultDate)}</td>
                  <td>{t(asset_risk_messages.risk_task_status[status])}</td>
                  <td>{format(new Date(lastStatusDate), DATE_FORMAT.defaultDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t(general_messages.no_data)}</p>
        )}
      </section>
      <section>
        <h3>{t(asset_risk_messages.asset_reports_table.title)}</h3>
        {incidentsData.length ? (
          <table>
            <thead>
              <tr>
                <th>{t(asset_risk_messages.asset_reports_table.name_column)}</th>
                <th>{t(asset_risk_messages.asset_reports_table.is_gdpr_column)}</th>
                <th>{t(asset_risk_messages.asset_reports_table.is_ongoing_column)}</th>
                <th>{t(asset_risk_messages.asset_reports_table.priority_column)}</th>
                <th>{t(asset_risk_messages.asset_reports_table.phase_column)}</th>
                <th>{t(asset_risk_messages.asset_reports_table.category_column)}</th>
                <th>{t(asset_risk_messages.asset_reports_table.incident_date_column)}</th>
              </tr>
            </thead>
            <tbody>
              {incidentsData.map(({ id, name, is_gdpr, is_ongoing, phase, priority, categorization, created_at }) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{t(is_gdpr ? general_messages.yes : general_messages.no)}</td>
                  <td>{t(is_ongoing ? general_messages.yes : general_messages.no)}</td>
                  <td>{t(incidentPriorityResolver(priority))}</td>
                  <td>{t(incidentPhaseResolver(phase))}</td>
                  <td>{categorization || t(general_messages.no_data)}</td>
                  <td>{format(new Date(created_at), DATE_FORMAT.defaultDateWithTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t(general_messages.no_data)}</p>
        )}
      </section>
    </main>
  );
};

export default RiskAnalysisPrintTemplate;
