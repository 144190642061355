import React, { useCallback, useMemo } from 'react';

import { Button, ButtonBase, Switch, Tooltip, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { DynamicOTPMethodDialogProps } from 'components/_dialogs/OTPMethodDialog/OTPMethodDialog';
import ColoredButton from 'components/ColoredButton';
import { TotpDevice } from 'config/api/manageTotpDevices/manageTotpDevices.types';
import exhaustiveGuard from 'helpers/exhaustiveGuard/exhaustiveGuard';
import useTwoFASwitch from 'hooks/useTwoFASwitch/useTwoFASwitch';
import otp_messages from 'messages/otp_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext/ConfirmationModalContext';

import useStyles from './ManageOTPSectionButtons.styles';

const userTwoFactorAuthStates = [
  'INACTIVE_WITHOUT_OTP', // 2fa disabled and no OTP methods
  'INACTIVE_WITH_OTP', // 2fa disabled but has OTP methods
  'INACTIVE_WITH_NOT_CONFIRMED_OTP', // 2fa disabled, has OTP methods but any of them is not confirmed
  'ACTIVE_WITH_OTP', // 2fa enabled, has OTP methods
] as const;
// Active without OTP or with not confirmed OTP should not be possible
type UserTwoFactorAuthState = typeof userTwoFactorAuthStates[number];

type Props = {
  hasActiveOtpDevice: boolean;
  otpDevices: TotpDevice[];
  setDialogProps: (dialogProps: DynamicOTPMethodDialogProps) => void;
};

const ManageOTPSectionButtons: React.FC<Props> = ({ otpDevices, hasActiveOtpDevice, setDialogProps }) => {
  const { t } = useTranslation();
  const { showConfirmationModal, closeConfirmationModal } = useConfirmationModalContext();
  const { isTwoFAEnabled, disableTwoFA, enableTwoFA } = useTwoFASwitch();

  const styles = useStyles();

  const userState = useMemo<UserTwoFactorAuthState>(() => {
    if (isTwoFAEnabled) return 'ACTIVE_WITH_OTP';
    if (otpDevices.length === 0) return 'INACTIVE_WITHOUT_OTP';
    if (!hasActiveOtpDevice) return 'INACTIVE_WITH_NOT_CONFIRMED_OTP';
    return 'INACTIVE_WITH_OTP';
  }, [isTwoFAEnabled, otpDevices, hasActiveOtpDevice]);

  const openOTPDialogNew = useCallback(() => setDialogProps({ initialStep: 1 }), []);

  const showAddOtpDialog = async () => {
    const shouldShowDialog = await showConfirmationModal({
      title: t(otp_messages.add_info_otp_device_dialog.title),
      body: t(otp_messages.add_info_otp_device_dialog.body),
    });

    if (shouldShowDialog) openOTPDialogNew();
  };

  const showAddOrConfirmOtpDialog = async () => {
    const confirmFactory = (deviceId: number) => () => {
      closeConfirmationModal();
      setDialogProps({ initialStep: 3, deviceId });
    };

    const shouldShowDialog = await showConfirmationModal({
      title: t(otp_messages.add_or_confirm_info_otp_device_dialog.title),
      yesLabel: t(otp_messages.add_or_confirm_info_otp_device_dialog.yesLabel),
      noLabel: t(otp_messages.add_or_confirm_info_otp_device_dialog.noLabel),
      body: (
        <div className={styles.addOrConfirmDialogRoot}>
          <Typography>{t(otp_messages.add_or_confirm_info_otp_device_dialog.body)}</Typography>
          <div>
            {otpDevices.map(device => (
              <div key={device.id} className={styles.addOrConfirmDialogRow}>
                <Typography>{device.name}</Typography>
                <Button onClick={confirmFactory(device.id)} size='small' variant='text'>
                  {t(otp_messages.add_or_confirm_info_otp_device_dialog.confirm_button_label)}
                </Button>
              </div>
            ))}
          </div>
        </div>
      ),
    });

    if (shouldShowDialog) openOTPDialogNew();
  };

  const resolveSwitchAction = useCallback(() => {
    switch (userState) {
      case 'INACTIVE_WITHOUT_OTP': {
        showAddOtpDialog();
        break;
      }
      case 'INACTIVE_WITH_NOT_CONFIRMED_OTP': {
        showAddOrConfirmOtpDialog();
        break;
      }
      case 'INACTIVE_WITH_OTP':
        enableTwoFA();
        break;
      case 'ACTIVE_WITH_OTP':
        disableTwoFA();
        break;
      default:
        exhaustiveGuard(userState);
    }
  }, [userState]);

  const isAddButtonDisabled = useMemo(() => {
    const statesForEnabled: UserTwoFactorAuthState[] = ['ACTIVE_WITH_OTP'];
    return !statesForEnabled.includes(userState);
  }, [userState]);

  return (
    <div className={styles.root}>
      <ButtonBase className={clsx(styles.switchButton, isTwoFAEnabled && styles.switchButtonActive)} onClick={resolveSwitchAction}>
        <Switch checked={!!isTwoFAEnabled} onChange={() => null} size='small' value='isTwoFAEnabled' />
        <Typography>{t(otp_messages.switch_button)}</Typography>
        <Tooltip title={t(otp_messages.switch_button_info)}>
          <InfoOutlinedIcon className={styles.icon} />
        </Tooltip>
      </ButtonBase>
      <Tooltip title={isAddButtonDisabled ? t(otp_messages.add_button_disabled_info) : ''}>
        <span>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={isAddButtonDisabled} onClick={openOTPDialogNew} variant='outlined'>
            {t(otp_messages.add_button)}
          </ColoredButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default ManageOTPSectionButtons;
