import React from 'react';

import { Tooltip } from '@material-ui/core';

import useStyles from './TrimHTMLText.styles';

type Props = {
  children: React.ReactNode | React.ReactNode[] | string;
  dontShowTooltip?: boolean;
  linesToShow: number;
};

const TrimHTMLText: React.FC<Props> = ({ children, linesToShow, dontShowTooltip }) => {
  const styles = useStyles({ linesToShow });
  const ref = React.useRef<HTMLDivElement>(null);

  if (!dontShowTooltip && children) {
    const isTextClamped = !!(ref?.current && ref.current.scrollHeight > ref.current.clientHeight);

    return (
      <Tooltip classes={{ tooltip: styles.tooltip }} enterDelay={500} title={isTextClamped ? children : ''}>
        <div ref={ref} className={styles.root}>
          {children}
        </div>
      </Tooltip>
    );
  }

  return <div className={styles.root}>{children}</div>;
};

export default TrimHTMLText;
