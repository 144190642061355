import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import ColoredButton from 'components/ColoredButton';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import FormTitle from 'components/FormTitle';
import SimpleFormLayout from 'components/SimpleFormLayout';
import commonEndpoints from 'config/api/common';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import organizationsApi from 'config/api/organizations/organizations';
import { OrganizationUpdateFormInput } from 'config/api/organizations/organizations.types';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { TRANSLATABLE_EMPLOYEES_NUMBER_DICTIONARY } from 'config/dictionaries/EMPLOYES_NUMBER_SELECT';
import { TRANSLATABLE_ORGANIZATION_COUNTRIES_DICTIONARY } from 'config/translatableConstants/TRANSLATABLE_ORGANIZATION_COUNTRIES';
import emailValidator from 'config/validators/emailValidator';
import useApiCall from 'hooks/useApiCall';
import useQueryParams from 'hooks/useQueryParams';
import auth_messages from 'messages/auth_messages';
import general_messages from 'messages/general_messages';
import label_messages from 'messages/label_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import validateCampaignCode from 'services/validateCampaignCode/validateCampaignCode';
import userDataStorage from 'storages/userDataStorage';

type Props = {
  initialData: OrganizationUpdateFormInput;
  onClose: () => void;
};

const UpdateOrganizationForm: React.FC<Props> = ({ initialData, onClose }) => {
  const { t } = useTranslation();
  const { apiCall, sending } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const { queryParams } = useQueryParams() as unknown as { queryParams: Record<string, string> };
  const history = useHistory();
  // @ts-ignore
  const userData = userDataStorage.get() || {};

  const emailInput = useRef<HTMLElement>(null);
  const campaignCodeInput = useRef<HTMLElement>(null);

  const queryClient = useQueryClient();

  const updateOrganizationMutation = useMutation(MUTATION_KEYS.UPDATE_ORGANIZATION, organizationsApi.updateOrganization, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.GET_ORGANIZATION_INFO, userData.organization]);
      queryClient.invalidateQueries([QUERY_KEYS.PRICING_INFO, userData.organization]);
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
      onClose();
    },
  });

  const [businessesTypes, setBusinessesTypes] = useState<{ key: string; label: string[] }[]>([]);
  const getDictionaries = async () => {
    const { data } = (await apiCall(commonEndpoints.getBusinessTypes())) as { data: { value: string; label: string }[] };
    setBusinessesTypes(data.map(({ value, label }) => ({ key: value, label: [`general>>business_types>>${value}`, label] })));
  };

  useEffect(() => {
    getDictionaries();
  }, []);

  const onSubmit = (data: OrganizationUpdateFormInput) => {
    validateCampaignCode(data.campaignCode, {
      onSuccess: () => {
        updateOrganizationMutation.mutate({ id: userData.organization, inputData: data });
      },
      onError: message => {
        campaignCodeInput.current?.focus();
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        formik.setFieldError('campaignCode', t(message));
      },
    });
  };

  const getCampaignCodeFromUrl = useCallback(
    () => queryParams.campaignCode || queryParams.CampaignCode || queryParams.campaign_code || queryParams.campaigncode,
    [queryParams],
  );

  const formik = useFormik<OrganizationUpdateFormInput>({
    initialValues: {
      campaignCode: initialData.campaignCode || getCampaignCodeFromUrl() || '',
      country: initialData.country || '',
      email: initialData.email || '',
      businessType: initialData.businessType || '',
      firstName: initialData.firstName || '',
      lastName: initialData.lastName || '',
      organizationSize: initialData.organizationSize || '',
      organizationNumber: initialData.organizationNumber || '',
      name: initialData.name || '',
    },
    onSubmit,
    validationSchema: yup.object({
      firstName: yup.string().required(t(validation_messages.required)),
      lastName: yup.string().required(t(validation_messages.required)),
      email: emailValidator({
        required: t(validation_messages.required),
        valid: t(validation_messages.email_valid),
      }),
      country: yup.string().required(t(validation_messages.required)),
    }),
  });

  useEffect(() => {
    // validate on autofill
    formik.validateForm();
  }, []);

  const goToPayment = async () => {
    history.push(PATHS.PRE_PAYMENT);
  };

  return (
    <SimpleFormLayout
      additionalButtons={() => (
        // @ts-ignore
        <ColoredButton customColor='secondary' onClick={goToPayment}>
          {t(auth_messages.payment.update_payment_method)}
        </ColoredButton>
      )}
      disabled={!formik.isValid}
      onSubmit={formik.handleSubmit}
      sending={sending}
      submitLabel={t(auth_messages.update_organization_info)}
    >
      <FormTitle>{t(auth_messages.register_sections.company)}</FormTitle>
      <FormTextInput disabled formik={formik} id='name' label={t(label_messages.organization_name)} required />
      <FormTextInput formik={formik} id='organizationNumber' label={t(label_messages.organization_number)} required />
      <FormSelect
        formik={formik}
        id='country'
        label={t(label_messages.country)}
        options={TRANSLATABLE_ORGANIZATION_COUNTRIES_DICTIONARY}
        required
      />
      <FormSelect formik={formik} id='businessType' label={t(label_messages.business_type)} options={businessesTypes} required />
      <FormSelect
        formik={formik}
        id='organizationSize'
        label={t(label_messages.employees_number)}
        options={TRANSLATABLE_EMPLOYEES_NUMBER_DICTIONARY}
        required
      />

      <FormTitle>{t(auth_messages.register_sections.contact_person)}</FormTitle>
      <FormTextInput formik={formik} id='firstName' label={t(label_messages.first_name)} required />
      <FormTextInput formik={formik} id='lastName' label={t(label_messages.last_name)} required />
      <FormTextInput formik={formik} id='email' inputRef={emailInput} label={t(label_messages.email)} required type='email' />
      <FormTitle>{t(auth_messages.campaign_code)}</FormTitle>
      <FormTextInput formik={formik} id='campaignCode' inputRef={campaignCodeInput} label={t(label_messages.campaign_code)} />
    </SimpleFormLayout>
  );
};

export default UpdateOrganizationForm;
