import React, { createContext, useContext, useState } from 'react';

import { dropRightWhile, dropRight } from 'lodash';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import AskQuestionDialog from 'components/_dialogs/AskQuestionDialog';
import AskQuestionSuccessDialog from 'components/_dialogs/AskQuestionSuccessDialog';
import GuideMeDialog from 'components/_dialogs/GuideMeDialog';
import GuideSummaryDialog from 'components/_dialogs/GuideSummaryDialog';
import QuestionEditDialog from 'components/_dialogs/QuestionEditDialog';
import QuestionOverviewDialog from 'components/_dialogs/QuestionOverviewDialog';
import instances from 'config/constants/instances';

import EmergencyDialog from '../../components/_dialogs/EmergencyDialog/EmergencyDialog';
import OnboardingDialog from '../../components/_dialogs/OnboardingDialog/OnboardingDialog';

const CommonDialogsContext = createContext(null);

const { Provider } = CommonDialogsContext;
const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;

export const COMMON_DIALOG_TYPES = {
  GUIDE_SUMMARY: 'guide-summary-dialog',
  ASK_QUESTION: 'AskQuestion', // don't touch it - used as enum in CustomPermissionsKey
  ASK_QUESTION_SUCCESS: 'ask-question-success-dialog',
  QUESTION_OVERVIEW: 'question-overview-dialog',
  QUESTION_EDIT: 'question-edit-dialog',
  INCIDENT_GUIDE: 'incident-guide-dialog',
  ONBOARDING: 'onboarding-dialog',
  EMERGENCY: 'emergency-dialog',
};

const removeOrphanDialogs = (dialogsStack, { type, props, config }) => {
  const topDialog = dialogsStack[dialogsStack.length - 1];
  const newDialog = { type, props, ...config };
  if (!topDialog) return [newDialog];
  const dialogsStackWithoutOrphans = dropRightWhile([...dialogsStack], ({ parent }) => !parent);
  return [...dialogsStackWithoutOrphans, newDialog];
};

const CommonDialogsProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState([]);

  const openCommonDialog = (type, props = {}, config = {}) => {
    if (!type || !Object.values(COMMON_DIALOG_TYPES).includes(type)) {
      // eslint-disable-next-line no-console
      if (!isProduction) console.error('Missing dialog type');
    } else {
      setDialogs(dialogStack => removeOrphanDialogs(dialogStack, { type, props, config }));
    }
  };

  const closeTopDialog = () => setDialogs(dialogsStack => dropRight(dialogsStack));

  const resolveDialog = () => {
    const dialogsToRender = [];
    const commonProps = { onClose: closeTopDialog, open: true, contextApi: { openCommonDialog, closeTopDialog } };
    dialogs.forEach(({ type, props }) => {
      // eslint-disable-next-line default-case
      switch (type) {
        case COMMON_DIALOG_TYPES.GUIDE_SUMMARY:
          dialogsToRender.push(<GuideSummaryDialog key={COMMON_DIALOG_TYPES.GUIDE_SUMMARY} {...commonProps} {...props} />);
          break;
        case COMMON_DIALOG_TYPES.ASK_QUESTION:
          dialogsToRender.push(<AskQuestionDialog key={COMMON_DIALOG_TYPES.ASK_QUESTION} {...commonProps} {...props} />);
          break;
        case COMMON_DIALOG_TYPES.ASK_QUESTION_SUCCESS:
          dialogsToRender.push(<AskQuestionSuccessDialog key={COMMON_DIALOG_TYPES.ASK_QUESTION_SUCCESS} {...commonProps} {...props} />);
          break;
        case COMMON_DIALOG_TYPES.QUESTION_OVERVIEW:
          dialogsToRender.push(<QuestionOverviewDialog key={COMMON_DIALOG_TYPES.QUESTION_OVERVIEW} {...commonProps} {...props} />);
          break;
        case COMMON_DIALOG_TYPES.QUESTION_EDIT:
          dialogsToRender.push(<QuestionEditDialog key={COMMON_DIALOG_TYPES.QUESTION_EDIT} {...commonProps} {...props} />);
          break;
        case COMMON_DIALOG_TYPES.INCIDENT_GUIDE:
          dialogsToRender.push(<GuideMeDialog key={COMMON_DIALOG_TYPES.INCIDENT_GUIDE} {...commonProps} {...props} />);
          break;
        case COMMON_DIALOG_TYPES.ONBOARDING:
          dialogsToRender.push(<OnboardingDialog key={COMMON_DIALOG_TYPES.ONBOARDING} {...commonProps} {...props} />);
          break;
        case COMMON_DIALOG_TYPES.EMERGENCY:
          dialogsToRender.push(<EmergencyDialog key={COMMON_DIALOG_TYPES.EMERGENCY} {...commonProps} {...props} />);
          break;
      }
    });
    return dialogsToRender;
  };

  return (
    <Provider value={{ openCommonDialog, closeTopDialog }}>
      {children}
      {dialogs.length ? resolveDialog() : null}
    </Provider>
  );
};

CommonDialogsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCommonDialogsContext = () => useContext(CommonDialogsContext);

export default CommonDialogsProvider;
